import React, { Fragment } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import {
    useGetEmployeeProfileImageByUUID,
    useEmployeeProfileImageUpdateFn,
} from '../../../actions/employee-profile-image';
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { extractFileName } from '../../../utils/ComponentUtilse';
import profileImg from "../../../images/avatar/user2.png";
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import LoadingOverlay from 'react-loading-overlay';



function ProfileImage(props) {

    const client = useQueryClient();

    const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const fileInputRef = useRef(null);

    const [employeeImageData, setEmployeeImageData] = useState({});

    const [file, setFile] = useState();

    // [currentAttachmentData, setCurrentAttachmentData] = useState(null);

    const {
        data: getEmployeeImageUUID,
        isLoading: loadingGetEmployeeImageUUIDFn,
        refetch: refetchEmployeeImage,
    } = useGetEmployeeProfileImageByUUID(employee_uuid);


    const {
        mutate: patchEmployeeImageFn,
        isLoading: loadingEmployeeImageUploadFn,
    } = useEmployeeProfileImageUpdateFn();


    const submitForm = () => {
        let FormValidity = FormRef.current.reportValidity();

        if (FormValidity === true) {
            updateEmployeeBankHandeler();
        }
    }


    const updateEmployeeBankHandeler = () => {

        let userData = {
            profile_image: employeeImageData.profile_image,
        }

        patchEmployeeImageFn(
            { employee_uuid, userData },
            {
                onSuccess: (response) => {
                    toast.success('Employee Image Updated', TostifyConfig);
                    setEmployeeImageData({});
                    client.invalidateQueries(['get-employee-profile-image-by-uuid']);
                    client.invalidateQueries(['get-pending-action-by-uuid']);
                    client.invalidateQueries(['get-employee-info-detail-by-uuid']);
                    props.setCardView({ image: true });
                },
            }
        );
    }


    const handleImageClick = () => {
        // Trigger a click event on the hidden file input
        fileInputRef.current.click();

    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (event.target.type === "file") {
            setEmployeeImageData({
                ...employeeImageData,
                [name]: event.target.files[0], // Capture the selected file object
            });
            setFile(URL.createObjectURL(event.target.files[0]));
        }
        else {
            setEmployeeImageData({
                ...employeeImageData,
                [name]: value,
            });
        }
    };

    // Immediately proceed with form submission after file selection
    useEffect(() => {
        if (employeeImageData?.profile_image) {
            submitForm();
        }
    }, [employeeImageData]);

    useEffect(() => {
        if (getEmployeeImageUUID) {
            // setEmployeeImageData(getEmployeeImageUUID);
            setFile(getEmployeeImageUUID.profile_image_url);
        }
    }, [getEmployeeImageUUID])

    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card" id='profile-image'>
                        <div className="card-header d-flex justify-content-between">
                            <h4 className="card-title">
                                <i className="fa fa-camera font-18 align-middle me-2 text-primary"></i>
                                &nbsp;Image
                            </h4>
                        </div>
                        <LoadingOverlay
                            active={loadingGetEmployeeImageUUIDFn}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>

                                    {file ? (
                                        <div className="text-center" >
                                            <div className="profile-image mb-4" >
                                                <img
                                                    src={file}
                                                    className="rounded-circle"
                                                    height="200px"
                                                    width="200px"
                                                    alt=""
                                                    Style="border 1px black"
                                                />
                                            </div>
                                        </div>
                                    ) :
                                        <div className="text-center">
                                            <div className="profile-image mb-4">
                                                <img
                                                    src={profileImg}
                                                    className="rounded-circle"
                                                    alt="Profile"
                                                    // onClick={handleImageClick}
                                                    style={{ cursor: 'pointer' }}
                                                    height="200px"
                                                    width="200px"
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className="text-center">
                                        <div style={{ position: 'relative' }}>
                                            <Button
                                                //disabled={!employeeImageData?.profile_image}
                                                onClick={handleImageClick}
                                                variant="success"
                                            >
                                                {(loadingEmployeeImageUploadFn) ? (
                                                    <>
                                                        <Spinner animation="border" size="sm" className="me-2" />
                                                        Loading...
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className='btn-icon-left'>
                                                            <i className='fa fa-upload' />
                                                        </span>&nbsp;&nbsp;
                                                        Upload
                                                    </>
                                                )}
                                            </Button>
                                            <input
                                                type="file"
                                                placeholder="Type Text"
                                                name="profile_image"
                                                onChange={handleInputChange}
                                                accept=".png, .jpg, .jpeg"
                                                ref={fileInputRef}
                                                required
                                                style={{ opacity: 0, position: 'absolute', top: 0, left: 0 }}
                                            />
                                        </div>
                                    </div>


                                    {/* <Form.Group className='mt-2'>
                                    <Row>
                                        <Col className='col-md-8'>
                                            <div className="col-sm-6 col-md-3 mg-t-10 mg-md-t-0">
                                                <Button
                                                    disabled={!employeeImageData?.profile_image}
                                                    onClick={submitForm}
                                                    variant="btn btn-info btn-block btn-sm"
                                                >{(loadingEmployeeImageUploadFn) ? (
                                                    <>
                                                        <Spinner animation="border" size="sm" className="me-2" />
                                                        Loading...
                                                    </>
                                                ) : (
                                                    'Submit'
                                                )}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group> */}
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default ProfileImage