import React, { Fragment } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useRef } from "react";
import { useLocation } from "react-router-dom/dist";
import {
  usePositionsUpdate,
  useCreatePositions,
  useGetPositionsDetail,
} from "../../actions/Positions-actions";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from "../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";
import LoadingOverlay from 'react-loading-overlay';


function AddPositions() {
  const navigate = useNavigate();

  const FormRef = useRef(null);

  const location = useLocation();

  let id = location?.state?.rowId;


  const [positions, setpositions] = useState({
    position_name: "",
    position_code: "",
    description: "",
  });

  console.log('positions',positions);

  const handleChange = (e) => {
    setpositions((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const { data: positionsDetail, isLoading: loadingpPositionsFn } = useGetPositionsDetail(
    location?.state?.rowId
  );

  const { mutate: postNewPositions, isLoading: loadingpostNewPositionsFn } =
    useCreatePositions();

  const { mutate: patchPositions, isLoading: loadingpatchPositionsInfoFn } =
    usePositionsUpdate();

  useEffect(() => {
    if (location?.state?.rowId) {
      setpositions(positionsDetail);
    }
  }, [positionsDetail]);

  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();
    if (FormValidity === true && location?.state?.rowId) {
      try {
        patchPositions(
          { id, positions },
          {
            onSuccess: (response) => {
              toast.success("Positions  Updated", TostifyConfig);
              navigate("/list-positions/");
            },
          }
        );
      } catch (error) {
        console.error(error);
      }
    } else if (FormValidity === true) {
      try {
        postNewPositions(positions, {
          onSuccess: (response) => {
            toast.success("Positions  Added", TostifyConfig);
            navigate("/list-positions/");
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Settings" motherMenu="Home" />
      <div>
        <div className="col-xl-12 col-xxl-12">
          <Card className="az-card">
            <Card.Header>
              <h3>{id ? "Edit Position" : "New Position"}</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingpPositionsFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-lg-6 mb-2">
                        <label className="text-label">
                          Position Name<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="position_name"
                          value={positions?.position_name}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                      <Col className="col-lg-6 mb-2">
                        <label className="text-label">
                          Position Code
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="position_code"
                          value={positions?.position_code}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label className="text-label">
                          Description
                        </label>
                        <Form.Control
                          Style="height:70px"
                          as="textarea"
                          rows={5}
                          placeholder="Type Text"
                          name="description"
                          value={positions?.description}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mt-2">
                    <Row>
                      <Col className="col-md-12">
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              navigate("/list-positions");
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;



                          <Button
                           variant="primary btn-sm"
                            onClick={handleSubmit}
                            disabled={loadingpPositionsFn && id || loadingpostNewPositionsFn || loadingpatchPositionsInfoFn}
                            >
                            {loadingpostNewPositionsFn ||
                              loadingpatchPositionsInfoFn ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                />
                                Loading...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default AddPositions;
