import React, { Fragment, } from 'react'
// import ComponentsClientSidebar from '../shared/ComponentsClientSidebar'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { useRef } from 'react'
// import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation, } from 'react-router-dom/dist'
import { useCreatePayslips, useGetPayslipDetail, usePayslipUpdate } from '../../actions/payslip-actions'
import { useState } from 'react';
import { useEffect } from 'react';
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { useGetEmployeeList } from '../../actions/employee-actions';
import { extractFileName } from '../../utils/ComponentUtilse';
import LoadingOverlay from 'react-loading-overlay';


function AddPayslips() {

  const navigate = useNavigate();

  const FormRef = useRef(null);

  const location = useLocation()

  let id = location?.state?.rowId

  const { data: getAllEmployee } = useGetEmployeeList()

  const { mutate: postNewPayslips, isLoading: loadingPostPayslipFn } = useCreatePayslips()

  const [employeeList, setEmployeeList] = useState([])

  const [currentAttachmentData, setCurrentAttachmentData] = useState(null);

  const { data: payslipDetail, refetch: refetchEmployeePayslip, isLoading: loadingPayslipFn } = useGetPayslipDetail(location?.state?.rowId)

  const { mutate: patchPayslip, isLoading: loadingPatchPayslipFn } = usePayslipUpdate()

  const [payslipData, setPayslipData] = useState({})


  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();
    if (FormValidity === true) {
      if (id) {
        UpdateEmployeePayslipHandeler();
      }
      else {
        postEmployeePayslipHandeler();
      }
    }
  }

  const postEmployeePayslipHandeler = () => {
    let payslipdata = {
      reporter: payslipData.reporter,
      resource: payslipData.resource,
      start_date: payslipData.start_date,
      end_date: payslipData.end_date,
      total_hours: payslipData.total_hours,
      notes: payslipData.notes,
      number_of_days: payslipData.number_of_days,
      attachment: payslipData.attachment,
    }
    try {
      postNewPayslips(payslipdata, {
        onSuccess: (response) => {
          navigate('/list-payslips/')
          // toast.success('PaySlip Added!!', TostifyConfig);
          // history.push('/payslips')
        }
      })
    } catch (error) {
      console.error(error);
    }

  }

  const UpdateEmployeePayslipHandeler = () => {
    let payslipdata = {
      reporter: payslipData.reporter,
      resource: payslipData.resource,
      start_date: payslipData.start_date,
      end_date: payslipData.end_date,
      total_hours: payslipData.total_hours,
      notes: payslipData.notes,
      number_of_days: payslipData.number_of_days,
      //attachment: payslipData.attachment,
    }

    if (payslipData?.attachment?.name) {
      payslipdata['attachment'] = payslipData?.attachment
    }
    patchPayslip({ id, payslipdata }, {
      onSuccess: (response) => {
        navigate('/list-payslips/')
        // toast.success('PaySlip Updated!!', TostifyConfig);
        // history.push('/payslips')
      }
    })
  }

  const SelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;
    const selectedOptionLabel = e.target.options[selectedIndex].label;
    setPayslipData({
      ...payslipData,
      [selectName]: selectValue,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (event.target.type === "file") {
      setPayslipData({
        ...payslipData,
        [name]: event.target.files[0], // Capture the selected file object
      });
      setCurrentAttachmentData(event.target.files[0].name);
    } else {
      setPayslipData({
        ...payslipData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (id) {
      refetchEmployeePayslip();
    }

    if (payslipDetail) {
      setPayslipData(payslipDetail);
      setCurrentAttachmentData(extractFileName(payslipDetail.attachment));
    }
  }, [payslipDetail, refetchEmployeePayslip, id])



  return (
    <Fragment>
      <PageTitle activeMenu="Settings" motherMenu="Home" />
      <div>
        <div className='col-xl-12 col-xxl-12'>
          <Card className="az-card">
            <Card.Header>
              <h3>{id ? "Edit Payslip" : "Add Payslip"}</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingPayslipFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-lg-6 mb-2'>
                        <label>Reporter<span className="required">*</span></label>
                        <Form.Select
                          name="reporter"
                          className="form-control"
                          value={payslipData?.reporter}
                          onChange={SelectChangeHandler}
                          required
                        >
                          <option value="">Select Reporter</option>
                          {getAllEmployee?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.first_name} {option.last_name} {option.employee_number ? ` (${option.employee_number})` : ''}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col className='col-md-6 mb-2'>
                        <label>Employee<span className="required">*</span></label>
                        <Form.Select
                          name="resource"
                          className="form-control"
                          value={payslipData?.resource}
                          onChange={SelectChangeHandler}
                          required
                        >
                          <option value="">Select Resource</option>
                          {getAllEmployee?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.first_name} {option.last_name} {option.employee_number ? ` (${option.employee_number})` : ''}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6 mb-2'>
                        <label>Start Date<span className="required">*</span></label>
                        <Form.Control
                          type="date"
                          name="start_date"
                          onChange={handleInputChange}
                          value={payslipData?.start_date}
                          required
                        />
                      </Col>
                      <Col className='col-md-6 mb-2'>
                        <label>End Date<span className="required">*</span></label>
                        <Form.Control
                          type="date"
                          name="end_date"
                          onChange={handleInputChange}
                          value={payslipData?.end_date}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6 mb-2'>
                        <label>Total Hours<span className="required">*</span></label>
                        <Form.Control
                          type="number"
                          name='total_hours'
                          placeholder="Total Hours"
                          value={payslipData?.total_hours}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                      <Col className='col-md-6 mb-2'>
                        <label>Notes</label>
                        <Form.Control
                          type="text"
                          name='notes'
                          placeholder="Notes"
                          value={payslipData?.notes}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6 mb-2'>
                        <label>Number of Days<span className="required">*</span></label>
                        <Form.Control
                          type="number"
                          name='number_of_days'
                          placeholder="Number of Days"
                          value={payslipData?.number_of_days}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                      <Col className='col-md-6 mb-2'>
                        <label>Attachment</label>
                        <Form.Control
                          type="file"
                          placeholder="Type Text"
                          name="attachment"
                          onChange={handleInputChange}
                        />
                        <Row className='col-md-6 mt-3'>
                          <label>Selected File</label>
                          <label>{currentAttachmentData}</label>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>


                  <Form.Group className='mt-2'>
                    <Row>
                      <Col className="col-md-12">
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              navigate("/list-payslips/");
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="primary btn-sm"
                            onClick={handleSubmit}
                            disabled={loadingPayslipFn && id || loadingPostPayslipFn || loadingPatchPayslipFn}
                          >
                            {loadingPostPayslipFn ||
                              loadingPatchPayslipFn ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                />
                                Loading...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>

        </div>
      </div>
    </Fragment>
  )
}

export default AddPayslips