import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo from '../../images/logo/white_logo.PNG';
import { useConfirmPassword } from '../../actions/auth-actions';
import { Alert } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

const ConfirmPassword = () => {
  const nav = useNavigate();
  const { id, token } = useParams();

  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccesss] = useState('');
  const [resetSuccess, setResetSuccesss] = useState(false);

  const [tooltipContent, setTooltipContent] = useState("");
  const [retypePasswordError, setRetypePasswordError] = useState('');
  const [passwordMessage, setPasswordMessage] = useState("");

  const [isOpen, setIsOpen] = useState(false);


  useEffect(() => {
    passwordContentFn(password);
  }, []);

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  const passwordContentFn = (passwordValue) => {
    let tooltip = "";
    if (passwordValue.length >= 8) {
      tooltip +=
        '<span class="text-success">&#10004; MUST contain at least 8 characters</span></br></br>';
    } else {
      tooltip +=
        '<span class="text-danger">&#10006; MUST contain at least 8 characters</span></br></br>';
    }

    if (/[A-Z]+/.test(passwordValue)) {
      tooltip +=
        '<span class="text-success">&#10004; MUST contain at least one uppercase letter</span></br></br>';
    } else {
      tooltip +=
        '<span class="text-danger">&#10006; MUST contain at least one uppercase letter</span></br></br>';
    }

    if (/[a-z]+/.test(passwordValue)) {
      tooltip +=
        '<span class="text-success">&#10004; MUST contain at least one lowercase letter</span></br></br>';
    } else {
      tooltip +=
        '<span class="text-danger">&#10006; MUST contain at least one lowercase letter</span></br></br>';
    }

    if (/[0-9]+/.test(passwordValue)) {
      tooltip +=
        '<span class="text-success">&#10004; MUST contain at least one number</span></br></br>';
    } else {
      tooltip +=
        '<span class="text-danger">&#10006; MUST contain at least one number</span></br></br>';
    }

    if (/[^A-Za-z0-9]+/.test(passwordValue)) {
      tooltip +=
        '<span class="text-success">&#10004; MUST contain at least one special character</span></br></br>';
    } else {
      tooltip +=
        '<span class="text-danger">&#10006; MUST contain at least one special character</span></br></br>';
    }
    setTooltipContent(tooltip);
  };

  const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length === 5
        ? "Strong"
        : verifiedList.length >= 2
          ? "Medium"
          : "Weak";

    passwordContentFn(passwordValue);

    setPassword(passwordValue);
    setPasswordMessage(strength);

    if (strength !== "Strong") {
      setPasswordMessage("Invalid password");
    } else {
      setPasswordMessage(strength);
    }

    console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
  };



  const { mutate: confirmPassword, isLoading: isLoadingPassword } = useConfirmPassword();

  const submitHandler = (e) => {
    e.preventDefault();

    if (!password && !retypePassword) {
      setPasswordError("Invalid Passwords")
    }

    if (password !== retypePassword) {
      setPasswordError("Passwords do not match.")
      return
    }


    if ((password && retypePassword) && (password === retypePassword)&&(passwordMessage === "Strong") ){
      setPasswordError('');
      confirmPassword({ 'password': password, 'id': id, 'token': token }, {
        onSuccess: async (response) => {
          setPasswordSuccesss(response?.data?.detail);
          setResetSuccesss(true);
        }, onError: (error) => {
          setPasswordError(error?.response?.data?.detail);
        }
      });
    }
    else {
      setPasswordError("Invalid Passwords")
    }
  }

  useEffect(() => {
    localStorage.clear();
  });

  return (
    <div className='authincation'>
      <div className='container'>
        <div className='row justify-content-center h-100 align-items-center'>
          <div className='col-md-6'>
            <div className='authincation-content'>
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <div className='auth-form'>

                    <div className='text-center mb-3'>
                      <Link to='/'>
                        <img src={logo} alt="logo" className="logo-text" width={100} />
                      </Link>
                    </div>
                    <h4 className='text-center mb-4 text-white '>
                      Reset Password
                    </h4>

                    {passwordSuccess &&
                      <Alert variant="success" className="solid">
                        <strong>Success! </strong> {passwordSuccess}
                      </Alert>
                    }

                    {passwordError &&
                      <Alert variant="danger" className="solid">
                        <strong>Error! </strong> {passwordError}
                      </Alert>
                    }

                    {!resetSuccess &&
                      <form action='' onSubmit={(e) => submitHandler(e)}>
                        <div className='form-group'>
                          <label className='text-white'>
                            <strong>New Password</strong>&nbsp;
                            <Tooltip
                              id="password-tooltip"
                              style={{
                                backgroundColor: "#f6f6f6",
                                color: "#222",
                                border: "1px solid #e7e7e7",
                              }}
                              events={['click']}
                              isOpen={isOpen}
                            />
                          </label>
                          <input
                            type='password'
                            className='form-control'
                            name='password'
                            value={password}
                            data-tooltip-id="password-tooltip"
                            data-tooltip-html={tooltipContent}
                            data-tooltip-place="left"
                            onChange={({ target }) => {
                              handlePassword(target.value);
                              setPassword(target.value);
                            }}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </div>
                        <div className='form-group'>
                          <label className='text-white'>
                            <strong>ReType New Password</strong>
                          </label>
                          <input
                            type='password'
                            className='form-control'
                            name='password'
                            value={retypePassword}
                            onChange={(e) => setRetypePassword(e.target.value)}
                          />
                          {passwordError && <span className="required">Passwords do not match.</span>}
                        </div>
                        <div className='text-center mt-4'>
                          <button type="submit" className="btn bg-white text-primary btn-block" disabled={isLoadingPassword}>
                            {isLoadingPassword ? (
                              "Loading..."
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    }

                    {resetSuccess &&
                      <button type="submit" className="btn bg-white text-primary btn-block"
                        disabled={isLoadingPassword}
                        onClick={() => nav('/login')}
                      >
                        {isLoadingPassword ? (
                          "Loading..."
                        ) : (
                          "Back to Login"
                        )}
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmPassword
