import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// Employee Education Request

const employeeEducationUpdateFn = async (employeeData) => {
  
    let {id, userData} = employeeData;
    let config = {
      method: "patch",
      url: `/employee/api/v1/employee_education/${id}/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: userData,
    };
    
    return await axios.request(config);
  };
  export const useEmployeeEducationUpdateFn = () => {
    return useMutation(["employee-education-update"], employeeEducationUpdateFn);
  };

  
const getEmployeeEducationByUUIDFn = async (query) => {
  let [, employee_uuid] = query.queryKey;
  let config = {
    method: "get",
    url: `/employee/api/v1/employee_education/?employee__employee_uuid=${employee_uuid}`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const response = await axios.request(config);
  return response?.data;
};
export const useGetEmployeeEducationByUUID = (employee_uuid) => {
  return useQuery(["get-employee-education-by-uuid", employee_uuid], getEmployeeEducationByUUIDFn, {enabled: !!employee_uuid});
};


const getEmployeeEducationByIDFn = async (query) => {
  let [, employee_id] = query.queryKey;
  let config = {
    method: "get",
    url: `/employee/api/v1/employee_education/${employee_id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const response = await axios.request(config);
  return response?.data;
};
export const useGetEmployeeEducationByID = (employee_id) => {
  return useQuery(["get-employee-education-by-id", employee_id], getEmployeeEducationByIDFn, {enabled: false});
};


const employeeEducationPostFn = async (employeeData) => {
  let config = {
    method: "post",
    url: `/employee/api/v1/employee_education/`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: employeeData,
  };
  
  return await axios.request(config);
};
export const useEmployeeEducationPostFn = () => {
  return useMutation(["employee-education-post"], employeeEducationPostFn);
};