import { Button, Spinner, Table, Badge } from "react-bootstrap";
import { isValidPermission } from "../../../services/AuthService";
import CustomTooltip from "../../../utils/CommonUtils";

const AdvertisementList = (props) => {

  const handleEdit = (id) => {
    props.setCardView({ advertisementEdit: true, advertisementId: id })
  }

  return (
    <div className="card" id="bank">
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title">
          <i class="fas fa-bullhorn font-18 align-middle me-2 text-primary"></i>
          &nbsp; Advertisement
        </h4>
        {isValidPermission('employee.add_employeebankdetail', props.getPermissionList) &&
          <Button className="me-3 btn-sm" variant="primary"
            onClick={() => {
              props.setCardView({ advertisementEdit: true });
            }}>
            <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Add
          </Button>
        }
      </div>
      <div className="card-body">
        {props.advertisementList.length > 0
          ? props.advertisementList.map((item, i) => {
            return (
              <div className="card" id="bank">
                {/* <div className="card-header d-flex justify-content-between">
                  <h4 className="card-title">
                    &nbsp;
                  </h4>

                  {isValidPermission('employee.add_employeebankdetail', props.getPermissionList) &&
                    <Button
                      className="btn-sm"
                      variant="primary"
                      onClick={() => {
                        props.setCardView({ advertisementEdit: true, advertisementId: item.id });
                      }}
                    >
                      <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Edit
                    </Button>
                  }
                </div> */}
                <div className="card-body" style={{ padding: '15px', boxShadow: '0 4px 8px rgba(2, 0, 0, 0.3)', borderRadius: '8px' }}>
                  <div className="form-validation">
                    <div className="row">
                      <div className="col-xl-11">
                        <div className="form-group mb-3 row">
                          <label className="col-lg-5 col-form-label">
                            Job advertisement platform:
                          </label>
                          <label className="col-lg-7 col-form-label">
                            {item?.job_advertisement_platform}
                          </label>
                          <label className="col-lg-5 col-form-label">
                            Job advertisement link:
                          </label>
                          <label className="col-lg-7 col-form-label">
                            {item?.job_advertisement_link}
                          </label>
                          <label className="col-lg-5 col-form-label">
                            Published On:
                          </label>
                          <label className="col-lg-7 col-form-label">
                            {item?.published_on}
                          </label>
                        </div>
                      </div>
                      <CustomTooltip id={`edit-${item.index}`} message="Edit">
                        <div className="col-xl-1">
                          <i style={{ cursor: 'pointer' }}
                            className="fas fa-pencil-alt font-18 align-end me-2 text-primary"
                            onClick={() => {
                              props.setCardView({ advertisementEdit: true, advertisementId: item.id });
                            }}>
                          </i>
                        </div>
                      </CustomTooltip>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
          : <div style={{ textAlign: 'center' }}>No Record Found</div>
        }
      </div>
    </div>

  )
}
export default AdvertisementList;