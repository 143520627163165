import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import '../components/table/FilteringTable/filtering.css';
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link, useHistory } from 'react-router-dom';
import { Button, Tab, Badge } from 'react-bootstrap';
import { Col, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { useTaskList } from '../../actions/task-action';
import { IsValidPermissions } from "../../services/AuthService";
import { RiseLoader } from "react-spinners";
import { dateHandler } from '../../utils/ComponentUtilse';
import { TASK_STATUS } from '../../utils/Constance'
import { useTaskDeleteById } from '../../actions/task-action';
import { deleteConfirmation } from '../../utils/ComponentUtilse';
import CustomTooltip from '../../utils/CommonUtils';


export const ListTask = () => {

    const navigate = useNavigate();

    const { data: getAllTask, isLoading: loadingGetTaskFn, refetch: refetchTask } = useTaskList()

    const { mutate: deleteTaskInfo, isLoading: loadingdeleteTaskFn } = useTaskDeleteById();

    const [data, setData] = useState([]);


    const [filterType, setFilterType] = useState("all");

    const [taskStatus, setTaskStatus] = useState("option"); // Initialize with the default value


    useEffect(() => {
        let filteredData = getAllTask;

        if (filteredData && filterType === "High") {
            // Filter data based on approved_status_name
            filteredData = getAllTask.filter(
                (task) => task.get_employee_priority === "High"
            );
            setData(filteredData);

        } else if (filteredData && filterType === "Medium") {
            // Filter data based on approved_status_name
            filteredData = getAllTask.filter(
                (task) => task.get_employee_priority === "Medium"
            );
            setData(filteredData);

        }
        else if (filteredData && filterType === "Low") {
            // Filter data based on approved_status_name
            filteredData = getAllTask.filter(
                (task) => task.get_employee_priority === "Low"
            );
            setData(filteredData);

        }
        else if (filteredData && filterType === "all") {
            filteredData = getAllTask;
            setData(filteredData);
        }


        if (taskStatus !== "option" && filteredData) {
            filteredData = filteredData.filter(
                (task) => task.task_status === parseInt(taskStatus)
            );
            setData(filteredData);
        }
        //  else if (taskStatus === "option" && filteredData) {
        //     filteredData = getAllTask;
        //     setData(filteredData);
        // }

    }, [getAllTask, filterType, taskStatus]);

    // useEffect(() => {
    //     if (taskStatus !== "option" && getAllTask) {
    //         const filteredData = getAllTask.filter(
    //             (task) => task.task_status === parseInt(taskStatus)
    //         );
    //         setData(filteredData);
    //     }
    // }, [taskStatus, getAllTask]);



    return (
        <>
            <PageTitle activeMenu="Filtering" motherMenu="Table" />
            <Tab.Container defaultActiveKey={'grid'}>
                <div className="d-flex flex-wrap mb-0 align-items-center" style={{ justifyContent: "space-between" }}>
                    <h6 className="text-black fs-16 font-w600 mb-3">Filters:</h6>
                    <div className="d-flex flex-wrap mb-0 align-items-center mb-1 pr-2">

                        <Button
                            className={`btn btn-primary light btn-rounded me-2 mb-2 btn-sm ${filterType === "all" && "btn-primary-active"}`}
                            onClick={() => setFilterType("all")}
                        >
                            All
                        </Button>
                        <Button
                            className={`btn btn-primary light btn-rounded me-2 mb-2 btn-sm ${filterType === "myLeave" && "btn-primary-active"}`}
                            onClick={() => setFilterType("High")}>
                            High
                        </Button>
                        <Button
                            className={`btn btn-primary light btn-rounded me-2 mb-2 btn-sm ${filterType === "myLeave" && "btn-primary-active"}`}
                            onClick={() => setFilterType("Medium")}>
                            Medium
                        </Button>
                        <Button
                            className={`btn btn-primary light btn-rounded me-2 mb-2 btn-sm ${filterType === "myLeave" && "btn-primary-active"}`}
                            onClick={() => setFilterType("Low")}>
                            Low
                        </Button>

                        <div className="input-group search-area d-lg-inline-flex d-none">
                            <form onSubmit={(e) => e.preventDefault()}>
                                <select
                                    value={taskStatus} // Use value instead of defaultValue
                                    onChange={(e) => setTaskStatus(e.target.value)}
                                    className="form-control input-rounded mb-0 me-4 mt-3 mt-sm-0 mb-2"
                                    id="inlineFormCustomSelect"
                                    Style="height:43px;"
                                >
                                    <option value="option" disabled>
                                        Choose...
                                    </option>
                                    <option value="option">
                                        All
                                    </option>
                                    {TASK_STATUS?.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </form>

                            <div className="input-group-append">
                                <span className="input-group-text">
                                    {/* <i className="fa fa-filter" Style="height:26px;"></i> */}
                                    <i className="flaticon-381-search-2"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mb-1 mt-2">
                        {IsValidPermissions('task.add_task') &&
                            <Link to={`/add-task/`}><Button className='me-2 btn-sm' variant='primary'>
                                <i className="fas fa-plus"></i>&nbsp;Task
                            </Button></Link>
                        }
                    </div>
                </div>
                <hr />
                <Tab.Content>
                    {!loadingGetTaskFn && (IsValidPermissions('task.view_task') || IsValidPermissions('task.can_view_employee_task')) &&
                        <Tab.Pane eventKey={'grid'}>
                            {data?.length > 0 ? (
                                <div className="row">
                                    {data?.map((item, i) => {
                                        return (
                                            <div className="col-xl-4 col-md-6">
                                                <div className="card shadow_1">
                                                    <div className="card-header d-flex justify-content-between">
                                                        <div>
                                                            <h4 className='text-left'><i className="fa fa-users"></i>&nbsp; {item?.title}</h4>
                                                        </div>
                                                        <div>
                                                            {IsValidPermissions('task.delete_task') && <CustomTooltip id={`delete-${item.index}`} message="Delete">
                                                                <Button
                                                                    className="btn btn-primary  btn-sm" variant="danger light"
                                                                    style={{ cursor: "pointer", padding: "6px 8px 6px 8px" }}
                                                                    onClick={() => deleteConfirmation(item.id, `Task - ${item?.title}`, deleteTaskInfo, refetchTask)}
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </Button>
                                                            </CustomTooltip>
                                                            }&nbsp;

                                                            {IsValidPermissions('task.change_task') &&
                                                                <CustomTooltip id={`edit-${item.index}`} message="Edit">
                                                                    <Button style={{ padding: "6px 8px 6px 8px" }} className="btn btn-primary  btn-sm" onClick={() => navigate('/add-task/', { state: { rowId: item?.id } })}><i className="fas fa-pencil-alt"></i></Button>
                                                                </CustomTooltip>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="card-body pb-1 pt-3">
                                                        <div className="row">
                                                            <div className="form-group mb-3 row" style={{ fontSize: "14px" }}>
                                                                <label className="col-lg-12 col-form-label">
                                                                    Task: &nbsp; <span className='text-black'>{item?.title}</span>
                                                                </label>
                                                                <label className="col-lg-8 text-black col-form-label">

                                                                </label>

                                                                <label className="col-lg-12 col-form-label">
                                                                    Description: &nbsp; <span className='text-black'>{item?.description}</span>
                                                                </label>

                                                                <label className="col-lg-4 col-form-label">
                                                                    Assignee:
                                                                </label>
                                                                <label className="col-lg-8 text-black col-form-label">
                                                                    {item?.get_employee_assignee}
                                                                </label>

                                                                <label className="col-lg-4 col-form-label">
                                                                    Reporter:
                                                                </label>
                                                                <label className="col-lg-8 text-black col-form-label">
                                                                    {item?.get_employee_reporter}
                                                                </label>

                                                                <label className="col-lg-5 col-form-label">
                                                                    Start Date:
                                                                </label>
                                                                <label className="col-lg-7 text-black col-form-label">
                                                                    {dateHandler(item?.start_date)}
                                                                </label>

                                                                <label className="col-lg-5 col-form-label">
                                                                    End Date:
                                                                </label>
                                                                <label className="col-lg-7 text-black col-form-label">
                                                                    {dateHandler(item?.end_date)}
                                                                </label>

                                                                <label className="col-lg-5 col-form-label">
                                                                    Orginal Estimate:
                                                                </label>
                                                                <label className="col-lg-7 text-black col-form-label">
                                                                    {item?.original_estimate}
                                                                </label>

                                                                <label className="col-lg-5 col-form-label">
                                                                    Priority:
                                                                </label>
                                                                <label className="col-lg-7 text-black col-form-label">
                                                                    <Badge pill bg="primary" className='badge-xs text-capitalize'>{item?.get_employee_priority}</Badge>
                                                                </label>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="text-center mt-4">
                                    <p>No Records Found</p>
                                </div>
                            )}
                        </Tab.Pane>
                    }

                    {loadingGetTaskFn &&
                        <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "calc(100vh - 200px)" }}>
                            <RiseLoader color="#40189D" />
                        </div>
                    }
                </Tab.Content>
            </Tab.Container>
        </>
    )

}
export default ListTask;