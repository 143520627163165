import { Button, Table, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isValidPermission } from "../../../../services/AuthService";

function LeaveView(props) {

    const handleEdit = (id) => {
        props.setCardView({ employeeLeaves: true})
    }

    return (
        <div className="card" id="leave-view">
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">
                    <i class="fas fa-envelope font-18 align-middle me-2 text-primary"></i>
                    &nbsp; Employee Leaves
                </h4>
            </div>

            <div className="card-body">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Leave Type</th>
                            <th className="text-center">Total Leaves</th>
                            <th className="width200 text-center">Taken</th>
                            <th className="width200 text-center">Remaining</th>
                            <th className="width200 text-center">Year</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.isLoading ?
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={6}>
                                    <Spinner animation="border" size="lg" className="me-2" />
                                </td>
                            </tr>
                            : props.employeeLeaveList.length > 0
                                ? props.employeeLeaveList.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item?.leave_type}</td>
                                            <td className="text-center">{item?.total_days}</td>
                                            <td className="text-center">{item?.total_taken}</td>
                                            <td className="text-center">{item?.days_remaining}</td>
                                            <td className="text-center">{item?.year}</td>
                                        </tr>
                                    );
                                })
                                : <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={6}>No records found</td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </div>

        </div>

    )
}
export default LeaveView;