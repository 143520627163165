import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const getEmployeeProjectFn = async (query) => {
    let [,] = query.queryKey;
    let config = {
      method: "get",
      // url: `/organization/api/v1/projects/?client__client_name=${client_name}`,
      url: `/organization/api/v1/projects/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useEmployeeProject = (client_name) => {
    return useQuery(["get-employee-project", client_name], getEmployeeProjectFn);
  };


const getEmployeeTeamsFn = async (query) => {
    let [, project_name] = query.queryKey;
    let config = {
      method: "get",
      url: `/organization/api/v1/teams/?project__project_name=${project_name}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useEmployeeTeam = (project_name) => {
    return useQuery(["get-employee-team", project_name], getEmployeeTeamsFn, {enabled: !!project_name});
  };