import { Spinner } from "react-bootstrap"

const DataTable = (props) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state,
		page,
		gotoPage,
		pageCount,
		pageOptions,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		setGlobalFilter,
	} = props.tableInstance

	const { globalFilter, pageIndex } = state

	return (
		<div className="table-responsive" style={{ overflowX: 'auto' }}>
			<div className="d-flex align-items-center justify-content-between">
				<div>
				</div>
				<div className="d-inline-flex mb-2">

				</div>
			</div>
			<table {...getTableProps()} style={{ overflowX: 'auto' }} className="table table-responsive-md filtering-table">
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()} className={column.className}>
									{column.render('Header')}
									{column.canFilter ? column.render('Filter') : null}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()} className="" >
					{props.isLoading ?
						<tr>
							<td style={{ textAlign: 'center' }} colSpan={props.columns.length}>
								<Spinner animation="border" size="lg" className="me-2" />
							</td>
						</tr>
						: page.length === 0 ?
							<tr>
								<td style={{ textAlign: 'center' }} colSpan={props.columns.length}>No records found</td>
							</tr> : page.map((row) => {
								prepareRow(row)
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>
										})}
									</tr>
								)
							})}
				</tbody>
			</table>
			<div className="d-flex justify-content-between">
				<span>
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{''}
				</span>
				<span className="table-index">
					Go to page : {' '}
					<input type="number"
						className="ms-2"
						defaultValue={pageIndex + 1}
						onChange={e => {
							const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(pageNumber)
						}}
					/>
				</span>
			</div>
			<div className="text-center">
				<div className="filter-pagination  mt-3">
					<button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

					<button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
						Previous
					</button>
					<button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
						Next
					</button>
					<button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
				</div>
			</div>
		</div>
	)
}

export default DataTable