import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

//API DELETE METHOD
const applicationDeleteById = async (id) => {
  try {
    const response = await axios.delete(`/recruitment/api/v1/job_application/${id}/`);
    return response.data;
  } catch (error) {
    // Handle error or throw it again
    throw error;
  }
};

export const useApplicationDeleteById = () => {
  return useMutation(["application-delete"], applicationDeleteById);
};

// API POST Method

const applicationPostFn = async (applicationData) => {
  let config = {
    method: "post",
    url: `/recruitment/api/v1/job_application/ `,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: applicationData,
  };

  return await axios.request(config);
};
export const useApplicationPostFn = () => {
  return useMutation(["application-post"], applicationPostFn);
};

// API PATCH Method
const applicationUpdateFn = async (applicationData) => {

  let { id, userData } = applicationData;
  let config = {
    method: "patch",
    url: `/recruitment/api/v1/job_application/${id}/`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: userData,
  };

  return await axios.request(config);
};
export const useApplicationUpdateFn = () => {
  return useMutation(["application-update"], applicationUpdateFn);
};

// API GET Method 

const getApplicationByIDFn = async (query) => {
  let [, id] = query.queryKey;
  let config = {
    method: "get",
    url: `/recruitment/api/v1/job_application/${id}/`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetApplicationByID = (id) => {
  return useQuery(["get-application-by-id", id], getApplicationByIDFn);
}