import React, {
  Fragment,
  useState,
  useEffect,
  useContext
} from "react";
import PageTitle from "../../layouts/PageTitle";
import Gender from "../../../utils/Constance";
import { MaritialStatus } from "../../../utils/Constance";
import { useParams, useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { RiseLoader } from "react-spinners";

import LeaveView from "./ProfileViewComponent/LeaveView";
import RtwEmployeeView from "./ProfileViewComponent/RtwEmployeeView";
import { RTW_STATUS_CHOICES } from "../../../utils/Constance";
import EmployeeRtwSharecode from "../employee_rtw/EmployeeRtwSharecode";
import EmployeeProfile from "../EmployeeProfile";
import ProfileImage from "../profile_image/ProfileImage";
import ProfileImageView from "./ProfileViewComponent/ProfileImage";
import EmergencyContact from "../EmergencyContact";
import BankDetails from "../bank/BankDetails";
import Employeevisa from "../visa/EmployeeVisa";
import EmployeePassport from "../passport/EmployeePassport";
import Certification from "./ProfileViewComponent/Certification";
import Education from "./ProfileViewComponent/Education";
import PassPort from "./ProfileViewComponent/Passport";
import Visa from "./ProfileViewComponent/Visa";
import Bank from "./ProfileViewComponent/Bank";
import EmployeeEducation from "../education/EmployeeEducation";
import EmployeeCos from "../employee_cos/EmployeeCos";
import CosView from "./ProfileViewComponent/CosView";
import EmployeeCertification from "../certification/EmployeeCertification";
import SubMenu from "./ProfileViewComponent/SubMenu";
import PendingAction from "./ProfileViewComponent/PendingAction";
import Profile from "./ProfileViewComponent/Profile";
import PersonalInfoList from "./ProfileViewComponent/PersonalInfo";
import EmergencyContactList from "./ProfileViewComponent/EmergencyContactList";
import PersonalInfo from "../PersonalInfo";
import RtwView from "./ProfileViewComponent/RtwView";
import EmployeeRtw from "../employee_rtw/EmployeeRtw";
import ChangeLogs from "./ProfileViewComponent/ChangeLogs";
import Document from "./ProfileViewComponent/Document";
import EmployeeDocument from "../documents/EmployeeDocument";
import { useGetEmployeeVisaByUUID } from "../../../actions/employee-visa-actions";
import { useGetEmployeePassPortByUUID } from "../../../actions/employee-passport-actions";
import { useGetEmployeeBankByUUID } from "../../../actions/employee-bank-actions";
import { useGetEmployeeCertificationByUUID } from "../../../actions/employee-certification-action";
import { useGetEmergencyContactByUUID } from "../../../actions/employee-actions";
import { useGetEmployeeEducationByUUID } from "../../../actions/employee-education-action";
import { useGetEmployeeDocumentByUUID } from "../../../actions/document-action";
import { useGetLeaveSummary } from "../../../actions/leave-actions";
import {
  useGetEmployeeInfoByUUID,
  useGetEmployeeInfoDetailByUUID,
} from "../../../actions/employee-actions";
import { usePendingActionByUUID } from "../../../actions/pending-actions";
import { useGetEmployeeCosByUUID, useGetEmployeeRtwByUUID } from "../../../actions/cos_rtw-actions";
import { ServiceContext } from "../../../context/ServiceContext";
import { isValidPermission, IsValidPermissions } from "../../../services/AuthService";
import EmployeeProfileExportModal from "./EmployeeProfileExport";

const EmployeeProfileView = () => {

  const { getPermissionList } = useContext(ServiceContext);

  const { employee_uuid } = useParams();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const [employeeUUID] = useState(employee_uuid);

  const [employeeProfileData, setEmployeeProfileData] = useState({});

  const [employeeProfileInfoData, setEmployeeProfileInfoData] = useState({});

  const [employeeEducationList, setEmployeeEducationList] = useState([]);

  const [employeeDocumentList, setEmployeeDocumentList] = useState([]);


  const [employeeCertificationList, setEmployeeCertificationList] = useState(
    []
  );

  const [employeeBankList, setEmployeeBankList] = useState([]);

  const [employeePassPortList, setEmployeePassPortList] = useState([]);

  const [employeeVisaList, setEmployeeVisaList] = useState([]);

  const [employeeCosList, setEmployeeCosList] = useState([]);

  const [employeeRtwList, setEmployeeRtwList] = useState([]);

  const [emergencyContactData, setEmergencyContactData] = useState({});

  const [pendingActionData, setPendingActionData] = useState({});

  const [initialData, setInitialData] = useState(null);


  const [cardView, setCardView] = useState({
    summary: true,
    profile: false,
    personalInfo: false,
    emergencyContact: false,
    passport: false,
    documents: false,
    bank: false,
    visa: false,
    rtw: false,
    cos: false,
  });

  // Update card state based on URL parameters
  // useEffect(() => {
  //   const params = Object.fromEntries(searchParams.entries());
  //   const newCardView = { ...cardView }; // Ensure you're not mutating initialCardView

  //   console.log('params', params)
  //   console.log('newCardView', newCardView)

  //   for (const key in params) {
  //     if (key in newCardView && params[key] === 'true') {
  //       newCardView[key] = true;
  //       newCardView.summary = false;
  //     }
  //   }
  //   setCardView(newCardView); // Update the state immutably
  // }, [searchParams]);


  const updateCardViewFromSearchParams = () => {
    const params = Object.fromEntries(searchParams.entries());
    const newCardView = { ...cardView };

    for (const key in params) {
      if (key in newCardView && params[key] === 'true') {
        newCardView[key] = true;
        newCardView.summary = false;
      }
    }

    setCardView(newCardView); // Update the state immutably
  };

  useEffect(() => {
    updateCardViewFromSearchParams();
  }, []);

  const [profileExportModal, setProfileExportModal] = useState(false)

  const {
    data: getPendingActionByUUID,
    refetch: refetchPendingActionByUUID,
    isLoading: LoadingPendingActionByUUID,
  } = usePendingActionByUUID(employeeUUID);


  const {
    data: getEmergencyContactByUUID,
    refetch: refetchEmergencyContactByUUID,
  } = useGetEmergencyContactByUUID(employeeUUID);

  const {
    data: getEmployeeDetailByUUID,
    isLoading: LoadingEmployeeDetailByUUID,
    refetch: refetchEmployeeDetailByUUID
  } = useGetEmployeeInfoDetailByUUID(employeeUUID);

  const { data: getEmployeeVisaFn, isLoading: loadingGetEmployeeVisaFn, refetch: refetchEmployeeEmployeeVisaFn } =
    useGetEmployeeVisaByUUID(employeeUUID);

  const {
    data: getEmployeePassPortFn,
    isLoading: loadingGetEmployeePassPortFn,
    refetch: refetchEmployeeEmployeePassPortFn
  } = useGetEmployeePassPortByUUID(employeeUUID);

  const { data: getEmployeeBankFn, isLoading: loadingGetEmployeeBankFn, refetch: refetchEmployeeEmployeeBankFn } =
    useGetEmployeeBankByUUID(employeeUUID);

  const {
    data: getEmployeeCertificationFn,
    isLoading: loadingGetEmployeeCertificationFn,
  } = useGetEmployeeCertificationByUUID(employeeUUID);

  const {
    data: getEmployeeDocumentFn,
    isLoading: loadingGetEmployeeDocumentFn,
    refetch: refetchDocuments
  } = useGetEmployeeDocumentByUUID(employeeUUID);

  const {
    data: getEmployeeEducationFn,
    isLoading: loadingGetEmployeeEducationFn,
  } = useGetEmployeeEducationByUUID(employeeUUID);

  const {
    data: getEmployeeCosFn,
    isLoading: loadingGetEmployeeCosFn,
    refetch: refetchEmployeeCosFn
  } = useGetEmployeeCosByUUID(employeeUUID);

  const {
    data: getEmployeeRtwFn,
    isLoading: loadingGetEmployeeRtwFn,
    refetch: refetchEmployeeRtwFn
  } = useGetEmployeeRtwByUUID(employeeUUID);

  const {
    data: getLeaveSummary,
    isLoading: loadingLeaveSummary
  } = useGetLeaveSummary(employeeUUID);

  const { data: getEmployeeInfoByUUID, refetch: refetchEmployeeInfoByUUID } =
    useGetEmployeeInfoByUUID(employeeUUID);

  const genderObj = Gender.find(
    (gender) => gender.id === employeeProfileInfoData.gender
  );
  const Gendername = genderObj ? genderObj.name : "";

  const MaritialStatusObj = MaritialStatus.find(
    (maritialstatus) =>
      maritialstatus.id === employeeProfileInfoData.marital_status
  );
  const MaritialStatusname = MaritialStatusObj ? MaritialStatusObj.name : "";

  const RtwStatusObj = RTW_STATUS_CHOICES.find(
    (rtwstatus) =>
      rtwstatus.id === employeeRtwList.rtw_status
  );
  const RtwStatusname = RtwStatusObj ? RtwStatusObj.name : "";

  useEffect(() => {
    if (getEmployeeDetailByUUID) {
      setEmployeeProfileData(getEmployeeDetailByUUID);

      let initials = "";

      if (getEmployeeDetailByUUID.first_name) {
        // Extract the first letter and capitalize it
        const firstNameInitial = getEmployeeDetailByUUID.first_name
          .charAt(0)
          .toUpperCase();
        initials += firstNameInitial;
      }

      if (getEmployeeDetailByUUID.last_name) {
        // Extract the first letter and capitalize it
        const lastNameInitial = getEmployeeDetailByUUID.last_name
          .charAt(0)
          .toUpperCase();
        initials += lastNameInitial;
      }
      setInitialData(initials);
      // refetchEmployeeDetailByUUID();

    }
  }, [getEmployeeDetailByUUID]);


  //UseEffect For pendingAction

  useEffect(() => {
    if (getPendingActionByUUID) {
      setPendingActionData(getPendingActionByUUID);
    }
  }, [getPendingActionByUUID]);

  useEffect(() => {
    if (getEmployeeCosFn) {
      setEmployeeCosList(getEmployeeCosFn);
      // refetchEmployeeCosFn();
    }
  }, [getEmployeeCosFn]);

  useEffect(() => {
    if (getEmployeeRtwFn) {
      setEmployeeRtwList(getEmployeeRtwFn);
      // refetchEmployeeRtwFn();
    }
  }, [getEmployeeRtwFn]);

  //UseEffect For personal Info
  useEffect(() => {
    if (getEmployeeInfoByUUID) {
      setEmployeeProfileInfoData(getEmployeeInfoByUUID);
      // refetchEmployeeInfoByUUID();
    }
  }, [getEmployeeInfoByUUID]);

  //UseEffect For  Education

  useEffect(() => {
    if (getEmployeeEducationFn) {
      setEmployeeEducationList(getEmployeeEducationFn);
    }
  }, [getEmployeeEducationFn]);

  //UseEffect For Certification

  useEffect(() => {
    if (getEmployeeCertificationFn) {
      setEmployeeCertificationList(getEmployeeCertificationFn);
    }
  }, [getEmployeeCertificationFn]);


  useEffect(() => {
    if (getEmployeeDocumentFn) {
      setEmployeeDocumentList(getEmployeeDocumentFn);
      // refetchDocuments();
    }
  }, [getEmployeeDocumentFn]);

  //UseEffect For BankDetails

  useEffect(() => {
    if (getEmployeeBankFn) {
      setEmployeeBankList(getEmployeeBankFn);
      // refetchEmployeeEmployeeBankFn();
    }
  }, [getEmployeeBankFn]);

  //UseEffect For PassPort

  useEffect(() => {
    if (getEmployeePassPortFn) {
      setEmployeePassPortList(getEmployeePassPortFn);
      // refetchEmployeeEmployeePassPortFn();
    }
  }, [getEmployeePassPortFn]);

  //UseEffect For Visa

  useEffect(() => {
    if (getEmployeeVisaFn) {
      setEmployeeVisaList(getEmployeeVisaFn);
      // refetchEmployeeEmployeeVisaFn();
    }
  }, [getEmployeeVisaFn]);

  //UseEffect For Emergency Contact

  useEffect(() => {
    if (getEmergencyContactByUUID) {
      setEmergencyContactData(getEmergencyContactByUUID);
      // refetchEmergencyContactByUUID();
    }
  }, [getEmergencyContactByUUID]);

  const handleProfileModal = () => {
    setProfileExportModal(true)
  }

  return (
    <Fragment>
      <PageTitle activeMenu="Employees Info" motherMenu="Employee" showButton={true} handleOnClick={handleProfileModal} buttonLabel='Export PDF' />
      <div className="row" >

        {LoadingEmployeeDetailByUUID &&
          <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "calc(100vh - 200px)" }}>
            <RiseLoader color="#40189D" />
          </div>
        }

        {/* Permission valid => employee.view_employee */}
        {!LoadingEmployeeDetailByUUID && IsValidPermissions('employee.view_employee') &&
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body" Style="background-color:#F2F2F2;">

                <ProfileImageView
                  LoadingEmployeeDetailByUUID={LoadingEmployeeDetailByUUID}
                  employeeProfileData={employeeProfileData}
                  initialData={initialData}
                  pendingActionData={pendingActionData}
                />

                <PendingAction
                  LoadingPendingActionByUUID={LoadingPendingActionByUUID}
                  pendingActionData={pendingActionData}
                  setCardView={setCardView}
                />

                <div className="email-left-box px-0 mb-3">
                  <SubMenu cardView={cardView} setCardView={setCardView} />
                </div>
                <div
                  className="email-right-box ms-0 ms-sm-4 ms-sm-0"
                  Style="background-color:#F2F2F2;"
                >
                  <div role="toolbar" className="toolbar ms-1 ms-sm-0">
                    {/* Employee Profile */}
                    {isValidPermission('employee.view_employee', getPermissionList) && (cardView?.summary || cardView?.profile) && (
                      // View Profile
                      <Profile
                        setCardView={setCardView}
                        employeeProfileData={employeeProfileData}
                        getPermissionList={getPermissionList}
                      />
                    )}
                    {isValidPermission('employee.change_employee', getPermissionList) && cardView?.profileEdit && (
                      // Update Profile
                      <EmployeeProfile
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                      />
                    )}

                    {/* Personal Info */}
                    {isValidPermission('employee.view_employeepersonalinfo', getPermissionList) && (cardView?.summary || cardView?.personalInfo) &&
                      <PersonalInfoList
                        employeeProfileInfoData={employeeProfileInfoData}
                        Gendername={Gendername}
                        MaritialStatus={MaritialStatus}
                        setCardView={setCardView}
                        getPermissionList={getPermissionList}
                      />
                    }

                    {isValidPermission('employee.change_employeepersonalinfo', getPermissionList) && cardView?.personalInfoEdit && (
                      // Update
                      <PersonalInfo
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                      />
                    )}

                    {/* Emergency contact */}
                    {isValidPermission('employee.view_emergencycontactinfo', getPermissionList) && (cardView?.summary || cardView?.emergencyContact) && (
                      <EmergencyContactList
                        emergencyContactData={emergencyContactData}
                        setCardView={setCardView}
                        getPermissionList={getPermissionList}
                      />
                    )}

                    {isValidPermission('employee.change_emergencycontactinfo', getPermissionList) && cardView?.emergencyContactEdit && (
                      <EmergencyContact
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                      />
                    )}

                    {/* Education */}
                    {isValidPermission('employee.view_employeeeducation', getPermissionList) && (cardView?.summary || cardView?.education) && (
                      <Education
                        setCardView={setCardView}
                        employeeEducationList={employeeEducationList}
                        getPermissionList={getPermissionList}
                        isLoading={loadingGetEmployeeEducationFn}
                      />
                    )}

                    {(isValidPermission('employee.add_employeeeducation', getPermissionList) || isValidPermission('employee.change_employeeeducation', getPermissionList)) && cardView?.educationEdit && (
                      <EmployeeEducation
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                        educationId={cardView?.educationId}
                      />
                    )}

                    {/* Certification */}
                    {isValidPermission('employee.view_employeecertification', getPermissionList) && (cardView?.summary || cardView?.certification) && (
                      <Certification
                        employeeCertificationList={employeeCertificationList}
                        setCardView={setCardView}
                        getPermissionList={getPermissionList}
                        isLoading={loadingGetEmployeeCertificationFn}
                      />
                    )}

                    {(isValidPermission('employee.add_employeecertification', getPermissionList) || isValidPermission('employee.change_employeecertification', getPermissionList)) && cardView?.certificationEdit && (
                      <EmployeeCertification
                        certificateId={cardView?.certificateId}
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                      />
                    )}

                    {/* Bank */}
                    {isValidPermission('employee.view_employeebankdetail', getPermissionList) && (cardView?.summary || cardView?.bank) && (
                      <Bank
                        setCardView={setCardView}
                        employeeBankList={employeeBankList}
                        getPermissionList={getPermissionList}
                        isLoading={loadingGetEmployeeBankFn}
                      />
                    )}

                    {(isValidPermission('employee.add_employeebankdetail', getPermissionList) || isValidPermission('employee.change_employeebankdetail', getPermissionList)) && cardView?.bankEdit && (
                      <BankDetails
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                        bankId={cardView?.bankId}
                      />
                    )}

                    {/* Passport */}
                    {isValidPermission('employee.view_employeepassport', getPermissionList) && (cardView?.summary || cardView?.passport) && (
                      <PassPort
                        setCardView={setCardView}
                        employeePassPortList={employeePassPortList}
                        getPermissionList={getPermissionList}
                        isLoading={loadingGetEmployeePassPortFn}
                      />
                    )}

                    {(isValidPermission('employee.add_employeepassport', getPermissionList) || isValidPermission('employee.change_employeepassport', getPermissionList)) && cardView?.passportEdit && (
                      <EmployeePassport
                        setCardView={setCardView}
                        passportId={cardView?.passportId}
                        employee_uuid={employeeUUID}
                      />
                    )}

                    {/* Visa */}
                    {isValidPermission('employee.view_employeevisa', getPermissionList) && (cardView?.summary || cardView?.visa) && (
                      <Visa
                        employeeVisaList={employeeVisaList}
                        setCardView={setCardView}
                        getPermissionList={getPermissionList}
                        isLoading={loadingGetEmployeeVisaFn}
                      />
                    )}

                    {(isValidPermission('employee.add_employeevisa', getPermissionList) || isValidPermission('employee.change_employeevisa', getPermissionList)) && cardView?.visaEdit && (
                      <Employeevisa
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                        visaId={cardView?.visaId}
                      />
                    )}

                    {/* Change Logs */}
                    {(cardView?.summary || cardView?.changelogs) &&
                      <ChangeLogs />
                    }


                    {/* COS */}
                    {isValidPermission('employee.view_employeecos', getPermissionList) && (cardView?.summary || cardView?.cos) && (
                      <CosView
                        employeeCosList={employeeCosList}
                        setCardView={setCardView}
                        getPermissionList={getPermissionList}
                      />
                    )}

                    {(isValidPermission('employee.add_employeecos', getPermissionList) || isValidPermission('employee.change_employeecos', getPermissionList)) && cardView?.cosEdit && (
                      <EmployeeCos
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                        cosId={cardView?.cosId}
                      />
                    )}

                    {/* RTW */}
                    {isValidPermission('employee.view_employeerighttowork', getPermissionList) && (cardView?.summary || cardView?.rtw) && (
                      <RtwView
                        employeeRtwList={employeeRtwList}
                        setCardView={setCardView}
                        RtwStatusname={RtwStatusname}
                        getPermissionList={getPermissionList}
                      />
                    )}

                    {(isValidPermission('employee.add_employeerighttowork', getPermissionList) || isValidPermission('employee.change_employeerighttowork', getPermissionList)) && cardView?.rtwEdit && (
                      <EmployeeRtw
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                        rtwId={cardView?.rtwId}
                      />
                    )}

                    {/* RTW Employee */}
                    {isValidPermission('employee.can_view_employee_rtw', getPermissionList) && (cardView?.summary || cardView?.employeertw) && (
                      <RtwEmployeeView
                        employee_uuid={employeeUUID}
                        employeeRtwList={employeeRtwList}
                        setCardView={setCardView}
                      />
                    )}

                    {(isValidPermission('employee.can_add_employee_rtw', getPermissionList) || isValidPermission('employee.change_employee_rtw', getPermissionList)) && cardView?.rtemployeertwwEdit && (
                      <EmployeeRtwSharecode
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                        rtwId={cardView?.rtwId}
                      />
                    )}

                    {/* Leaves Employee */}
                    {(cardView?.summary || cardView?.employeeLeaves) && (
                      <LeaveView
                        employee_uuid={employeeUUID}
                        employeeLeaveList={getLeaveSummary}
                        isLoading={loadingLeaveSummary}
                        setCardView={setCardView}
                      />
                    )}

                    {(isValidPermission('employee.view_employeeprofileimage', getPermissionList) || isValidPermission('employee.add_employeeprofileimage', getPermissionList)) && (cardView?.summary || cardView?.image) && (
                      <ProfileImage
                        employee_uuid={employeeUUID}
                        setCardView={setCardView}
                      />
                    )}

                    {/* Certification */}
                    {isValidPermission('employee.view_employeecertification', getPermissionList) && (cardView?.summary || cardView?.documents) && (
                      <Document
                        employeeDocumentList={employeeDocumentList}
                        setCardView={setCardView}
                        getPermissionList={getPermissionList}
                        isLoading={loadingGetEmployeeDocumentFn}
                        refetchDocuments={refetchDocuments}
                      />
                    )}

                    {(isValidPermission('employee.add_employeecertification', getPermissionList) || isValidPermission('employee.change_employeecertification', getPermissionList)) && cardView?.documentsEdit && (
                      <EmployeeDocument
                        documentId={cardView?.documentId}
                        setCardView={setCardView}
                        employee_uuid={employeeUUID}
                        refetchDocuments={refetchDocuments}
                      />
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {(!LoadingEmployeeDetailByUUID && !isValidPermission('employee.view_employee', getPermissionList)) &&
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <Alert variant="warning" show={true}>

                  <svg
                    viewBox='0 0 24 24'
                    width='24'
                    height='24'
                    stroke='currentColor'
                    strokeWidth='2'
                    fill='none'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='me-2'
                  >
                    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                    <line x1='12' y1='9' x2='12' y2='13'></line>
                    <line x1='12' y1='17' x2='12.01' y2='17'></line>
                  </svg>

                  <strong>Info!  </strong> You don't have permission to view Employee Information.

                </Alert>
              </div>
            </div>
          </div>
        }
      </div>
      {profileExportModal && <EmployeeProfileExportModal
        loadingEmployeeDetailByUUID={LoadingEmployeeDetailByUUID}
        profileExportModal={profileExportModal}
        setProfileExportModal={setProfileExportModal}
        employeeProfileData={employeeProfileData}
        employeeProfileInfoData={employeeProfileInfoData}
        emergencyContactData={emergencyContactData}
        employeeEducationList={employeeEducationList}
        employeeCertificationList={employeeCertificationList}
        employeeBankList={employeeBankList}
        employeePassPortList={employeePassPortList}
        employeeVisaList={employeeVisaList}
        employeeCosList={employeeCosList}
        employeeRtwList={employeeRtwList}
        employeeLeaveList={getLeaveSummary}
      />}
    </Fragment>

  );
};
export default EmployeeProfileView;
