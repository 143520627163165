import { Button, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import BankAccountChangeLog from "./change_log/BankAccountChangeLog";
import PersonalInfoChangeLog from "./change_log/PersonalInfoChangeLog";
import EmergencyContactChangeLog from "./change_log/EmergencyContactChangeLog";

function ChangeLogs(props) {
    return (
        <>
            <PersonalInfoChangeLog />
            <EmergencyContactChangeLog/>
            <BankAccountChangeLog/>
        </>
    )
}

export default ChangeLogs;
