import React, { useState } from "react";
import logo from "../../images/logo-full.png";
import { Link } from "react-router-dom";
import { COMMON_MESSAGES, FORGOT_PASSWORD_MESSAGES, VALIDATION_MESSAGES } from "../../utils/ConstantMessages";
import { validateEmail } from "../../utils/CommonUtils";
import { Alert } from "react-bootstrap";
import { useResetPassword } from '../../actions/auth-actions';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailSuccess, setEmailSuccesss] = useState('');

  const { isLoadingLogin, handleShowSignInPage } = props;

  const { mutate: forgotPassword, isLoading: isLoadingPassword } = useResetPassword();

  const handleSubmit = () => {
    if (!email) {
      setEmailError(VALIDATION_MESSAGES.emailRequired);
    } else if (!validateEmail(email)) {
      setEmailError(VALIDATION_MESSAGES.enterValidEmail);
    } else {
      setEmailError('');

      forgotPassword({'email': email}, {
				onSuccess: async (response) => {
          setEmailSuccesss(response?.data?.detail);
          //handleShowSignInPage(true);
				}, onError: (error) => {
          setEmailError(error?.response?.data?.detail);
				}
			});
      // handleShowSignInPage(true);
    }
  }

  return (
    <div className="authincation-content">
          { emailSuccess &&
          <Alert  variant="success" className="solid">
						<strong>Success! </strong> {emailSuccess}
					</Alert>
          }

      <div className="mb-4">
        <h3 className="mb-1 font-w600">{FORGOT_PASSWORD_MESSAGES.forgotPasswordTitle}</h3>
      </div>
      {/* <form onSubmit={handleSubmit}> */}
      <div className="form-group">
        <label className="mb-2 ">
          <strong className="">Email</strong>
        </label>
        <input type="email" className="form-control" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
        {emailError && <div className="text-danger fs-12 mt-2">{emailError}</div>}
      </div>
      <div className="mb-4">
        <p className="font-w400">{FORGOT_PASSWORD_MESSAGES.temporaryPwdContent}</p>
      </div>
      <div className="text-center mt-2">
        <button type="button" className="btn btn-primary btn-block mb-3" onClick={handleSubmit} disabled={isLoadingPassword}>
          {isLoadingPassword ? (
            <>
              {COMMON_MESSAGES.loading}
            </>
          ) : (
            FORGOT_PASSWORD_MESSAGES.submitButton
          )}
        </button>
        <button onClick={() => handleShowSignInPage(true)} type="button" className="btn btn-outline-dark btn-block">
          {FORGOT_PASSWORD_MESSAGES.backButton}
        </button>
      </div>
      {/* </form> */}
    </div>
  );
};

export default ForgotPassword;
