import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const createNewVisaType = async (data) => {
    let config = {
      method: "post",
      url: "employee/api/v1/employee_visa_type/",
      headers: {
        "Content-Type": "application/json",
      },
      data:data
    };
    
    return await axios.create().request(config)
  };
  export const useCreateVisaType = () => {
    return useMutation(["announcement-register"], createNewVisaType);
  };

  
const getAllVisaType = async () => {
  let config = {
    method: "get",
    url: "employee/api/v1/employee_visa_type/",
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const response = await axios.request(config);
  return response?.data;
};
export const useGetVisaTypeList = () => {
  return useQuery(["get-all-visatype"], getAllVisaType);
};


const getVisaTypeDetail = async (query) => {
  let [, id] = query.queryKey;
  let config = {
    method: "get",
    url: `employee/api/v1/employee_visa_type/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const response = await axios.request(config);
  return response?.data;
};
export const useGetVisaTypeDetail = (id) => {
  return useQuery(["get-visatype-by-id", id], getVisaTypeDetail,{enabled: id?true:false});
};

const visatypeUpdate = async (data) => {
  let {id, visatype} = data;
  let config = {
    method: "patch",
    url: `employee/api/v1/employee_visa_type/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: visatype,
  };
  return await axios.request(config);
};
export const useVisaTypeUpdate = () => {
  return useMutation(["visatype-update"], visatypeUpdate);
};

//API DELETE METHOD

const visaTypeDeleteById = async (id) => {
  try {
    const response = await axios.delete(`/employee/api/v1/employee_visa_type/${id}/`);
    return response.data;
  } catch (error) {
    // Handle error or throw it again
    throw error;
  }
};

export const useVisaTypeDeleteById = () => {
  return useMutation(["visa-type-delete"], visaTypeDeleteById);
};

