import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";


const getNotificationsFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/notification/api/v1/notify/?employee__employee_uuid=${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetNotificationsFn = (employee_uuid) => {
    return useQuery(["get-notifications", employee_uuid], getNotificationsFn);
};


// API PATCH Method
const notificationsUpdateFn = async (employeeData) => {

    let { id, userData } = employeeData;
    let config = {
        method: "patch",
        url: `/notification/api/v1/notify/${id}/`,
        headers: {
            "Content-Type": "application/json",
        },
        data: userData,
    };

    return await axios.request(config);
};
export const useNotificationsUpdateFn = (id) => {
    return useMutation(["notifications-update", id], notificationsUpdateFn);
};