import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { isValidPermission } from "../../../../services/AuthService";
import { handleFileDownload } from '../../../../utils/ComponentUtilse';
import { dateHandler } from "../../../../utils/ComponentUtilse";
import { ServiceContext } from "../../../../context/ServiceContext";


function CosView(props) {

    const { canEditEmployee } = useContext(ServiceContext);


    return (
        <div className="card" id="cos">
            <>
                <div className="card-header d-flex justify-content-between">
                    <h4 className="card-title">
                        <i className="fas fa-address-card font-18 align-middle me-2 text-primary"></i>
                        &nbsp; COS
                    </h4>
                    {canEditEmployee && (isValidPermission('employee.add_employeecos', props.getPermissionList) || isValidPermission('employee.change_employeecos', props.getPermissionList)) &&
                        <Button
                            className="me-2 btn-sm"
                            variant="primary"
                            onClick={() => {
                                props.setCardView({ cosEdit: true, cosId: props?.employeeCosList?.[0]?.id });
                            }}
                        >
                            <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;{props?.employeeCosList?.[0]?.id ? "Edit" : "Add"}
                        </Button>
                    }
                </div>
                <div className="card-body">
                    <div className="form-validation">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="form-group mb-3 row">
                                    <label className="col-lg-4 col-form-label">
                                        licence number:
                                    </label>
                                    <label className="col-lg-8 col-form-label">
                                        {props?.employeeCosList?.[0]?.sponsor_licence_number}
                                    </label>
                                    <label className="col-lg-4 col-form-label">
                                        Sponsor name:
                                    </label>
                                    <label className="col-lg-8 col-form-label">
                                        {props?.employeeCosList?.[0]?.sponsor_name}
                                    </label>
                                    <label className="col-lg-4 col-form-label">
                                        Certificate number:
                                    </label>
                                    <label className="col-lg-8 col-form-label">
                                        {props?.employeeCosList?.[0]?.certificate_number}
                                    </label>
                                    <label className="col-lg-4 col-form-label">
                                        Certificate date:
                                    </label>
                                    <label className="col-lg-8 col-form-label">
                                        {dateHandler(props?.employeeCosList?.[0]?.certificate_date)}
                                    </label>
                                    <label className="col-lg-4 col-form-label">
                                        Assigned date:
                                    </label>
                                    <label className="col-lg-8 col-form-label">
                                        {dateHandler(props?.employeeCosList?.[0]?.assigned_date)}
                                    </label>
                                    <label className="col-lg-4 col-form-label">
                                        Expiry date:
                                    </label>
                                    <label className="col-lg-8 col-form-label">
                                        {dateHandler(props?.employeeCosList?.[0]?.expiry_date)}
                                    </label>
                                    <label className="col-lg-4 col-form-label">
                                        Sponsor note:
                                    </label>
                                    <label className="col-lg-8 col-form-label">
                                        {props?.employeeCosList?.[0]?.sponsor_note}
                                    </label>
                                    <label className="col-lg-4 col-form-label">
                                        Attachment:
                                    </label>
                                    <label className="col-lg-8 col-form-label">
                                        {props?.employeeCosList?.[0]?.attachment_url ? <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleFileDownload(props?.employeeCosList?.[0]?.attachment_url)}><i className="fa fa-download"></i></Button> : null}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
        // <div className="card">
        //     <div className="card-header d-flex justify-content-between">
        //         <h4 className="card-title">
        //             <i className="fa fa-newspaper font-18 align-middle me-2 text-primary"></i>
        //             &nbsp; COS
        //         </h4>
        //         <Button
        //             className="me-2 btn-sm"
        //             variant="primary"
        //             onClick={() => {
        //                 props.setCardView({ cosEdit: true });
        //             }}
        //         >
        //             <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Add
        //         </Button>
        //     </div>
        //     <div className="card-body">
        //         {/* <ListEmployeeEducation /> */}
        //         <Table responsive>
        //             <thead>
        //                 <tr>
        //                     <th>Sponsor licence number</th>
        //                     <th>Sponsor name</th>
        //                     <th>Certificate number</th>
        //                     <th>Certificate date</th>
        //                     <th>Assigned date</th>
        //                     <th>Expiry date</th>
        //                     <th>Sponsor note</th>
        //                 </tr>
        //             </thead>
        //             <tbody>
        //                 {props.employeeCosList.map((item, i) => {
        //                     return (
        //                         <tr>
        //                             <td>{item.sponsor_licence_number}</td>
        //                             <td>{item.sponsor_name}</td>
        //                             <td>{item.certificate_number}</td>
        //                             <td>{item.certificate_date}</td>
        //                             <td>{item.assigned_date}</td>
        //                             <td>{item.expiry_date}</td>
        //                             <td>{item.sponsor_note}</td>
        //                             <td><Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i></Button></td>
        //                         </tr>
        //                     );
        //                 })}
        //             </tbody>
        //         </Table>
        //     </div>
        // </div>
    )
}

export default CosView;