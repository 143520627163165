import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const recruitmentPostFn = async (userData) => {
    let config = {
        method: "post",
        url: `/recruitment/api/v1/job_vacancy/`,
        headers: {
            "Content-Type": "application/json",
        },
        data: userData,
    };

    return await axios.create().request(config);
};
export const useRecruitmentPostFn = () => {
    return useMutation(["recruitment-post"], recruitmentPostFn);
};


const recruitmentUpdateFn = async (employeeData) => {
    let { id, userData } = employeeData;
    let config = {
        method: "patch",
        url: `/recruitment/api/v1/job_vacancy/${id}/`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: userData,
    };
    return await axios.request(config);
};
export const useRecruitmentUpdateFn = () => {
    return useMutation(["recruitment-update"], recruitmentUpdateFn);
};


const getRecruitmentFn = async () => {
    let config = {
        method: "get",
        url: "/recruitment/api/v1/job_vacancy/",
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useRecruitments = () => {
    return useQuery(["get-recruitment"], getRecruitmentFn);
};

const getJobInfoDetailByIDFn = async (query) => {
    let [, id] = query.queryKey;
    let config = {
        method: "get",
        url: `/recruitment/api/v1/job_vacancy/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};

export const useGetJobInfoDetailByID = (id) => {
    return useQuery(["get-job-info-detail-by-id", id], getJobInfoDetailByIDFn);
};

// Advertisement API GET Method

const getAdvertisementByIDFn = async (query) => {
    let [, id] = query.queryKey;
    let config = {
        method: "get",
        url: `recruitment/api/v1/rlmt_details/?job_id__job_id=${id}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};

export const useGetAdvertisementByJobID = (id) => {
    return useQuery(["get-rlmt-details-by-jobid", id], getAdvertisementByIDFn);
};

// Application API GET Method

const getApplicationByIDFn = async (query) => {
    let [, id] = query.queryKey;
    let config = {
        method: "get",
        url: `recruitment/api/v1/job_application/?job_id__job_id=${id}`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};

export const useGetApplicationByID = (id) => {
    return useQuery(["get-application-details-by-jobid", id], getApplicationByIDFn);
};

const jobUpdateFn = async (jobData) => {
    let {jobId, jobDetailData} = jobData;
    let config = {
      method: "patch",
      url: `/recruitment/api/v1/job_vacancy/${jobId}/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: jobDetailData,
    };
    return await axios.request(config);
  };
  export const useJobUpdateFn = () => {
    return useMutation(["job-vacancy-update"], jobUpdateFn);
  };

const getInterviewStatusFn = async () => {
let config = {
    method: "get",
    url: "/recruitment/api/v1/interview_status/",
    headers: {
        "Content-Type": "application/json",
    },
};

const response = await axios.request(config);
    return response?.data;
};
export const useGetInterviewStatus = () => {
    return useQuery(["get-interview-status"], getInterviewStatusFn);
};