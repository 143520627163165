import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAllDashBoardSummary = async () => {
    let config = {
        method: "get",
        url: "/dashboard/admin-dashboard_summary/",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await axios.request(config);
    return response?.data;
};

export const useDashBoardSummary = () => {
    return useQuery(["get-dashboard-summary"], getAllDashBoardSummary);
};

const getAllSponseredEmployees = async () => {
    let config = {
        method: "get",
        url: "/dashboard/dashboard-sponsered-employee/",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await axios.request(config);
    return response?.data;
};

export const useSponseredEmployees = () => {
    return useQuery(["get-sponsered-employee"], getAllSponseredEmployees);
};

const getAllPassportExpiry = async () => {
    let config = {
        method: "get",
        url: "/dashboard/dashboard-passport-expiry-list/",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await axios.request(config);
    return response?.data;
};

export const usePassportExpiry = () => {
    return useQuery(["get-passport-expiry-employee"], getAllPassportExpiry);
};

const getAllVisaExpiry = async () => {
    let config = {
        method: "get",
        url: "/dashboard/dashboard-visa-expiry-list/",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await axios.request(config);
    return response?.data;
};

export const useVisaExpiry = () => {
    return useQuery(["get-visa-expiry-employee"], getAllVisaExpiry);
};

const getAllCOSExpiry = async () => {
    let config = {
        method: "get",
        url: "/dashboard/dashboard-cos-expiry-list/",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await axios.request(config);
    return response?.data;
};

export const useCOSExpiry = () => {
    return useQuery(["get-cos-expiry-employee"], getAllCOSExpiry);
};


const getAllRTWExpiry = async () => {
    let config = {
        method: "get",
        url: "/dashboard/dashboard-rtw-expiry-list/",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const response = await axios.request(config);
    return response?.data;
};

export const useRTWExpiry = () => {
    return useQuery(["get-rtw-expiry-employee"], getAllRTWExpiry);
};