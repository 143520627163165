import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const passwordUpdateFn = async (data) => {
    let { new_password, current_password } = data;
    let config = {
        method: "patch",
        url: `/user_management/current-password-reset/`,
        headers: {
            "Content-Type": "application/json",
        },
        data: { 'new_password': new_password, 'current_password': current_password },
    };
    return await axios.request(config);
};
export const usePasswordUpdateFn = () => {
    return useMutation(["password-update"], passwordUpdateFn);
};