import axios from 'axios';
import { isExpired, decodeToken } from "react-jwt";
import { compose, indexOf } from 'ramda';
import { useContext } from 'react';
import { ServiceContext } from "../context/ServiceContext";


export function getQueryParamFromUrl(url, paramName) {
    const parsedUrl = new URL(url);
    return parsedUrl.searchParams.get(paramName);
}

export function sessionLogout(navigate, setIsAuthenticated) {
    localStorage.clear();
    const currentUrl = window.location.href;
    const email_id = getQueryParamFromUrl(currentUrl, 'user_email');
    setIsAuthenticated(false);

    if (window.location.pathname.includes('/confirm-password/')) {
        return
    }

    if (email_id) {
        navigate(`/login?user_email=${email_id}`, { state: { emailId: email_id } });
    }
    else {
        navigate(`/login`);
    }
}

export function runLogoutTimer(timer, navigate) {
    setTimeout(() => {
        sessionLogout(navigate);
    }, timer);
}

export function checkAutoLogin(navigate, setIsAuthenticated, getPermissionList, setPermissionsList, setEmployeeUUID, getCompanyList, setCompanyList, setEmployeeFullName, setIsAdminUser, setIsSubsriptionActive, setMaxNumberOfUser, setIsRecruitmentEnabled, setCanEditEmployee) {

    const tokenDetailsString = localStorage.getItem('access_token');
    const employee_uuid = localStorage.getItem('employee_uuid');
    const employee_fullname = localStorage.getItem('employee_fullname');

    if (!tokenDetailsString) {
        sessionLogout(navigate, setIsAuthenticated);
        return;
    }

    if (!employee_uuid) {
        sessionLogout(navigate, setIsAuthenticated);
        return;
    }

    const decodedUserToken = decodeToken(tokenDetailsString);
    const isTokerExpiry = isExpired(tokenDetailsString)

    if (isTokerExpiry) {
        sessionLogout(navigate, setIsAuthenticated);
        return;
    }

    let expireDate = new Date(decodedUserToken.exp);
    let todaysDate = new Date();
    const timer = expireDate.getTime() * 1000 - todaysDate.getTime();
    runLogoutTimer(timer, navigate);

    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenDetailsString}`;

    setIsAuthenticated(true);
    setEmployeeUUID(employee_uuid);
    setEmployeeFullName(employee_fullname);

    if (getPermissionList.length === 0) {
        getUserPermission(setPermissionsList, setIsAdminUser, setIsSubsriptionActive, setMaxNumberOfUser, setIsRecruitmentEnabled, setCanEditEmployee);
    }

    if (getCompanyList.length === 0) {
        getUserCompany(setCompanyList);
    }
}

export async function getUserPermission(setPermissionsList, setIsAdminUser, setIsSubsriptionActive, setMaxNumberOfUser, setIsRecruitmentEnabled, setCanEditEmployee) {
    let config = {
        method: "get",
        url: "user_management/user-permissions/",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const resp = await axios.request(config);

    if (resp?.status === 200) {
        setPermissionsList(resp?.data?.user_permissions);
        setIsAdminUser(resp?.data?.is_admin_account);
        setIsSubsriptionActive(resp?.data?.subscription_is_active);
        setMaxNumberOfUser(resp?.data?.number_of_users);
        setIsRecruitmentEnabled(resp?.data?.is_recruitment_enabled);
        setCanEditEmployee(resp?.data?.can_edit_employee);
    } else {
        setPermissionsList([])
    }
}


export async function getUserCompany(setCompanyList) {
    let config = {
        method: "get",
        url: "organization/api/v1/company/",
        headers: {
            "Content-Type": "application/json",
        },
    };
    const resp = await axios.request(config);

    if (resp?.status === 200) {
        setCompanyList(resp?.data);
    } else {
        setCompanyList([]);
    }
}



export function isLogin() {
    const tokenDetailsString = localStorage.getItem('access_token');

    if (tokenDetailsString) {
        return true;
    } else {
        return false;
    }
}

export function isValidPermission(perms, getPermissionList) {
    if (indexOf(perms, getPermissionList) >= 0) {
        return true;
    }
    return false; //ToDo: temp true until user permission implementation
}

export function IsValidPermissions(perms) {

    const { getPermissionList } = useContext(ServiceContext);

    if (indexOf(perms, getPermissionList) >= 0) {
        return true;
    }
    return false; //ToDo: temp true until user permission implementation
}