import React, { createContext, useEffect, useState } from "react";

export const ServiceContext = createContext();

const ServiceContextProvider = (props) => {

  const [getPermissionList, setPermissionsList] = useState([]);

  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const [getEmployeeUUID, setEmployeeUUID] = useState('');

  const [getEmployeeFullName, setEmployeeFullName] = useState('');

  const [getCompanyList, setCompanyList] = useState([]);

  const [getSideMenu, setSideMenu] = useState(true);

  const [isAdminUser, setIsAdminUser] = useState(null);

  const [isRecruitmentEnabled, setIsRecruitmentEnabled] = useState(null);

  const [employeeOnboardModal, setEmployeeOnboardModal] = useState(false);

  const [recruitmentModal, setRecruitmentModal] = useState(false);

  const [isSubsriptionActive, setIsSubsriptionActive] = useState(false);

  const [maxNumberOfUser, setMaxNumberOfUser] = useState();

  const [canEditEmployee, setCanEditEmployee] = useState(false);

  return (
    <ServiceContext.Provider
      value={{
        isRecruitmentEnabled,
        setIsRecruitmentEnabled,
        isAuthenticated,
        setIsAuthenticated,
        getEmployeeUUID,
        setEmployeeUUID,
        getPermissionList,
        setPermissionsList,
        getSideMenu,
        setSideMenu,
        isAdminUser,
        setIsAdminUser,
        employeeOnboardModal,
        setEmployeeOnboardModal,
        getCompanyList,
        setCompanyList,
        getEmployeeFullName,
        setEmployeeFullName,
        recruitmentModal,
        setRecruitmentModal,
        isSubsriptionActive,
        setIsSubsriptionActive,
        maxNumberOfUser,
        setMaxNumberOfUser,
        canEditEmployee,
        setCanEditEmployee
      }}
    >
      {props.children}
    </ServiceContext.Provider>
  );
};

export default ServiceContextProvider;