import React, { useContext } from "react";
import { useDispatch , useSelector } from 'react-redux';
/// React router dom
import { Link } from "react-router-dom";
import { navtoggle } from "../../../store/actions/AuthActions";
import { ServiceContext } from "../../../context/ServiceContext";
/// images
// import logo from "../../../images/logo.png";
import logo from "../../../images/logo/ukvicas.png";
import logoText from "../../../images/logo-text.png";

const NavHader = () => {
   //const [toggle, setToggle] = useState(false);
   const dispatch = useDispatch();
   const sideMenu = useSelector(state => state.sideMenu);
   const { getSideMenu, setSideMenu, getCompanyList } = useContext(ServiceContext);	
   const handleToogle = () => {
     dispatch(navtoggle(!sideMenu));
   };
   return (
      <div className="nav-header d-flex justify-content-center">
         {(window.innerWidth < 768 || sideMenu) && 
         <Link to="/" className="brand-logo">
            <img className="brand-title-custom" src={getCompanyList[0]?.logo_url ? `${getCompanyList[0]?.logo_url}`: logo} alt="brand logo" width={"80%"} />
         </Link>
         }

         {(window.innerWidth > 768 && !sideMenu) && 
         
         <div className="fs-18" style={{"padding-top": "10%"}}>
         <Link to="/" style={{fontWeight: "bolder", color: "aliceblue", textShadow: "4px 4px 2px rgba(0,0,0,0.6)"}} >{ getCompanyList[0]?.company_name }</Link> </div>
         
         }
         

         

         <div className="nav-control" 
            onClick={() => {              
               handleToogle()
             }}
         >
            <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
