import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";


const getAllCompany = async () => {
    let config = {
      method: "get",
      url: "organization/api/v1/company/",
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetCompanyList = () => {
    return useQuery(["get-all-company"], getAllCompany);
  };

  const createNewCompany = async (companyData) => {
    let config = {
      method: "post",
      url: "organization/api/v1/company/",
      headers: {
        "Content-Type": "application/json",
      },
      data:companyData
    };
    
    return await axios.create().request(config)
  };
  export const useCreateCompany = () => {
    return useMutation(["company-register"], createNewCompany);
  };

  
  const getCompanyById = async (query) => {
    let [, id] = query.queryKey;
    let config = {
      method: "get",
      url: `organization/api/v1/company/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetCompanyById = (id) => {
    return useQuery(["get-company-by-id", id], getCompanyById,{enabled: id ? true:false});
  };

  const companyUpdateById = async (data) => {
    let {id, newCompany} = data;
    let config = {
      method: "patch",
      url: `organization/api/v1/company/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: newCompany,
    };
    return await axios.request(config);
  };
  export const useCompanyUpdateById = () => {
    return useMutation(["company-update"], companyUpdateById);
  };
