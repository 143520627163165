import { useState, useEffect } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { dateTimeHandler } from "../../../../../utils/ComponentUtilse";
import {
    useGetPhoneNumberLogByUUID,
    useGetEmailLogByUUID,
    useGetAddressLogByUUID,
    useGetWorkPhoneLogByUUID
} from "../../../../../actions/changelog-actions";
import { useParams } from "react-router-dom";


function PersonalInfoChangeLog() {

    const { employee_uuid } = useParams();
    const [employeeUUID] = useState(employee_uuid);
    const [emailLog, setEmailLog] = useState([]);
    const [addressLog, setAddressLog] = useState([]);
    const [phoneNumberLog, setPhoneNumberLog] = useState([]);
    const [workPhoneLog, setWorkPhoneLog] = useState([]);

    const {
        data: getEmailLog,
        isLoading: loadingEmailLog
    } = useGetEmailLogByUUID(employeeUUID);

    const {
        data: getAddressLog,
        isLoading: loadingAddressLog
    } = useGetAddressLogByUUID(employeeUUID);

    const {
        data: getPhoneNumberLog,
        isLoading: loadingPhoneNumberLog
    } = useGetPhoneNumberLogByUUID(employeeUUID);

    const {
        data: getworkPhoneLog,
        isLoading: loadingworkPhoneLog
    } = useGetWorkPhoneLogByUUID(employeeUUID);

    useEffect(() => {
        if (getEmailLog) {
            setEmailLog(getEmailLog);
        }
    }, [getEmailLog]);

    useEffect(() => {
        if (getPhoneNumberLog) {
            setPhoneNumberLog(getPhoneNumberLog);
        }
    }, [getPhoneNumberLog]);

    useEffect(() => {
        if (getAddressLog) {
            setAddressLog(getAddressLog);
        }
    }, [getAddressLog]);

    useEffect(() => {
        if (getworkPhoneLog) {
            setWorkPhoneLog(getworkPhoneLog);
        }
    }, [getworkPhoneLog]);

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">
                    <i className="fa fa-id-badge font-18 align-middle me-2 text-primary"></i>
                    &nbsp; Personal Info Change History
                </h4>
            </div>
            <div className="card-body">
                <h5>
                    {/* <i className="fas fa-user-graduate font-18 align-middle me-2 text-primary"></i> */}
                    &nbsp; Address Change History
                </h5>
                <hr />
                {/* <ListEmployeeEducation /> */}
                <Table responsive striped> 
                    <thead>
                        <tr>
                            <th>Address1</th>
                            <th>Address2</th>
                            <th>City</th>
                            <th>Country</th>
                            <th>County</th>
                            <th>Postcode</th>
                            <th>Date Changed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loadingAddressLog ?
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={7}>
                                    <Spinner animation="border" size="lg" className="me-2" />
                                </td>
                            </tr>
                            : addressLog?.length > 0
                                ? addressLog.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item.address1}</td>
                                            <td>{item.address2}</td>
                                            <td>{item.city}</td>
                                            <td>{item.country}</td>
                                            <td>{item.state}</td>
                                            <td>{item.zip_code}</td>
                                            <td>{dateTimeHandler(item.created_at)}</td>
                                        </tr>
                                    );
                                })
                                : <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={7}>No records found</td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card-body">
                        <h5>
                            {/* <i className="fas fa-user-graduate font-18 align-middle me-2 text-primary"></i> */}
                            &nbsp; Contact Number Change History
                        </h5>
                        <hr />
                        {/* <ListEmployeeEducation /> */}
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>Contact Number</th>
                                    <th>Date Changed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingworkPhoneLog ?
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={2}>
                                            <Spinner animation="border" size="lg" className="me-2" />
                                        </td>
                                    </tr>
                                    : workPhoneLog.length > 0
                                        ? workPhoneLog.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{item.mobile_phone}</td>
                                                    <td>{dateTimeHandler(item.created_at)}</td>
                                                </tr>
                                            );
                                        })
                                        : <tr>
                                            <td style={{ textAlign: 'center' }} colSpan={2}>No records found</td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card-body">
                        <h5>
                            {/* <i className="fas fa-user-graduate font-18 align-middle me-2 text-primary"></i> */}
                            &nbsp; Email Change History
                        </h5>
                        <hr />
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Date Changed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadingEmailLog ?
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={5}>
                                            <Spinner animation="border" size="lg" className="me-2" />
                                        </td>
                                    </tr>
                                    : emailLog.length > 0
                                        ? emailLog.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{item.email}</td>
                                                    <td>{dateTimeHandler(item.created_at)}</td>
                                                </tr>
                                            );
                                        })
                                        : <tr>
                                            <td style={{ textAlign: 'center' }} colSpan={5}>No records found</td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </div>

                </div>


            </div>



        </div>

    )

}
export default PersonalInfoChangeLog;