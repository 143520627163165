import React, { useContext } from "react";
import { Button, Spinner, Table, Badge } from "react-bootstrap";
import { isValidPermission } from "../../../../services/AuthService";
import { handleFileDownload } from '../../../../utils/ComponentUtilse';
import CustomTooltip from "../../../../utils/CommonUtils";
import { ServiceContext } from "../../../../context/ServiceContext";

function Bank(props) {

    const { canEditEmployee } = useContext(ServiceContext);

    const handleEdit = (id) => {
        props.setCardView({ bankEdit: true, bankId: id })
    }

    return (
        <div className="card" id="bank">
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">
                    <i class="fas fa-university font-18 align-middle me-2 text-primary"></i>
                    &nbsp; Bank Details
                </h4>
                {canEditEmployee && isValidPermission('employee.add_employeebankdetail', props.getPermissionList) &&
                    <Button className="me-2 btn-sm" variant="primary"
                        onClick={() => {
                            props.setCardView({ bankEdit: true });
                        }}>
                        <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Add
                    </Button>
                }
            </div>
            <div className="card-body">
                <Table responsive>
                    <thead>
                        <tr>
                            <th className="minWidth200">Account Number</th>
                            <th className="minWidth150">Sort Code</th>
                            <th className="minWidth200">Bank Name</th>
                            <th className="minWidth50">Primary Account</th>
                            {isValidPermission('employee.change_employeebankdetail', props.getPermissionList) &&
                                <th className="minWidth100">Action</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {props.isLoading ?
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={5}>
                                    <Spinner animation="border" size="lg" className="me-2" />
                                </td>
                            </tr>
                            : props.employeeBankList.length > 0
                                ? props.employeeBankList.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item.account_number}</td>
                                            <td>{item.sort_code}</td>
                                            <td>{item.bank_name}</td>

                                            <td className="text-center">
                                                {item.primary_account ?
                                                    <Badge pill bg="success" className='badge-sm text-capitalize'>Yes</Badge> :
                                                    <Badge pill bg="danger" className='badge-sm text-capitalize'>No</Badge>
                                                }
                                            </td>
                                            {canEditEmployee && isValidPermission('employee.change_employeebankdetail', props.getPermissionList) &&
                                                <td>
                                                    <CustomTooltip id={`edit-${item.index}`} message="Edit">
                                                        <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i></Button>
                                                    </CustomTooltip>
                                                    {item.attachment_url ?
                                                        <CustomTooltip id={`download-${item.index}`} message="Download">
                                                            <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleFileDownload(item.attachment_url)}><i className="fa fa-download"></i></Button>
                                                        </CustomTooltip> : null}
                                                </td>
                                            }
                                        </tr>
                                    );
                                })
                                : <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={5}>No records found</td>
                                </tr>}
                    </tbody>
                </Table>
            </div>
        </div>

    )
}
export default Bank;