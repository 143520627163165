import React, { Fragment } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useRef } from "react";
import { useLocation } from "react-router-dom/dist";
import {
  useCountryUpdate,
  useCreateCountry,
  useGetCountryDetail,
} from "../../actions/country-action";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from "../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";
import LoadingOverlay from 'react-loading-overlay';


function AddCountry() {
  const navigate = useNavigate();

  const FormRef = useRef(null);

  const location = useLocation();
  let id = location?.state?.rowId;

  const [country, setcountry] = useState({
    country_of_issue: "",
    description: "",
  });

  const handleChange = (e) => {
    setcountry((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const { data: countryDetail, isLoading: loadingCountryFn } = useGetCountryDetail(location?.state?.rowId);

  const { mutate: postNewCountry, isLoading: loadingpostNewCountryFn } =
    useCreateCountry();
  const { mutate: patchCountry, isLoading: loadingpatchCountryInfoFn } =
    useCountryUpdate();

  useEffect(() => {
    if (location?.state?.rowId) {
      setcountry(countryDetail);
    }
  }, [countryDetail]);

  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();
    if (FormValidity === true && location?.state?.rowId) {
      try {
        patchCountry(
          { id, country },
          {
            onSuccess: (response) => {
              toast.success("Country  Updated", TostifyConfig);
              navigate("/list-country/");
            },
          }
        );
      } catch (error) {
        console.error(error);
      }
    } else if (FormValidity === true) {
      try {
        postNewCountry(country, {
          onSuccess: (response) => {
            toast.success("Country  Added", TostifyConfig);
            navigate("/list-country/");
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Settings" motherMenu="Home" />
      <div>
        <div className="col-xl-12 col-xxl-12">
          <Card className="az-card">
            <Card.Header>
              <h3>{id ? "Edit Country" : "New Country"}</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingCountryFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-lg-6 mb-2">
                        <label className="text-label">
                          Country Of Issue<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="country_of_issue"
                          value={country?.country_of_issue}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>

                      <Col className="col-md-6 mb-2">
                        <label className="text-label">
                          Description
                        </label>
                        <Form.Control
                          Style="height:70px"
                          as="textarea"
                          rows={5}
                          placeholder="Type Text"
                          name="description"
                          value={country?.description}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mt-2">
                    <Row>
                      <Col className="col-md-12">
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              navigate("/list-country");
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button variant="primary btn-sm"
                           onClick={handleSubmit}
                           disabled={loadingCountryFn && id|| loadingpostNewCountryFn || loadingpatchCountryInfoFn}
                           
                           >
                            {loadingpostNewCountryFn ||
                              loadingpatchCountryInfoFn ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                />
                                Loading...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default AddCountry;
