import React, { Fragment } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useRef } from "react";
import { useLocation } from "react-router-dom/dist";
import {
  useNationalityUpdate,
  useCreateNationality,
  useGetNationalityDetail,
} from "../../actions/nationality-action";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from "../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";


function AddNationality() {
  const navigate = useNavigate();

  const FormRef = useRef(null);

  const location = useLocation();

  let id = location?.state?.rowId;

  const [nationality, setnationality] = useState({
    nationality: "",
    description: "",
  });

  const handleChange = (e) => {
    setnationality((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const { data: nationalityDetail } = useGetNationalityDetail(
    location?.state?.rowId
  );
  
  const { mutate: postNewNationality, isLoading: loadingpostNewNationalityFn } =
  useCreateNationality();
  
  const { mutate: patchNationality, isLoading: loadingpatchNationalityInfoFn } =
  useNationalityUpdate();

  useEffect(() => {
    if (location?.state?.rowId) {
        setnationality(nationalityDetail);
    }
  }, [nationalityDetail]);

  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();
    if (FormValidity === true && location?.state?.rowId) {
      try {
        patchNationality(
          { id, nationality },
          {
            onSuccess: (response) => {
              toast.success("Nationality  Updated", TostifyConfig);
              navigate("/list-nationality/");
            },
          }
        );
      } catch (error) {
        console.error(error);
      }
    } else if (FormValidity === true) {
      try {
        postNewNationality(nationality, {
          onSuccess: (response) => {
            toast.success("Nationality  Added", TostifyConfig);
            navigate("/list-nationality/");
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Settings" motherMenu="Home" />
      <div>
        <div className="col-xl-12 col-xxl-12">
          <Card className="az-card">
            <Card.Header>
              <h3>{id ? "Edit Nationality" : "New Nationality"}</h3>
            </Card.Header>
            <Card.Body>
              <form ref={FormRef}>
                <Form.Group className="mb-3">
                  <Row>
                    <Col className="col-lg-6 mb-2">
                      <label className="text-label">
                      Nationality<span className="required">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        placeholder="Type Text"
                        name="nationality"
                        value={nationality?.nationality}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                    
                    <Col className="col-md-6 mb-2">
                      <label className="text-label">
                        Description
                      </label>
                      <Form.Control
                        Style="height:70px"
                        as="textarea"
                        rows={5}
                        placeholder="Type Text"
                        name="description"
                        value={nationality?.description}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mt-2">
                  <Row>
                    <Col className="col-md-12">
                    <hr />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="danger light btn-sm"
                          onClick={() => {
                            navigate("/list-nationality/");
                          }}
                        >
                          Cancel
                        </Button>
                        &nbsp;&nbsp;



                        <Button variant="primary btn-sm" onClick={handleSubmit}>
                          {loadingpostNewNationalityFn ||
                          loadingpatchNationalityInfoFn ? (
                            <>
                              <Spinner
                                animation="border"
                                size="sm"
                                className="me-2"
                              />
                              Loading...
                            </>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default AddNationality;
