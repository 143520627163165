import React, { Fragment } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useRef } from "react";
import {
  useEmployeeUpdateFn,
  useGetEmployeeByUUID,
} from "../../actions/employee-actions";
import {
  useEmployeePosition,
  useEmployeeWorkLocation,
  useEmployeeInfo,
  // useEmployeeClient,
} from "../../actions/employee-actions";
import {
  useEmployeeProject,
  // useEmployeeTeam
} from "../../actions/organization-action";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { Title, TostifyConfig } from "../../utils/Constance";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import LoadingOverlay from "react-loading-overlay";
import { extractFileName } from "../../utils/ComponentUtilse";

function EmployeeProfile(props) {
  const client = useQueryClient();

  const employee_uuid = props?.employee_uuid;

  const [isChecked, setIsChecked] = useState(false);

  const [skills, setSkills] = useState([]);

  const [employeeProfileData, setEmployeeProfileData] = useState({});

  const [currentAttachmentData, setCurrentAttachmentData] = useState(null);

  const [selectClientProject, setClientProject] = useState({
    clients: "",
    projects: "",
  });

  const [phoneError, setPhoneError] = useState("");

  const FormRef = useRef(null);

  const { data: getEmployeePosition, isLoading: loadingPosition } =
    useEmployeePosition();

  const { data: getEmployeeWorkLocation, isLoading: loadingWorkLocation } =
    useEmployeeWorkLocation();

  const { data: getEmployeeInfo, isLoading: loadingEmployeeInfo } =
    useEmployeeInfo();

  const {
    data: getEmployeeProject,
    isLoading: loadingProject,
    refetch: refetchEmployeeProject,
  } = useEmployeeProject(selectClientProject?.clients);

  const {
    data: getEmployeeByUUID,
    isLoading: loadingEmployeeByUUID,
    refetch: refetchEmployeeByUUID,
  } = useGetEmployeeByUUID(employee_uuid);

  const { mutate: patchEmployeeFn, isLoading: createEmployeePostLoading } =
    useEmployeeUpdateFn();

  const updateEmployeeHandeler = () => {
    let FormValidity = FormRef.current.reportValidity();

    // Check if phone number is valid
    const startsWithZero = /^0/;
    const tenDigits = /^0\d{10}$/;
    if (!startsWithZero.test(employeeProfileData.work_phone) || !tenDigits.test(employeeProfileData.work_phone)) {
      setPhoneError("Mobile Number Format is: 07800123456");
      return;
    }

    if (FormValidity === true) {
      let userData = {
        title: employeeProfileData.title,
        first_name: employeeProfileData.first_name,
        middle_name: employeeProfileData.middle_name,
        last_name: employeeProfileData.last_name,
        // work_email: employeeProfileData.work_email,
        position: employeeProfileData.position_master,
        work_phone: employeeProfileData.work_phone,
        work_location: employeeProfileData.work_location,
        employee_start_date: employeeProfileData.employee_start_date,
        employment_status: employeeProfileData.employment_status,
        onboarding_mentor: employeeProfileData.onboarding_mentor,
        direct_manager: employeeProfileData.direct_manager,
        employee_number: employeeProfileData.employee_number,
        skills: employeeProfileData.skills,
        clients: employeeProfileData.client_master,
        company: employeeProfileData.company,
        projects_m2m: employeeProfileData.projects_m2m,
        teams: employeeProfileData.team_master,
        is_sponsored_employee: employeeProfileData?.is_sponsored_employee,
        ni_number: employeeProfileData?.ni_number,
        soc: employeeProfileData?.soc,
        weekly_working_hours: employeeProfileData?.weekly_working_hours,
      };

      if (employeeProfileData?.job_contract?.name) {
        userData['job_contract'] = employeeProfileData.job_contract
      }

      patchEmployeeFn(
        { employee_uuid, userData },
        {
          onSuccess: () => {
            toast.success("Employee Profile Updated", TostifyConfig);
            refetchEmployeeByUUID();
            client.invalidateQueries(["get-employee-info-detail-by-uuid"]);
            client.invalidateQueries(["get-pending-action-by-uuid"]);
            props.setCardView({ profile: true });
          },
        }
      );
    }
  };

  const handlePhoneInputChange = (e) => {
    const { name, value } = e.target;

    // Remove leading +44 from the existing value
    let newValue = value.replace(/^\+44/, '');

    // Allow only numbers and the backspace key
    const onlyNumbers = /^[0-9\b]+$/;
    if (newValue !== "" && !onlyNumbers.test(newValue)) {
      // Display an error message or handle invalid input
      setPhoneError("Phone number must contain only numbers");
      return;
    }

    // Check if the number starts with zero and has exactly 10 digits after it
    const startsWithZero = /^0/;
    const tenDigits = /^0\d{0,9}$/;

    // Allow backspace and exactly 10 digits after zero
    if (newValue !== "" && !onlyNumbers.test(newValue) && (!startsWithZero.test(newValue) || !tenDigits.test(newValue))) {
      // Display an error message or handle invalid input

      setPhoneError("Mobile Number Format is: 07800123456");
      return;
    }

    setPhoneError("");
    setEmployeeProfileData({ ...employeeProfileData, [name]: newValue });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (e.target.type === "file") {
      setEmployeeProfileData({
        ...employeeProfileData,
        [name]: e.target.files[0], // Capture the selected file object
      });
      setCurrentAttachmentData(e.target.files[0].name);
    }
    else if (e.target.type === "checkbox") {
      setEmployeeProfileData({
        ...employeeProfileData,
        [name]: e.target.checked,
      });
      setIsChecked(e.target.checked);
    }
    else {
      setEmployeeProfileData({ ...employeeProfileData, [name]: value });
    }
  };

  const employeeSelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;
    const selectedOptionLabel = e.target.options[selectedIndex].label;
    setEmployeeProfileData({
      ...employeeProfileData,
      [selectName]: selectValue,
    });

    if (selectName === "client_master") {
      setClientProject((prev) => ({
        ...prev,
        clients: selectedOptionLabel,
        projects: "",
      }));
    }

    if (selectName === "project_master") {
      setClientProject((prev) => ({
        ...prev,
        projects: selectedOptionLabel,
      }));
    }
  };

  const CustomClearText = () => "clear all";
  const ClearIndicator = (props) => {
    const {
      children = <CustomClearText />,
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
    );
  };

  // Format options for Select component
  const formattedEmployeeProjectOptions = getEmployeeProject?.map((option) => ({
    value: option.id,
    label: option.project_name,
  }));

  let handleTagInputChange = (tags) => {
    setSkills(tags);
    setEmployeeProfileData({
      ...employeeProfileData,
      skills: tags.toString(),
    });
  };

  useEffect(() => {
    if (getEmployeeByUUID) {
      setEmployeeProfileData(getEmployeeByUUID);
      setIsChecked(getEmployeeByUUID.is_sponsored_employee);
      setSkills(
        getEmployeeByUUID?.skills ? getEmployeeByUUID.skills.split(",") : []
      );
      if (employeeProfileData?.job_contract) {
        setEmployeeProfileData((prevData) => ({
          ...prevData,
          job_contract: employeeProfileData.job_contract,
        }));
      }
      else {
        setCurrentAttachmentData(extractFileName(getEmployeeByUUID?.job_contract));
      }
    }
  }, [getEmployeeByUUID]);

  return (
    <Fragment>
      <div>
        <div className="col-xl-12 col-xxl-12">
          <Card className="az-card">
            <Card.Header>
              <h4 className="card-title">
                <i class="fa fa-user text-primary"></i> &nbsp; Update Profile
              </h4>
            </Card.Header>
            <LoadingOverlay
              active={loadingEmployeeByUUID}
              spinner
              text="Fetching..."
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-lg-6 mb-2">
                        <label className="text-label">
                          Title<span className="required">*</span>
                        </label>
                        <Form.Select
                          name="title"
                          className="form-control"
                          value={employeeProfileData.title}
                          onChange={employeeSelectChangeHandler}
                          required
                        >
                          <option value="">Select Title</option>
                          {Title?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label className="text-label">
                          First Name<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="first_name"
                          onChange={handleInputChange}
                          value={employeeProfileData.first_name}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>Middle Name</label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="middle_name"
                          onChange={handleInputChange}
                          value={employeeProfileData.middle_name}
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>
                          Last Name<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="last_name"
                          onChange={handleInputChange}
                          value={employeeProfileData.last_name}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>
                          Work Email<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="work_email"
                          onChange={handleInputChange}
                          value={employeeProfileData.work_email}
                          disabled
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>Position</label>
                        <Form.Select
                          name="position_master"
                          className="form-control"
                          value={employeeProfileData.position_master}
                          onChange={employeeSelectChangeHandler}
                          disabled={loadingPosition}
                        >
                          <option value="">Select Position</option>
                          {getEmployeePosition?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.position_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>


                      <Col className="col-md-6 mb-2">
                        <div
                          className={`form-group mb-3`}
                        >
                          <label className="text-label">Contact Number <span className="required">*</span></label>
                          <div className="input-group">
                            <span className="input-group-text ">
                              <img src="https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_United_Kingdom.png" Style="width: 30px; margin-right: 8px;" />
                              +44
                              {/* <i className="fa fa-user" />{" "} */}
                            </span>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="07800123456"
                              name="work_phone"
                              onChange={handlePhoneInputChange}
                              value={employeeProfileData.work_phone}
                              required
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {phoneError && phoneError}
                            </div>

                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            />
                          </div>
                        </div>

                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>Work Location</label>
                        <Form.Select
                          name="work_location"
                          className="form-control"
                          value={employeeProfileData.work_location}
                          onChange={employeeSelectChangeHandler}
                          disabled={loadingWorkLocation}
                        >
                          <option value="">Select Location</option>
                          {getEmployeeWorkLocation?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.location_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>
                          Start Date<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="date"
                          name="employee_start_date"
                          onChange={handleInputChange}
                          value={employeeProfileData.employee_start_date}
                          required
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>OnBoarding Mentor</label>
                        <Form.Select
                          name="onboarding_mentor"
                          className="form-control"
                          value={employeeProfileData.onboarding_mentor}
                          onChange={employeeSelectChangeHandler}
                          disabled={loadingEmployeeInfo}
                        >
                          <option value="">Select Mentor</option>
                          {getEmployeeInfo?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.first_name} {option.last_name} {option.employee_number ? ` (${option.employee_number})` : ''}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>Reporting Manager</label>
                        <Form.Select
                          name="direct_manager"
                          className="form-control"
                          value={employeeProfileData.direct_manager}
                          onChange={employeeSelectChangeHandler}
                          disabled={loadingEmployeeInfo}
                        >
                          <option value="">Select Manager</option>
                          {getEmployeeInfo?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.first_name} {option.last_name}{" "}
                              {option.employee_number
                                ? ` (${option.employee_number})`
                                : ""}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>
                          Employee Number<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          name="employee_number"
                          placeholder="Employee Number"
                          value={employeeProfileData.employee_number}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>
                          National Insurance Number
                          <span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          name="ni_number"
                          placeholder="NI Number"
                          value={employeeProfileData.ni_number}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>Skills</label>
                        <TagsInput
                          className="form-control"
                          name="skills"
                          addOnBlur={true}
                          onlyUnique={true}
                          value={skills}
                          // inputProps={{placeholder:'Add Skill tag'}}
                          onChange={handleTagInputChange}
                          addKeys={[",", ";"]}
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>
                          SOC Number
                        </label>
                        <Form.Control
                          type="text"
                          name="soc"
                          placeholder="SOC Number"
                          value={employeeProfileData.soc}
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col className="col-md-6 mb-0">
                        <label>Project/Work/Branch</label>
                        <Select
                          // className="form-control"
                          closeMenuOnSelect={false}
                          components={{ ClearIndicator }}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              cursor: "pointer",
                              color: state.isFocused ? "#003366" : "black",
                              height: "55px",
                              "border-color": "hsl(228deg 20% 95.1%)",
                            }),
                          }}
                          value={employeeProfileData.project_master}
                          isMulti
                          options={formattedEmployeeProjectOptions}
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions.map((option) => option.value);
                            setEmployeeProfileData(prevState => ({
                              ...prevState,
                              project_master: selectedOptions,
                              projects_m2m: selectedValues,
                            }));
                          }}
                        />
                        {/* <Form.Select
                          name="project_master"
                          value={employeeProfileData.project_master}
                          onChange={employeeSelectChangeHandler}
                          disabled={loadingProject}
                          className="form-control"
                        >
                          <option value="">Select Project</option>
                          {getEmployeeProject?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.project_name}
                            </option>
                          ))}
                        </Form.Select> */}
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mt-2">
                    <Row>
                      <Col className="col-md-6 mb-0">
                        <label>Job Contract</label>
                        <Form.Control
                          type="file"
                          placeholder="Type Text"
                          name="job_contract"
                          onChange={handleInputChange}
                        />
                        <Row className="col-md-6 mt-3">
                          <label>Selected File</label>
                          <label>{currentAttachmentData}</label>
                        </Row>
                      </Col>

                      <Col className="col-md-6 mb-2">
                        <label>
                          Weekly Working Hours
                        </label>
                        <Form.Control
                          type="number"
                          name="weekly_working_hours"
                          placeholder="Working hours"
                          value={employeeProfileData.weekly_working_hours}
                          onChange={handleInputChange}
                        />
                      </Col>

                      <Col className="col-md-6 mb-2">
                        <div className="form-check custom-checkbox mt-5">
                          <input
                            type="checkbox"
                            name="is_sponsored_employee"
                            className="form-check-input"
                            id="is_sponsored_employee"
                            checked={isChecked}
                            onChange={handleInputChange}
                            value={employeeProfileData.is_sponsored_employee}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="is_sponsored_employee"
                          >
                            <b>Is Sponsored Employee</b>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mt-2">
                    <Row>
                      <Col>
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              props.setCardView({ profile: true });
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="primary btn-sm"
                            disabled={
                              createEmployeePostLoading || loadingEmployeeByUUID
                            }
                            onClick={updateEmployeeHandeler}
                          >
                            {createEmployeePostLoading ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                />
                                Loading...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default EmployeeProfile;
