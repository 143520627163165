import { Row, Col, Button } from "react-bootstrap";
import { IsValidPermissions } from "../../../../services/AuthService";

function PendingAction(props) {
  return (
    <>
      {(!props?.LoadingPendingActionByUUID && !props?.pendingActionData?.is_pending_action) ?
        <div className="card" id="pending-actions" style={{ "height": "auto" }}>
          <div className="card-header">
            <h4 className="card-title">
              <i className="fa fa-exclamation-circle font-18 align-middle me-2 text-danger"></i>
              Pending Actions
            </h4>
          </div>
          <div className="card-body">
            <div className="btn-page">
              <Row>
                {!props?.pendingActionData?.is_employee_profile_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ profileEdit: true });
                        }}
                      >
                        <i class="fa fa-user"></i>
                      </Button>
                    </div>
                    <div>Profile</div>
                  </Col>
                }
                {!props?.pendingActionData?.is_employee_personal_info_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ personalInfoEdit: true });
                        }}
                      >
                        <i class="fa fa-id-badge"></i>
                      </Button>
                    </div>
                    <div>Personal Info</div>
                  </Col>
                }
                {!props?.pendingActionData?.is_employee_education_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ educationEdit: true });
                        }}
                      >
                        <i class="fas fa-user-graduate"></i>
                      </Button>
                    </div>
                    <div>Education</div>
                  </Col>
                }
                {!props?.pendingActionData?.is_employee_certificate_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ certificationEdit: true });
                        }}
                      >
                        <i class="fa fa-certificate"></i>
                      </Button>
                    </div>
                    <div>Certification’s </div>
                  </Col>
                }
                {!props?.pendingActionData?.is_employee_passport_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ passportEdit: true });
                        }}
                      >
                        <i class="fas fa-address-card"></i>
                      </Button>
                    </div>
                    <div>PassPort</div>
                  </Col>
                }
                {!props?.pendingActionData?.is_employee_visa_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ visaEdit: true });
                        }}
                      >
                        <i class="fa fa-newspaper"></i>
                      </Button>
                    </div>
                    <div>Visa</div>
                  </Col>
                }
                {!props?.pendingActionData?.is_employee_bank_detail_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ bankEdit: true });
                        }}
                      >
                        <i class="fas fa-university"></i>
                      </Button>
                    </div>
                    <div>Bank</div>
                  </Col>
                }
                {!props?.pendingActionData?.is_employee_emergency_contact_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ emergencyContactEdit: true });
                        }}
                      >
                        <i class="fa fa-phone"></i>
                      </Button>
                    </div>
                    <div>Emergency Contact</div>
                  </Col>
                }

                {IsValidPermissions('employee.view_employeerighttowork') && !props?.pendingActionData?.is_employee_rtw_admin_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ rtw: true });
                        }}>
                        <i class="fa fa-window-maximize"></i>
                      </Button>
                    </div>
                    <div>RTW</div>
                  </Col>
                }

                {IsValidPermissions('employee.can_view_employee_rtw') && !props?.pendingActionData?.is_employee_rtw_employee_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ employeertw: true });
                        }}>
                        <i class="fa fa-window-maximize"></i>
                      </Button>
                    </div>
                    <div>RTW</div>
                  </Col>
                }

                {!props?.pendingActionData?.is_employee_profile_image_completed &&
                  <Col lg="3" className="text-center mb-4">
                    <div>
                      <Button
                        className="shadow btn-xs  btn-round sharp me-1 btn-circle-ico"
                        variant="primary light"
                        onClick={() => {
                          props.setCardView({ image: true });
                        }}>
                        <i class="fa fa-camera"></i>
                      </Button>
                    </div>
                    <div>Profile Image</div>
                  </Col>
                }


              </Row>
            </div>
          </div>
        </div> : <></>
      }
    </>
  );
}

export default PendingAction;