import React, { Fragment, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import {
    useEmployeeBankPostFn,
    useEmployeeBankUpdateFn,
    useGetEmployeeBankByID
} from '../../../actions/employee-bank-actions';
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { extractFileName } from '../../../utils/ComponentUtilse';
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import LoadingOverlay from 'react-loading-overlay';



function BankDetails(props) {

    const client = useQueryClient();

    console.log('props',props)

    let id = props?.bankId

    const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const [employeeBankData, setEmployeeBankData] = useState({});

    const [isChecked, setIsChecked] = useState(false);

    // [currentAttachmentData, setCurrentAttachmentData] = useState(null);

    const {
        data: getEmployeeBankID,
        isLoading: loadingGetEmployeeBankIDFn,
        refetch: refetchEmployeeBank,
    } = useGetEmployeeBankByID(id);

    const {
        mutate: postEmployeeBankFn,
        isLoading: loadingPostEmployeeBankFn,
    } = useEmployeeBankPostFn();

    const {
        mutate: patchEmployeeBankFn,
        isLoading: loadingEmployeeBankFn,
    } = useEmployeeBankUpdateFn();


    const submitForm = () => {
        let FormValidity = FormRef.current.reportValidity();

        if (FormValidity === true) {
            if (id) {
                updateEmployeeBankHandeler();
            }
            else {
                postEmployeeBankHandeler();
            }
        }
    }

    const postEmployeeBankHandeler = () => {

        let userData = {
            //attachment: employeeVisaData.attachment,
            bank_name: employeeBankData.bank_name,
            sort_code: employeeBankData.sort_code,
            account_number: employeeBankData.account_number,
            address: employeeBankData.address,
            primary_account: employeeBankData.primary_account,
            employee_employee_uuid: employee_uuid
        }

        postEmployeeBankFn(
            userData,
            {
                onSuccess: (response) => {
                    //navigate('/list-bank')
                    toast.success('Bank Details Added', TostifyConfig);
                    client.invalidateQueries(['get-employee-bank-by-uuid']);
                    client.invalidateQueries(['get-pending-action-by-uuid']);
                    props.setCardView({ bank: true });
                },
            }
        );
    }


    const updateEmployeeBankHandeler = () => {

        let userData = {
            //attachment: employeeVisaData.attachment,
            bank_name: employeeBankData.bank_name,
            sort_code: employeeBankData.sort_code,
            account_number: employeeBankData.account_number,
            address: employeeBankData.address,
            primary_account: employeeBankData.primary_account,
            employee_employee_uuid: employee_uuid
        }

        patchEmployeeBankFn(
            { id, userData },
            {
                onSuccess: (response) => {
                    //navigate('/list-bank')
                    toast.success('Bank Details Updated', TostifyConfig);
                    // history.push(`/employee/bank_details_list/${employee_uuid}`);
                    client.invalidateQueries(['get-employee-bank-by-uuid']);
                    props.setCardView({ bank: true });
                },
            }
        );
    }

    // const handleCheckboxChange = (event) => {
    //     const isChecked = e.target.checked;
    //     setIsChecked(isChecked);

    // }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (event.target.type === "checkbox") {
            setEmployeeBankData({
                ...employeeBankData,
                [name]: event.target.checked,
            });
            setIsChecked(event.target.checked);
        }
        else {
            setEmployeeBankData({
                ...employeeBankData,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        if (id) {
            refetchEmployeeBank();
        }

        if (getEmployeeBankID) {
            setEmployeeBankData(getEmployeeBankID);
            setIsChecked(getEmployeeBankID.primary_account)


            //setCurrentAttachmentData(extractFileName(getEmployeeBankID.attachment));
        }
    }, [getEmployeeBankID])

    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>Bank Details</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingGetEmployeeBankIDFn && id}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Account number<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="account_number"
                                                    onChange={handleInputChange}
                                                    value={employeeBankData.account_number}
                                                    required />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">Sort code<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="sort_code"
                                                    onChange={handleInputChange}
                                                    value={employeeBankData.sort_code}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Bank name<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="bank_name"
                                                    onChange={handleInputChange}
                                                    value={employeeBankData.bank_name}
                                                    required />
                                            </Col>
                                            {/* <Col className='col-md-6 mb-2'>
                                            <label>Address<span className="required">*</span></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Type Text"
                                                name="address"
                                                onChange={handleInputChange}
                                                value={employeeBankData.address}
                                                required />
                                        </Col> */}
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>

                                                <div className="form-check custom-checkbox mb-3">
                                                    <input
                                                        type="checkbox"
                                                        name="primary_account"
                                                        className="form-check-input"
                                                        id="primary_account"
                                                        checked={isChecked}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="primary_account"
                                                    >
                                                        <b>Primary Account</b>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mt-2'>
                                        <Row>
                                            <Col>
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            props.setCardView({ bank: true });
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="primary btn-sm"
                                                        onClick={submitForm}
                                                        disabled={(loadingGetEmployeeBankIDFn && id) || loadingPostEmployeeBankFn || loadingEmployeeBankFn}
                                                    >
                                                        {(loadingPostEmployeeBankFn || loadingEmployeeBankFn) ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    className="me-2"
                                                                />
                                                                Loading...
                                                            </>
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>
                </div>
            </div>
        </Fragment>
    )
}

export default BankDetails;