import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAllTeams = async () => {
  let config = {
    method: "get",
    url: "/organization/api/v1/teams/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetTeamList = () => {
  return useQuery(["get-all-teams"], getAllTeams);
};


const createNewTeam = async (team) => {
  let config = {
    method: "post",
    url: "organization/api/v1/teams/",
    headers: {
      "Content-Type": "application/json",
    },
    data: team
  };

  return await axios.create().request(config)
};
export const useCreateTeam = () => {
  return useMutation(["team-register"], createNewTeam);
};


const getTeamById = async (query) => {
  let [, id] = query.queryKey;
  let config = {
    method: "get",
    url: `organization/api/v1/teams/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetTeamById = (id) => {
  return useQuery(["get-team-by-id", id], getTeamById, { enabled: id ? true : false });
};

const teamUpdateById = async (data) => {
  let { id, team } = data;
  let config = {
    method: "patch",
    url: `organization/api/v1/teams/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: team,
  };
  return await axios.request(config);
};
export const useTeamUpdateById = () => {
  return useMutation(["company-update"], teamUpdateById);
};

//API DELETE METHOD
const teamsDeleteById = async (id) => {
  try {
    const response = await axios.delete(`/organization/api/v1/teams/${id}/`);
    return response.data;
  } catch (error) {
    // Handle error or throw it again
    throw error;
  }
};

export const useTeamsDeleteById = () => {
  return useMutation(["teams-delete"], teamsDeleteById);
};