import React, { useContext } from "react";
import { Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isValidPermission } from "../../../../services/AuthService";
import { handleFileDownload } from '../../../../utils/ComponentUtilse';
import ReactToPdf from 'react-to-pdf'
import { Margin, usePDF } from "react-to-pdf";
import { dateHandler } from "../../../../utils/ComponentUtilse";
import { ServiceContext } from "../../../../context/ServiceContext";

function Profile(props) {

  const { canEditEmployee } = useContext(ServiceContext);
  
  const { toPDF, targetRef } = usePDF({
    filename: "usepdf-example.pdf",
    page: { margin: Margin.MEDIUM }
  });

  return (
    <div className="card" id="work-profile">
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title">
          <i class="fa fa-user text-primary"></i> &nbsp; Work Profile
        </h4>
        {canEditEmployee && isValidPermission('employee.change_employee', props.getPermissionList) &&
          <Button
            className="me-2 btn-sm"
            variant="primary"
            onClick={() => {
              props.setCardView({ profileEdit: true });
            }}
          >
            <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Edit
          </Button>
        }
        {/* <Button className="me-2 btn-sm" onClick={toPDF}>Download PDF</Button> */}

      </div>
      <div className="card-body" ref={targetRef}>
        <div className="form-validation">
          <div className="row">
            <div className="col-xl-12">
              <div className="form-group mb-3 row">
                <label className="col-lg-4 col-form-label">
                  Employee Name:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData?.first_name}{" "}
                  {props.employeeProfileData?.last_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  Employee Number:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData.employee_number}
                </label>
                <label className="col-lg-4 col-form-label">
                  Work Email:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData.work_email}
                </label>
                <label className="col-lg-4 col-form-label">
                  Position:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData?.position_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  Contact Number:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData.work_phone ?
                    props.employeeProfileData.work_phone.startsWith('+44') ?
                      props.employeeProfileData.work_phone.substring(3) :
                      '+44 ' + props.employeeProfileData.work_phone
                    :
                    null}
                </label>
                <label className="col-lg-4 col-form-label">
                  Work Location:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData.work_location_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  Start Date:
                </label>
                <label className="col-lg-8 col-form-label">
                  {dateHandler(props.employeeProfileData.employee_start_date)}
                </label>
                <label className="col-lg-4 col-form-label">
                  Reporting Manager:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData.direct_manager_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  Project/Work/Branch:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData.project_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  National Insurance Number:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData.ni_number}
                </label>
                <label className="col-lg-4 col-form-label">
                  SOC Number:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData.soc}
                </label>
                <label className="col-lg-6 col-form-label">
                  Job Contract:
                </label>
                <label className="col-lg-6 col-form-label">
                  {props?.employeeProfileData?.job_contract_url ? <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleFileDownload(props?.employeeProfileData?.job_contract_url)}><i className="fa fa-download"></i></Button> : null}
                </label>
                <label className="col-lg-4 col-form-label">
                  Weekly Working Hours:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props?.employeeProfileData?.weekly_working_hours}
                </label>
                <label className="col-lg-4 col-form-label">
                  Sponsored Employee:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileData.is_sponsored_employee ?
                    <Badge pill bg="success" className='badge-sm text-capitalize'>Yes</Badge> :
                    <Badge pill bg="danger" className='badge-sm text-capitalize'>No</Badge>
                  }
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile;