import React, { Fragment, } from 'react'
// import ComponentsClientSidebar from '../shared/ComponentsClientSidebar'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import { useGetEmployeeInfoByUUID, useEmployeeInfoUpdateFn } from '../../actions/employee-actions';
import { useCountryofIssueFn } from '../../actions/employee-passport-actions';
import { Gender, MaritialStatus } from "../../utils/Constance";
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { TostifyConfig } from "../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import LoadingOverlay from 'react-loading-overlay';



function PersonalInfo(props) {

    const client = useQueryClient();

    const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const [employeeProfileInfoData, setEmployeeProfileInfoData] = useState({});

    const [phoneError, setPhoneError] = useState("");

    const { data: getEmployeeInfoByUUID,
        refetch: refetchEmployeeInfoByUUID,
        isLoading: loadingEmployeeInfoUUID,
    } = useGetEmployeeInfoByUUID(employee_uuid);

    const { data: getCountryofIssue, isLoading: loadingCountryofIssue } = useCountryofIssueFn();

    const {
        mutate: patchEmployeeInfoFn,
        isLoading: createEmployeePostLoading,
    } = useEmployeeInfoUpdateFn();

    useEffect(() => {
        if (getEmployeeInfoByUUID) {
            setEmployeeProfileInfoData(getEmployeeInfoByUUID);
        }
    },
        [getEmployeeInfoByUUID]);

    const updateEmployeeInfoHandeler = () => {
        let FormValidity = FormRef.current.reportValidity();

        // Check if phone number is valid
        const startsWithZero = /^0/;
        const tenDigits = /^0\d{10}$/;
        if (!startsWithZero.test(employeeProfileInfoData.home_phone) || !tenDigits.test(employeeProfileInfoData.home_phone)) {
            setPhoneError("Mobile Number Format is: 07800123456");
            return;
        }

        if (FormValidity === true) {

            let userData = {
                personal_email: employeeProfileInfoData.personal_email,
                address2: employeeProfileInfoData.address2,
                gender: employeeProfileInfoData.gender,
                home_phone: employeeProfileInfoData.home_phone,
                state: employeeProfileInfoData.state,
                address1: employeeProfileInfoData.address1,
                city: employeeProfileInfoData.city,
                marital_status: employeeProfileInfoData.marital_status,
                nationality: employeeProfileInfoData.nationality,
                zip_code: employeeProfileInfoData.zip_code,
                // mobile_phone: employeeProfileInfoData.mobile_phone,
                date_of_birth: employeeProfileInfoData.date_of_birth,
                country: employeeProfileInfoData.country ? parseInt(employeeProfileInfoData.country) : null
            }

            // Validate email format
            const emailPattern = /^\S+@\S+\.\S+$/;
            if (userData.personal_email && !emailPattern.test(userData.personal_email)) {
                toast.error("Invalid email format", TostifyConfig);
                return;
            }

            patchEmployeeInfoFn({ employee_uuid, userData }, {
                onSuccess: (response) => {
                    toast.success("Employee PersonalInfo Updated", TostifyConfig);
                    refetchEmployeeInfoByUUID();
                    client.invalidateQueries(['get-employee-personal-info-by-uuid']);
                    client.invalidateQueries(['get-pending-action-by-uuid']);
                    props.setCardView({ personalInfo: true });
                },
            });
        }
    };

    const handlePhoneInputChange = (e) => {
        const { name, value } = e.target;

        // Remove leading +44 from the existing value
        let newValue = value.replace(/^\+44/, '');

        // Allow only numbers and the backspace key
        const onlyNumbers = /^[0-9\b]+$/;
        if (newValue !== "" && !onlyNumbers.test(newValue)) {
            // Display an error message or handle invalid input
            setPhoneError("Phone number must contain only numbers");
            return;
        }

        // Check if the number starts with zero and has exactly 10 digits after it
        const startsWithZero = /^0/;
        const tenDigits = /^0\d{0,9}$/;

        // Allow backspace and exactly 10 digits after zero
        if (newValue !== "" && !onlyNumbers.test(newValue) && (!startsWithZero.test(newValue) || !tenDigits.test(newValue))) {
            // Display an error message or handle invalid input

            setPhoneError("Mobile Number Format is: 07800123456");
            return;
        }

        setPhoneError("");
        setEmployeeProfileInfoData({ ...employeeProfileInfoData, [name]: newValue });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmployeeProfileInfoData({ ...employeeProfileInfoData, [name]: value });
    };

    const employeeSelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        setEmployeeProfileInfoData({
            ...employeeProfileInfoData,
            [selectName]: selectValue,
        });
    };

    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>Personal Info</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingEmployeeInfoUUID}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Date Of Birth<span className="required">*</span></label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Type Text"
                                                    name="date_of_birth"
                                                    onChange={handleInputChange}
                                                    value={employeeProfileInfoData.date_of_birth}
                                                    required
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">Address1<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="address1"
                                                    onChange={handleInputChange}
                                                    value={employeeProfileInfoData.address1}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Address2</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="address2"
                                                    onChange={handleInputChange}
                                                    value={employeeProfileInfoData.address2}
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>City<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="city"
                                                    onChange={handleInputChange}
                                                    value={employeeProfileInfoData.city}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>County<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="state"
                                                    onChange={handleInputChange}
                                                    value={employeeProfileInfoData.state}
                                                    required
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>PostCode<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="zip_code"
                                                    onChange={handleInputChange}
                                                    value={employeeProfileInfoData.zip_code}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Country<span className="required">*</span></label>
                                                <Form.Select
                                                    name="country"
                                                    value={employeeProfileInfoData.country}
                                                    onChange={employeeSelectChangeHandler}
                                                    required
                                                    className='form-control'
                                                >
                                                    <option value="">Select Country</option>
                                                    {getCountryofIssue?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.country_of_issue}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Nationality<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="nationality"
                                                    onChange={handleInputChange}
                                                    value={employeeProfileInfoData.nationality}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Personal eMail</label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="eg: jhon@yahoo.com"
                                                    name="personal_email"
                                                    onChange={handleInputChange}
                                                    value={employeeProfileInfoData.personal_email}
                                                    pattern="^\S+@\S+\.[a-zA-Z]{2,}$"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid email address.
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <div
                                                    className={`form-group mb-3`}
                                                >
                                                    <label className="text-label">Home Phone</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text ">
                                                            <img src="https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_United_Kingdom.png" Style="width: 30px; margin-right: 8px;" />
                                                            +44
                                                            {/* <i className="fa fa-user" />{" "} */}
                                                        </span>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="07800123456"
                                                            name="home_phone"
                                                            onChange={handlePhoneInputChange}
                                                            value={employeeProfileInfoData.home_phone}
                                                        />
                                                        <div
                                                            id="val-username1-error"
                                                            className="invalid-feedback animated fadeInUp"
                                                            style={{ display: "block" }}
                                                        >
                                                            {phoneError && phoneError}
                                                        </div>

                                                        <div
                                                            id="val-username1-error"
                                                            className="invalid-feedback animated fadeInUp"
                                                            style={{ display: "block" }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="home_phone"
                                                    onChange={handleInputChange}
                                                    value={employeeProfileInfoData.home_phone}
                                                /> */}
                                            </Col>
                                            {/* <Col className='col-md-6 mb-2'>
                                                <label>Mobile Phone<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="mobile_phone"
                                                    onChange={handleInputChange}
                                                    value={employeeProfileInfoData.mobile_phone}
                                                    required
                                                />
                                            </Col> */}
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>

                                            <Col className='col-md-6 mb-2'>
                                                <label>Gender<span className="required">*</span></label>
                                                <Form.Select
                                                    name="gender"
                                                    className='form-control'
                                                    onChange={employeeSelectChangeHandler}
                                                    value={employeeProfileInfoData.gender}
                                                    required
                                                >
                                                    <option value="">Select Gender</option>
                                                    {Gender?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Marital Status<span className="required">*</span></label>
                                                <Form.Select
                                                    name="marital_status"
                                                    className='form-control'
                                                    onChange={employeeSelectChangeHandler}
                                                    value={employeeProfileInfoData.marital_status}
                                                    required
                                                >
                                                    <option value="">Select Maritial Status</option>
                                                    {MaritialStatus?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>

                                        </Row>
                                    </Form.Group>

                                    <Form.Group className='mt-2'>
                                        <Row>
                                            <Col>
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            props.setCardView({ personalInfo: true });
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        disabled={createEmployeePostLoading || loadingEmployeeInfoUUID}
                                                        variant="primary btn-sm"
                                                        onClick={updateEmployeeInfoHandeler}
                                                    >
                                                        {createEmployeePostLoading ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    className="me-2"
                                                                />
                                                                Loading...
                                                            </>
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default PersonalInfo