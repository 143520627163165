import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { GlobalFilter } from '../components/table/FilteringTable/GlobalFilter';
import '../components/table/FilteringTable/filtering.css';
import { useGetCompanyList } from '../../actions/company-action';
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetAnnouncementTopicsList } from '../../actions/announcement-action'
import { useGetAnnouncemntDetail } from '../../actions/announcement-action'
import { useGetAnnouncementList } from '../../actions/announcement-action'
import DataTable from '../pages/commonPages/DataTable';
import CustomTooltip from '../../utils/CommonUtils';

export const ListAnnouncementsTopic = () => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { data: getAllTopics, isLoading: loadingGetTopicsFn } = useGetAnnouncementTopicsList()

    const columns = useMemo(
        () => [

            {
                Header: "Topic",
                Footer: "Topic",
                accessor: "topic",
                Filter: ColumnFilter,
                className: "width320",
            },
            {
                Header: "Description",
                Footer: "Description",
                accessor: "description",
                Filter: ColumnFilter,
            },
            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                disableFilters: true,
                Cell: ({ cell, row }) => (
                    <>
                        <CustomTooltip id={`edit-${row.index}`} message="Edit">
                            <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => navigate('/add-announcementsTopic/', { state: { rowId: row?.original?.id } })}><i className="fas fa-pencil-alt"></i></Button>
                        </CustomTooltip>

                    </>
                )

            }
        ],
        []
    );

    useEffect(() => {
        if (getAllTopics) {
            setData(getAllTopics);
        }
    }, [getAllTopics]);

    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const { globalFilter, pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Filtering" motherMenu="Table" />
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Table Filtering</h4>
                </div>
                <div className="card-body">
                    <div className="d-flex flex-wrap mb-0 align-items-center" style={{ justifyContent: "space-between" }}>

                        <div></div>

                        <div className="mb-1 mt-2">
                            <Link to={`/add-announcementsTopic/`}><Button className='me-2 btn-sm' variant='primary'>
                                <i className="fas fa-plus"></i>&nbsp;Announcement Topic
                            </Button></Link>
                        </div>

                    </div>

                    <DataTable
                        isLoading={loadingGetTopicsFn}
                        columns={columns}
                        tableInstance={tableInstance}
                    />
                </div>
            </div>
        </>
    )

}
export default ListAnnouncementsTopic;