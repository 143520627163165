import React, { Fragment } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import LoadingOverlay from "react-loading-overlay";
import { useEmployeeDropdown, useEmployeePosition, usegetJobDetailByID } from "../../../actions/employee-actions";
import { EMPLOYMENT_TYPE, JOB_TYPE, Title, TostifyConfig } from "../../../utils/Constance";
import { useGetJobInfoDetailByID, useJobUpdateFn } from "../../../actions/recruitment-actions";
import { Select2Select, Select2Employee } from "../../pages/commonPages/Select2Select";

const JobVacancyUpdate = (props) => {
  const client = useQueryClient();

  const jobId = props?.jobId;

  const [isChecked, setIsChecked] = useState(false);

  const [jobData, setJobData] = useState({});

  const step1FormRef = useRef(null);

  const [primaryskills, setPrimarySkills] = useState([]);

  const [secondaryskills, setSecondarySkills] = useState([]);

  const FormRef = useRef(null);

  const {
    data: getJobDetailByID,
    isLoading: loadingJobByID,
    refetch: refetchJobByID,
  } = useGetJobInfoDetailByID(jobId);

  const { data: getEmployeePosition } = useEmployeePosition();

  const { data: getEmployeeInfo, isLoading: loadingEmployeeInfo } =
    useEmployeeDropdown();



  useEffect(() => {
    if (getJobDetailByID) {
      setJobData(getJobDetailByID);
      setPrimarySkills(getJobDetailByID?.primary_skills ? getJobDetailByID?.primary_skills.split(',') : [])
      setSecondarySkills(getJobDetailByID?.secondary_skills ? getJobDetailByID?.secondary_skills.split(',') : [])
    }
  }, [getJobDetailByID]);

  const { mutate: patchEmployeeFn, isLoading: updateJobPostLoading } =
    useJobUpdateFn();

  const updateJobHandeler = () => {
    let FormValidity = step1FormRef.current.reportValidity();

    if (FormValidity === true) {
      let jobDetailData = {
        position: jobData.position,
        no_of_vacancies: jobData.no_of_vacancies,
        job_type: jobData.job_type,
        employment_type: jobData.employment_type,
        weekly_working_hours: jobData.weekly_working_hours,
        job_description: jobData.job_description,
        minimum_years_of_experience: jobData.minimum_years_of_experience,
        work_location: jobData.work_location,
        primary_skills: jobData.primaryskills,
        secondary_skills: jobData.secondaryskills,
        soc_code: jobData.soc_code,
        salary_range_min: jobData.salary_range_min,
        salary_range_max: jobData.salary_range_max,
        interviewer_name: jobData.interviewer_name,
      };

      patchEmployeeFn(
        { jobId, jobDetailData },
        {
          onSuccess: () => {
            toast.success("Job Vacancy Updated", TostifyConfig);
            refetchJobByID();
            props.setCardView({ jobVacancy: true });
          },
        }
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (e.target.type === "checkbox") {
      setJobData({
        ...jobData,
        [name]: e.target.checked,
      });
      setIsChecked(e.target.checked);
    } else {
      setJobData({ ...jobData, [name]: value });
    }
  };

  const handleKeyDown = (event) => {
    // Prevent the dot from being entered
    if (event.key === '.' || event.key === 'e' || event.key === '-') {
      event.preventDefault();
    }
  };

  const SelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    // const selectedOptionLabel = e.target.options[selectedIndex].label;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;

    setJobData({
      ...jobData,
      [selectName]: selectValue,
    });
  };

  return (
    <Fragment>
      <div>
        <div className="col-xl-12 col-xxl-12">
          <Card className="az-card">
            <Card.Header>
              <h4 className="card-title">
                <i class="fa fa-user text-primary"></i> &nbsp; Update Job Vacancy
              </h4>
            </Card.Header>
            <LoadingOverlay
              active={loadingJobByID}
              spinner
              text="Fetching..."
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={step1FormRef}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Col className="col-md-12">
                          <label className="col-form-label">Job Position <span className="text-danger">*</span></label>
                          <Select2Select
                            name='position'
                            className="mb-3"
                            required
                            selectOptions={getEmployeePosition}
                            value={jobData.position}
                            element_id={'id'}
                            element_name={'position_name'}
                            getState={jobData}
                            setStatet={setJobData}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group>
                        <Col className="col-md-14">
                          <label className="col-form-label">No of vacancies <span className="text-danger">*</span></label>
                          <Form.Control
                            type="number"
                            min="0"
                            step="1"
                            className="form-control mb-3"
                            name="no_of_vacancies"
                            value={jobData.no_of_vacancies}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            required
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group>
                        <label className="col-form-label">
                          Salary Range Minimum(£) <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="number"
                          min="0"
                          step="1"
                          className="form-control mb-3"
                          name="salary_range_min"
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          value={jobData.salary_range_min}
                          required
                        />
                      </Form.Group>

                      <Form.Group>
                        <label className="col-form-label">
                          Job Type <span className="text-danger">*</span>
                        </label>
                        <Form.Select
                          name="job_type"
                          className="mb-4 form-control"
                          value={jobData.job_type}
                          onChange={SelectChangeHandler}
                          required
                        >
                          <option value="">Select Job Type</option>
                          {JOB_TYPE?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group>
                        <label>Employment Type <span className="text-danger">*</span></label>
                        <Form.Select
                          name="employment_type"
                          className="form-control mb-4"
                          value={jobData.employment_type}
                          onChange={SelectChangeHandler}
                          required
                        >
                          <option value="">Select Employment Type</option>
                          {EMPLOYMENT_TYPE?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group>
                        <label>Interviewer Name <span className="text-danger">*</span></label>
                        <Select2Employee
                          name='interviewer_name'
                          className="mb-3"
                          required
                          selectOptions={getEmployeeInfo}
                          value={jobData.interviewer_name}
                          getState={jobData}
                          setStatet={setJobData}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-md-6">

                      <Form.Group>
                        <label className="col-form-label">
                          Minimum Years Of Experience <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="number"
                          min="0"
                          step="1"
                          className="form-control mb-3"
                          name="minimum_years_of_experience"
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          value={jobData.minimum_years_of_experience}
                          required
                        />
                      </Form.Group>

                      <Form.Group>
                        <label className="col-form-label">
                          Soc Code
                        </label>
                        <Form.Control
                          type="text"
                          className="form-control mb-3"
                          name="soc_code"
                          onChange={handleInputChange}
                          value={jobData.soc_code}
                        />
                      </Form.Group>

                      <Form.Group>
                        <label className="col-form-label">
                          Salary Range Maximum(£) <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="number"
                          min="0"
                          step="1"
                          className="form-control mb-3"
                          name="salary_range_max"
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          value={jobData.salary_range_max}
                          required
                        />
                      </Form.Group>

                      <Form.Group>
                        <label className="col-form-label">
                          Weekly Working Hours <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="number"
                          min="0"
                          step="1"
                          className="form-control mb-3"
                          name="weekly_working_hours"
                          onChange={handleInputChange}
                          onKeyDown={handleKeyDown}
                          value={jobData.weekly_working_hours}
                          required
                        />
                      </Form.Group>

                      <Form.Group>
                        <label className="col-form-label">
                          Primary Skills <span className="text-danger">*</span>
                        </label>
                        <TagsInput
                          className="form-control mb-3"
                          name="primary_skills"
                          addOnBlur={true}
                          onlyUnique={true}
                          value={primaryskills}
                          onChange={(tags) => {
                            setPrimarySkills(tags);
                            setJobData({
                              ...jobData,
                              primaryskills: tags.toString(),
                            });
                          }}
                          addKeys={[",", ";"]}
                        />
                      </Form.Group>

                      <Form.Group>
                        <label className="col-form-label">
                          Secondry Skills
                        </label>
                        <TagsInput
                          className="form-control mb-3"
                          name="secondary_skills"
                          addOnBlur={true}
                          onlyUnique={true}
                          value={secondaryskills}
                          onChange={(tags) => {
                            setSecondarySkills(tags);
                            setJobData({
                              ...jobData,
                              secondaryskills: tags.toString(),
                            });
                          }}
                          addKeys={[",", ";"]}
                        />
                      </Form.Group>


                    </div>

                    <Form.Group>
                      <label className="col-form-label">Job Description</label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        className="mb-3"
                        name="job_description"
                        onChange={handleInputChange}
                        value={jobData.job_description}
                      />
                    </Form.Group>
                  </div>
                </form>
                <Form.Group className="mt-2">
                  <Row>
                    <Col>
                      <hr />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="danger light btn-sm"
                          onClick={() => {
                            props.setCardView({ jobVacancy: true });
                          }}
                        >
                          Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          variant="primary btn-sm"
                          onClick={updateJobHandeler}
                          disabled={updateJobPostLoading || loadingJobByID}
                        >
                          {updateJobPostLoading ? (
                            <>
                              <Spinner
                                animation="border"
                                size="sm"
                                className="me-2"
                              />
                              Loading...
                            </>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default JobVacancyUpdate;
