import React, { Fragment, } from 'react'
// import ComponentsClientSidebar from '../shared/ComponentsClientSidebar'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { useRef } from 'react'
// import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation, } from 'react-router-dom/dist'
import { useGetCompanyList } from '../../actions/company-action'
import { useGetClientList } from '../../actions/client-actions'
import { useGetProjectList } from '../../actions/project-action'
import { useCreateTeam, useGetTeamById, useTeamUpdateById } from '../../actions/team-action'
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { GENERIC_STATUS_CHOICES as statusOption } from '../../utils/Constance';
import LoadingOverlay from 'react-loading-overlay';

// import { toast } from 'react-toastify';
// import { TostifyConfig } from '../../Constance';


function AddTeam() {

  const navigate = useNavigate();

  const FormRef = useRef(null);
  // const history = useHistory()
  const location = useLocation()

  let id = location?.state?.rowId

  const [team, setTeam] = useState({
    team_name: '',
    team_short_name: '',
    team_manager: '',
    company: '',
    project: '',
    client: '',
    location: ''
  })

  const [projectList, setProjectList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const [clientList, setClientList] = useState([])
  const { data: getAllClient } = useGetClientList()
  const { data: getAllProject } = useGetProjectList()
  const { data: getAllCompany } = useGetCompanyList()

  const { mutate: postNewTeam, isLoading: loadingpostNewTeamFn, } = useCreateTeam()

  const { mutate: patchTeamInfo, isLoading: loadingpatchTeamInfoFn, } = useTeamUpdateById()
  const { data: teamDetailsById, isLoading: loadingTeamInfoFn } = useGetTeamById(location?.state?.rowId)

  useEffect(() => {
    if (getAllProject) {
      setProjectList(getAllProject);
    }
    if (getAllCompany) {
      setCompanyList(getAllCompany)
    }
    if (getAllClient) {
      setClientList(getAllClient)
    }
  }, [getAllProject, getAllCompany, getAllClient]);

  useEffect(() => {
    if (location?.state?.rowId) {
      setTeam(teamDetailsById)
    }
  }, [teamDetailsById])


  const clientOption = clientList?.map((e) => {
    return { label: e.client_name, value: e.id }
  })
  const projectOption = projectList?.map((e) => {
    return { label: e.project_name, value: e.id }
  })
  const companyOption = companyList?.map((e) => {
    return { label: e.company_name, value: e.id }
  })


  const handleChange = (e) => {
    setTeam((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }


  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();

    if (FormValidity) {
      if (location?.state?.rowId) {
        try {
          patchTeamInfo({ id, team }, {
            onSuccess: (response) => {
              navigate('/list-team')
              // toast.success('Team Updated!!', TostifyConfig);
              // history.push('/team')
            }
          })
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          postNewTeam(team, {
            onSuccess: (response) => {
              // toast.success('Team Added!!', TostifyConfig);
              // history.push('/team')
              navigate('/list-team')
            }
          })
        } catch (error) {
          console.error(error);
        }
      }

    }

  }

  return (
    <Fragment>
      <PageTitle activeMenu={id ? "Edit Team" : "New Team"} motherMenu="Settings" />
      <div>
        <div className='col-xl-12 col-xxl-12'>
          <Card className="az-card">
            <LoadingOverlay
              active={loadingTeamInfoFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            >
              <Card.Body>
                <h3>{id ? "Edit Team" : "New Team"}</h3>
                <form ref={FormRef}>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-lg-6 mb-2'>
                        <label className="text-label">Team Name<span className="required">*</span></label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="team_name"
                          value={team?.team_name}
                          onChange={(e) => handleChange(e)}
                          required />
                      </Col>c
                      <Col className='col-md-6 mb-2'>
                        <label>Team Short Name</label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="team_short_name"
                          value={team?.team_short_name}
                          onChange={(e) => handleChange(e)}
                          required />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6 mb-2'>
                        <label>Team Manager</label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="team_manager"
                          value={team?.team_manager}
                          onChange={(e) => handleChange(e)}
                          required />
                      </Col>
                      <Col className='col-md-6 mb-2'>
                        <label>Company</label>
                        <Select
                          options={companyOption}
                          value={companyOption?.filter((e) => e.value === team?.company)}
                          onChange={(e) => setTeam({ ...team, company: e.value })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6 mb-2'>
                        <label>Project</label>
                        <Select
                          options={projectOption}
                          value={projectOption?.filter((e) => e.value === team?.project)}
                          onChange={(e) => setTeam({ ...team, project: e.value })}
                        />
                      </Col>
                      <Col className='col-md-6 mb-2'>
                        <label>Client</label>
                        <Select
                          options={clientOption}
                          value={clientOption?.filter((e) => e.value === team?.client)}
                          onChange={(e) => setTeam({ ...team, client: e.value })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6 mb-2'>
                        <label>Location</label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="location"
                          value={team?.location}
                          onChange={(e) => handleChange(e)}
                          required />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className='mt-2'>
                    <Row>
                      <Col className="col-md-12">
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              navigate("/list-team/");
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="primary btn-sm"
                            onClick={handleSubmit}
                            disabled={loadingTeamInfoFn && id || loadingpostNewTeamFn || loadingpatchTeamInfoFn}
                          >
                            {(loadingpostNewTeamFn || loadingpatchTeamInfoFn) ? (
                              <>
                                <Spinner animation="border" size="sm" className="me-2" />
                                Loading...
                              </>
                            ) : (
                              'Submit'
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>


                  {/* <Form.Group className='mt-2'>
                    <Row>
                      <Col className='col-md-8'>
                        <div className="col-sm-6 col-md-3 mg-t-10 mg-md-t-0">
                          <Button
                            variant="btn btn-info btn-block btn-sm"
                            onClick={handleSubmit}
                            disabled={loadingTeamInfoFn && id || loadingpostNewTeamFn || loadingpatchTeamInfoFn}
                          >
                            {(loadingpostNewTeamFn || loadingpatchTeamInfoFn) ? (
                              <>
                                <Spinner animation="border" size="sm" className="me-2" />
                                Loading...
                              </>
                            ) : (
                              'Submit'
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group> */}
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  )
}

export default AddTeam