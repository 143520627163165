import React, { useContext } from 'react'
/// React router dom
import { Routes, Route, Outlet } from 'react-router-dom'
import { useSelector } from "react-redux";
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

//Employee
import DashboardRoute from './dashboard/DashboardRoute';
import EmployeeProfile from './employee/EmployeeProfile';
import AdminDashBoard from './dashboard/AdminDashboard';
import PersonalInfo from './employee/PersonalInfo';
import EmergencyContact from './employee/EmergencyContact';
import EmployeeEducation from './employee/education/EmployeeEducation';
import ListEmployeeEducation from './employee/education/ListEmployeeEducation';
import EmployeeCertification from './employee/certification/EmployeeCertification';
import ListEmployeeCertification from './employee/certification/ListEmployeeCertification';
import EmployeePassport from './employee/passport/EmployeePassport';
import ListEmployeePassPort from './employee/passport/ListEmployeePassport';
import Employeevisa from './employee/visa/EmployeeVisa';
import ListEmployeeVisa from './employee/visa/ListEmployeeVisa';
import BankDetails from './employee/bank/BankDetails';
import ListBankDetails from './employee/bank/ListBankDetails';
import ListEmployee from './employee/employee_view/EmployeeList';
import ProfileImage from './employee/profile_image/ProfileImage';
import EmployeeProfileView from './employee/employee_view/EmployeeProfileView';
import EmployeeOnboarding from '../jsx/employee/EmployeeOnboarding';
import TimeSheetView from './timesheet/TimeSheetView';
import EmployeeTimeSheetView from './timesheet/EmployeeTimeSheetView';
import { ToastContainer } from "react-toastify";
import AddLeaveBalance from './leave_balance/AddLeaveBalance';
import LeaveBalanceListView from './leave_balance/ListLeaveBalance';
import AddLeaveAdmin from './leaves/AddLeaveAdmin';
import ListLeavesAdmin from './leaves/ListLeavesAdmin';
import ExpiryDashBoard from './dashboard/ExpiryDashboard';
import NotificationsList from './layouts/nav/NotificationsList';
import DraftEmployeeList from './recruitment/jobViewComponent/DraftEmployeeList';
import TimeSheetSummary from './timesheet/TimeSheetSummary';

//Components
import AddCompany from './company/AddCompany';
import CompanyListView from './company/ListCompanyView';
// import Company from './company/ListCompanyView';

//Announcements
import AddAnnouncements from './announcements/AddAnnouncements';
import ListAnnouncements from './announcements/ListAnnouncements';
import AddAnnouncementsTopic from './announcements/AddAnnouncementsTopics';
import ListAnnouncementsTopic from './announcements/ListAnnouncementsTopics';


//Task
import AddTask from './task/AddTask';
import ListTask from './task/ListTask';
import AddPriority from './task/AddPriority';
import ListPriority from './task/ListPriority';


/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import ConfirmPassword from './pages/ConfirmPassword'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'

import ProjectListView from './project/Listproject';
import TeamListView from './team/Listteam';
import ClientListView from './client/ListClient';
import AddClient from './client/AddClient';
import AddProject from './project/Addproject';
import AddTeam from './team/AddTeam';
import PayslipsListView from './payslips/Listpayslips';
import AddPayslips from './payslips/Addpayslips';
//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';

//Work location
import AddWorkLocation from './worklocation/AddWorkLocation';
import { WorklocationListView } from './worklocation/Listworklocation';

//Positions
import AddPositions from './Positions/AddPositions';
import PositionsListView from './Positions/ListPositions';

//Country
import AddCountry from './Country/AddCountry';
import CountryListView from './Country/ListCountry';

//Nationality
import AddNationality from './Nationality/AddNationality';
import NationalityListView from './Nationality/ListNationality';

//VisaType
import AddVisaType from './Visatype/AddVisatype';
import VisaTypeListView from './Visatype/ListVisatype';

//Recuritments
import RecruitmentList from './recruitment/RecruitmentList';


//Pricing
import Subscription from './pricing/Subscription';
import AdminProfileView from './employee/employee_view/ProfileViewComponent/AdminProfileView';
import AddLeave from './leaves/AddLeave';
import ListLeaves from './leaves/ListLeaves';
import LeavePolicyView from './leaves/ListLeavePolicy';
import AddLeavePolicy from './leaves/AddLeavePolicy';
import JobVacancyView from './recruitment/jobViewComponent/JobVacancyView';
import UsersList from './recruitment/Users/UsersList';
import { ServiceContext } from '../context/ServiceContext';

const Markup = () => {

  const { isRecruitmentEnabled } = useContext(ServiceContext);


  const allroutes = [
    /// Dashboard
    { url: '', component: <DashboardRoute /> },
    { url: 'dashboard', component: <AdminDashBoard /> },

    { url: 'expiry-dashboard', component: <ExpiryDashBoard /> },

    { url: 'admin-dashboard', component: <AdminDashBoard /> },

    { url: 'notification-list', component: <NotificationsList /> },


    // TimeSheet 
    { url: 'timesheet-view', component: <TimeSheetView /> },
    { url: 'timesheet-listview', component: <EmployeeTimeSheetView /> },
    { url: 'timesheet-summary', component: <TimeSheetSummary /> },


    //Recuritments
    { url: 'recuritments-list', component: <RecruitmentList /> },
    { url: 'draftemployee-list', component: <DraftEmployeeList /> },


    { url: 'job-view/:id', component: <JobVacancyView /> },


    //Employee Screen Routes 
    { url: 'employee-profile', component: <EmployeeProfile /> },
    { url: 'employee-personal-info', component: <PersonalInfo /> },
    { url: 'emergency-contact', component: <EmergencyContact /> },
    { url: 'add-education', component: <EmployeeEducation /> },
    { url: 'list-education', component: <ListEmployeeEducation /> },
    { url: 'add-certification', component: <EmployeeCertification /> },
    { url: 'list-certification', component: <ListEmployeeCertification /> },
    { url: 'add-passport', component: <EmployeePassport /> },
    { url: 'list-passport', component: <ListEmployeePassPort /> },
    { url: 'add-visa', component: <Employeevisa /> },
    { url: 'list-visa', component: <ListEmployeeVisa /> },
    { url: 'add-bank', component: <BankDetails /> },
    { url: 'list-bank', component: <ListBankDetails /> },
    { url: 'list-employee', component: <ListEmployee /> },
    { url: 'employee-image', component: <ProfileImage /> },
    { url: 'employee-profile-view', component: <EmployeeProfileView /> },
    { url: 'employee-profile-view/:employee_uuid', component: <EmployeeProfileView /> },

    { url: 'add-leave-balance', component: <AddLeaveBalance /> },
    { url: 'list-leave-balance', component: <LeaveBalanceListView /> },
    { url: 'admin-add-leave', component: <AddLeaveAdmin /> },
    { url: 'admin-leave-list', component: <ListLeavesAdmin /> },
    { url: 'admin-leave-policy', component: <LeavePolicyView /> },
    { url: 'add-leave-policy', component: <AddLeavePolicy /> },

    { url: 'add-company/', component: <AddCompany /> },
    { url: 'list-company', component: <CompanyListView /> },
    { url: 'list-project', component: <ProjectListView /> },
    { url: 'list-team', component: <TeamListView /> },
    { url: 'list-client', component: <ClientListView /> },
    { url: 'add-client', component: <AddClient /> },
    { url: 'add-project', component: <AddProject /> },
    { url: 'add-team', component: <AddTeam /> },
    { url: 'list-payslips', component: <PayslipsListView /> },
    { url: 'add-payslips', component: <AddPayslips /> },


    // { url: 'list-company', component: <Company/>},
    //Announcements
    { url: 'add-announcements', component: <AddAnnouncements /> },
    { url: 'list-announcements', component: <ListAnnouncements /> },

    { url: 'add-announcementsTopic', component: <AddAnnouncementsTopic /> },
    { url: 'list-announcementsTopic', component: <ListAnnouncementsTopic /> },

    //Task
    { url: 'add-task/', component: <AddTask /> },
    { url: 'list-task/', component: <ListTask /> },
    { url: 'add-Priority/', component: <AddPriority /> },
    { url: 'list-Priority/', component: <ListPriority /> },


    /// pages
    // { url: 'page-register', component: <Registration/> },
    // { url: 'login', component: <Login/> },    

    //Pricing
    { url: 'Subscription/', component: <Subscription /> },

    //work location

    { url: 'add-worklocation/', component: <AddWorkLocation /> },
    { url: 'list-worklocation/', component: <WorklocationListView /> },

    //Positions
    { url: 'add-positions', component: <AddPositions /> },
    { url: 'list-positions/', component: <PositionsListView /> },

    //Country
    { url: 'add-country', component: <AddCountry /> },
    { url: 'list-country/', component: <CountryListView /> },

    //Nationality
    { url: 'add-nationality', component: <AddNationality /> },
    { url: 'list-nationality/', component: <NationalityListView /> },

    //VisaType
    { url: 'add-visatype', component: <AddVisaType /> },
    { url: 'list-visatype/', component: <VisaTypeListView /> },

    { url: 'app-profile/', component: <AdminProfileView /> },

    { url: 'users-list/', component: <UsersList /> },

    //Leaves
    { url: 'add-leave', component: <AddLeave /> },
    { url: 'leave-list/', component: <ListLeaves /> },

  ]

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/confirm-password/:id/:token/" element={<ConfirmPassword />} />
        {/* <Route path='page-lock-screen' element= {<LockScreen />} /> */}
        <Route path='page-error-400' element={<Error400 />} />
        <Route path='page-error-403' element={<Error403 />} />
        <Route path='page-error-404' element={<Error404 />} />
        <Route path='page-error-500' element={<Error500 />} />
        <Route path='page-error-503' element={<Error503 />} />
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            // Check if isRecruitmentEnabled is true and route is for recruitment
            (data.url.includes('recuritments-list') || data.url.includes('draftemployee-list')) && !isRecruitmentEnabled ?
              null :
              <Route
                key={i}
                exact
                path={`${data.url}`}
                element={data.component}
              />
          ))}
        </Route>
      </Routes>

      <ScrollToTop />
    </>
  )
}

function MainLayout() {
  const sideMenu = useSelector(state => state.sideMenu);
  return (
    <div id="main-wrapper" className={`show ${sideMenu ? "menu-toggle" : "menu-expand"}`}>

      <Nav />
      <ToastContainer />
      {/* <div className="content-body" style={{ minHeight: window.screen.height - 45 }}> */}
      <div className="content-body">
        <div className="container-fluid">
          <EmployeeOnboarding />
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )

};
export default Markup
