import { useState } from "react"
import { Button, FormControl, FormGroup, FormLabel, Modal, Row } from "react-bootstrap"
import { PASSWORD_MESSAGES, VALIDATION_MESSAGES } from "../../utils/ConstantMessages";
import { useNavigate } from "react-router-dom";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  let errorsObj = { newPassword: '', confirmPassword: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [passwordMissMatch, setPasswordMissMatch] = useState(false)
  const { setIsAuthenticated } = props

  const handleOnClick = () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (!newPassword) {
      errorObj.newPassword = VALIDATION_MESSAGES.newPasswordRequired
      error = true
    }
    if (!confirmPassword) {
      errorObj.confirmPassword = VALIDATION_MESSAGES.confirmPasswordRequired
      setPasswordMissMatch(false)
      error = true
    }
    setErrors(errorObj)
    if (error) {
      return
    }
    const passwordData = {
      "newPassword": newPassword,
      "confirmPassword": confirmPassword,
    }

    if (newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        setPasswordMissMatch(true)
      } else {
        navigate('/dashboard')
        setShow(false)
        setIsAuthenticated(true)
      }
    }
  };

  const handleConfirmPassword = (e) => {
    let { value } = e.target
    if (!value)
      setPasswordMissMatch(false)
    setConfirmPassword(e.target.value)
    if (newPassword && confirmPassword) {
      if (newPassword !== value) {
        setPasswordMissMatch(true)
      } else {
        setPasswordMissMatch(false)
      }
    }
  }
  const handleShow = () => setShow(true);
  const backdropStyle = {
    backgroundColor: '#6101bb', // Change the background color here
  }
  return (
    <div style={{ backgroundColor: '#6101bb' }}>
      <Modal backdrop='static' show={show} size="lg" centered backdropStyle>
        <Modal.Header>
          <Modal.Title>{PASSWORD_MESSAGES.resetPassword}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup controlId="passwordField" className="mb-3">
            <FormLabel>{PASSWORD_MESSAGES.newPassword}</FormLabel>
            <FormControl type="password" placeholder="Enter your new password" name="newPassword" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
            {errors.newPassword && !newPassword && <div className="text-danger fs-12 mt-2">{errors.newPassword}</div>}
          </FormGroup>
          <FormGroup controlId="passwordField" className="mb-3">
            <FormLabel>{PASSWORD_MESSAGES.confirmPassword}</FormLabel>
            <FormControl
              type="password"
              placeholder="Enter your confirm password"
              name="confirmPassword"
              onChange={handleConfirmPassword}
              value={confirmPassword} />
            {errors.confirmPassword && !confirmPassword && <div className="text-danger fs-12 mt-2">{errors.confirmPassword}</div>}
            {passwordMissMatch && <div className="text-danger fs-12 mt-2">{VALIDATION_MESSAGES.passwordMisMatch}</div>}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <Button variant="primary" onClick={handleOnClick}>
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ResetPassword
