import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { GlobalFilter } from '../components/table/FilteringTable/GlobalFilter';
import '../components/table/FilteringTable/filtering.css';
import { useGetProjectList } from '../../actions/project-action'
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IsValidPermissions } from '../../services/AuthService';
import DataTable from '../pages/commonPages/DataTable';
import { useProjectDeleteById } from '../../actions/project-action';
import { deleteConfirmation } from '../../utils/ComponentUtilse';
import CustomTooltip from '../../utils/CommonUtils';
import { dateHandler } from '../../utils/ComponentUtilse';

export const ProjectListView = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([])

    const { data: getAllProject, isLoading: loadingGetProjectFn, refetch: refetchProject } = useGetProjectList()

    const { mutate: deleteProjectInfo, isLoading: loadingdeleteProjectFn } = useProjectDeleteById();


    useEffect(() => {
        if (getAllProject) {
            //setCompanyList(getAllCompany);
            setData(getAllProject);
        }
    }, [getAllProject]);

    const columns = useMemo(
        () => [
            {
                Header: "Project Name",
                Footer: "Project Name",
                accessor: "project_name",
                Filter: ColumnFilter,
                className: "minWidth200"
            },
            {
                Header: "Project Code",
                Footer: "Project Code",
                accessor: "project_short_name",
                Filter: ColumnFilter,
                className: "minWidth100"
            },
            // {
            //     Header: "Client",
            //     Footer: "Client",
            //     accessor: "client_name",
            //     Filter: ColumnFilter,
            //     className: "minWidth100"
            // },
            {
                Header: " Start Date",
                Footer: " Start Date",
                accessor: "start_date",
                Cell: ({ row }) => {
                    const { original } = row;
                    return <span>{dateHandler(original.start_date)}</span>;
                },
                Filter: ColumnFilter,
                className: "minWidth100"
            },
            {
                Header: "End Date",
                Footer: "End Date",
                accessor: "end_date",
                Cell: ({ row }) => {
                    const { original } = row;
                    return <span>{dateHandler(original.end_date)}</span>;
                },
                Filter: ColumnFilter,
                className: "minWidth100"
            },
            {
                Header: "Status",
                Footer: "Status",
                accessor: "status_name",
                Filter: ColumnFilter,
                className: "minWidth100"
            },

            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                disableFilters: true,
                className: "minWidth100",
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip id={`delete-${row.index}`} message="Delete">
                            <Button
                                className="shadow btn-xs sharp me-1"
                                variant="danger light"
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteConfirmation(row.original.id, `Project - ${row.original.project_name}`, deleteProjectInfo, refetchProject)}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </CustomTooltip>&nbsp;

                        {IsValidPermissions('organization.change_projects') &&
                            <CustomTooltip id={`edit-${row.index}`} message="Edit">
                                <Button
                                    className="shadow btn-xs sharp me-1"
                                    variant='primary light'
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleEdit(row.original.id)}
                                >
                                    <i className="fa fa-pencil-alt"></i>
                                </Button>
                            </CustomTooltip>
                        }
                    </>
                )

            },
        ],
        []
    );



    const handleView = (id) => {

    }

    const handleEdit = (id) => {
        navigate('/add-project/', { state: { rowId: id } })
    }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const { globalFilter, pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Project" motherMenu="Settings" />
            {IsValidPermissions('organization.view_projects') &&
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Project</h4>
                        {IsValidPermissions('organization.add_projects') &&
                            <div className="d-inline-flex">
                                <Link to={'/add-project/'}>
                                    <Button className='me-2' variant='primary btn-sm'>
                                        <i class="fa fa-user-plus" aria-hidden="true"></i> &nbsp; Project
                                    </Button>
                                </Link>
                            </div>
                        }

                        {/* <div className="az-content-breadcrumb">
                                <span>Settings</span>
                                <span>Project</span>
                                <span>List</span>
                            </div> */}
                    </div>
                    <div className="card-body">
                        <DataTable
                            isLoading={loadingGetProjectFn}
                            columns={columns}
                            tableInstance={tableInstance}
                        />
                    </div>
                </div>
            }

            {(!loadingGetProjectFn && !IsValidPermissions('organization.view_projects')) &&
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <Alert variant="warning" show={true}>

                                <svg
                                    viewBox='0 0 24 24'
                                    width='24'
                                    height='24'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='me-2'
                                >
                                    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                    <line x1='12' y1='9' x2='12' y2='13'></line>
                                    <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                </svg>

                                <strong>Info!  </strong> You don't have permission to view Projects.

                            </Alert>
                        </div>
                    </div>
                </div>
            }



        </>
    )

}
export default ProjectListView;