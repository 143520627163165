import { useState } from "react";
import { Button, Table, Spinner, Badge } from "react-bootstrap";
import { isValidPermission } from "../../../../services/AuthService";
import { handleFileDownload } from "../../../../utils/ComponentUtilse";
import { dateHandler } from "../../../../utils/ComponentUtilse";
import { Alertmsg } from "../../../../utils/CommonUtils";
import CustomTooltip from "../../../../utils/CommonUtils";

function RtwView(props) {
  const [showAlert, setShowAlert] = useState(true);

  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleEdit = (id) => {
    props.setCardView({ rtwEdit: true, rtwId: id });
  };

  return (
    <div className="card" id="rtw-admin">
      <>
        <div className="card-header d-flex justify-content-between">
          <h4 className="card-title">
            <i className="fas fa-address-card font-18 align-middle me-2 text-primary"></i>
            &nbsp; Right To Work
          </h4>
          {(isValidPermission(
            "employee.add_employeerighttowork",
            props.getPermissionList
            )) && (
            <Button
              className="me-2 btn-sm"
              variant="primary"
              onClick={() => {
                props.setCardView({
                  rtwEdit: true,
                });
              }}
            >
              <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;
              Add
              {/* {props?.employeeRtwList?.[0]?.id ? "Edit" : "Add"} */}
            </Button>
          )
          }
        </div>

        <div className="card-body">

            {showAlert && !props?.employeeRtwList?.find(employee => employee?.current_active === true) ? (
                  <Alertmsg closeAlert={closeAlert} />
              ) : null
              }

          <div className="form-validation">
            <div className="row">
            {props?.employeeRtwList?.map(n=>{
              return (n.current_active === true ? <>
              <div className="col-xl-4 col-xs-4 mb-3">
                  <div style={{ display: "grid", textAlign: "center" }}>
                    <b>Screenshot Preview</b>
                    {n?.profile_image_url?
                    <img
                      src={n?.profile_image_url}
                      Style="width: 2in; height: 2.5in; object-fit: fill; object-position: center;"
                      alt="profile-img"
                    />: <h6 className="mt-5">No Image to Preview</h6>
                  }
                  </div>
                </div> 
                <div className="col-xl-6">
                                <div className="form-group mb-3 row">
                                    <label className="col-lg-6 col-form-label">
                                        RTW Reference:
                                    </label>
                                    <label className="col-lg-6 col-form-label">
                                        {props?.employeeRtwList?.[0]?.rtw_reference}
                                    </label>
                                    <label className="col-lg-6 col-form-label">
                                        Date of Check:
                                    </label>
                                    <label className="col-lg-6 col-form-label">
                                        {dateHandler(props?.employeeRtwList?.[0]?.rtw_submit_date)}
                                    </label>
                                    <label className="col-lg-6 col-form-label">
                                        Expiry Date:
                                    </label>
                                    <label className="col-lg-6 col-form-label">
                                        {dateHandler(props?.employeeRtwList?.[0]?.rtw_expiry_date)}
                                    </label>
                                    <label className="col-lg-6 col-form-label">
                                        RTW Status:
                                    </label>
                                    <label className="col-lg-6 col-form-label">
                                        {props?.employeeRtwList?.[0]?.rtw_status_text}
                                    </label>
                                    <label className="col-lg-6 col-form-label">
                                        Attachment:
                                    </label>
                                    <label className="col-lg-6 col-form-label">
                                        {props?.employeeRtwList?.[0]?.attachment_url ? <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleFileDownload(props?.employeeRtwList?.[0]?.attachment_url)}><i className="fa fa-download"></i></Button> : null}
                                    </label>
                                </div>
                            </div>
                            </>
                            : ''
                          )
                        })}

              <h4 className="mt-4">Right To Work List:</h4>   
              <Table responsive>
                <thead>
                  <tr>
                    <th className="minWidth150">Share Code</th>
                    <th className="minWidth150">RTW Reference</th>
                    <th className="minWidth120">Date of Check</th>
                    <th className="minWidth150">Status</th>
                    <th className="minWidth120">Expiry Date</th>
                    <th className="minWidth50">Current Active</th>
                    {isValidPermission(
                      "employee.change_employeerighttowork",
                      props.getPermissionList
                    ) && <th className="minWidth100">Action</th>}
                    {/* {isValidPermission(
                      "employee.change_employeevisa",
                      props.getPermissionList
                    ) && <th className="minWidth100">Action</th>} */}
                  </tr>
                </thead>
                <tbody>
                  {props.isLoading ? (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={7}>
                        <Spinner
                          animation="border"
                          size="lg"
                          className="me-2"
                        />
                      </td>
                    </tr>
                  ) : props.employeeRtwList.length > 0 ? (
                    props.employeeRtwList.map((item, i) => {
                      return (
                        <tr>
                          <td>{item.emp_share_code}</td>
                          <td>{item.rtw_reference}</td>
                          <td>{dateHandler(item.rtw_submit_date)}</td>
                          <td>{item.rtw_status_text}</td>
                          <td>{dateHandler(item.rtw_expiry_date)}</td>
                          <td className="text-center">
                            {item.current_active ? (
                              <Badge
                                pill
                                bg="success"
                                className="badge-sm text-capitalize"
                              >
                                Yes
                              </Badge>
                            ) : (
                              <Badge
                                pill
                                bg="danger"
                                className="badge-sm text-capitalize"
                              >
                                No
                              </Badge>
                            )}
                          </td>
                          {isValidPermission(
                            "employee.change_employeerighttowork",
                            props.getPermissionList
                          ) && (
                            <td>
                              <CustomTooltip
                                id={`edit-${item.index}`}
                                message="Edit"
                              >
                                <Button
                                  className="shadow btn-xs sharp me-1"
                                  variant="primary light"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleEdit(item.id)}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </Button>
                              </CustomTooltip>
                              {item.profile_image_url ? (
                                <CustomTooltip
                                  id={`download-${item.index}`}
                                  message="Download"
                                >
                                  <Button
                                    className="shadow btn-xs sharp me-1"
                                    variant="primary light"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleFileDownload(item.profile_image_url)
                                    }
                                  >
                                    <i className="fa fa-download"></i>
                                  </Button>
                                </CustomTooltip>
                              ) : null}
                            </td>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td style={{ textAlign: "center" }} colSpan={7}>
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
export default RtwView;
