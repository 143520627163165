import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { Button, Spinner, Form, Row, Table, Badge, Card, Alert, Col, Modal, ProgressBar, Dropdown } from "react-bootstrap";
import {
    useTable,
    useGlobalFilter,
    useFilters,
    usePagination,
} from "react-table";
import "../../components/table/FilteringTable/filtering.css";
import { useEmployeeInfo } from "../../../actions/employee-actions";
import { useDraftEmployeeInfo, useDraftEmployeeUpdateFn } from "../../../actions/draft-employee-actions";
import { ColumnFilter } from "../../components/table/FilteringTable/ColumnFilter";
import { useNavigate, Link } from "react-router-dom";
import DataTable from "../../pages/commonPages/DataTable";
import { IsValidPermissions } from "../../../services/AuthService";
import { dateHandler } from "../../../utils/ComponentUtilse";
import Swal from 'sweetalert2';
import CustomTooltip from "../../../utils/CommonUtils";
import { ServiceContext } from "../../../context/ServiceContext";

export const DraftEmployeeList = () => {

    const navigate = useNavigate();

    const { setEmployeeOnboardModal, maxNumberOfUser } = useContext(ServiceContext);

    const [alertModal, setAlertModal] = useState(false)

    const [data, setData] = useState([]);

    const {
        data: getEmployeeListViewFn,
        isLoading: loadingGetEmployeeListFn,
        isFetching: loadingGetEmployeeFetchFn,
        refetch: refetchEmployee,
    } = useDraftEmployeeInfo(true, false);

    const {
        mutate: patchDraftEmployeeFn,
        isLoading: loadingDraftEmployeeFn,
    } = useDraftEmployeeUpdateFn();

    const updateDraftEmployeeHandeler = (employee_uuid) => {

        let userData = {
            is_active_employee: true,
        }

        patchDraftEmployeeFn(
            { employee_uuid, userData },
            {
                onSuccess: (response) => {
                    navigate(`/employee-profile-view/${employee_uuid}`)
                    refetchEmployee()
                },
            }
        );
    }

    const swalConfirm = (employee_uuid) => {

        if (Number(maxNumberOfUser) >= data?.length + 1) {
            Swal.fire({
                title: 'Convert to Active Employee?',
                text: 'Are you sure you want to convert this candidate to a Active Employee?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, convert it!',
            }).then((result) => {
                if (result.isConfirmed) {
                    // If the user confirms, call the post handler function
                    updateDraftEmployeeHandeler(employee_uuid);
                }
            });
        } else {
            setAlertModal(true)
        }
    }



    useEffect(() => {
        if (getEmployeeListViewFn) {
            setData(getEmployeeListViewFn);
        }
    }, [getEmployeeListViewFn]);

    const columns = useMemo(
        () => [
            {
                Header: "Employee",
                Footer: "",
                accessor: "employee_fullname",
                Filter: ColumnFilter,
                className: "minWidth220",
                Cell: ({ cell, row }) => {
                    // Initialize initials to an empty string
                    let initials = "";

                    if (row?.original?.first_name) {
                        // Extract the first letter and capitalize it
                        const firstNameInitial = row?.original?.first_name
                            .charAt(0)
                            .toUpperCase();
                        initials += firstNameInitial;
                    }

                    if (row?.original?.last_name) {
                        // Extract the first letter and capitalize it
                        const lastNameInitial = row?.original?.last_name
                            .charAt(0)
                            .toUpperCase();
                        initials += lastNameInitial;
                    }
                    return (
                        <div>
                            {row?.original?.employee_profile_image ? (
                                <div className="media">
                                    <img
                                        src={
                                            row?.original?.employee_profile_image_url
                                                ?.profile_image_url
                                        }
                                        className="rounded-lg me-2 user-avator-img"
                                        alt=""
                                    />{" "}
                                    <div className="media-body text-nowrap">
                                        {row.original.active ? (
                                            <Link
                                                className="table-icon-row"
                                                to={`/employee-profile-view/${row?.original?.employee_uuid}`}
                                            >
                                                <h6 className="text-black  fs-16 mb-0">
                                                    {row?.original?.first_name}
                                                </h6>
                                                <span className="text-black fs-14 mb-0">
                                                    {row?.original?.last_name}
                                                </span>
                                            </Link>
                                        ) : (
                                            <>
                                                <h6 className="text-black  fs-16 mb-0">
                                                    {row?.original?.first_name}
                                                </h6>
                                                <span className="text-black fs-14 mb-0">
                                                    {row?.original?.last_name}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="media">
                                    <div className="rounded-lg me-2 justify-content-center user-avator-letter">
                                        {initials}
                                    </div>{" "}
                                    <div className="media-body text-nowrap">
                                        {row.original.active ? (
                                            <Link
                                                className="table-icon-row"
                                                to={`/employee-profile-view/${row?.original?.employee_uuid}`}
                                            >
                                                <h6 className="text-black  fs-16 mb-0">
                                                    {row?.original?.first_name}
                                                </h6>
                                                <span className="text-black fs-14 mb-0">
                                                    {row?.original?.last_name}
                                                </span>
                                            </Link>
                                        ) : (
                                            <>
                                                <h6 className="text-black  fs-16 mb-0">
                                                    {row?.original?.first_name}
                                                </h6>
                                                <span className="text-black fs-14 mb-0">
                                                    {row?.original?.last_name}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <span className="w-space-no"></span>
                                </div>
                            )}
                        </div>
                    );
                },
            },

            {
                Header: "Email",
                Footer: "Email",
                accessor: "work_email",
                Filter: ColumnFilter,
                className: "minWidth320",
            },

            {
                Header: "Phone",
                Footer: "Phone",
                accessor: "work_phone",
                Filter: ColumnFilter,
                className: "minWidth320",
            },
            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                disableFilters: true,
                className: "minWidth150",
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip id={`edit-${row.index}`} message="Convert To Active Employee">
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="primary"
                                    className="light sharp i-false"
                                >
                                    <i className="fa fa-ellipsis-h"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => swalConfirm(row?.original?.employee_uuid)}>Convert To Active Employee</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </CustomTooltip>
                    </>
                ),
            },
        ],
        []
    );

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );




    return (
        <>
            {IsValidPermissions("employee.can_view_all_employee") &&
                <div className="card">
                    <div className="card-body">
                        <div className="card-header">
                            <h4 className="card-title" >Draft Employees</h4>
                            {/* {IsValidPermissions("employee.can_add_all_employee") && (
                                    <div>
                                        <Button
                                            className="me-2"
                                            style={{ marginTop: "-20px" }}
                                            variant="primary btn-sm"
                                            onClick={handleAddEmployee}
                                            disabled={loadingGetEmployeeListFn}
                                        >
                                            <i class="fa fa-user-plus" aria-hidden="true"></i>{" "}
                                            &nbsp; Employee

                                        </Button>
                                    </div>
                                )} */}
                        </div>
                        <DataTable
                            isLoading={loadingGetEmployeeListFn}
                            columns={columns}
                            tableInstance={tableInstance}
                        />

                    </div>
                </div>
            }

            {alertModal && <Modal show={alertModal} centered onHide={() => setAlertModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title><i class="fa fa-info-circle text-danger" aria-hidden="true"></i> &nbsp; Limit Exceeded!</Modal.Title>
                </Modal.Header>
                <Modal.Body>You have exceeded the maximum number of user creation limit,
                    Please upgrade your subscription plan to add more users.</Modal.Body>
                <Modal.Footer>
                    <Link to={'/Subscription/'}>
                        <Button className='me-2' variant='primary btn-sm'>
                            <i class="fa fa-address-card" aria-hidden="true"></i> &nbsp; View Subscription
                        </Button>

                    </Link>
                    <Button variant='danger light btn-sm' onClick={() => setAlertModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>}
            
            {!loadingGetEmployeeListFn &&
                !IsValidPermissions("employee.can_view_all_employee") && (
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <Alert variant="warning" show={true}>
                                    <svg
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="me-2"
                                    >
                                        <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                        <line x1="12" y1="9" x2="12" y2="13"></line>
                                        <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                    </svg>
                                    <strong>Info! </strong> You don't have permission to view
                                    Employee Information.
                                </Alert>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
};

export default DraftEmployeeList;