import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// API POST Method

const employeeBankPostFn = async (employeeData) => {
    let config = {
      method: "post",
      url: `/employee/api/v1/employee_bank_detail/ `,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: employeeData,
    };
    
    return await axios.request(config);
  };
  export const useEmployeeBankPostFn = () => {
    return useMutation(["employee-bank-post"], employeeBankPostFn);
  };


  // API PATCH Method
  const employeeBankUpdateFn = async (employeeData) => {
  
    let {id, userData} = employeeData;
    let config = {
      method: "patch",
      url: `/employee/api/v1/employee_bank_detail/${id}/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: userData,
    };
    
    return await axios.request(config);
  };
  export const useEmployeeBankUpdateFn = () => {
    return useMutation(["employee-bank-update"], employeeBankUpdateFn);
  };

 // API GET Method Using UUID

  const getEmployeeBankByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
      method: "get",
      url: `/employee/api/v1/employee_bank_detail/?employee__employee_uuid=${employee_uuid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetEmployeeBankByUUID = (employee_uuid) => {
    return useQuery(["get-employee-bank-by-uuid", employee_uuid], getEmployeeBankByUUIDFn, {enabled: !!employee_uuid});
  };

  // API GET Method 
  
  const getEmployeeBankByIDFn = async (query) => {
    let [, employee_id] = query.queryKey;
    let config = {
      method: "get",
      url: `/employee/api/v1/employee_bank_detail/${employee_id}/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetEmployeeBankByID = (employee_id) => {
    return useQuery(["get-employee-bank-by-id", employee_id], getEmployeeBankByIDFn, {enabled: false});
  };  