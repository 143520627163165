import React, { Fragment, useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useRef } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { TostifyConfig } from "../../utils/Constance";
import { useQueryClient } from "@tanstack/react-query";
import LoadingOverlay from 'react-loading-overlay';
import { useLocation, } from 'react-router-dom/dist'
import DatePicker from 'react-datepicker';
import { toast } from "react-toastify";
import {
    useLeavePolicy,
    useGetLeaveBalanceByID,
    useGetLeaveBalanceByUUID,
    useLeaveBalancePostFn,
    useLeaveBalanceUpdateFn
} from "../../actions/leavebalance-actions";
import { useEmployeeDropdown } from "../../actions/employee-actions";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { LEAVE_MONTH_STATUS } from "../../utils/Constance";

function AddLeaveBalance() {

    const client = useQueryClient();

    const navigate = useNavigate();

    const location = useLocation()

    let id = location?.state?.rowId

    //const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const [leaveBalanceData, setLeaveBalanceData] = useState({
        year: new Date().getFullYear(),
        month_start: LEAVE_MONTH_STATUS[0].id
    });

    console.log('leaveBalanceData', leaveBalanceData)

    const [leaveTypeData, setLeaveTypeData] = useState([]);

    const [selectedDate, setSelectedDate] = useState(null);

    const [selectedMonth, setSelectedMonth] = useState(null);

    const [parserDate, setParserDate] = useState(null);

    const { data: getEmployeeInfo, isLoading: loadingEmployeeInfo } =
        useEmployeeDropdown();


    const {
        data: getLeaveBalanceID,
        isLoading: loadingGetLeaveBalanceIDFn,
        refetch: refetchLeaveBalanceIDFn,
    } = useGetLeaveBalanceByID(id);

    const {
        data: getLeavepolicy,
        isLoading: loadingLeavepolicy,
        refetch: refetchLeavepolicy,
    } = useLeavePolicy();

    const {
        mutate: postLeaveBalanceFn,
        isLoading: loadingPostLeaveBalanceFn,
    } = useLeaveBalancePostFn();

    const {
        mutate: patchLeaveBalanceFn,
        isLoading: loadingLeaveBalanceFn,
    } = useLeaveBalanceUpdateFn();


    const submitForm = () => {
        let FormValidity = FormRef.current.reportValidity();

        if (FormValidity === true) {
            if (id) {
                updateLeaveBalanceHandeler();
            }
            else {
                postLeaveBalanceHandeler();
            }
        }
    }

    const postLeaveBalanceHandeler = () => {

        let userData = {
            leave_type: leaveBalanceData.leave_type,
            custom_leave_days: leaveBalanceData.custom_leave_days,
            total_annual_days: leaveBalanceData.total_annual_days,
            balance_carry_over: leaveBalanceData.balance_carry_over,
            employee_employee_uuid: leaveBalanceData.employee_employee_uuid,
            year: leaveBalanceData.year,
            month_start: leaveBalanceData.month_start
        }

        postLeaveBalanceFn(
            userData,
            {
                onSuccess: (response) => {
                    navigate('/list-leave-balance/')
                    toast.success('Leave Balance Added', TostifyConfig);

                },
            }
        );
    }

    const updateLeaveBalanceHandeler = () => {

        let userData = {
            leave_type: leaveBalanceData.leave_type,
            custom_leave_days: leaveBalanceData.custom_leave_days,
            total_annual_days: leaveBalanceData.total_annual_days,
            balance_carry_over: leaveBalanceData.balance_carry_over,
            employee_employee_uuid: leaveBalanceData.employee_employee_uuid,
            year: leaveBalanceData.year,
            month_start: leaveBalanceData.month_start
        }


        patchLeaveBalanceFn(
            { id, userData },
            {
                onSuccess: (response) => {
                    navigate('/list-leave-balance/')
                    toast.success('Leave Balance Updated', TostifyConfig);

                },
            }
        );
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLeaveBalanceData({
            ...leaveBalanceData,
            [name]: value,
        });
    };

    const SelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        const selectedOptionLabel = e.target.options[selectedIndex].label;
        setLeaveBalanceData({
            ...leaveBalanceData,
            [selectName]: selectValue,
        });
    };


    const handleYearChange = (event) => {
        const { name, value } = event.target;

        // Ensure the selected year is either the current year or the last year
        const currentYear = new Date().getFullYear();
        const lastYear = currentYear - 1;
        if (value === currentYear.toString() || value === lastYear.toString()) {
            setLeaveBalanceData({
                ...leaveBalanceData,
                [name]: value,
            });
        }

    };

    const handleMonthChange = (event) => {
        const { name, value } = event.target;
        // Update the state with the selected month ID
        setLeaveBalanceData({
            ...leaveBalanceData,
            [name]: value,
        });
    };


    useEffect(() => {
        if (getLeavepolicy) {
            setLeaveTypeData(getLeavepolicy);
        }
    }, [getLeavepolicy]);



    useEffect(() => {
        if (leaveBalanceData?.year) {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            // Set the selected date to the first day of the current month and year
            const firstDayOfMonth = new Date(parseInt(leaveBalanceData?.year), 1);
            setSelectedDate(firstDayOfMonth);
            setParserDate(`${year}`);
        }
    }, [leaveBalanceData?.year]);

    useEffect(() => {
        if (id) {
            refetchLeaveBalanceIDFn();
        }
    }, [refetchLeaveBalanceIDFn, id])

    useEffect(() => {

        if (getLeaveBalanceID) {
            setLeaveBalanceData(getLeaveBalanceID);
        }
    }, [getLeaveBalanceID])


    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;

    // Function to generate options for the year select
    const generateYearOptions = () => {
        return [currentYear, lastYear].map((year) => (
            <option key={year} value={year}>
                {year}
            </option>
        ));
    };

    const generateMonthOptions = () => {
        return LEAVE_MONTH_STATUS.map((month) => (
            <option key={month.id} value={month.id}>
                {month.name}
            </option>
        ));
    };



    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>{id ? "Edit Leave Balance" : "New Leave Balance"}</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingGetLeaveBalanceIDFn && id}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Leave Type<span className="required">*</span></label>
                                                <Form.Select
                                                    name="leave_type"
                                                    className="form-control"
                                                    value={leaveBalanceData.leave_type}
                                                    onChange={SelectChangeHandler}
                                                    required
                                                >
                                                    <option value="">Select Leave Type</option>
                                                    {leaveTypeData?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.policy_name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="mb-2">Employee<span className="required">*</span></label>
                                                <Form.Select
                                                    name="employee_employee_uuid"
                                                    className="form-control"
                                                    value={leaveBalanceData.employee_employee_uuid}
                                                    onChange={SelectChangeHandler}
                                                    required
                                                >
                                                    <option value="">Select Employee</option>
                                                    {getEmployeeInfo?.map((option) => (
                                                        <option key={option.id} value={option.employee_uuid}>
                                                            {option.first_name} {option.last_name} {option.employee_number ? ` (${option.employee_number})` : ''}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            {/* <Col className='col-md-6 mb-2'>
                                                <label>Year <span className="required">*</span></label><br />
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    showMonthYearPicker
                                                    dateFormat="MM/yyyy"
                                                    className="form-control mt-0"
                                                    required
                                                />
                                            </Col> */}

                                            <Col className='col-md-3 mb-2'>
                                                <label>Year <span className="required">*</span></label>
                                                <Form.Select
                                                    name="year"
                                                    className="form-control mt-0"
                                                    value={leaveBalanceData.year || currentYear} // Set default value to current year
                                                    onChange={handleYearChange}
                                                    required
                                                >
                                                    {generateYearOptions()}
                                                </Form.Select>
                                            </Col>
                                            <Col className='col-md-3 mb-2'>
                                                <label>Month <span className="required">*</span></label><br />
                                                <Form.Select
                                                    name="month_start"
                                                    className="form-control mt-0"
                                                    value={leaveBalanceData.month_start || LEAVE_MONTH_STATUS[0].id} // Set default value to the first month
                                                    onChange={handleMonthChange}
                                                    required
                                                >
                                                    {generateMonthOptions()}
                                                </Form.Select>
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Custom Leave Days</label>
                                                <Form.Control
                                                    type="number"
                                                    min={0}
                                                    name="custom_leave_days"
                                                    onChange={handleInputChange}
                                                    value={leaveBalanceData.custom_leave_days}
                                                />
                                            </Col>

                                        </Row>
                                    </Form.Group>

                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">Total Annual Days<span className="required">*</span></label>
                                                <Form.Control
                                                    type="number"
                                                    min={0}
                                                    name="total_annual_days"
                                                    onChange={handleInputChange}
                                                    value={leaveBalanceData.total_annual_days}
                                                    required />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Balance Carry Over</label>
                                                <Form.Control
                                                    type="number"
                                                    min={0}
                                                    name="balance_carry_over"
                                                    onChange={handleInputChange}
                                                    value={leaveBalanceData.balance_carry_over}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row>
                                            {/* <Col className='col-md-6 mb-2'>
                                                <label>Leave Type Name</label>
                                                <Form.Control
                                                    type="text"
                                                    name="leave_type_name"
                                                    onChange={handleInputChange}
                                                    value={leaveBalanceData.leave_type_name}
                                                />
                                            </Col> */}
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className='mt-2'>
                                        <Row>
                                            <Col>
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            navigate('/list-leave-balance/')
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="primary btn-sm"
                                                        onClick={submitForm}
                                                        disabled={loadingGetLeaveBalanceIDFn && id || loadingPostLeaveBalanceFn || loadingLeaveBalanceFn}
                                                    >
                                                        {(loadingPostLeaveBalanceFn || loadingLeaveBalanceFn) ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    className="me-2"
                                                                />
                                                                Loading...
                                                            </>
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default AddLeaveBalance;
