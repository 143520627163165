import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import '../components/table/FilteringTable/filtering.css';
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTaskPriorityList } from '../../actions/task-action'
import DataTable from '../pages/commonPages/DataTable';


export const ListPriority = () => {

    const navigate = useNavigate();


    const { data: getAllPriority, isLoading: loadingGetTaskPriorityFn } = useTaskPriorityList();

    const [data, setData] = useState([]);


    useEffect(() => {
        if (getAllPriority) {

            setData(getAllPriority);
        }
    }, [getAllPriority]);


    const columns = useMemo(
        () => [

            {
                Header: "Priority Range",
                Footer: "Priority Range",
                accessor: "priority_range",
                Filter: ColumnFilter,
            },

            {
                Header: "Description",
                Footer: "Description",
                accessor: "description",
                Filter: ColumnFilter,
            },
            {
                Header: "Priority Name",
                Footer: "Priority Name",
                accessor: "priority_name",
                Filter: ColumnFilter,
            },

            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                disableFilters: true,
                Cell: ({ cell, row }) => { return <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => navigate('/add-Priority/', { state: { rowId: row.id } })}><i className="fas fa-pencil-alt"></i></Button> },

            },
        ],
        []
    );



    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const { globalFilter, pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Filtering" motherMenu="Table" />
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Table Filtering</h4>
                </div>
                <div className="card-body">
                    <div className="d-flex flex-wrap mb-0 align-items-center" style={{ justifyContent: "space-between" }}>
                        <div></div>
                        <div className="mb-1 mt-2">

                            <Link to={`/add-Priority/`}><Button className='me-2 btn-sm' variant='primary'>
                                <i className="fas fa-plus"></i>&nbsp;Task Priority
                            </Button></Link>

                        </div>
                    </div>
                    <DataTable
                        isLoading={loadingGetTaskPriorityFn}
                        columns={columns}
                        tableInstance={tableInstance}
                    />
                </div>
            </div>
        </>
    )

}
export default ListPriority;