import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const getPendingActionByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/employee/employee_pending_actions/${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };
    
    const response = await axios.request(config);
    return response?.data;
};
export const usePendingActionByUUID = (employee_uuid) => {
    return useQuery(["get-pending-action-by-uuid", employee_uuid], getPendingActionByUUIDFn);
};