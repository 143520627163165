import React, { Fragment, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import {
    useEmployeeVisaPostFn,
    useEmployeeVisaUpdateFn,
    useGetEmployeeVisaByID
} from '../../../actions/employee-visa-actions';
import { useVisaFn } from '../../../actions/employee-visa-actions';
import { useCountryofIssueFn } from '../../../actions/employee-passport-actions';
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { extractFileName } from '../../../utils/ComponentUtilse';
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import LoadingOverlay from 'react-loading-overlay';



function Employeevisa(props) {

    const client = useQueryClient();

    let id = props?.visaId

    const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const [employeeVisaData, setEmployeeVisaData] = useState({});

    const [isChecked, setIsChecked] = useState(false);

    const [currentAttachmentData, setCurrentAttachmentData] = useState(null);

    const { data: getVisa, isLoading: loadingVisa } = useVisaFn();

    const { data: getCountryofIssue, isLoading: loadingCountryofIssue } = useCountryofIssueFn();


    const {
        data: getEmployeeVisaID,
        isLoading: loadingGetEmployeeVisaIDFn,
        refetch: refetchEmployeeVisa,
    } = useGetEmployeeVisaByID(id);

    const {
        mutate: postEmployeeVisaFn,
        isLoading: loadingPostEmployeeVisaFn,
    } = useEmployeeVisaPostFn();

    const {
        mutate: patchEmployeeVisaFn,
        isLoading: loadingEmployeeVisaFn,
    } = useEmployeeVisaUpdateFn();


    const submitForm = () => {
        let FormValidity = FormRef.current.reportValidity();

        if (FormValidity === true) {
            if (id) {
                updateEmployeeVisaHandeler();
            }
            else {
                postEmployeeVisaHandeler();
            }
        }
    }

    const postEmployeeVisaHandeler = () => {

        let userData = {
            attachment: employeeVisaData.attachment,
            issue_date: employeeVisaData.issue_date,
            expiration_date: employeeVisaData.expiration_date,
            purpose_of_travel: employeeVisaData.purpose_of_travel,
            visa_type: employeeVisaData.visa_type,
            country_of_issue: employeeVisaData.country_of_issue,
            remarks: employeeVisaData.remarks,
            visa_number: employeeVisaData.visa_number,
            issuing_authority: employeeVisaData.issuing_authority,
            current_active: employeeVisaData.current_active,
            employee_employee_uuid: employee_uuid
        }

        postEmployeeVisaFn(
            userData,
            {
                onSuccess: (response) => {
                    toast.success('Employee Visa Added', TostifyConfig);
                    client.invalidateQueries(['get-employee-visa-by-uuid']);
                    client.invalidateQueries(['get-pending-action-by-uuid']);
                    props.setCardView({ visa: true });
                },
            }
        );
    }


    const updateEmployeeVisaHandeler = () => {

        let userData = {
            //attachment: employeeVisaData.attachment,
            issue_date: employeeVisaData.issue_date,
            expiration_date: employeeVisaData.expiration_date,
            purpose_of_travel: employeeVisaData.purpose_of_travel,
            visa_type: employeeVisaData.visa_type,
            country_of_issue: employeeVisaData.country_of_issue,
            remarks: employeeVisaData.remarks,
            visa_number: employeeVisaData.visa_number,
            issuing_authority: employeeVisaData.issuing_authority,
            current_active: employeeVisaData.current_active,
            employee_employee_uuid: employee_uuid
        }

        if (employeeVisaData?.attachment?.name) {
            userData['attachment'] = employeeVisaData.attachment
        }

        patchEmployeeVisaFn(
            { id, userData },
            {
                onSuccess: (response) => {
                    //navigate('/list-visa')
                    toast.success('Employee Visa Updated', TostifyConfig);
                    client.invalidateQueries(['get-employee-visa-by-uuid']);
                    props.setCardView({ visa: true });
                    // history.push(`/employee/employee_visa_list/${employee_uuid}`);
                },
            }
        );
    }

    // const handleCheckboxChange = (event) => {
    //     setIsChecked(event.target.checked);
    // }
    const employeeSelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        const selectedOptionLabel = e.target.options[selectedIndex].label;
        setEmployeeVisaData({
            ...employeeVisaData,
            [selectName]: selectValue,
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (event.target.type === "file") {
            setEmployeeVisaData({
                ...employeeVisaData,
                [name]: event.target.files[0], // Capture the selected file object
            });
            setCurrentAttachmentData(event.target.files[0].name);
        } else if (event.target.type === "checkbox") {
            setEmployeeVisaData({
                ...employeeVisaData,
                [name]: event.target.checked,
            });
            setIsChecked(event.target.checked);
        }
        else {
            setEmployeeVisaData({
                ...employeeVisaData,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        if (id) {
            refetchEmployeeVisa();
        }

        if (getEmployeeVisaID) {
            setEmployeeVisaData(getEmployeeVisaID);
            setCurrentAttachmentData(extractFileName(getEmployeeVisaID.attachment));
            setIsChecked(getEmployeeVisaID.current_active)

        }
    }, [getEmployeeVisaID, refetchEmployeeVisa, id])

    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>Employee Visa</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingGetEmployeeVisaIDFn && id }
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Visa Number<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="visa_number"
                                                    onChange={handleInputChange}
                                                    value={employeeVisaData.visa_number}
                                                    required />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Visa Type<span className="required">*</span></label>
                                                <Form.Select
                                                    name="visa_type"
                                                    value={employeeVisaData.visa_type}
                                                    onChange={employeeSelectChangeHandler}
                                                    required
                                                    className='form-control'
                                                >
                                                    <option value="">Select Visa Type</option>
                                                    {getVisa?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.visa_type}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            {/* <Col className='col-md-6 mb-2'>
                                            <label className="text-label">Visa Type<span className="required">*</span></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Type Text"
                                                name="visa_type"
                                                onChange={handleInputChange}
                                                value={employeeVisaData.visa_type}
                                                required />
                                        </Col> */}
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Issue Date<span className="required">*</span></label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Type Text"
                                                    name="issue_date"
                                                    onChange={handleInputChange}
                                                    value={employeeVisaData.issue_date}
                                                    required />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Expiration Date<span className="required">*</span></label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Type Text"
                                                    name="expiration_date"
                                                    onChange={handleInputChange}
                                                    value={employeeVisaData.expiration_date}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Issuing Authority<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="issuing_authority"
                                                    onChange={handleInputChange}
                                                    value={employeeVisaData.issuing_authority}
                                                    required />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Purpose Of Travel</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="purpose_of_travel"
                                                    onChange={handleInputChange}
                                                    value={employeeVisaData.purpose_of_travel}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Country Of Issue<span className="required">*</span></label>
                                                <Form.Select
                                                    name="country_of_issue"
                                                    value={employeeVisaData.country_of_issue}
                                                    onChange={employeeSelectChangeHandler}
                                                    required
                                                    className='form-control'
                                                >
                                                    <option value="">Select Country Of Issue</option>
                                                    {getCountryofIssue?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.country_of_issue}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>



                                            <Col className='col-md-6 mb-2'>
                                                <div className="form-check custom-checkbox mt-5">
                                                    <input
                                                        type="checkbox"
                                                        name="current_active"
                                                        className="form-check-input"
                                                        id="current_active"
                                                        checked={isChecked}
                                                        onChange={handleInputChange}
                                                        value={employeeVisaData.current_active}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="current_active"
                                                    >
                                                        <b>Current Active</b>
                                                    </label>
                                                </div>

                                                {/* <input
                                                type='checkbox'
                                                name="current_active"
                                                checked={isChecked}
                                                onChange={handleInputChange}
                                                value={employeeVisaData.current_active}
                                            />&nbsp;
                                            <label>Current Active</label> */}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Remarks</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="remarks"
                                                    onChange={handleInputChange}
                                                    value={employeeVisaData.remarks}
                                                />
                                            </Col>

                                            {/* <Col className='col-md-6 mb-2'>
                                            <label>Country Of Issue<span className="required">*</span></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Type Text"
                                                name="country_of_issue"
                                                onChange={handleInputChange}
                                                value={employeeVisaData.country_of_issue}
                                                required />
                                        </Col> */}
                                            <Col className='col-md-6 mb-2'>
                                                <label>Attachment</label>
                                                <Form.Control
                                                    type="file"
                                                    placeholder="Type Text"
                                                    name="attachment"
                                                    onChange={handleInputChange}
                                                />
                                                <Row>
                                                    <label className='mt-3'>Selected File</label>
                                                    <label>{currentAttachmentData}</label>
                                                </Row>
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mt-2'>
                                        <Row>
                                            <Col>
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            props.setCardView({ visa: true });
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="primary btn-sm"
                                                        onClick={submitForm}
                                                        disabled={loadingGetEmployeeVisaIDFn && id|| loadingPostEmployeeVisaFn || loadingEmployeeVisaFn}
                                                    >
                                                        {(loadingPostEmployeeVisaFn || loadingEmployeeVisaFn) ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    className="me-2"
                                                                />
                                                                Loading...
                                                            </>
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default Employeevisa