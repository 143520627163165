import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

//API DELETE METHOD

const leavePolicyDeleteById = async (id) => {
  try {
      const response = await axios.delete(`/leave_management/api/v1/leave_policy/${id}/`);
      return response.data;
  } catch (error) {
      // Handle error or throw it again
      throw error;
  }
};

export const useLeavePolicyDeleteById = () => {
  return useMutation(["leavepolicy-delete"], leavePolicyDeleteById);
};

const leavePolicyUpdateById = async (data) => {
  let { id, policyData } = data;
  let config = {
    method: "patch",
    url: `leave_management/api/v1/leave_policy/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: policyData,
  };
  return await axios.request(config)
}

export const useLeavePolicyUpdateById = () => {
  return useMutation(["leavePolicy-update"], leavePolicyUpdateById)
}

const createNewLeavePolicy = async (leavePolicyData) => {
  let config = {
    method: "post",
    url: "leave_management/api/v1/leave_policy/",
    headers: {
      "Content-Type": "application/json",
    },
    data: leavePolicyData
  };

  return await axios.create().request(config)
}

export const useCreateLeavePolicy = () => {
  return useMutation(["LeavePolicy-register"], createNewLeavePolicy)
}

const getLeavePolicyById = async (query) => {
  let [, id] = query.queryKey;
  let config = {
    method: "get",
    url: `leave_management/api/v1/leave_policy/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config)
  return response?.data
}

export const useGetLeavePolicyById = (id) => {
  return useQuery(["get-LeavePolicy-by-id", id], getLeavePolicyById, { enabled: id ? true : false })
}