import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const getSearchResults = async (query) => {
    let [, data] = query.queryKey;
    let config = {
        method: "get",
        url: `/dashboard/global-search/?search_text=${data}`,
        // url: `/timesheet/api/v1/timesheet_summary_report/?resource__employee_uuid=${employee_uuid}&projects__project_name=${project_name}&date=${date}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetSearchResults = (data) => {
    return useQuery(["get-search-results", data], getSearchResults);
};