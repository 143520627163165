import React, { Fragment, } from 'react'
// import ComponentsClientSidebar from '../shared/ComponentsClientSidebar'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { useRef } from 'react'
// import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation, } from 'react-router-dom/dist'
import { useGetProjectList } from '../../actions/project-action';
import { useGetEmployeeList } from '../../actions/employee-actions';
import { useGetCompanyList } from '../../actions/company-action';
import { useGetClientList } from '../../actions/client-actions';
import { useGetTeamList } from '../../actions/team-action';
import { useAnnouncementUpdate, useCreateAnnouncement, useGetAnnouncementTopicsList, useGetAnnouncemntDetail } from '../../actions/announcement-action';
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { TostifyConfig } from '../../utils/Constance';
import { extractFileName } from '../../utils/ComponentUtilse';
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';

function AddAnnouncements() {

    const navigate = useNavigate();

    const FormRef = useRef(null);

    const location = useLocation()
    let id = location?.state?.rowId

    const [selectedValue, setSelectedValue] = useState('');

    const [currentAttachmentData, setCurrentAttachmentData] = useState(null);

    const [announcement, setAnnouncement] = useState({})

    const { data: announcementDetail, isLoading: loadingAnnouncementFn, refetch: refetchannouncementDetail } = useGetAnnouncemntDetail(id)
    const { data: getAllProject } = useGetProjectList()
    const { data: getAllEmployee } = useGetEmployeeList()
    const { data: getAllCompany } = useGetCompanyList()
    const { data: getAllClient } = useGetClientList()
    const { data: getAllTopics } = useGetAnnouncementTopicsList()
    const { mutate: postNewAnnouncement, isLoading: loadingpostNewAnnouncementFn, } = useCreateAnnouncement()
    const { mutate: patchAnnouncement, isLoading: loadingpatchAnnouncementInfoFn } = useAnnouncementUpdate()

    const handleSubmit = () => {
        let FormValidity = FormRef.current.reportValidity();

        if (FormValidity === true) {
            if (id) {
                updateAnnouncementHandeler();
            }
            else {
                postAnnouncementHandeler();
            }
        }
    }
    const postAnnouncementHandeler = () => {

        let announcementData = {
            title: announcement.title,
            message: announcement.message,
            teams: announcement.teams,
            project: announcement.project,
            employee: announcement.employee,
            company: announcement.company,
            client: announcement.client,
            author: announcement.author,
            topic: announcement.topic,
            publish_date: announcement.publish_date,
            expiry_date: announcement.expiry_date,
            attachment: announcement.attachment,
            announcement_type: selectedValue
        };

        postNewAnnouncement(
            announcementData,
            {
                onSuccess: (response) => {
                    navigate('/list-announcements/')
                    toast.success('Announcement Added', TostifyConfig);
                },
            }
        );
    }

    const updateAnnouncementHandeler = () => {

        let announcementData = {
            title: announcement.title,
            message: announcement.message,
            teams: announcement.teams,
            project: announcement.project,
            employee: announcement.employee,
            company: announcement.company,
            client: announcement.client,
            author: announcement.author,
            topic: announcement.topic,
            publish_date: announcement.publish_date,
            expiry_date: announcement.expiry_date,
            //attachment: announcement.attachment
        };
        if (announcement?.attachment?.name) {
            announcementData['attachment'] = announcement.attachment
        }

        patchAnnouncement(
            { id, announcementData },
            {
                onSuccess: (response) => {
                    navigate('/list-announcements/')
                    toast.success('Announcement Updated', TostifyConfig);
                },
            }
        );
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (event.target.type === "file") {

            setAnnouncement({
                ...announcement,
                [name]: event.target.files[0], // Capture the selected file object
            });
            setCurrentAttachmentData(event.target.files[0].name);
        } else {
            setAnnouncement({
                ...announcement,
                [name]: value,
            });
        }
    };
    const SelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        const selectedOptionLabel = e.target.options[selectedIndex].label;
        setAnnouncement({
            ...announcement,
            [selectName]: selectValue,
        });
    };

    useEffect(() => {
        if (id) {
            refetchannouncementDetail();
        }
        if (announcementDetail) {
            setAnnouncement(announcementDetail);
            setCurrentAttachmentData(extractFileName(announcementDetail.attachment));
        }
    }, [announcementDetail, refetchannouncementDetail, id])

    const today = new Date();

    // Convert the date to 'YYYY-MM-DD' format
    const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

    return (
        <Fragment>
            <PageTitle activeMenu="Settings" motherMenu="Home" />
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>{id ? "Edit Announcement" : "New Announcement"}</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingAnnouncementFn && id}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Title<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="title"
                                                    value={announcement?.title}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Topic<span className="required">*</span></label>
                                                <Form.Select
                                                    name='topic'
                                                    className="form-control"
                                                    value={announcement?.topic}
                                                    onChange={SelectChangeHandler}
                                                    required
                                                >
                                                    <option value="">Select Title</option>
                                                    {getAllTopics?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.topic}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row>
                                            <Col className='col-md-6 my-2'>
                                                <label>Author<span className="required">*</span></label>
                                                <Form.Select
                                                    name='author'
                                                    className="form-control"
                                                    value={announcement?.author}
                                                    onChange={SelectChangeHandler}
                                                    required
                                                >
                                                    <option value="">Select Author</option>
                                                    {getAllEmployee?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.first_name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Attachment</label>
                                                <Form.Control
                                                    type="file"
                                                    placeholder="Type Text"
                                                    name="attachment"
                                                    onChange={handleInputChange}
                                                />
                                                <Row className='col-md-6 mt-3'>
                                                    <label>Selected File</label>
                                                    <label>{currentAttachmentData}</label>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group>
                                        <Row>
                                            <Col className='col-md-12 my-2'>
                                                <label className="text-label">Message<span className="required">*</span></label>
                                                <Form.Control
                                                    Style="height:80px"
                                                    as="textarea"
                                                    rows={5}
                                                    placeholder="Type Text"
                                                    name="message"
                                                    value={announcement?.message}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <br />
                                    <hr />
                                    <br />
                                    <Form.Group>
                                        <Row>
                                            <Col className='col-md-6'>
                                                <label>Publish To<span className="required">*</span></label>
                                                <Form.Select
                                                    className='form-control'
                                                    value={selectedValue}
                                                    onChange={(e) => setSelectedValue(e.target.value)}
                                                    required
                                                >
                                                    <option value="">Select</option>
                                                    <option value="company">Company</option>
                                                    {/* <option value="project">Project</option> */}
                                                    <option value="employee">Employee</option>
                                                    {/* <option value="client">Client</option> */}
                                                </Form.Select>
                                            </Col>
                                            <Col className='col-md-6'>
                                                {selectedValue === 'project' && (
                                                    <>
                                                        <label>Project<span className="required">*</span></label>
                                                        <Form.Select
                                                            name='project'
                                                            className="form-control"
                                                            value={announcement?.project}
                                                            onChange={SelectChangeHandler}
                                                            required
                                                        >
                                                            <option value="">Select Project</option>
                                                            {getAllProject?.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.project_name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    </>
                                                )}

                                                {selectedValue === 'employee' && (
                                                    <>
                                                        <label>Employee<span className="required">*</span></label>
                                                        <Form.Select
                                                            name='employee'
                                                            className="form-control"
                                                            value={announcement?.employee}
                                                            onChange={SelectChangeHandler}
                                                            required
                                                        >
                                                            <option value="">Select Employee</option>
                                                            {getAllEmployee?.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.first_name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    </>
                                                )}

                                                {selectedValue === 'company' && (
                                                    <>
                                                        <label>Company</label>
                                                        <Form.Select
                                                            name='company'
                                                            className="form-control"
                                                            value={announcement?.company}
                                                            onChange={SelectChangeHandler}
                                                            required
                                                        >
                                                            <option value="">Select Company</option>
                                                            {getAllCompany?.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.company_name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    </>
                                                )}

                                                {selectedValue === 'client' && (
                                                    <>
                                                        <label>Client</label>
                                                        <Form.Select
                                                            name='client'
                                                            className="form-control"
                                                            value={announcement?.client}
                                                            onChange={SelectChangeHandler}
                                                            required
                                                        >
                                                            <option value="">Select Client</option>
                                                            {getAllClient?.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.client_name}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                    </>
                                                )}

                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mt-3 mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Publish Date<span className="required">*</span></label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Type Text"
                                                    name="publish_date"
                                                    value={announcement?.publish_date}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Expiry Date<span className="required">*</span></label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Type Text"
                                                    name="expiry_date"
                                                    value={announcement?.expiry_date}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <br />
                                    <br />

                                    <Form.Group className="mt-2">
                                        <Row>
                                            <Col className="col-md-12">
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            navigate("/list-announcements/");
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="primary btn-sm"
                                                        onClick={handleSubmit}
                                                        disabled={loadingAnnouncementFn && id || loadingpostNewAnnouncementFn || loadingpatchAnnouncementInfoFn}
                                                    >{(loadingpostNewAnnouncementFn || loadingpatchAnnouncementInfoFn) ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" className="me-2" />
                                                            Loading...
                                                        </>
                                                    ) : (
                                                        'Submit'
                                                    )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default AddAnnouncements