import React, {
  Fragment,
  useState,
  useEffect,
  useContext
} from "react";
import PageTitle from "../../layouts/PageTitle";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { RiseLoader } from "react-spinners";
import ProfileImageView from "../../employee/employee_view/ProfileViewComponent/ProfileImage";
import SubMenu from "../../employee/employee_view/ProfileViewComponent/SubMenu";
import { ServiceContext } from "../../../context/ServiceContext";
import { useGetEmployeeInfoDetailByUUID } from "../../../actions/employee-actions";
import { IsValidPermissions, isValidPermission } from "../../../services/AuthService";
import { useGetAdvertisementByJobID, useGetApplicationByID, useGetInterviewStatus, useGetJobInfoDetailByID } from "../../../actions/recruitment-actions";
import JobSubMenu from "./JobSubMenu";
import Profile from "../../employee/employee_view/ProfileViewComponent/Profile";
import JobVacancyDetail from "./JobVacancyDetail";
import AdvertisementList from "./Advertisement";
import JobApplication from "./JobApplication";
import JobVacancyUpdate from "./JobVacancyUpdate";
import AdvertisementDetail from "./AdvertisementDetail";
import JobApplicationDetail from "./JobApplicationDetail";
import SummaryBox from "./JobVacancySummaryHeader";

const JobVacancyView = () => {

  const { getPermissionList } = useContext(ServiceContext);

  const { id } = useParams();

  const [jobId] = useState(id);
  const [initialData, setInitialData] = useState(null);
  const [pendingActionData, setPendingActionData] = useState({});
  const [cardView, setCardView] = useState({
    summary: true,
  })
  const [jobData, setJobData] = useState({});

  const [advertisementList, setAdvertisementList] = useState([]);
  const [applicationList, setApplicationList] = useState([]);


  const {
    data: getJobDetailByID,
    isLoading: LoadingJobDetailByID,
  } = useGetJobInfoDetailByID(jobId);

  const {
    data: getInterviewStatusData,
    isLoading: loadingGetInterviewStatusFn,
    refetch: refetchInterviewStatus,
  } = useGetInterviewStatus();

  const { data: getAdvertisementFn, isLoading: loadingGetAdvertisementFn } =
    useGetAdvertisementByJobID(jobData.job_id);

  const { data: getApplicationFn, isLoading: loadingGetApplicationFn, refetch: refetchGetApplicationFn } =
    useGetApplicationByID(jobData.job_id);


  useEffect(() => {
    if (getJobDetailByID) {
      setJobData(getJobDetailByID);
    }
  }, [getJobDetailByID]);

  useEffect(() => {
    if (getAdvertisementFn) {
      setAdvertisementList(getAdvertisementFn);
    }
  }, [getAdvertisementFn]);

  useEffect(() => {
    if (getApplicationFn) {
      setApplicationList(getApplicationFn);
    }
  }, [getApplicationFn]);

  return (
    <Fragment>
      <PageTitle activeMenu="Job Info" motherMenu="Job Vacancy" />
      <div className="row" >

        {LoadingJobDetailByID &&
          <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "calc(100vh - 200px)" }}>
            <RiseLoader color="#40189D" />
          </div>
        }

        {/* Permission valid => employee.view_employee */}
        {!LoadingJobDetailByID && IsValidPermissions('employee.view_employee') &&
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body" Style="background-color:#F2F2F2;">

                <SummaryBox
                  LoadingJobDetailByID={LoadingJobDetailByID}
                  jobDetails={jobData}
                  pendingActionData={pendingActionData}
                  applicationList={applicationList}
                  skills={jobData?.primary_skills ? jobData?.primary_skills.split(',') : []}
                />

                <div className="email-left-box px-0 mb-3">
                  <JobSubMenu cardView={cardView} setCardView={setCardView} />
                </div>
                <div
                  className="email-right-box ms-0 ms-sm-4 ms-sm-0"
                  Style="background-color:#F2F2F2;"
                >
                  {isValidPermission('employee.view_employee', getPermissionList) && (cardView?.summary || cardView?.jobVacancy) && (
                    <JobVacancyDetail
                      setCardView={setCardView}
                      jobData={jobData}
                      getPermissionList={getPermissionList}
                      skills={jobData?.primary_skills ? jobData?.primary_skills.split(',') : []}
                      secondarySkills={jobData?.secondary_skills ? jobData?.secondary_skills.split(',') : []}
                    />
                  )}
                  {isValidPermission('employee.change_employee', getPermissionList) && cardView?.jobVacancyEdit && (
                    <JobVacancyUpdate
                      setCardView={setCardView}
                      jobId={jobId}
                    />
                  )}
                  {isValidPermission('employee.view_employeebankdetail', getPermissionList) && (cardView?.summary || cardView?.advertisement) && (
                    <AdvertisementList
                      setCardView={setCardView}
                      advertisementList={advertisementList}
                      getPermissionList={getPermissionList}
                      isLoading={loadingGetAdvertisementFn}
                    />
                  )}
                  {isValidPermission('employee.view_employeebankdetail', getPermissionList) && cardView?.advertisementEdit && (
                    <AdvertisementDetail
                      setCardView={setCardView}
                      jobId={jobId}
                      advertisementId={cardView?.advertisementId}
                      jobIdName={jobData?.job_id}
                    />
                  )}

                  {isValidPermission('employee.view_employeebankdetail', getPermissionList) && (cardView?.summary || cardView?.application) && (
                    <JobApplication
                      setCardView={setCardView}
                      applicationList={applicationList}
                      getPermissionList={getPermissionList}
                      isLoading={loadingGetApplicationFn}
                      interviewStatusData={getInterviewStatusData}
                      refetchGetApplicationFn={refetchGetApplicationFn}
                    />
                  )}

                  {isValidPermission('employee.view_employeebankdetail', getPermissionList) && cardView?.applicationEdit && (
                    <JobApplicationDetail
                      setCardView={setCardView}
                      jobId={jobId}
                      applicationId={cardView?.applicationId}
                      jobIdName={jobData?.job_id}
                      interviewerName={jobData?.interviewer_name}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </Fragment>

  );
};
export default JobVacancyView;
