import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const postLeave = async (leave) => {
  let config = {
    method: "post",
    url: "leave_management/api/v1/leave_employee/",
    headers: {
      "Content-Type": "application/json",
    },
    data: leave
  };

  return await axios.request(config)
}
export const usePostLeave = () => {
  return useMutation(["post-leave"], postLeave);
};

const getLeaves = async (query) => {
  let [, employee_uuid] = query.queryKey;
  let config = {
    method: "get",
    url: `/leave_management/api/v1/leave_employee/?employee__employee_uuid=${employee_uuid}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
}
export const useGetLeavesByUUID = (employee_uuid) => {
  return useQuery(["get-employee-leaves", employee_uuid], getLeaves);
}

const getAllLeaves = async (query) => {
  let config = {
    method: "get",
    url: `/leave_management/api/v1/leave_employee/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
}
export const useGetAllLeaves = () => {
  return useQuery(["get-all-leaves"], getAllLeaves);
}

const getLeaveBalance = async (query) => {
  let [, employee_uuid] = query.queryKey;

  let config = {
    method: "get",
    url: `/leave_management/api/v1/leave_balance/?employee__employee_uuid=${employee_uuid}`,
    headers: {
      "Content-Type": "application/json",
    },
  }

  const response = await axios.request(config);
  return response?.data
}
export const useGetLeaveBalance = (employee_uuid) => {
  return useQuery(["get-leave-balance", employee_uuid], getLeaveBalance, { enabled: employee_uuid ? true : false });
}

const getLeaveSummary = async (query) => {
  let [, employee_uuid] = query.queryKey;

  let config = {
    method: "get",
    url: `/leave_management/leave_balance_summary/${employee_uuid}`,
    headers: {
      "Content-Type": "application/json",
    },
  }

  const response = await axios.request(config);
  return response?.data
}
export const useGetLeaveSummary = (employee_uuid) => {
  return useQuery(["get-leave-summary", employee_uuid], getLeaveSummary, { enabled: employee_uuid ? true : false });
}

// API PATCH Method
const leaveUpdateFn = async (employeeData) => {

  let { id, userData } = employeeData;
  let config = {
      method: "patch",
      url: `/leave_management/api/v1/leave_employee/${id}/`,
      headers: {
          "Content-Type": "application/json",
      },
      data: userData,
  };

  return await axios.request(config);
};
export const useLeaveUpdateFn = () => {
  return useMutation(["leave-update"], leaveUpdateFn);
};

// API GET Method 

const getLeaveByIDFn = async (query) => {
  let [, id] = query.queryKey;
  let config = {
      method: "get",
      url: `/leave_management/api/v1/leave_employee/${id}/`,
      headers: {
          "Content-Type": "application/json",
      },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetLeaveByID = (id) => {
  return useQuery(["get-leave-by-id", id], getLeaveByIDFn, { enabled: false });
};