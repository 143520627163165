import React, { Fragment, useState, useEffect, useContext } from "react";
import { Card, Table, Col, Button, Spinner, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import './icon.css'
import { useGetExpirydata } from "../../actions/expiry-dashboard-actions";
import { dateHandler } from "../../utils/ComponentUtilse";


function ExpiryDashBoard() {

    // const [dashBoardExpiryData, setDashBoardExpiryData] = useState({});

    const [passportExpiryData, setPassportExpiryData] = useState([]);
    const [visaExpiryData, setVisaExpiryData] = useState([]);
    const [cosExpiryData, setCOSExpiryData] = useState([]);
    const [rtwExpiryData, setRTWExpiryData] = useState([]);
    const [days, setDays] = useState('730');
    const [selectedOptionName, setSelectedOptionName] = useState('Less Than 2 Years');

    const {
        data: getExipryData,
        isLoading: LoadingExipryData,
    } = useGetExpirydata(days);

    useEffect(() => {
        if (getExipryData) {
            setPassportExpiryData(getExipryData?.passport_expiry);
        }
    }, [getExipryData]);

    useEffect(() => {
        if (getExipryData) {
            setVisaExpiryData(getExipryData?.visa_expiry);
        }
    }, [getExipryData]);

    useEffect(() => {
        if (getExipryData) {
            setCOSExpiryData(getExipryData?.cos_expiry);
        }
    }, [getExipryData]);

    useEffect(() => {
        if (getExipryData) {
            setRTWExpiryData(getExipryData?.rtw_expiry);
        }
    }, [getExipryData]);

    const handleSelectChange = (e) => {
        const selectedDays = e.target.value;
        setDays(selectedDays);

        // Update the selected option name
        switch (selectedDays) {
            case '730':
                setSelectedOptionName('Less Than 2 Years');
                break;
            case '365':
                setSelectedOptionName('Less Than 1 Year');
                break;
            case '90':
                setSelectedOptionName('Less Than 90 days');
                break;
            case '30':
                setSelectedOptionName('Less Than 30 days');
                break;
            default:
                setSelectedOptionName('');
        }
    };


    return (
        <Fragment>
            <div className="row">
                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                    <div><h6 className="text-black fs-16 font-w600 mb-3">Filter By:</h6> {selectedOptionName && <p style={{ Left: "0px " }} >{selectedOptionName}</p>}</div>
                    <div className="input-group search-area d-lg-inline-flex d-none">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <select
                                className="form-control input-rounded mb-0 me-4 mt-3 mt-sm-0 mb-2"
                                id="inlineFormCustomSelect"
                                Style="height:43px;"
                                value={days}
                                onChange={handleSelectChange}
                            >
                                <option value="option" disabled>
                                    Choose...
                                </option>
                                <option value="730">
                                    Less Than 2 Years
                                </option>
                                <option value="365">
                                    Less Than 1 Years
                                </option>
                                <option value="90">
                                    Less Than 90 days
                                </option>
                                <option value="30">
                                    Less Than 30 days
                                </option>

                            </select>
                        </form>

                        <div className="input-group-append">
                            <span className="input-group-text">
                                {/* <i className="fa fa-filter" Style="height:26px;"></i> */}
                                <i className="flaticon-381-search-2"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Employees Passport Expiry</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive className="verticle-middle">
                                <thead>
                                    <tr>
                                        <th scope="col">Employee</th>
                                        <th scope="col">#Number</th>
                                        <th scope="col">Nationality</th>
                                        <th scope="col">Passport Number</th>
                                        <th scope="col">Document type</th>
                                        <th scope="col">Expiry Date</th>
                                        <th scope="col">Expiry Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {LoadingExipryData ?
                                        <tr>
                                            <td style={{ textAlign: 'center' }} colSpan={7}>
                                                <Spinner animation="border" size="lg" className="me-2" />
                                            </td>
                                        </tr>
                                        : passportExpiryData.length > 0
                                            ? passportExpiryData?.map((item, i) => {

                                                return (
                                                    <tr>
                                                        <td>{item?.employee_name}</td>
                                                        <td>{item?.employee_number}</td>
                                                        <td>{item?.nationality}</td>
                                                        <td>{item?.document_number}</td>
                                                        <td>{item?.document_type}</td>
                                                        <td>{dateHandler(item?.expiry_date)}</td>
                                                        <td>{item?.days_to_expiry} days</td>
                                                        {/* <td><Button variant="warning" className="light sharp i-false" onClick={() => { navigate(`/employee-profile-view/${item?.employee_uuid}`, { state: { 'employee_uuid': item?.employee_uuid } }) }}>...</Button></td> */}
                                                    </tr>
                                                );
                                            })
                                            : <tr>
                                                <td style={{ textAlign: 'center' }} colSpan={7}>No records found</td>
                                            </tr>}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Employees Visa Expiry</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive className="verticle-middle">
                                <thead>
                                    <tr>
                                        <th scope="col">Employee</th>
                                        <th scope="col">#Number</th>
                                        <th scope="col">Visa Number</th>
                                        <th scope="col">Document Type</th>
                                        <th scope="col">Expiry Date</th>
                                        <th scope="col">Expiry Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {LoadingExipryData ?
                                        <tr>
                                            <td style={{ textAlign: 'center' }} colSpan={7}>
                                                <Spinner animation="border" size="lg" className="me-2" />
                                            </td>
                                        </tr>
                                        : visaExpiryData.length > 0
                                            ? visaExpiryData?.map((item, i) => {

                                                return (
                                                    <tr>
                                                        <td>{item?.employee_name}</td>
                                                        <td>{item?.employee_number}</td>
                                                        <td>{item?.document_number}</td>
                                                        <td>{item?.document_type}</td>
                                                        <td>{dateHandler(item?.expiry_date)}</td>
                                                        <td>{item?.days_to_expiry} days</td>
                                                        {/* <td><Button variant="warning" className="light sharp i-false" onClick={() => { navigate(`/employee-profile-view/${item?.employee_uuid}`, { state: { 'employee_uuid': item?.employee_uuid } }) }}>...</Button></td> */}
                                                    </tr>
                                                );
                                            })
                                            : <tr>
                                                <td style={{ textAlign: 'center' }} colSpan={7}>No records found</td>
                                            </tr>}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Employees COS Expiry</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive className="verticle-middle">
                                <thead>
                                    <tr>
                                        <th scope="col">Employee</th>
                                        <th scope="col">#Number</th>
                                        <th scope="col">Certificate No</th>
                                        <th scope="col">Document Type</th>
                                        <th scope="col">Expiry Date</th>
                                        <th scope="col">Expiry Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {LoadingExipryData ?
                                        <tr>
                                            <td style={{ textAlign: 'center' }} colSpan={7}>
                                                <Spinner animation="border" size="lg" className="me-2" />
                                            </td>
                                        </tr>
                                        : cosExpiryData.length > 0
                                            ? cosExpiryData?.map((item, i) => {

                                                return (
                                                    <tr>
                                                        <td>{item?.employee_name}</td>
                                                        <td>{item?.employee_number}</td>
                                                        <td>{item?.document_number}</td>
                                                        <td>{item?.document_type}</td>
                                                        <td>{dateHandler(item?.expiry_date)}</td>
                                                        <td>{item?.days_to_expiry} days</td>
                                                        {/* <td><Button variant="warning" className="light sharp i-false" onClick={() => { navigate(`/employee-profile-view/${item?.employee_uuid}`, { state: { 'employee_uuid': item?.employee_uuid } }) }}>...</Button></td> */}
                                                    </tr>
                                                );
                                            })
                                            : <tr>
                                                <td style={{ textAlign: 'center' }} colSpan={7}>No records found</td>
                                            </tr>}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Employees RTW Expiry</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive className="verticle-middle">
                                <thead>
                                    <tr>
                                        <th scope="col">Employee</th>
                                        <th scope="col">#Number</th>
                                        <th scope="col">Document Number</th>
                                        <th scope="col">Document Type</th>
                                        <th scope="col">Expiry Date</th>
                                        <th scope="col">Expiry Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {LoadingExipryData ?
                                        <tr>
                                            <td style={{ textAlign: 'center' }} colSpan={6}>
                                                <Spinner animation="border" size="lg" className="me-2" />
                                            </td>
                                        </tr>
                                        : rtwExpiryData.length > 0
                                            ? rtwExpiryData?.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <td>{item?.employee_name}</td>
                                                        <td>{item?.employee_number}</td>
                                                        <td>{item?.document_number}</td>
                                                        <td>{item?.document_type}</td>
                                                        <td>{dateHandler(item?.expiry_date)}</td>
                                                        <td>{item?.days_to_expiry} days</td>
                                                        {/* <td><Button variant="warning" className="light sharp i-false" onClick={() => { navigate(`/employee-profile-view/${item?.employee_uuid}`, { state: { 'employee_uuid': item?.employee_uuid } }) }}>...</Button></td> */}
                                                    </tr>
                                                );
                                            })
                                            : <tr>
                                                <td style={{ textAlign: 'center' }} colSpan={6}>No records found</td>
                                            </tr>}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </Fragment>
    );
};

export default ExpiryDashBoard;