import { useState, useContext } from "react";
import { Button, Table, Alert } from "react-bootstrap";
import { IsValidPermissions } from "../../../../services/AuthService";
import { Link } from "react-router-dom";
import { Alertmsg } from "../../../../utils/CommonUtils";
import { ServiceContext } from "../../../../context/ServiceContext";

function RtwEmployeeView(props) {

    const { canEditEmployee } = useContext(ServiceContext);

    const [showAlert, setShowAlert] = useState(true);

    const closeAlert = () => {
        setShowAlert(false);
    };


    return (
        <div className="card" id="rtw-employee">
            <>
                <div className="card-header d-flex justify-content-between">
                    <h4 className="card-title">
                        <i className="fas fa-address-card font-18 align-middle me-2 text-primary"></i>
                        &nbsp;Right To Work
                    </h4>
                    {canEditEmployee && IsValidPermissions('employee.can_add_employee_rtw') &&
                        !props?.employeeRtwList?.find(employee => employee?.current_active === true) ? (
                        <Button
                            className="me-2 btn-sm"
                            variant="primary"
                            onClick={() => {
                                props.setCardView({ rtemployeertwwEdit: true, rtwId: props?.employeeRtwList?.[0]?.id });
                            }}
                        >
                            <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Add
                        </Button>
                    ) : null
                    }
                </div>

                <div className="card-body">
                    {showAlert && !props?.employeeRtwList?.find(employee => employee?.current_active === true) ? (
                        <Alertmsg closeAlert={closeAlert} />
                    ) : null
                    }

                    <div className="form-validation">
                        <div className="row">
                            <div className="col-xl-6">
                            <div className="form-group mb-3 row">
                                    <>
                                        <label className="col-lg-6 col-form-label">
                                            Share Code:
                                        </label>
                                        <label className="col-lg-6 col-form-label">
                                            {props.employeeRtwList.find(employee => employee?.current_active === true)?.emp_share_code}
                                        </label>
                                        <label className="col-lg-6 col-form-label">
                                            Share Code Expiry:
                                        </label>
                                        <label className="col-lg-6 col-form-label">
                                            {props.employeeRtwList.find(employee => employee?.current_active === true)?.emp_share_code_expiry}
                                        </label>
                                        <label className="col-lg-6 col-form-label">
                                            RTW Reference:
                                        </label>
                                        <label className="col-lg-6 col-form-label">
                                            {props.employeeRtwList.find(employee => employee?.current_active === true)?.rtw_reference}
                                        </label>
                                        <label className="col-lg-6 col-form-label">
                                            RTW Status:
                                        </label>
                                        <label className="col-lg-6 col-form-label">
                                            {props.employeeRtwList.find(employee => employee?.current_active === true)?.rtw_status_text}
                                        </label>
                                    </>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>

    )
}
export default RtwEmployeeView;