import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import { Card, Table, Col, Button, Row, Form, Modal, Spinner } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { useEmployeeWorkLocation, useEmployeeDropdown } from "../../actions/employee-actions";
import { useGetProjectByUUId } from "../../actions/project-action";
import { useGetEmployeeInfoDetailByUUID } from "../../actions/employee-actions";
import { usePostTimesheet, useGetTimesheetByUUID } from "../../actions/timesheet-actions";
import { TostifyConfig } from "../../utils/Constance";
import { toast } from "react-toastify";
import { IsValidPermissions } from "../../services/AuthService";
import { ServiceContext } from "../../context/ServiceContext";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { format, parseISO } from 'date-fns';




function TimeSheetView() {

    const { isAdminUser, getEmployeeUUID } = useContext(ServiceContext);

    const FormRef = useRef(null);

    const [showModal, setShowModal] = useState(false);

    const [getProjectName, setProjectName] = useState("");

    const [getEmployeeUUid, setEmployeeUUid] = useState("");


    const [employeeProfileData, setEmployeeProfileData] = useState({});

    const [getProjectTimesheetData, setProjectTimesheetData] = useState([]);

    const [modalData, setModalData] = useState({
        date: "",
        start_time: moment('09:00:00', 'HH:mm:ss'),
        end_time: null,
        total_hours: 0,
        projects: null,
        resource: null,
        reporter: null,
    });


    const { data: getEmployeeInfo, isLoading: loadingEmployeeInfo } =
        useEmployeeDropdown();

    const { data: getEmployeeWorkLocation } = useEmployeeWorkLocation();

    const {
        data: getEmployeeProjectsByUUID,
        isFetching: LoadingEmployeeProjectsByUUID,
    } = useGetProjectByUUId(getEmployeeUUid);

    const {
        data: getEmployeeDetailByUUID,
        isLoading: LoadingEmployeeDetailByUUID,
    } = useGetEmployeeInfoDetailByUUID(getEmployeeUUid);

    const {
        data: getEmployeeTimesheetByUUID,
        refetch: refetchEmployeeTimesheet,
        isFetching: LoadingEmployeeTimeSheet,
    } = useGetTimesheetByUUID(getEmployeeUUid, getProjectName);

    const { mutate: postTimesheetFn, isLoading: postTimesheetLoading } =
        usePostTimesheet();

    let handleDateClick = (arg) => {
        // bind with an arrow function
        let FormValidity = FormRef.current.reportValidity();
        let filterDate = getProjectTimesheetData?.filter((item) => {
            if (item?.date === arg.dateStr) {
                return item;
            } else {
                return null;
            }
            //return item
        });

        if (FormValidity === true && filterDate?.length <= 0) {
            setModalData((prevState) => ({
                ...prevState,
                date: arg.dateStr,
                total_hours: 0,
            }));
            setShowModal(true);
        }
    };

    const formatTime = (timeString) => {
        if (timeString) {
            const selectedTime = new Date(timeString);
            const hours = selectedTime.getHours();
            const minutes = selectedTime.getMinutes();
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        }
        return null;
    };

    let handelModalButtonClick = () => {
        const formattedStartTime = formatTime(modalData.start_time);
        const formattedEndTime = formatTime(modalData.end_time);
        if (
            modalData.total_hours > 0 &&
            modalData.start_time &&
            modalData.end_time &&
            modalData.date &&
            modalData.projects &&
            // modalData.reporter &&
            modalData.resource &&
            modalData.location
        ) {
            postTimesheetFn({
                ...modalData,
                start_time: formattedStartTime,
                end_time: formattedEndTime,
            }, {
                onSuccess: (e) => {
                    toast.success('TimeSheet Updated!!', TostifyConfig);
                    setShowModal(false);
                    refetchEmployeeTimesheet();
                },
            });
        }
    };

    const employeeSelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        setEmployeeProfileData({
            ...employeeProfileData,
            [selectName]: selectValue,
        });
        setProjectName(null);
    };

    let projectSelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        //const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        const selectedLabel = e.target.options[selectedIndex].label;

        setModalData((prevState) => ({
            ...prevState,
            projects: selectValue,
        }));

        setProjectName(selectedLabel);
    };

    const handleEventClick = (info) => {
        // `info` contains information about the clicked event
        console.log("Event clicked:", info.event);
        console.log(info);
        // Perform any additional actions you want
    };

    const eventContent = (arg) => {
        return (
            <div>
                <strong>Project Name:</strong>{" "}
                <span dangerouslySetInnerHTML={{ __html: arg.event.title }} />
                <br />
                <strong>Total Hours:</strong>{" "}
                <span
                    dangerouslySetInnerHTML={{
                        __html: arg.event._def.extendedProps.total_hours,
                    }}
                />
            </div>
        );
    };

    useEffect(() => {
        let eventTimestamp = getEmployeeTimesheetByUUID?.map((e) => {
            return {
                title: e.project_name,
                date: e.date,
                timeText: e.date,
                total_hours: e.total_hours,
                backgroundColor: "rgb(72,61,139)",
            };
        });
        setProjectTimesheetData(eventTimestamp);
    }, [getEmployeeTimesheetByUUID]);

    useEffect(() => {
        if (getEmployeeUUid && getProjectName) {
            refetchEmployeeTimesheet();
        } else {
            setProjectTimesheetData([]);
        }
    }, [
        getEmployeeUUid,
        getProjectName,
        refetchEmployeeTimesheet,
        setProjectTimesheetData,
    ]);

    useEffect(() => {
        if (getEmployeeDetailByUUID) {
            setModalData((prevStat) => ({
                ...prevStat,
                reporter: getEmployeeDetailByUUID?.direct_manager,
                resource: getEmployeeDetailByUUID?.id,
            }));
        }
    }, [getEmployeeDetailByUUID]);

    useEffect(() => {
        if (isAdminUser === false) {
            setEmployeeUUid(getEmployeeUUID);
        }
    }, [getEmployeeUUID, isAdminUser]);

    useEffect(() => {
        if (employeeProfileData?.employee) {
            setEmployeeUUid(employeeProfileData?.employee);
        }
    }, [employeeProfileData?.employee]);

    return (
        <Fragment>
            <div>
                {(IsValidPermissions('timesheet.add_timesheet') || IsValidPermissions('timesheet.can_add_employee_timesheet')) &&
                    <div className='col-xl-12 col-xxl-12'>
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title">
                                    <i class="fa fa-newspaper font-18 align-middle me-2 text-primary"></i>
                                    &nbsp; Add Timesheet
                                </h4>
                            </div>
                            <div className="card-body">
                                <form ref={FormRef}>
                                    <Form.Group>
                                        <Row>
                                            {(IsValidPermissions('timesheet.add_timesheet')) &&
                                                <Col className="col-lg">
                                                    <label className="mb-2">Employee</label>
                                                    <Form.Select
                                                        name="employee"
                                                        className="form-control"
                                                        value={employeeProfileData.employee}
                                                        onChange={employeeSelectChangeHandler}
                                                        disabled={loadingEmployeeInfo}
                                                        required
                                                    >
                                                        <option value="">Select Employee</option>
                                                        {getEmployeeInfo?.map((option) => (
                                                            <option key={option.id} value={option.employee_uuid
                                                            }>
                                                                {option.first_name} {option.last_name} {option.employee_number ? ` (${option.employee_number})` : ''}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            }
                                            <Col className="col-md-6">
                                                <label className="mb-2">Project</label>
                                                <Form.Select
                                                    className="form-control mb-0"
                                                    name="client_master"
                                                    required
                                                    disabled={LoadingEmployeeProjectsByUUID || LoadingEmployeeDetailByUUID}
                                                    onChange={projectSelectChangeHandler}
                                                >
                                                    <option value="">Select Project</option>
                                                    {LoadingEmployeeProjectsByUUID ? (
                                                        <option className="text-muted" value="" disabled selected>
                                                            Fetching...
                                                        </option>
                                                    ) : (
                                                        getEmployeeProjectsByUUID?.map((option) => (
                                                            <option key={option.project} value={option.project}>
                                                                {option.project_name}
                                                            </option>
                                                        ))
                                                    )}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col className="col-lg">
                                                <label>Reporter</label>
                                                <Form.Control
                                                    className="mb-0"
                                                    type="text"
                                                    value={getEmployeeDetailByUUID?.direct_manager_name}
                                                    disabled
                                                />
                                            </Col>
                                            <Col className="col-lg mg-t-10 mg-lg-t-0">
                                                <label>Assignee</label>
                                                <Form.Control
                                                    className="mb-0"
                                                    type="text"
                                                    value={`${getEmployeeDetailByUUID?.first_name || " "} ${getEmployeeDetailByUUID?.last_name || " "
                                                        }`}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                        {/* row */}
                                    </Form.Group>
                                </form>
                            </div>
                        </div>
                        <ModalComponent
                            showModal={showModal}
                            setShowModal={setShowModal}
                            modalData={modalData}
                            setModalData={setModalData}
                            getEmployeeWorkLocation={getEmployeeWorkLocation}
                            handelModalButtonClick={handelModalButtonClick}
                            postTimesheetLoading={postTimesheetLoading}
                        />
                        <div className="card">
                            <div className="card-body">
                                <LoadingOverlay
                                    active={LoadingEmployeeTimeSheet}
                                    spinner
                                    text='Fetching...'
                                    className="custom-overlay"
                                >
                                    <FullCalendar
                                        plugins={[dayGridPlugin, interactionPlugin]}
                                        //height={500}
                                        initialView="dayGridMonth"
                                        weekends={true}
                                        dateClick={handleDateClick}
                                        // events={[
                                        //     { title: 'event 1', date: '2023-08-01' },
                                        //     { title: 'event 2 gfhgfhjghgjhghgchcfcgfc jhbkjhvhvhgv hgvhjvh', date: '2023-08-02', display: 'block', backgroundColor: '#f2f2f2'},
                                        //     {
                                        //         title: '<span style="color: red;">Event with HTML</span>',
                                        //         start: '2023-08-25',
                                        //       },
                                        // ]}
                                        events={getProjectTimesheetData}
                                        eventContent={eventContent}
                                        eventClick={handleEventClick}
                                    />
                                </LoadingOverlay>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    )
}

function ModalComponent(props) {
    const ModalFormRef = useRef(null);
    const totalHoursInputRef = useRef(null);

    const [modalValidation, setModalValidation] = useState("");

    let closeModal = () => {
        props.setShowModal(false);
    };

    const handleButtonClick = (e) => {
        let FormValidity = ModalFormRef.current.reportValidity();
        if (FormValidity === true) {
            props.handelModalButtonClick();
        }
    }


    const handleTimeChange = (key, value) => {
        props.setModalData({
            ...props.modalData,
            [key]: value,
            total_hours: calculateTotalHours(props.modalData.start_time, props.modalData.end_time, key, value),
        });
    };

    const startTimeDefault = moment('09:00:00', 'HH:mm:ss');
    const calculateTotalHours = (start, end, changedKey, changedValue) => {
        if (changedKey === 'start_time' || changedKey === 'end_time') {
            const startTime = changedKey === 'start_time' ? changedValue : start;
            const endTime = changedKey === 'end_time' ? changedValue : end;

            if (startTime && endTime) {
                const startMoment = moment(startTime, 'HH:mm');
                const endMoment = moment(endTime, 'HH:mm');

                if (endMoment.isSameOrAfter(startMoment)) {
                    const duration = moment.duration(endMoment.diff(startMoment));
                    const hours = Math.floor(duration.asHours());
                    const minutes = Math.floor(duration.asMinutes()) % 60;

                    // Format the result as 'hours.minutes'
                    const formattedHours = `${hours}.${String(minutes).padStart(2, '0')}`;

                    setModalValidation("");
                    return formattedHours;
                } else {
                    setModalValidation("Invalid Time Slot");
                    return '';
                }
            }
        }

        return '';
    };





    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "total_hours" && parseFloat(value) <= 0) {
            setModalValidation("Invalid Total Hours");
        } else {
            setModalValidation("");
        }
        props.setModalData({ ...props.modalData, [name]: value });
    };

    const employeeLocationSelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        props.setModalData({
            ...props.modalData,
            [selectName]: selectValue,
        });
    };


    return (
        <Modal
            className="fade"
            show={props.showModal}
            backdrop="static"
            onHide={() => closeModal()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Total Working Hours</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h5>
                    <strong>Date: {props.modalData.date}</strong>
                </h5>
                <br />
                <form ref={ModalFormRef}>
                    <Row>
                        <Col className="col-lg">
                            <label>Work Location</label>
                            <Form.Select
                                name="location"
                                value={props.modalData.location}
                                onChange={employeeLocationSelectChangeHandler}
                                className="mb-4"
                                required
                            >
                                <option value="">Select Location</option>
                                {props.getEmployeeWorkLocation?.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.location_name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-lg-6">
                            <label>Start Time</label>
                            <TimePicker
                                showSecond={false}
                                minuteStep={5}
                                defaultValue={startTimeDefault}
                                className="form-control mb-4"
                                onChange={(value) => handleTimeChange('start_time', value)} />
                        </Col>
                        <Col className="col-lg-6">
                            <label>End Time</label>
                            <TimePicker
                                showSecond={false}
                                minuteStep={5}
                                className="form-control mb-4"
                                onChange={(value) => handleTimeChange('end_time', value)} />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="col-lg">
                            <label>Total Hours</label>
                            <Form.Control
                                type="number"
                                onChange={handleInputChange}
                                name="total_hours"
                                value={props.modalData.total_hours}
                                ref={totalHoursInputRef}
                                onClick={() => {
                                    if (totalHoursInputRef.current) {
                                        totalHoursInputRef.current.focus();
                                        totalHoursInputRef.current.select();
                                    }
                                }}
                                className="mb-4"
                                required
                                readOnly
                            />
                            <div className="text-danger">{modalValidation}</div>
                        </Col>
                    </Row>
                    {/* row */}
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary btn-sm"
                    onClick={handleButtonClick}
                    disabled={props.postTimesheetLoading}
                >
                    {(props.postTimesheetLoading) ? (
                        <>
                            <Spinner
                                animation="border"
                                size="sm"
                                className="me-2"
                            />
                            Loading...
                        </>
                    ) : (
                        "Save"
                    )}
                </Button>
                <Button variant="danger light btn-sm" onClick={() => closeModal()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TimeSheetView;