import React, { Fragment } from "react";
// import ComponentsClientSidebar from '../shared/ComponentsClientSidebar'
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useRef } from "react";
// import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation } from "react-router-dom/dist";
import {
  useCompanyUpdateById,
  useCreateCompany,
  useGetCompanyById,
} from "../../actions/company-action";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from "../layouts/PageTitle";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";
import LoadingOverlay from 'react-loading-overlay';

function AddCompany() {
  const navigate = useNavigate();

  const FormRef = useRef(null);
  const location = useLocation();

  let id = location?.state?.rowId;

  const { mutate: postNewCompany, isLoading: loadingpostNewCompanyFn } =
    useCreateCompany();
  const { mutate: patchCompanyInfo, isLoading: loadingpatchCompanyInfoFn } =
    useCompanyUpdateById();

  const [newCompany, setNewCompany] = useState({
    company_name: "",
    email: "",
    contact_number: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    state: "",
    zip_code: "",
  });

  const handleChange = (e) => {
    setNewCompany((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();
    if (FormValidity) {
      if (location?.state?.rowId) {
        try {
          patchCompanyInfo(
            { id, newCompany },
            {
              onSuccess: (response) => {
                toast.success("Company Profile Updated", TostifyConfig);
                navigate("/list-company");
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          postNewCompany(newCompany, {
            onSuccess: (response) => {
              toast.success("Company Profile Added", TostifyConfig);
              navigate("/list-company");
            },
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const { data: companyDetailsById, isLoading: loadingCompanyInfoFn, refetch: companyRefetchById } =
    useGetCompanyById(id);

  useEffect(() => {
    if (id) {
      companyRefetchById();
    }

    if (companyDetailsById) {
      setNewCompany(companyDetailsById);
    }
  }, [companyDetailsById, companyRefetchById, id]);

  return (
    <Fragment>
      <PageTitle
        activeMenu={id ? "Edit Company" : "New Company"}
        motherMenu="Settings"
      />
      <div>
        <div className="col-xl-12 col-xxl-12">
          <Card className="az-card">
            <Card.Header>
              <h3>{id ? "Edit Company" : "New Company"}</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingCompanyInfoFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-lg-6 mb-2">
                        <label className="text-label">
                          Company Name<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="company_name"
                          value={newCompany?.company_name}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>
                          Company Email<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="email"
                          placeholder="Type Text"
                          name="email"
                          value={newCompany?.email}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>
                          Contact Number<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="contact_number"
                          value={newCompany?.contact_number}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>
                          Address1<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="address1"
                          value={newCompany?.address1}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>Address2</label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="address2"
                          value={newCompany?.address2}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>

                      <Col className="col-md-6 mb-2">
                        <label>
                          City<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="city"
                          value={newCompany?.city}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>
                          State<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="state"
                          value={newCompany?.state}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>
                          Country<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="country"
                          value={newCompany?.country}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>
                          Zip Code<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="zip_code"
                          value={newCompany?.zip_code}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  {/* <Form.Group className='mb-3'>
                                    <Row>
                                        <Col className='col-md-6 mb-2'>
                                            <label>Status<span className="required">*</span></label>
                                            <Select
                                                options={statusOption}
                                                value={statusOption.filter((opt) => opt.value === newCompany?.status)}
                                                onChange={(e) => setNewCompany({ ...newCompany, status: e.value })}

                                            />
                                        </Col>
                                    </Row>
                                </Form.Group> */}
                  <Form.Group className="mt-2">
                    <Row>
                      <Col className="col-md-12">
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              navigate("/list-company");
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                           variant="primary btn-sm"
                            onClick={handleSubmit}
                            disabled={loadingCompanyInfoFn && id || loadingpostNewCompanyFn || loadingpatchCompanyInfoFn}
                            >
                            {loadingpostNewCompanyFn ||
                              loadingpatchCompanyInfoFn ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                />
                                Loading...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default AddCompany;
