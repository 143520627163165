import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useGetClientList } from '../../actions/client-actions'
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../components/table/FilteringTable/filtering.css';
import { IsValidPermissions } from '../../services/AuthService';
import DataTable from '../pages/commonPages/DataTable';
import { useClientDeleteById } from '../../actions/client-actions';
import { useQueryClient } from '@tanstack/react-query';
import { deleteConfirmation } from '../../utils/ComponentUtilse';


export const ClientListView = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const { data: getAllClient, isLoading: loadingClientFn, refetch: refetchClient } = useGetClientList();

    const { mutate: deleteClientInfo, isLoading: loadingdeleteClientFn } = useClientDeleteById();


    useEffect(() => {
        if (getAllClient) {
            setData(getAllClient);
            refetchClient();
        }
    }, [getAllClient, refetchClient]);

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                Footer: "Name",
                accessor: "client_name",
                Filter: ColumnFilter,
                className: "width220",
            },
            {
                Header: "Short Name",
                Footer: "Short Name",
                accessor: "client_short_name",
                Filter: ColumnFilter,
                className: "width50",
            },
            {
                Header: "Mail",
                Footer: "Mail",
                accessor: "contact_mail",
                Filter: ColumnFilter,
            },
            {
                Header: "Manager ",
                Footer: " Manager",
                accessor: "contact_manager",
                Filter: ColumnFilter,
            },
            {
                Header: "Phone Number",
                Footer: "Phone Number",
                accessor: "contact_phone_number",
                Filter: ColumnFilter,
            },
            {
                Header: "Contact Address",
                Footer: "Contact Address",
                accessor: "contact_address",
                Filter: ColumnFilter,
            },
            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                Filter: ColumnFilter,
                disableFilters: true,
                Cell: ({ row }) => (
                    <>
                        <Button
                            className="shadow btn-xs sharp me-1"
                            variant="danger light"
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteConfirmation(row.original.id, `Client - ${row.original.client_name}`, deleteClientInfo, refetchClient)}
                        >
                            <i className="fa fa-trash"></i>
                        </Button>&nbsp;&nbsp;

                        {IsValidPermissions('employee.change_employeeclient') && (
                            <Button
                                className="shadow btn-xs sharp me-1"
                                variant='primary light'
                                style={{ cursor: "pointer" }}
                                onClick={() => handleEdit(row.original.id)}
                            >
                                <i className="fa fa-pencil-alt"></i>
                            </Button>
                        )}
                    </>
                )
            }

        ],
        []
    );

    const handleEdit = (id) => {
        navigate('/add-client/', { state: { rowId: id } })
    }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    const { pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Client" motherMenu="Settings" />
            {IsValidPermissions('employee.view_employeeclients') &&
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title"><i class="fa fa-users" aria-hidden="true"></i> Client</h4>
                        {IsValidPermissions('employee.add_employeeclients') &&
                            <div className="d-inline-flex">
                                <Link to={`/add-client/`}>
                                    <Button className='me-2' variant='primary btn-sm'>
                                        <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; Client
                                    </Button>
                                </Link>
                            </div>
                        }

                    </div>
                    <div className="card-body">
                        <DataTable
                            isLoading={loadingClientFn}
                            columns={columns}
                            tableInstance={tableInstance}
                        />
                    </div>
                </div>
            }

            {(!loadingClientFn && !IsValidPermissions('employee.view_employeeclients')) &&
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <Alert variant="warning" show={true}>

                                <svg
                                    viewBox='0 0 24 24'
                                    width='24'
                                    height='24'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='me-2'
                                >
                                    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                    <line x1='12' y1='9' x2='12' y2='13'></line>
                                    <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                </svg>

                                <strong>Info!  </strong> You don't have permission to view Clients.

                            </Alert>
                        </div>
                    </div>
                </div>
            }




        </>
    )

}
export default ClientListView;