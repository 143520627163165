import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

//API DELETE METHOD
const projectDeleteById = async (id) => {
  try {
      const response = await axios.delete(`/organization/api/v1/projects/${id}/`);
      return response.data;
  } catch (error) {
      // Handle error or throw it again
      throw error;
  }
};

export const useProjectDeleteById = () => {
  return useMutation(["project-delete"], projectDeleteById);
};

const getAllProject = async () => {
    let config = {
      method: "get",
      url: "/organization/api/v1/projects/",
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetProjectList = () => {
    return useQuery(["get-all-project"], getAllProject);
  };

  const createNewProject = async (projectData) => {
    let config = {
      method: "post",
      url: "organization/api/v1/projects/",
      headers: {
        "Content-Type": "application/json",
      },
      data:projectData
    };
    
    return await axios.create().request(config)
  };
  export const useCreateProject = () => {
    return useMutation(["project-register"], createNewProject);
  };

  const getProjectById = async (query) => {
    let [, id] = query.queryKey;
    let config = {
      method: "get",
      url: `organization/api/v1/projects/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetProjectById = (id) => {
    return useQuery(["get-project-by-id", id], getProjectById,{enabled: id?true:false});
  };

  const projectUpdateById = async (data) => {
    let {id, newProject} = data;
    let config = {
      method: "patch",
      url: `organization/api/v1/projects/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: newProject,
    };
    return await axios.request(config);
  };
  export const useProjectUpdateById = () => {
    return useMutation(["project-update"], projectUpdateById);
  };


  const getProjectEmployeeUUId = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
      method: "get",
      url: `employee/api/v1/employee_projects/?employee__employee_uuid=${employee_uuid}&current_active=true`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetProjectByUUId = (employee_uuid) => {
    return useQuery(["get-project-by-uuid", employee_uuid], getProjectEmployeeUUId,{enabled: !!employee_uuid});
  };
