import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"

const getSubscriptionList = async () => {
  let config = {
    method: "get",
    url: "/user_management/user-subscription-list/",
    headers: {
      "Content-Type": "application/json",
    },
  }
  const response = await axios.request(config)
  return response?.data;
}

export const useSubscriptionList = () => {
  return useQuery(["get-user-subscription-list"], getSubscriptionList)
}

const getSubscription = async () => {
  let config = {
    method: "get",
    url: "user_management/user-subscription/",
    headers: {
      "Content-Type": "application/json",
    },
  }
  const response = await axios.request(config)
  return response?.data;
}

export const useSubscription = () => {
  return useQuery(["get-user-subscription"], getSubscription)
}

const getUsersInfoFn = async () => {

  let config = {
    method: "get",
    url: `/user_management/profile_management/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
}

export const useUserInfo = () => {
  return useQuery(["get-users-info"], getUsersInfoFn, { cacheTime: 0 });
}

const updateUserRole = async (userData) => {
  const { employee_uuid, role, can_edit } = userData
  let config = {
    method: "put",
    url: `/user_management/profile_management/?employee_uuid=${employee_uuid}&role=${role}&can_edit=${can_edit}`,
    headers: {
      "Content-Type": "application/json",
    }
  };

  return await axios.create().request(config)
}

export const useUpdateUserRole = () => {
  return useMutation(["update-user-role"], updateUserRole)
}