import React, { useEffect, useState } from 'react'
import './style.css';
import { Link } from 'react-router-dom';
import { useSubscription } from '../../actions/user-management-action';
import SubscriptionList from './SubscriptionList';
import { RiseLoader } from 'react-spinners'

const Subscription = () => {

    const [subscription, setSubscription] = useState('month')

    const [subscriptionList, setSubscriptionList] = useState([])

    const [planCardView, setPlanCardView] = useState(false)

    const { data: getSubscriptionList, isLoading: getSubscriptionLoading } = useSubscription()

    useEffect(() => {
        if (getSubscriptionList?.length > 0) {
            setSubscriptionList(getSubscriptionList)
            setPlanCardView(false)
        } else if (!getSubscriptionLoading) setPlanCardView(true)
    }, [getSubscriptionList])

    return (
        <>
            {!planCardView && subscriptionList?.length > 0 ?
                <SubscriptionList isLoading={getSubscriptionLoading} subscriptionList={subscriptionList} setPlanCardView={setPlanCardView} />
                :
                !getSubscriptionLoading && planCardView && <div class="container">
                    {getSubscriptionList?.length > 0 && <i className="fas fa-2x fa-arrow-left font-36 align-middle me-2 text-primary" style={{ cursor: 'pointer' }} onClick={() => setPlanCardView(false)}></i>}
                    <div class="text-center">
                        <div class="price-tabs" role="tablist">
                            <Link className={`nav-link ${subscription === 'month' ? 'active' : ''}`} to="" role="tab" data-toggle="tab" onClick={() => { setSubscription('month') }} >Monthly</Link>
                            <Link className={`nav-link ${subscription === 'year' ? 'active' : ''}`} to="" role="tab" data-toggle="tab" onClick={() => { setSubscription('year') }}>Yearly</Link>
                        </div>
                    </div>
                    <div class="tab-content wow fadeIn" style={{ "visibility": "visible", "animation-name": "fadeIn" }}>
                        <div role="tabpanel" class={`tab-pane fade ${subscription === 'year' ? 'show active' : ''}`} id="yearly">
                            <div class="row justify-content-center">
                                <div class="col-md-6 col-lg-4 mb-30">
                                    <div class="price-item text-center">
                                        <div class="price-top">
                                            <h4>BRONZE</h4>
                                            <h2 class="mb-0"><sup>£</sup>250</h2>
                                            <span class="text-capitalize">PER YEAR</span>
                                        </div>
                                        <div class="price-content mb-5">
                                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">3 Users</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">Access Anytime</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">All Basic Modules</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-close mr-2"></i>
                                                    <span>Instant Chat Support</span>
                                                </li>

                                            </ul>
                                            <div className='d-flex align-items-center' style={{ flexDirection: 'column' }}>
                                                <Link className='btn btn-primary btn-sm mt-5'>Contact Admin</Link>
                                                <div className="fas fa-envelope mt-3"> onboard@ukvicas.com</div>
                                                <div className="fas fa-phone mt-3"> 020 32 900 800</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4 mb-30">
                                    <div class="price-item text-center popular">
                                        <div class="price-top">
                                            <h4>SILVER</h4>
                                            <h2 class="mb-0"><sup>£</sup>500</h2>
                                            <span class="text-capitalize">PER YEAR</span>
                                        </div>
                                        <div class="price-content mb-5">
                                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">10 Users</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">Access Anytime</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">All Basic Modules</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-close mr-2"></i>
                                                    <span>Instant Chat Support</span>
                                                </li>


                                            </ul>
                                            <div className='d-flex align-items-center' style={{ flexDirection: 'column' }}>
                                                <Link className='btn btn-light btn-sm mt-5'>Contact Admin</Link>
                                                <div className="fas fa-envelope text-white mt-3"> onboard@ukvicas.com</div>
                                                <div className="fas fa-phone text-white mt-3"> 020 32 900 800</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4 mb-30">
                                    <div class="price-item text-center">
                                        <div class="price-top">
                                            <h4>GOLD</h4>
                                            <h2 class="mb-0"><sup>£</sup>1000</h2>
                                            <span class="text-capitalize">PER YEAR</span>
                                        </div>
                                        <div class="price-content mb-5">
                                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">25 Users</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">Access Anytime</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">All Basic Modules</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-close mr-2"></i>
                                                    <span>Instant Chat / Voice Support</span>
                                                </li>

                                            </ul>
                                            <div className='d-flex align-items-center' style={{ flexDirection: 'column' }}>
                                                <Link className='btn btn-primary btn-sm mt-5'>Contact Admin</Link>
                                                <div className="fas fa-envelope mt-3"> onboard@ukvicas.com</div>
                                                <div className="fas fa-phone mt-3"> 020 32 900 800</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div role="tabpanel" class={`tab-pane fade ${subscription === 'month' ? 'show active' : ''}`} id="monthly">
                            <div class="row justify-content-center">
                                <div class="col-md-6 col-lg-4 mb-30">
                                    <div class="price-item text-center">
                                        <div class="price-top">
                                            <h4>BRONZE</h4>
                                            <h2 class="mb-0"><sup>£</sup>25.00</h2>
                                            <span class="text-capitalize">PER MONTH</span>
                                        </div>
                                        <div class="price-content mb-5">
                                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">3 Users</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">Unlimited File Uploads</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">All Basic Modules</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-close mr-2"></i>
                                                    <span>Instant Chat Support</span>
                                                </li>

                                            </ul>
                                            <div className='d-flex align-items-center' style={{ flexDirection: 'column' }}>
                                                <Link className='btn btn-primary btn-sm mt-5'>Contact Admin</Link>
                                                <div className="fas fa-envelope mt-3"> onboard@ukvicas.com</div>
                                                <div className="fas fa-phone mt-3"> 020 32 900 800</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4 mb-30">
                                    <div class="price-item text-center popular">
                                        <div class="price-top">
                                            <h4>SILVER</h4>
                                            <h2 class="mb-0"><sup>£</sup>50.00</h2>
                                            <span class="text-capitalize">PER MONTH</span>
                                        </div>
                                        <div class="price-content mb-5">
                                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">10 Users</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">Unlimited File Uploads</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">All Basic Modules</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-close mr-2"></i>
                                                    <span>Instant Chat Support</span>
                                                </li>

                                            </ul>
                                            <div className='d-flex align-items-center' style={{ flexDirection: 'column' }}>
                                                <Link className='btn btn-light btn-sm mt-5'>Contact Admin</Link>
                                                <div className="fas fa-envelope text-white mt-3"> onboard@ukvicas.com</div>
                                                <div className="fas fa-phone text-white mt-3"> 020 32 900 800</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4 mb-30">
                                    <div class="price-item text-center">
                                        <div class="price-top">
                                            <h4>GOLD</h4>
                                            <h2 class="mb-0"><sup>£</sup>100.00</h2>
                                            <span class="text-capitalize">PER MONTH</span>
                                        </div>
                                        <div class="price-content mb-5">
                                            <ul class="border-bottom mb-30 mt-md-4 pb-3 text-left">
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">25 Users</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">Unlimited File Uploads</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-check mr-2"></i>
                                                    <span class="c-black">All Basic Modules</span>
                                                </li>
                                                <li>
                                                    <i class="zmdi zmdi-close mr-2"></i>
                                                    <span>Instant Chat Support</span>
                                                </li>


                                            </ul>
                                            <div className='d-flex align-items-center' style={{ flexDirection: 'column' }}>
                                                <Link className='btn btn-primary btn-sm mt-5'>Contact Admin</Link>
                                                <div className="fas fa-envelope mt-3"> onboard@ukvicas.com</div>
                                                <div className="fas fa-phone mt-3"> 020 32 900 800</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {getSubscriptionLoading &&
                <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "calc(100vh - 200px)" }}>
                    <RiseLoader color="#40189D" />
                </div>
            }
        </>
    )
}

export default Subscription