import { useState, useEffect } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { dateTimeHandler } from "../../../../../utils/ComponentUtilse";
import {
    useGetEmergencyAddressLogByUUID,
    useGetEmergencyEmailLogByUUID,
    useGetEmergencyPhoneLogByUUID
} from "../../../../../actions/changelog-actions";
import { useParams } from "react-router-dom";

function EmergencyContactChangeLog() {
    const { employee_uuid } = useParams();
    const [employeeUUID] = useState(employee_uuid);
    const [emergencyPhoneLog, setEmergencyPhoneLog] = useState([]);
    const [emergencyAddressLog, setEmergencyPAddressLog] = useState([]);
    const [emergencyEmailLog, setEmergencyEmailLog] = useState([]);

    const {
        data: getEmergencyPhoneLogByUUID,
        isLoading: loadinggetEmergencyPhoneLog
    } = useGetEmergencyPhoneLogByUUID(employeeUUID);

    const {
        data: getEmergencyAddressLogByUUID,
        isLoading: loadinggetEmergencyAddressLog
    } = useGetEmergencyAddressLogByUUID(employeeUUID);

    const {
        data: getEmergencyEmailLogByUUID,
        isLoading: loadinggetEmergencyEmailLog
    } = useGetEmergencyEmailLogByUUID(employeeUUID);

    useEffect(() => {
        if (getEmergencyPhoneLogByUUID) {
            setEmergencyPhoneLog(getEmergencyPhoneLogByUUID);
        }
    }, [getEmergencyPhoneLogByUUID]);

    useEffect(() => {
        if (getEmergencyAddressLogByUUID) {
            setEmergencyPAddressLog(getEmergencyAddressLogByUUID);
        }
    }, [getEmergencyAddressLogByUUID]);

    useEffect(() => {
        if (getEmergencyEmailLogByUUID) {
            setEmergencyEmailLog(getEmergencyEmailLogByUUID);
        }
    }, [getEmergencyEmailLogByUUID]);

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">
                    <i className="fa fa-phone font-18 align-middle me-2 text-primary"></i>
                    &nbsp; Emergency Contact Change History
                </h4>
            </div>
            <div className="card-body">
                <h5>
                    {/* <i className="fas fa-user-graduate font-18 align-middle me-2 text-primary"></i> */}
                    &nbsp;Contact Address History
                </h5>
                <hr />
                {/* <ListEmployeeEducation /> */}
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Address1</th>
                            <th>Address2</th>
                            <th>City</th>
                            <th>Country</th>
                            <th>County</th>
                            <th>Postcode</th>
                            <th>Date Changed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loadinggetEmergencyAddressLog ?
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={7}>
                                    <Spinner animation="border" size="lg" className="me-2" />
                                </td>
                            </tr>
                            : emergencyAddressLog.length > 0
                                ? emergencyAddressLog.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item.address1}</td>
                                            <td>{item.address2}</td>
                                            <td>{item.city}</td>
                                            <td>{item.country}</td>
                                            <td>{item.state}</td>
                                            <td>{item.zip_code}</td>
                                            <td>{dateTimeHandler(item.created_at)}</td>
                                        </tr>
                                    );
                                })
                                : <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={7}>No records found</td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card-body">
                        <h5>
                            {/* <i className="fas fa-user-graduate font-18 align-middle me-2 text-primary"></i> */}
                            &nbsp;Primary Contact History
                        </h5>
                        <hr />
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>Contact Number</th>
                                    <th>Date Changed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadinggetEmergencyPhoneLog ?
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={2}>
                                            <Spinner animation="border" size="lg" className="me-2" />
                                        </td>
                                    </tr>
                                    : emergencyPhoneLog.length > 0
                                        ? emergencyPhoneLog.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{item.mobile_phone}</td>
                                                    <td>{dateTimeHandler(item.created_at)}</td>
                                                </tr>
                                            );
                                        })
                                        : <tr>
                                            <td style={{ textAlign: 'center' }} colSpan={5}>No records found</td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </div>

                </div>
                <div className="col-md-12">
                    <div className="card-body">
                        <h5>
                            {/* <i className="fas fa-user-graduate font-18 align-middle me-2 text-primary"></i> */}
                            &nbsp;Email Change History
                        </h5>
                        <hr />
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Date Changed</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loadinggetEmergencyEmailLog ?
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={2}>
                                            <Spinner animation="border" size="lg" className="me-2" />
                                        </td>
                                    </tr>
                                    : emergencyEmailLog.length > 0
                                        ? emergencyEmailLog.map((item, i) => {
                                            return (
                                                <tr>
                                                    <td>{item.email}</td>
                                                    <td>{dateTimeHandler(item.created_at)}</td>
                                                </tr>
                                            );
                                        })
                                        : <tr>
                                            <td style={{ textAlign: 'center' }} colSpan={5}>No records found</td>
                                        </tr>
                                }
                            </tbody>
                        </Table>
                    </div>

                </div>
            </div>
        </div>

    )

}

export default EmergencyContactChangeLog;
