import { Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useState } from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";


export function Alertmsg(props) {

  // const [showAlert, setShowAlert] = useState(true);

  // const closeAlert = () => {
  //   setShowAlert(false);
  // };

  return (
    <Alert variant="info" dismissible className="solid">
      <strong>Info! </strong><p className='fs-12'>Prove your right to work in the UK by following the instructions provided in the link below. Don't forget to submit the share code and expiry date. &nbsp;<Link to="https://www.gov.uk/prove-right-to-work/get-a-share-code-online" target="_blank">Click here</Link></p>
      <button className="btn-close" onClick={props.closeAlert}>
        <span>
          <i className="fa-solid fa-xmark"></i>
        </span>
      </button>
    </Alert>
  );
}

export const validateEmail = (email) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return pattern.test(email)
}

const CustomTooltip = ({ id, message, children }) => {
  return (
    <OverlayTrigger key={`tooltip-${id}`} placement="top" overlay={<Tooltip id={`tooltip-${id}`}>{message}</Tooltip>}>
      {children}
    </OverlayTrigger>
  );
};
export default CustomTooltip;


