import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// API POST Method

const employeeCertificationPostFn = async (employeeData) => {
    let config = {
      method: "post",
      url: `/employee/api/v1/employee_certification/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: employeeData,
    };
    
    return await axios.request(config);
  };
  export const useEmployeeCertificationPostFn = () => {
    return useMutation(["employee-certification-post"], employeeCertificationPostFn);
  };


  // API PATCH Method
  const employeeCertificationUpdateFn = async (employeeData) => {
  
    let {id, userData} = employeeData;
    let config = {
      method: "patch",
      url: `/employee/api/v1/employee_certification/${id}/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: userData,
    };
    
    return await axios.request(config);
  };
  export const useEmployeeCertificationUpdateFn = () => {
    return useMutation(["employee-certification-update"], employeeCertificationUpdateFn);
  };

 // API GET Method Using UUID

  const getEmployeeCertificationByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
      method: "get",
      url: `/employee/api/v1/employee_certification/?employee__employee_uuid=${employee_uuid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetEmployeeCertificationByUUID = (employee_uuid) => {
    return useQuery(["get-employee-certification-by-uuid", employee_uuid], getEmployeeCertificationByUUIDFn, {enabled: !!employee_uuid});
  };

  // API GET Method 
  
  const getEmployeeCertificationByIDFn = async (query) => {
    let [, employee_id] = query.queryKey;
    let config = {
      method: "get",
      url: `/employee/api/v1/employee_certification/${employee_id}/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetEmployeeCertificationByID = (employee_id) => {
    return useQuery(["get-employee-certification-by-id", employee_id], getEmployeeCertificationByIDFn, {enabled: false});
  }; 