import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// API POST Method

const advertisementPostFn = async (advertisementData) => {
    let config = {
      method: "post",
      url: `/recruitment/api/v1/rlmt_details/ `,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: advertisementData,
    };
    
    return await axios.request(config);
  };
  export const useAdvertisementPostFn = () => {
    return useMutation(["advertisement-post"], advertisementPostFn);
  };


  // API PATCH Method
  const advertisementUpdateFn = async (advertisementData) => {
  
    let {id, userData} = advertisementData;
    let config = {
      method: "patch",
      url: `/recruitment/api/v1/rlmt_details/${id}/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: userData,
    };
    
    return await axios.request(config);
  };
  export const useAdvertisementUpdateFn = () => {
    return useMutation(["advertisement-update"], advertisementUpdateFn);
  };

  // API GET Method 
  
  const getadvertisementByIDFn = async (query) => {
    let [, id] = query.queryKey;
    let config = {
      method: "get",
      url: `/recruitment/api/v1/rlmt_details/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetAdvertisementByID = (id) => {
    return useQuery(["get-advertisement-by-id", id], getadvertisementByIDFn, {enabled: false});
  };  