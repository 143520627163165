import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// API POST Method

const employeeDocumentPostFn = async (employeeData) => {
    let config = {
        method: "post",
        url: `/employee/api/v1/employee_documents/`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: employeeData,
    };

    return await axios.request(config);
};
export const useEmployeeDocumentPostFn = () => {
    return useMutation(["employee-document-post"], employeeDocumentPostFn);
};


// API PATCH Method
const employeeDocumentUpdateFn = async (employeeData) => {

    let { id, userData } = employeeData;
    let config = {
        method: "patch",
        url: `/employee/api/v1/employee_documents/${id}/`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: userData,
    };

    return await axios.request(config);
};
export const useEmployeeDocumentUpdateFn = () => {
    return useMutation(["employee-document-update"], employeeDocumentUpdateFn);
};

// API GET Method Using UUID

const getEmployeeDocumentByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/employee/api/v1/employee_documents/?employee__employee_uuid=${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetEmployeeDocumentByUUID = (employee_uuid) => {
    return useQuery(["get-employee-document-by-uuid", employee_uuid], getEmployeeDocumentByUUIDFn, { enabled: !!employee_uuid });
};

// API GET Method 

const getEmployeeDocumentByIDFn = async (query) => {
    let [, employee_id] = query.queryKey;
    let config = {
        method: "get",
        url: `/employee/api/v1/employee_documents/${employee_id}/`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetEmployeeDocumentByID = (employee_id) => {
    return useQuery(["get-employee-document-by-id", employee_id], getEmployeeDocumentByIDFn, { enabled: false });
};


//API DELETE METHOD
const documentDeleteById = async (id) => {
    try {
        const response = await axios.delete(`/employee/api/v1/employee_documents/${id}/`);
        return response.data;
    } catch (error) {
        // Handle error or throw it again
        throw error;
    }
};

export const useDocumentDeleteById = () => {
    return useMutation(["document-delete"], documentDeleteById);
};