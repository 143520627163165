import React from "react";
import {
  Row,
  Button,
  Modal,
  Container,
  Badge,
  Card,
  CardBody,
  Table,
  Spinner
} from "react-bootstrap";
import { usePDF, Margin } from "react-to-pdf";

const EmployeeProfileExportModal = (props) => {
  const { employeeProfileData,
    employeeProfileInfoData,
    emergencyContactData,
    employeeEducationList,
    employeeCertificationList,
    employeeBankList,
    employeePassPortList,
    employeeVisaList,
    employeeCosList,
    employeeRtwList,
    employeeLeaveList,
    loadingEmployeeDetailByUUID
  } = props

  const { toPDF, targetRef } = usePDF({
    method: "save",
    filename: `${employeeProfileData?.employee_fullname}-${employeeProfileData?.employee_number}.pdf`,
    page: { margin: Margin.MEDIUM }
  })

  return (
    <Modal
      className="fade  bd-example-modal-lg"
      show={props.profileExportModal}
      size="lg"
      fullscreen='md-down'
      scrollable={true}
    >
      <Modal.Header>
        <Modal.Title>Profile Details</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => props.setProfileExportModal(false)}
        ></Button>
      </Modal.Header>

      <Modal.Body style={{ backgroundColor: '#ebe7e7c4' }}>
        {loadingEmployeeDetailByUUID ?
          <div className="d-flex justify-content-center">
            <Spinner animation="border" size="lg" className="me-2" />Loading...
          </div>
          :
          <div ref={targetRef}>
            {/* Profile Basic details */}
            <div className="d-flex justify-content-between row">
              <div className="col-lg-3">
                {employeeProfileData?.employee_profile_image &&
                  <img src={employeeProfileData?.employee_profile_image_url?.profile_image_url} style={{ height: '100px', width: '100px' }} alt="profile-img" />
                }
              </div>
              <div className="col-lg-3"></div>
              <div className="col-lg-6 row flex-column">
                <span><strong>{employeeProfileData?.employee_fullname}  ({employeeProfileData?.employee_number})</strong></span>
                <span><i class="fa fa-envelope text-primary"></i> &nbsp; {employeeProfileData?.work_email}</span>
                <span><i class="fa fa-phone text-primary"></i> &nbsp; {employeeProfileData?.work_phone}</span>
              </div>
            </div>
            <hr />
            {/* Work Details */}
            <Container>
              <h4 className="card-title">
                <i class="fa fa-user text-primary"></i> &nbsp; Work Profile
              </h4>
              <Card>
                <CardBody style={{ borderRadius: '6px' }}>
                  <Row>
                    <label className="col-lg-4 col-form-label">
                      Full Name:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData?.employee_fullname}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Employee Number:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData?.employee_number}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Email:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData?.work_email}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Contact Number:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData?.work_phone}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Position:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData?.position_name}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Contact Number:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData.work_phone}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Work Location:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData.work_location_name}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Start Date:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData.employee_start_date}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Reporting Manager:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData.direct_manager_name}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Client:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData.client_name}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Project/Work/Branch:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData.project_name}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      National Insurance Number:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData.ni_number}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      SOC Number:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData.soc}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Sponsored Employee:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeProfileData.is_sponsored_employee ?
                        <Badge pill bg="success" className='badge-sm text-capitalize'>Yes</Badge> :
                        <Badge pill bg="danger" className='badge-sm text-capitalize'>No</Badge>
                      }
                    </label>
                  </Row>
                </CardBody>
              </Card>
            </Container>

            {/* Personal Information */}
            <Container>
              <h4 className="card-title">
                <i className="fa fa-id-badge font-18 align-middle me-2 text-primary"></i>
                &nbsp; Personal Info
              </h4>
              <Card>
                <CardBody style={{ borderRadius: '6px' }}>
                  <label className="col-lg-4 col-form-label">
                    Date Of Birth:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.date_of_birth}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    Home Phone:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.home_phone}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    Personal Email:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.personal_email}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    Gender:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.gender_name}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    Maritial Status:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.marital_status_name}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    Nationality:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.nationality}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    Address1:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.address1}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    Address2:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.address2}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    City:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.city}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    County:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.state}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    PostCode:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.zip_code}
                  </label>
                  <label className="col-lg-4 col-form-label">
                    Country:
                  </label>
                  <label className="col-lg-8 col-form-label">
                    {employeeProfileInfoData?.country_name}
                  </label>
                </CardBody>
              </Card>
            </Container>

            {/* Emergency Contact */}
            <Container>
              <h4 className="card-title">
                <i class="fa fa-phone text-primary"></i> &nbsp; Emergency
              </h4>
              <Card>
                <CardBody style={{ borderRadius: '6px' }}>
                  <Row>
                    <label className="col-lg-4 col-form-label">
                      FullName:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {emergencyContactData?.full_name}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Home Phone:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {emergencyContactData?.home_phone}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Email:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {emergencyContactData?.email}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Relationship:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {emergencyContactData?.relationship}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Address1:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {emergencyContactData?.address1}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      City:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {emergencyContactData?.city}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      County:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {emergencyContactData?.state}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      PostCode:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {emergencyContactData?.zip_code}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Country:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {emergencyContactData?.country_name}
                    </label>
                  </Row>
                </CardBody>
              </Card>
            </Container>

            {/* Education */}
            <Container>
              <h4 className="card-title">
                <i class="fas fa-user-graduate text-primary"></i> &nbsp; Education
              </h4>
              <Card>
                <CardBody style={{ borderRadius: '6px' }}>
                  <Row>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Degree</th>
                          <th>Instituation</th>
                          <th>Major</th>
                          <th>Year</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeEducationList?.length > 0
                          ? employeeEducationList.map((item, i) => {
                            return (
                              <tr>
                                <td>{item.degree}</td>
                                <td>{item.institution}</td>
                                <td>{item.major}</td>
                                <td>{item.year}</td>
                              </tr>
                            );
                          })
                          : <tr>
                            <td style={{ textAlign: 'center' }} colSpan={5}>No records found</td>
                          </tr>
                        }
                      </tbody>
                    </Table>
                  </Row>
                </CardBody>
              </Card>
            </Container>

            {/* Certification’s */}
            <Container>
              <h4 className="card-title">
                <i class="fa fa-user text-primary"></i> &nbsp; Certification’s
              </h4>
              <Card>
                <CardBody style={{ borderRadius: '6px' }}>
                  <Row>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Instituation</th>
                          <th>Name</th>
                          <th>Issue Date</th>
                          <th>Expiration Date</th>
                          <th>Descripition</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeCertificationList?.length > 0
                          ? employeeCertificationList.map((item, i) => {
                            return (
                              <tr>
                                <td>{item.institution}</td>
                                <td>{item.name}</td>
                                <td>{item.issued_date}</td>
                                <td>{item.expiration_date}</td>
                                <td>{item.description}</td>
                              </tr>
                            );
                          })
                          : <tr>
                            <td style={{ textAlign: 'center' }} colSpan={6}>No records found</td>
                          </tr>
                        }
                      </tbody>
                    </Table>
                  </Row>
                </CardBody>
              </Card>
            </Container>

            {/* Bank Details */}
            {employeeBankList?.length > 0
              && employeeBankList.map((item, i) => {
                if (item.primary_account) {
                  return (
                    <Container>
                      <h4 className="card-title">
                        <i class="fas fa-university text-primary"></i> &nbsp; Bank Details
                      </h4>
                      <Card>
                        <CardBody style={{ borderRadius: '6px' }}>
                          <Row>
                            <label className="col-lg-4 col-form-label">
                              Account Number:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.account_number}</label>

                            <label className="col-lg-4 col-form-label">
                              Sort Code:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.sort_code}</label>
                            <label className="col-lg-4 col-form-label">
                              Bank Name:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.bank_name}</label>
                          </Row>
                        </CardBody>
                      </Card>
                    </Container>
                  )
                }
              })}

            {/* Passport */}
            {employeePassPortList?.length > 0
              && employeePassPortList.map((item, i) => {
                if (item.current_active) {
                  return (
                    <Container>
                      <h4 className="card-title">
                        <i class="fas fa-address-card text-primary"></i> &nbsp; Passport
                      </h4>
                      <Card>
                        <CardBody style={{ borderRadius: '6px' }}>
                          <Row>
                            <label className="col-lg-4 col-form-label">
                              Passport Number:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.passport_number}</label>

                            <label className="col-lg-4 col-form-label">
                              Country Of Issue:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.country_of_issue_name}</label>
                            <label className="col-lg-4 col-form-label">
                              Issue Date:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.issue_date}</label>
                            <label className="col-lg-4 col-form-label">
                              Expiration Date:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.expiration_date}</label>
                          </Row>
                        </CardBody>
                      </Card>
                    </Container>
                  )
                }
              })}

            {/* Visa */}
            {employeeVisaList?.length > 0
              && employeeVisaList.map((item, i) => {
                if (item.current_active) {
                  return (
                    <Container>
                      <h4 className="card-title">
                        <i class="fa fa-newspaper text-primary"></i> &nbsp; Visa
                      </h4>
                      <Card>
                        <CardBody style={{ borderRadius: '6px' }}>
                          <Row>
                            <label className="col-lg-4 col-form-label">
                              Visa Number:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.visa_number}</label>

                            <label className="col-lg-4 col-form-label">
                              Visa Type:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.visa_type_name}</label>
                            <label className="col-lg-4 col-form-label">
                              Country Of Issue:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.country_of_issue_name}</label>
                            <label className="col-lg-4 col-form-label">
                              Issue Date:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.issue_date}</label>
                            <label className="col-lg-4 col-form-label">
                              Expiration Date:
                            </label>
                            <label className="col-lg-8 col-form-label">{item.expiration_date}</label>
                          </Row>
                        </CardBody>
                      </Card>
                    </Container>
                  )
                }
              })}

            {/* COS */}
            <Container>
              <h4 className="card-title">
                <i class="fas fa-address-card text-primary"></i> &nbsp; COS
              </h4>
              <Card>
                <CardBody style={{ borderRadius: '6px' }}>
                  <Row>
                    <label className="col-lg-4 col-form-label">
                      licence number:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeCosList?.[0]?.sponsor_licence_number}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Sponsor name:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeCosList?.[0]?.sponsor_name}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Certificate number:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeCosList?.[0]?.certificate_number}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Certificate date:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeCosList?.[0]?.certificate_date}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Assigned date:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeCosList?.[0]?.assigned_date}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Expiry date:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeCosList?.[0]?.expiry_date}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Sponsor note:
                    </label>
                    <label className="col-lg-8 col-form-label">
                      {employeeCosList?.[0]?.sponsor_note}
                    </label>
                    <label className="col-lg-4 col-form-label">
                      Attachment:
                    </label>
                  </Row>
                </CardBody>
              </Card>
            </Container>

            {/*  Right To Work */}
            <Container>
              <h4 className="card-title">
                <i class="fas fa-address-card text-primary"></i> &nbsp;  Right To Work
              </h4>
              <Card>
                <CardBody style={{ borderRadius: '6px' }}>
                  <Row>
                    <label className="col-lg-6 col-form-label">
                      RTW Reference:
                    </label>
                    <label className="col-lg-6 col-form-label">
                      {employeeRtwList?.[0]?.rtw_reference}
                    </label>
                    <label className="col-lg-6 col-form-label">
                      Date of Check:
                    </label>
                    <label className="col-lg-6 col-form-label">
                      {employeeRtwList?.[0]?.rtw_submit_date}
                    </label>
                    <label className="col-lg-6 col-form-label">
                      Expiry Date:
                    </label>
                    <label className="col-lg-6 col-form-label">
                      {employeeRtwList?.[0]?.rtw_expiry_date}
                    </label>
                    <label className="col-lg-6 col-form-label">
                      RTW Status:
                    </label>
                    <label className="col-lg-6 col-form-label">
                      {employeeRtwList?.[0]?.rtw_status_text}
                    </label>
                    <label className="col-lg-6 col-form-label">
                      Attachment:
                    </label>
                  </Row>
                </CardBody>
              </Card>
            </Container>

            {/* Employee Leaves */}
            <Container>
              <h4 className="card-title">
                <i class="fas fa-envelope text-primary"></i> &nbsp; Employee Leaves
              </h4>
              <Card>
                <CardBody style={{ borderRadius: '6px' }}>
                  <Row>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Leave Type</th>
                          <th className="text-center">Total Leaves</th>
                          <th className="width200 text-center">Taken</th>
                          <th className="width200 text-center">Remaining</th>
                          <th className="width200 text-center">Year</th>
                        </tr>
                      </thead>
                      <tbody>
                        {employeeLeaveList?.length > 0
                          ? employeeLeaveList.map((item, i) => {
                            return (
                              <tr>
                                <td>{item?.leave_type}</td>
                                <td className="text-center">{item?.total_days}</td>
                                <td className="text-center">{item?.total_taken}</td>
                                <td className="text-center">{item?.days_remaining}</td>
                                <td className="text-center">{item?.year}</td>
                              </tr>
                            );
                          })
                          : <tr>
                            <td style={{ textAlign: 'center' }} colSpan={6}>No records found</td>
                          </tr>
                        }
                      </tbody>
                    </Table>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </div>
        }
      </Modal.Body>


      <Modal.Footer>
        <Button
          variant="danger light btn-sm"
          onClick={() => props.setProfileExportModal(false)}
        >
          Close
        </Button>
        <Button
          variant="primary btn-sm"
          onClick={() => {
            toPDF()
            props.setProfileExportModal(false)
          }}
        >
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EmployeeProfileExportModal