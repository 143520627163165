import React, { useContext } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { isValidPermission } from "../../../../services/AuthService";
import { handleFileDownload } from '../../../../utils/ComponentUtilse';
import { dateHandler } from "../../../../utils/ComponentUtilse";
import CustomTooltip from "../../../../utils/CommonUtils";
import { useDocumentDeleteById } from "../../../../actions/document-action";
import { deleteConfirmation } from "../../../../utils/ComponentUtilse";
import { ServiceContext } from "../../../../context/ServiceContext";

function Document(props) {

    const { canEditEmployee } = useContext(ServiceContext);

    const { mutate: deleteDocumentInfo, isLoading: loadingdeleteDocumentFn } = useDocumentDeleteById();


    const handleEdit = (id) => {
        props.setCardView({ documentsEdit: true, documentId: id })
    }

    return (
        <div className="card" id="documents">
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">
                    <i className="fa fa-paperclip font-18 align-middle me-2 text-primary"></i>
                    &nbsp; Documents
                </h4>
                {canEditEmployee && isValidPermission('employee.add_employeecertification', props.getPermissionList) &&
                    <Button
                        className="me-2 btn-sm"
                        variant="primary"
                        onClick={() => {
                            props.setCardView({ documentsEdit: true });
                        }}
                    >
                        <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Add
                    </Button>
                }
            </div>
            <div className="card-body">
                <Table responsive>
                    <thead>
                        <tr>
                            <th className="minWidth220">File Name</th>
                            <th className="minWidth220">Description</th>
                            <th className="minWidth220">Expiry Date	</th>
                            {isValidPermission('employee.change_employeecertification', props.getPermissionList) &&
                                <th className="minWidth100">Action</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {props.loadingGetEmployeeDocumentFn ?
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={6}>
                                    <Spinner animation="border" size="lg" className="me-2" />
                                </td>
                            </tr>
                            : props.employeeDocumentList?.length > 0
                                ? props.employeeDocumentList.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item.name}</td>
                                            <td>{item.description}</td>
                                            <td>{item.expiry_date}</td>
                                            {canEditEmployee && isValidPermission('employee.change_employeecertification', props.getPermissionList) &&
                                                <td>
                                                    <CustomTooltip id={`edit-${item.index}`} message="Edit">
                                                        <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i></Button>
                                                    </CustomTooltip>

                                                    <CustomTooltip id={`delete-${item.index}`} message="Delete">
                                                        <Button
                                                            className="shadow btn-xs sharp me-1"
                                                            variant="danger light"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => deleteConfirmation(item.id, `Document - ${item.name}`, deleteDocumentInfo, props.refetchDocuments)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </Button>
                                                    </CustomTooltip>&nbsp;

                                                    {item.attachment_url ?
                                                        <CustomTooltip id={`download-${item.index}`} message="Download">
                                                            <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleFileDownload(item.attachment_url)}><i className="fa fa-download"></i></Button>
                                                        </CustomTooltip> : null}

                                                </td>
                                            }
                                        </tr>
                                    );
                                })
                                : <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={6}>No records found</td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </div>
        </div>

    );

}
export default Document;