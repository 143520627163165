import React, { useState, useRef, useEffect } from "react";
import { Button, Spinner, Form, Row, Table, Badge, Card, Accordion, Col, Modal } from "react-bootstrap";
import { isValidPermission } from "../../../services/AuthService";
import { handleFileDownload } from "../../../utils/ComponentUtilse";
import { useInterViewStatusPostFn } from "../../../actions/interviewstatus-actions";
import { useEmployeeInfo } from "../../../actions/employee-actions";
import { INTERVIEW_STATUS } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { TostifyConfig } from "../../../utils/Constance";
import { useNavigate, Link } from "react-router-dom";
import { useGetInterViewStatusById } from "../../../actions/interviewstatus-actions";
import { useApplicationUpdateFn, useApplicationDeleteById, useGetApplicationByID } from '../../../actions/recruitment-application-action';
import { getBadgeColor } from "../../../utils/Constance";
import { useDraftEmployeeFn } from "../../../actions/draft-employee-actions";
import { deleteConfirmation } from "../../../utils/ComponentUtilse";
import Swal from 'sweetalert2';
import CustomTooltip from "../../../utils/CommonUtils";


const JobApplication = (props) => {

  const [basicModal, setBasicModal] = useState(false);

  const [applicationId, setApplicationId] = useState('');

  const [id, setId] = useState('');

  const [timeLine, setTimeLine] = useState([]);

  const [draftEmployee, setDraftEmployee] = useState({});

  const [activeAccordionItem, setActiveAccordionItem] = useState('');

  const [interviewStatusId, setInterviewStatusId] = useState('');

  const navigate = useNavigate();

  const refetch = props.refetchGetApplicationFn;

  const { mutate: deleteApplicationsInfo, isLoading: loadingdeleteApplicationsFn } = useApplicationDeleteById();

  const { mutate: postDraftEmployeeFn, isLoading: draftEmployeePostLoading } =
    useDraftEmployeeFn();


  const {
    data: getInterViewStatusById,
    isLoading: loadingInterViewStatusById,
    refetch: refetchInterViewStatusById,
  } = useGetInterViewStatusById(activeAccordionItem);

  const {
    data: getApplicationID,
    isLoading: loadingGetApplicationIDFn,
    refetch: refetchApplicationID
  } = useGetApplicationByID(id);

  useEffect(() => {
    if (id) {
      refetchApplicationID();
    }
    if (getApplicationID) {
      setDraftEmployee(getApplicationID);
    }
  }, [getApplicationID]);


  useEffect(() => {
    if (getInterViewStatusById) {
      setTimeLine(getInterViewStatusById);
      refetchInterViewStatusById();
    }
  }, [getInterViewStatusById, refetchInterViewStatusById]);


  const handleEdit = (id) => {
    props.setCardView({ applicationEdit: true, applicationId: id })
  }

  const handleButton = (id) => {
    setApplicationId(id);
    setBasicModal(true);
  }

  const handleAccordionHeaderClick = (item) => {
    setActiveAccordionItem(item?.id);
    setInterviewStatusId(item?.interview_status)
    setId(item?.id)
  };


  const postDraftEmployeeHandeler = () => {

    let userData = {
      first_name: draftEmployee.candidate_first_name,
      last_name: draftEmployee.candidate_last_name,
      work_phone: draftEmployee.mobile_number,
      work_email: draftEmployee.email_address,
      sponsorship_required:draftEmployee.sponsorship_required,
      job_applicant_id: id
    };

    postDraftEmployeeFn(
      userData,
      {
        onSuccess: (response) => {
          toast.success('Draft Employee Added', TostifyConfig);
          navigate('/draftemployee-list/');
          // client.invalidateQueries(['get-employee-certification-by-uuid']);
          // client.invalidateQueries(['get-pending-action-by-uuid']);
          // props.setCardView({ certification: true });
        },
        onError: (error) => {
          console.error('Error while converting to Draft Employee:', error);
          toast.error('Failed to convert to Draft Employee. Please try again.', TostifyConfig);
        },
      }
    );
  }

  const swalConfirm = () => {
    Swal.fire({
      title: 'Convert to Draft Employee?',
      text: 'Are you sure you want to convert this candidate to a Draft Employee?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, convert it!',
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, call the post handler function
        postDraftEmployeeHandeler();
      }
    });
  }


  return (
    <div className="card" id="bank">
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title">
          <i class="fas fa-file-alt font-18 align-middle me-2 text-primary"></i>
          &nbsp;Candidates
        </h4>
        {isValidPermission('employee.add_employeebankdetail', props.getPermissionList) &&
          <Button className="me-2 btn-sm" variant="primary"
            onClick={() => {
              props.setCardView({ applicationEdit: true });
            }}>
            <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Add
          </Button>
        }
      </div>
      <div className="card-body">
        <Col xl="12">
          <Card>
            <Card.Body>
              <Accordion
                className="accordion accordion-header-bg "
              // defaultActiveKey="0"
              >
                {props.isLoading ?
                  <tr>
                    <td style={{ textAlign: 'center' }} colSpan={5}>
                      <Spinner animation="border" size="lg" className="me-2" />
                    </td>
                  </tr>
                  : props.applicationList.length > 0
                    ? props.applicationList.map((item, i) => {

                      return (
                        <>
                          <Accordion.Item key={i} eventKey={`${i}`}
                            onClick={() => handleAccordionHeaderClick(item)}>
                            <Accordion.Header className={`accordion-header accordion-header-primary`}>
                              <div className="d-flex justify-content-between" style={{ width: "90%" }}>
                                <div>
                                  <i className="fa fa-user"></i> &nbsp;
                                  <span className="accordion-header-text">{item.candidate_first_name} {item.candidate_last_name}</span>
                                </div>
                                <div>
                                  <Badge pill bg={getBadgeColor(item.interview_status_name)} className='badge-sm text-capitalize'> {item.interview_status_name}</Badge>
                                </div>

                              </div>
                            </Accordion.Header>
                            <Accordion.Collapse eventKey={`${i}`} >
                              <div className="accordion-body">
                                <div className="card-header d-flex justify-content-between">
                                  <h4 className="card-title">
                                    <i class="fas fa-file-alt font-18 align-middle me-2 text-primary"></i>
                                    &nbsp;{item.candidate_first_name} {item.candidate_last_name}
                                  </h4>
                                  <div>

                                    {item.interview_status_name === "Candidate Selected" &&
                                      <CustomTooltip id={`convert-${item.index}`} message="Covert to Draft Employee">
                                        <Button
                                          className="me-2 btn-sm"
                                          variant="primary"
                                          disabled={item.is_convert_to_employee}
                                          style={{ cursor: "pointer" }}
                                          onClick={() => swalConfirm()}
                                        >
                                          <i class="fa fa-user-circle"></i> Covert to Draft Employee
                                        </Button>
                                      </CustomTooltip>
                                    }

                                    {isValidPermission('employee.change_employeebankdetail', props.getPermissionList) &&
                                      <CustomTooltip id={`edit-${item.index}`} message="Edit">
                                        <Button
                                          className="shadow btn-xs sharp me-1"
                                          variant='primary light'
                                          style={{ cursor: "pointer" }}
                                          disabled={item.interview_status_name === "Candidate Selected" || item.interview_status_name === "Candidate Rejected"}
                                          onClick={() => handleEdit(item.id)}>
                                          <i className="fas fa-pencil-alt"></i>
                                        </Button>
                                      </CustomTooltip>
                                    }
                                    &nbsp;
                                    <CustomTooltip id={`delete-${item.index}`} message="Delete">
                                      <Button
                                        className="shadow btn-xs sharp me-1"
                                        variant="danger light"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => deleteConfirmation(item.id, `Application - ${item.candidate_first_name}`, deleteApplicationsInfo, refetch)}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    </CustomTooltip>&nbsp;
                                    <CustomTooltip id={`edit-${item.index}`} message="Change Interview Status">
                                      <Button
                                        className="shadow btn-xs sharp me-1"
                                        variant='primary light'
                                        style={{ cursor: "pointer" }}
                                        disabled={item.interview_status_name === "Candidate Selected" || item.interview_status_name === "Candidate Rejected"}
                                        onClick={() => handleButton(item.id)}>
                                        <i className="fa fa-ellipsis-h"></i>
                                      </Button>
                                    </CustomTooltip>

                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="form-validation">
                                    <div className="row">
                                      <div className="col-xl-12">
                                        <div className="form-group mb-3 row">
                                          {/* <label className="col-lg-3 col-form-label">
                                          Candidate Name:
                                        </label>
                                        <label className="col-lg-9 col-form-label">
                                          {item.candidate_first_name} {item.candidate_last_name}
                                        </label> */}
                                          <label className="col-lg-3 col-form-label">
                                            Email:
                                          </label>
                                          <label className="col-lg-9 col-form-label">
                                            {item.email_address}
                                          </label>
                                          <label className="col-lg-3 col-form-label">
                                            Interview Status :
                                          </label>
                                          <label className="col-lg-9 col-form-label">
                                            <Badge pill bg={getBadgeColor(item.interview_status_name)} className='badge-sm text-capitalize'> {item.interview_status_name}</Badge>
                                          </label>
                                          {item.resume ?
                                            <label className="col-lg-3 col-form-label">
                                              Resume :
                                            </label> : null}
                                          <label className="col-lg-9 col-form-label">
                                            {item.resume ? <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleFileDownload(item.resume)}><i className="fa fa-download"></i></Button> : null}
                                          </label>
                                        </div>

                                        <h4 className='card-title'>Interview Process</h4>
                                        <div
                                          style={{ height: '370px' }}
                                          id='DZ_W_TimeLine'
                                          className='widget-timeline dz-scroll height370'
                                        >

                                          <ul className='timeline'>
                                            {loadingInterViewStatusById ? (
                                              <li>
                                                <div className='timeline-badge primary'></div>
                                                <Link
                                                  className='timeline-panel text-muted'
                                                  to='#'
                                                  style={{ textAlign: 'center' }}
                                                >
                                                  <Spinner animation="border" size="lg" className="me-2" />
                                                </Link>
                                              </li>
                                            ) : timeLine.length > 0 ? (
                                              timeLine.map((data, i) => (
                                                <li key={i}>
                                                  <div className='timeline-badge primary'></div>
                                                  <div className='timeline-panel text-muted'>
                                                    <div className="d-flex justify-content-between">
                                                      <div>
                                                        <h6 className='mb-0'>
                                                          <b>Interviewer Name:</b> {data?.interviewer_name_text}
                                                        </h6>
                                                      </div>
                                                      <div> <Badge pill bg={getBadgeColor(data?.interview_status_name)} className='badge-sm text-capitalize'>{data?.interview_status_name}</Badge></div>
                                                    </div>
                                                    {data?.interview_notes &&
                                                      <div>
                                                        <span className="mt-2"> <b>Notes:</b> {data?.interview_notes}</span>
                                                      </div>
                                                    }
                                                    <span className="mt-3"><b>Date:</b>{data?.interviewer_date} &nbsp;&nbsp;{data?.location ? <b>Location: {data?.location}</b> : null} </span>

                                                  </div>

                                                </li>
                                              ))
                                            ) : (
                                              // Add your fallback content here if there are no timeline entries
                                              <li style={{ textAlign: 'center' }}>No Interview Status found</li>
                                            )}
                                          </ul>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </Accordion.Collapse>
                          </Accordion.Item>

                        </>
                      );
                    })
                    : <tr>
                      <td style={{ textAlign: 'center' }} colSpan={5}>No record found</td>
                    </tr>}

              </Accordion>
            </Card.Body>
          </Card>
        </Col>

      </div>
      <ModalComponent
        basicModal={basicModal}
        setBasicModal={setBasicModal}
        applicationId={applicationId}
        refetchInterViewStatusById={refetchInterViewStatusById}
        refetch={refetch}
        interviewStatusId={interviewStatusId}

      />
    </div>

  )
}

function ModalComponent(props) {

  const formRef = useRef(null);

  const [interViewData, setInterviewData] = useState({});

  const [statusId, setStatusId] = useState('')

  const {
    mutate: postInterViewStatusFn,
    isLoading: loadingPostInterViewStatusFn,
  } = useInterViewStatusPostFn();

  const {
    mutate: patchApplicationFn,
    isLoading: loadingApplicationFn,
  } = useApplicationUpdateFn();

  const { data: getEmployeeInfo, refetch: employeeInfoRefetch } =
    useEmployeeInfo();


  const SelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;

    setInterviewData({
      ...interViewData,
      [selectName]: selectValue,
    });
  };

  const SelectStatusChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectName = e.target.name;
    const selectedOption = e.target.options[selectedIndex];

    const selectValue = selectedOption.value;
    const selectId = selectedOption.getAttribute("data-id");

    setStatusId(selectId);

    setInterviewData({
      ...interViewData,
      [selectName]: selectValue,
      [`${selectName}_id`]: selectId,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInterviewData({
      ...interViewData,
      [name]: value,
    });
  };

  const submitForm = () => {
    let FormValidity = formRef.current.reportValidity();

    if (FormValidity === true) {
      postApplicationHandeler();
    }
  }

  const updateApplicationHandeler = () => {

    let userData = {
      interview_status: statusId,
    }

    let id = props.applicationId

    patchApplicationFn(
      { id, userData },
      {
        onSuccess: (response) => {
          props.refetch();
        },
      }
    );
  }


  const postApplicationHandeler = () => {

    let userData = {
      job_applicantion: props.applicationId,
      interview_status_id: interViewData.interview_status_id,
      interview_status_name: interViewData.interview_status_name,
      interview_notes: interViewData.interview_notes,
      interviewer_name: interViewData.interviewer_name,
      interviewer_date: interViewData.interviewer_date,
      location: interViewData.location,
    }

    postInterViewStatusFn(
      userData,
      {
        onSuccess: (response) => {
          toast.success('Application Updated', TostifyConfig);
          props.refetchInterViewStatusById()
          updateApplicationHandeler()
          // client.invalidateQueries(['get-application-details-by-jobid']);
          props.setBasicModal(false)
          props.refetch();
          setInterviewData({})
        },
      }
    );
  }



  return (
    <Modal className="fade bd-example-modal-lg" size="lg" show={props.basicModal} onHide={props.setBasicModal}>
      <Modal.Header>
        <Modal.Title>Interview Status</Modal.Title>
        <Button
          variant=""
          className="btn-close"
          onClick={() => props.setBasicModal(false)}
        >
        </Button>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef}>
          <Form.Group className='mb-3'>
            <Row>
              <Col className='col-md-6 mb-2'>
                <label>Interview Status<span className="required">*</span></label>
                <Form.Select
                  name="interview_status_name"
                  className="mb-4 form-control"
                  value={interViewData.interview_status_name}
                  onChange={SelectStatusChangeHandler}
                >
                  <option value="">Select Status</option>
                  {INTERVIEW_STATUS?.map((option) => (
                    <option
                      key={option.id}
                      value={option.name}
                      data-id={option.id}
                      hidden={option.id <= props.interviewStatusId}
                    >
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="col-md-6 mb-2">
                <label>Interviewer <span className="text-danger">*</span></label>
                <Form.Select
                  name="interviewer_name"
                  className="form-control"
                  value={interViewData.interviewer_name}
                  onChange={SelectChangeHandler}
                  required
                >
                  <option value="">Select Interviewer</option>
                  {getEmployeeInfo?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.first_name} {option.last_name} {option.employee_number ? ` (${option.employee_number})` : ''}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className='mb-3'>
            <Row>
              <Col className='col-md-6 mb-2'>
                <label>Interview Date<span className="required">*</span></label>
                <Form.Control
                  type="date"
                  name="interviewer_date"
                  onChange={handleInputChange}
                  value={interViewData.interviewer_date}
                  required />
              </Col>

              <Col className='col-md-6 mb-2'>
                <label>Location<span className="required">*</span></label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  name="location"
                  onChange={handleInputChange}
                  value={interViewData.location}
                  required
                />
              </Col>

            </Row>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Row>
              <Col className='col-md-12 mb-2'>
                <label>Interview Notes<span className="required">*</span></label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="mb-3"
                  name="interview_notes"
                  onChange={handleInputChange}
                  value={interViewData.interview_notes}
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className='mt-2'>
            <Row>
              <Col>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    variant="danger light btn-sm"
                    onClick={() => {
                      props.setBasicModal(false)
                    }}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="primary btn-sm"
                    onClick={submitForm}
                    disabled={loadingPostInterViewStatusFn}
                  >
                    {(loadingPostInterViewStatusFn) ? (
                      <>
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-2"
                        />
                        Loading...
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form.Group>
        </form>

      </Modal.Body>
    </Modal>
  )
}
export default JobApplication;