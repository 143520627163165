import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const createNewAnnouncement = async (data) => {
  let config = {
    method: "post",
    url: "announcement/api/v1/announcement/",
    headers: {
      "Content-Type": "application/json",
    },
    data: data
  };

  return await axios.request(config);
};
export const useCreateAnnouncement = () => {
  return useMutation(["announcement-register"], createNewAnnouncement);
};


const getAllAnnouncement = async () => {
  let config = {
    method: "get",
    url: "announcement/api/v1/announcement/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetAnnouncementList = () => {
  return useQuery(["get-all-announcement"], getAllAnnouncement);
};


const getAnnouncementDetail = async (query) => {
  let [, id] = query.queryKey;
  let config = {
    method: "get",
    url: `announcement/api/v1/announcement/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetAnnouncemntDetail = (id) => {
  return useQuery(["get-announcement-by-id", id], getAnnouncementDetail, { enabled: id ? true : false });
};

const announcementUpdate = async (data) => {
  let { id, announcementData } = data;
  let config = {
    method: "patch",
    url: `announcement/api/v1/announcement/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: announcementData,
  };
  return await axios.request(config);
};
export const useAnnouncementUpdate = () => {
  return useMutation(["announcement-update"], announcementUpdate);
};

// AnnouncementTopic
const createAnnouncementTopic = async (data) => {
  let config = {
    method: "post",
    url: "announcement/api/v1/announcement_topic/",
    headers: {
      "Content-Type": "application/json",
    },
    data: data
  };

  return await axios.request(config)
};
export const useCreateAnnouncementTopic = () => {
  return useMutation(["announcement-topic-register"], createAnnouncementTopic);
}

const announcementTopicUpdate = async (data) => {
  let { id, userData } = data;
  let config = {
    method: "patch",
    url: `announcement/api/v1/announcement_topic/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: userData,
  };
  return await axios.request(config);
};
export const useAnnouncementTopicUpdate = () => {
  return useMutation(["announcement-topic-update"], announcementTopicUpdate);
};

const getAllAnnouncementTopics = async () => {
  let config = {
    method: "get",
    url: "announcement/api/v1/announcement_topic/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetAnnouncementTopicsList = () => {
  return useQuery(["get-all-announcement-topic"], getAllAnnouncementTopics);
};

const getAnnouncementTopicDetail = async (query) => {
  let [, id] = query.queryKey;
  let config = {
    method: "get",
    url: `announcement/api/v1/announcement_topic/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetAnnouncementTopicDetail = (id) => {
  return useQuery(["get-announcementtopic-by-id", id], getAnnouncementTopicDetail, { enabled: id ? true : false });
};