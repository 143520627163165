import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// COS ACTIONS

const employeePostCosFn = async (userData) => {
    let config = {
        method: "post",
        url: `/employee/api/v1/employee_cos/`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: userData,
    };

    return await axios.create().request(config);
};
export const useEmployeePostCosFn = () => {
    return useMutation(["employee-cos-post"], employeePostCosFn);
};


const employeeCosUpdateFn = async (employeeData) => {
    let { id, userData } = employeeData;
    let config = {
        method: "patch",
        url: `/employee/api/v1/employee_cos/${id}/`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: userData,
    };
    return await axios.request(config);
};
export const useEmployeeCosUpdateFn = () => {
    return useMutation(["employee-cos-update"], employeeCosUpdateFn);
};

const getEmployeeCosByIDFn = async (query) => {
    let [, id] = query.queryKey;
    let config = {
        method: "get",
        url: `/employee/api/v1/employee_cos/${id}/`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetEmployeeCosByID = (id) => {
    return useQuery(["get-employee-rtw-by-id", id], getEmployeeCosByIDFn, {enabled: false});
};

const getEmployeeCosByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/employee/api/v1/employee_cos/?employee__employee_uuid=${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetEmployeeCosByUUID = (employee_uuid) => {
    return useQuery(["get-employee-cos-by-uuid", employee_uuid], getEmployeeCosByUUIDFn, { enabled: !!employee_uuid });
};


// RTW ACTIONS
const employeePostRtwFn = async (userData) => {
    let config = {
        method: "post",
        url: `/employee/api/v1/employee_rtw_reference_check/`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: userData,
    };

    return await axios.create().request(config);
};
export const useEmployeePostRtwFn = () => {
    return useMutation(["employee-rtw-post"], employeePostRtwFn);
};


const employeeRtwUpdateFn = async (employeeData) => {
    let { id, userData } = employeeData;
    let config = {
        method: "patch",
        url: `/employee/api/v1/employee_rtw_reference_check/${id}/`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: userData,
    };
    return await axios.request(config);
};
export const useEmployeeRtwUpdateFn = () => {
    return useMutation(["employee-rtw-update"], employeeRtwUpdateFn);
};

const getEmployeeRtwByIDFn = async (query) => {
    let [, id] = query.queryKey;
    let config = {
        method: "get",
        url: `/employee/api/v1/employee_rtw_reference_check/${id}/`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetEmployeeRtwByID = (id) => {
    return useQuery(["get-employee-rtw-by-id", id], getEmployeeRtwByIDFn, { enabled: false });
};

const getEmployeeRtwByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/employee/api/v1/employee_rtw_reference_check/?employee__employee_uuid=${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetEmployeeRtwByUUID = (employee_uuid) => {
    return useQuery(["get-employee-rtw-by-uuid", employee_uuid], getEmployeeRtwByUUIDFn, { enabled: !!employee_uuid });
};

//Employee RWT

const employeeRtwFn = async (userData) => {
    let config = {
        method: "post",
        url: `/employee/api/v1/employee_rtw_share_code_check/`,
        headers: {
            "Content-Type": "application/json",
        },
        data: userData,
    };

    return await axios.create().request(config);
};
export const useEmployeeRtwPostFn = () => {
    return useMutation(["rtw-post-fn"], employeeRtwFn);
};