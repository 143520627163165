export const MenuList = (isAdminUser, employeeUUID, isRecruitmentEnabled) => {

  let recruitmentTab = null;

  if (isAdminUser && isRecruitmentEnabled) {
    recruitmentTab = {
      title: "Recuritment",
      classsChange: "mm-collapse",
      iconStyle: <i className="fa fa-handshake"></i>,
      perms: "user_management.view_menu_admin_employee_list",
      content: [
        {
          parentTitle: "Recuritment",
          title: "Recruitment List",
          to: isRecruitmentEnabled ? `recuritments-list` : "",
          perms: "user_management.view_menu_admin_employee_list",
        },
        {
          parentTitle: "Recuritment",
          title: "Draft Employees",
          to: "draftemployee-list",
          perms: "user_management.view_menu_admin_employee_list",
        }
      ]
    };
  }

  // Later in your code, make sure to check if recruitmentTab is not null before accessing its properties
  if (recruitmentTab !== null) {
    console.log(recruitmentTab.classsChange); // Accessing classsChange property
  }
  console.log('isRecruitmentEnabled', isRecruitmentEnabled)

  return [
    //Dashboard For Admin
    {
      title: "Dashboard",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-desktop"></i>,
      to: "dashboard",
      perms: "user_management.view_menu_admin_dashboard",
    },



    // //Dashboard for Employee
    // {
    //   title: "Dashboard",
    //   classsChange: "mm-collapse",
    //   iconStyle: <i className="flaticon-381-networking"></i>,
    //   to: "dashboard",
    //   perms: "user_management.view_menu_employee_dashboard",
    // },

    //Recuritment menu for Admin
    // {
    //   title: "Recuritment",
    //   classsChange: "mm-collapse",
    //   iconStyle: <i class="fa fa-handshake"></i>,
    //   perms: "user_management.view_menu_admin_employee_list",
    //   content: [
    //     {
    //       parentTitle: "Recuritment",
    //       title: "Recuritment List",
    //       to: isAdminUser && isRecruitmentEnabled ? "recuritments-list" : "",
    //       perms: "user_management.view_menu_admin_employee_list",
    //     },
    //     {
    //       parentTitle: "Recuritment",
    //       title: "Draft Employees",
    //       to: "draftemployee-list",
    //       perms: "user_management.view_menu_admin_employee_list",
    //     },
    //   ],
    // },


    // {
    //   title: "Recuritment",
    //   classsChange: "mm-collapse",
    //   iconStyle: <i className="fa fa-handshake"></i>,
    //   perms: "user_management.view_menu_admin_employee_list",
    //   content: isAdminUser && isRecruitmentEnabled ? [
    //     {
    //       parentTitle: "Recuritment",
    //       title: "Recruitment List",
    //       to: "recuritments-list",
    //       perms: "user_management.view_menu_admin_employee_list",
    //     },
    //     {
    //       parentTitle: "Recuritment",
    //       title: "Draft Employees",
    //       to: "draftemployee-list",
    //       perms: "user_management.view_menu_admin_employee_list",
    //     }
    //   ] : []
    // },

    recruitmentTab,


    //Employee menu for Admin
    {
      title: "Employee",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-user"></i>,
      perms: "user_management.view_menu_admin_employee_list",
      content: [
        {
          parentTitle: "Employee",
          title: "Employee List",
          to: "list-employee",
          perms: "user_management.view_menu_admin_employee_list",
        },
        {
          parentTitle: "Employee",
          title: "Expiry Report",
          classsChange: "mm-collapse",
          to: "expiry-dashboard",
          perms: "user_management.view_menu_admin_dashboard",
        },
      ],
    },

    //Employee menu for Employee
    // {
    //   title: "Employee",
    //   classsChange: "mm-collapse",
    //   iconStyle: <i className="flaticon-381-television"></i>,
    //   perms: "user_management.view_menu_employee_employee_list",
    //   content: [
    //     {
    //       title: "Employee View",
    //       to: isAdminUser ? "" : `employee-profile-view/${employeeUUID}`,
    //       perms: "user_management.view_menu_employee_employee_list",
    //     },
    //   ],
    // },

    //Dashboard for Employee
    {
      title: "Employee Profile",
      classsChange: "mm-collapse",
      to: isAdminUser ? "" : `employee-profile-view/${employeeUUID}`,
      iconStyle: <i class="fa fa-user"></i>,
      perms: "user_management.view_menu_employee_employee_list",
    },

    //Timesheets For Admin
    {
      title: "Timesheet",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-calendar"></i>,
      perms: "user_management.view_menu_admin_add_timesheet",
      content: [
        {
          parentTitle: "Timesheet",
          title: "Add Timesheet",
          to: "timesheet-view",
          perms: "user_management.view_menu_admin_add_timesheet",
        },
        {
          parentTitle: "Timesheet",
          title: "Monthly Report",
          to: "timesheet-listview",
          perms: "user_management.view_menu_admin_timesheet_report",
        },
        {
          parentTitle: "Timesheet",
          title: "Timesheet Summary",
          to: "timesheet-summary",
          perms: "user_management.view_menu_admin_timesheet_report",
        },
      ],
    },

    //Timesheets for Employee
    {
      title: "Timesheet",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-calendar"></i>,
      perms: "user_management.view_menu_employee_add_timesheet",
      content: [
        {
          parentTitle: "Timesheet",
          title: "Add Timesheet",
          to: "timesheet-view",
          perms: "user_management.view_menu_employee_add_timesheet",
        },
        {
          parentTitle: "Timesheet",
          title: "Monthly Report",
          to: "timesheet-listview",
          perms: "user_management.view_menu_employee_timesheet_report",
        },
      ],
    },

    //Announcements For Admin
    {
      title: "Announcements",
      classsChange: "mm-collapse",
      iconStyle: <i className="fa fa-bullhorn"></i>,
      perms: "user_management.view_menu_admin_announcements",
      content: [
        {
          parentTitle: "Announcements",
          title: "Announcements",
          to: "list-announcements/",
          perms: "user_management.view_menu_admin_announcements",
        },
        {
          parentTitle: "Announcements",
          title: "Announcements Topics",
          to: "list-announcementsTopic/",
          perms: "user_management.view_menu_admin_announcements_topics",
        },
      ],
    },

    //Announcements for Employee
    {
      title: "Announcements",
      classsChange: "mm-collapse",
      iconStyle: <i className="fa fa-bullhorn"></i>,
      perms: "user_management.view_menu_employee_announcements",
      content: [
        {
          parentTitle: "Announcements",
          title: "Announcements",
          to: "list-announcements/",
          perms: "user_management.view_menu_employee_announcements",
        },
      ],
    },

    //Payslips For Admin
    {
      title: "Payslips",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-file" ></i>,
      perms: "user_management.view_menu_admin_payslips",
      content: [
        {
          parentTitle: "Payslips",
          title: "Payslips",
          to: "list-payslips",
          perms: "user_management.view_menu_admin_payslips",
        },
      ],
    },

    //Payslips for Employee
    {
      title: "Payslips",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-file"></i>,
      perms: "user_management.view_menu_employee_payslips",
      content: [
        {
          parentTitle: "Payslips",
          title: "Payslips",
          to: "list-payslips",
          perms: "user_management.view_menu_employee_payslips",
        },
      ],
    },

    //Taks For Admin
    {
      title: "Task",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-tasks"></i>,
      perms: "user_management.view_menu_admin_task",
      content: [
        {
          parentTitle: "Task",
          title: "Task",
          to: "list-task/",
          perms: "user_management.view_menu_admin_task",
        },
        // {
        //   title: "Task Priority",
        //   to: "list-Priority",
        //   perms: "user_management.view_menu_admin_task",
        // },
      ],
    },

    //Taks For Employee
    {
      title: "Task",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-tasks"></i>,
      perms: "user_management.view_menu_employee_task",
      content: [
        {
          parentTitle: "Task",
          title: "Task",
          to: "list-task",
          perms: "user_management.view_menu_employee_task",
        },
      ],
    },

    //Leaves For Admin
    {
      title: "Leaves",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-envelope"></i>,
      perms: "user_management.view_menu_admin_task",
      content: [
        {
          parentTitle: "Leaves",
          title: "Apply Leave",
          to: "admin-add-leave",
          perms: "user_management.view_menu_admin_leaves",
        },
        {
          parentTitle: "Leaves",
          title: "Leave List",
          to: "admin-leave-list",
          perms: "user_management.view_menu_admin_leaves",
        },
        {
          parentTitle: "Leaves",
          title: "Leave Balance",
          to: "list-leave-balance",
          perms: "user_management.view_menu_admin_leaves",
        },
        {
          parentTitle: "Leaves",
          title: "Leave Policy",
          to: "admin-leave-policy",
          perms: "user_management.view_menu_admin_leaves",
        },
      ],
    },

    //Leaves For Employee
    {
      title: "Leaves",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-envelope"></i>,
      perms: "user_management.view_menu_employee_leaves",
      content: [
        {
          parentTitle: "Leaves",
          title: "Apply Leave",
          to: "add-leave",
          perms: "user_management.view_menu_employee_leaves",
        },
        {
          parentTitle: "Leaves",
          title: "Leave List",
          to: "leave-list/",
          perms: "user_management.view_menu_employee_leaves",
        }
      ],
    },

    //Forms
    {
      title: "Settings",
      classsChange: "mm-collapse",
      iconStyle: <i class="fa fa-cogs"></i>,
      perms: "user_management.view_menu_admin_company",
      content: [
        {
          parentTitle: "Settings",
          title: "Company",
          to: "list-company",
          perms: "user_management.view_menu_admin_company",
        },
        // {
        //   parentTitle: "Settings",
        //   title: "Client",
        //   to: "list-client",
        //   perms: "user_management.view_menu_admin_client",
        // },
        {
          parentTitle: "Settings",
          title: "Project/Work/Branch",
          to: "list-project",
          perms: "user_management.view_menu_admin_project",
        },
        // {
        //   parentTitle: "Settings",
        //   title: "Team",
        //   to: "list-team",
        //   perms: "user_management.view_menu_admin_team",
        // },
        {
          parentTitle: "Settings",
          title: 'Work Location',
          to: 'list-worklocation',
          perms: "user_management.view_menu_worklocation",
        },
        {
          parentTitle: "Settings",
          title: 'Position',
          to: 'list-positions',
          perms: "user_management.view_menu_position",
        },
        {
          parentTitle: "Settings",
          title: 'Country',
          to: 'list-country',
          perms: "user_management.view_menu_country",
        },
        {
          parentTitle: "Settings",
          title: 'Nationality',
          to: 'list-nationality',
          perms: "user_management.view_menu_nationality",
        },
        {
          parentTitle: "Settings",
          title: 'Visa Type',
          to: 'list-visatype',
          perms: "user_management.view_menu_visatype",
        }
      ],
    },

    // Membership
    {
      title: "Membership",
      classsChange: "mm-collapse",
      iconStyle: <i className="fa fa-address-card"></i>,
      perms: "user_management.view_menu_admin_subscription",
      content: [
        {
          parentTitle: "Membership",
          title: "Subscription",
          to: "Subscription/",
          perms: "user_management.view_menu_admin_subscription",
        },
      ],
    },
  ];
};
