import React, { useState, useEffect } from 'react';
import { Badge, Col, Dropdown } from 'react-bootstrap';
import { JOB_STATUS } from '../../../utils/Constance';
import { useJobUpdateFn } from '../../../actions/recruitment-actions';

const SummaryBox = (props) => {

  const [selectedStatus, setSelectedStatus] = useState(null);

  let jobId = props?.jobDetails?.id;

  const { mutate: patchEmployeeFn, isLoading: updateJobPostLoading } =
    useJobUpdateFn();


  // Find the corresponding status object from JOB_STATUS array based on job_status received as props
  useEffect(() => {
    const status = JOB_STATUS.find(status => status.id === props?.jobDetails?.job_status);
    setSelectedStatus(status);
  }, [props?.jobDetails?.job_status]);


  const updateJobHandler = (id, name) => {

    setSelectedStatus({ id, name });

    let jobDetailData = { job_status: id };

    patchEmployeeFn(
      { jobId, jobDetailData },
      {
        onSuccess: () => {
          // toast.success("Job Vacancy Updated", TostifyConfig);
          // refetchJobByID();
          // props.setCardView({ jobVacancy: true });
        },
      }
    );

  };

  return (
    <div className="row bg-white rounded py-3 px-md-3 px-0 mb-3">
      <div className="col-md-7">
        <div class="text-left">
          <div className="mb-5 mt-2">
            <div style={{ fontSize: '32px', fontWeight: 700 }}>
              {props?.jobDetails?.position_name?.toUpperCase()}
              &nbsp; (<span style={{ fontSize: '32px', fontWeight: 700 }}>{props?.jobDetails?.job_id}</span>)
            </div>
            <br />

            <div className='mb-2' style={{ fontSize: '20px', fontWeight: 600 }}>Job Description:
            </div>
            <div style={{ textAlign: "justify", padding: "0 44px 0 18px" }}>
              {props?.jobDetails?.job_description}
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-5 mt-2">
        <div class="row">
          <div>
            <label className="col-lg-6 col-form-label">
              Job Status :
            </label>
            <label className="col-lg-6 col-form-label">
              <Col xl={3}>
                <Dropdown>
                  <Dropdown.Toggle
                    variant='primary'
                    size='sm'
                    className='mt-1 mb-2'
                  >
                    {selectedStatus ? selectedStatus.name : ''}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {JOB_STATUS.map((item) => (
                      <Dropdown.Item key={item.id} onClick={() => updateJobHandler(item.id, item.name)}>{item.name}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </label>
          </div>
          <div>
            <label className="col-lg-6 col-form-label">
              No of Vacancies:
            </label>
            <label className="col-lg-6 col-form-label">
              {props.jobDetails?.no_of_vacancies}
            </label>
          </div>
          <div className='d-flex'>
            <label className="col-lg-6 col-form-label">
              Skills Required:
            </label>
            <span className='d-flex'>
              {props?.skills?.map((tag, index) => (
                <Badge key={index} pill variant="primary" className="me-2 mb-3 d-block">
                  <span>
                    {tag}
                  </span>
                </Badge>
              ))}
            </span>
          </div>
          <div>
            <label className="col-lg-6 col-form-label">
              Salary Range:
            </label>
            <label className="col-lg-6 col-form-label">
              £{props.jobDetails?.salary_range_min} - £{props.jobDetails?.salary_range_max}
            </label>
          </div>
          <div>
            <label className="col-lg-6 col-form-label">
              Job Type:
            </label>
            <label className="col-lg-6 col-form-label">
              {props.jobDetails?.job_type_name}
            </label>
          </div>
          <div>
            <label className="col-lg-6 col-form-label">
              Employment Type:
            </label>
            <label className="col-lg-6 col-form-label">
              {props.jobDetails?.employment_type_name}
            </label>
          </div>
          <div>
            <label className="col-lg-6 col-form-label">
              No of Applications:
            </label>
            <label className="col-lg-6 col-form-label">
              {props?.applicationList?.length}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryBox;
