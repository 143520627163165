import React, { useContext, useState, useEffect, useMemo, useRef } from "react";
import { ServiceContext } from "../../../context/ServiceContext";
import { Link } from "react-router-dom";
/// Scroll
//import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from './Logout';
/// Image
import profile from "../../../images/profile/17.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown, Badge, Spinner } from "react-bootstrap";
import Notifications from "./Notifications";
import { useGetSearchResults } from "../../../actions/search-action";
import debounce from 'lodash.debounce';
import {
   useGetEmployeeInfoByUUID,
   useGetEmployeeInfoDetailByUUID,
} from "../../../actions/employee-actions";
import { useGetEmployeeVisaByUUID } from "../../../actions/employee-visa-actions";
import { useGetEmployeePassPortByUUID } from "../../../actions/employee-passport-actions";
import { useGetEmployeeBankByUUID } from "../../../actions/employee-bank-actions";
import { useGetEmployeeCertificationByUUID } from "../../../actions/employee-certification-action";
import { useGetEmergencyContactByUUID } from "../../../actions/employee-actions";
import { useGetEmployeeEducationByUUID } from "../../../actions/employee-education-action";
import { useGetEmployeeDocumentByUUID } from "../../../actions/document-action";
import { useGetEmployeeCosByUUID, useGetEmployeeRtwByUUID } from "../../../actions/cos_rtw-actions";

const Header = ({ onNote, toggle, onProfile, onNotification }) => {

   const { isAdminUser, setCompanyList, getEmployeeFullName } = useContext(ServiceContext);

   const [searchQuery, setSearchQuery] = useState('');
   const [searchData, setSearchData] = useState([]);
   const [showClearIcon, setShowClearIcon] = useState(false); // To track whether to show the clear icon
   const [isLoading, setLoading] = useState(false);
   const [employeeUUID, setEmployeeUUID] = useState('');

   const {
      refetch: refetchEmergencyContactByUUID,
   } = useGetEmergencyContactByUUID(employeeUUID);

   const {
      refetch: refetchEmployeeDetailByUUID
   } = useGetEmployeeInfoDetailByUUID(employeeUUID);

   // const {
   //    refetch: refetchEmployeeEmployeeVisaFn
   // } = useGetEmployeeVisaByUUID(employeeUUID);

   // const {
   //    refetch: refetchEmployeeEmployeePassPortFn
   // } = useGetEmployeePassPortByUUID(employeeUUID);

   // const {
   //    refetch: refetchEmployeeEmployeeBankFn
   // } = useGetEmployeeBankByUUID(employeeUUID);

   // const {
   //    refetch: refetchDocuments
   // } = useGetEmployeeDocumentByUUID(employeeUUID);

   // const {
   //    refetch: refetchEmployeeCosFn
   // } = useGetEmployeeCosByUUID(employeeUUID);

   // const {
   //    refetch: refetchEmployeeRtwFn
   // } = useGetEmployeeRtwByUUID(employeeUUID);

   // const { refetch: refetchEmployeeInfoByUUID } =
   //    useGetEmployeeInfoByUUID(employeeUUID);

   const {
      data: getSearchResults,
      isLoading: loadingSearchResults,
      refetch: refetchSearchResults,
   } = useGetSearchResults(searchQuery);

   // Update search data when search results change
   useEffect(() => {
      if (getSearchResults) {
         setLoading(false);
         setSearchData(getSearchResults);
      }
   }, [getSearchResults]);

   const handleInputChange = (e) => {
      const inputValue = e.target.value;
      setSearchQuery(inputValue);
      setShowClearIcon(inputValue.trim() !== '');

      // Show loader while fetching search results
      setLoading(true);

      // Only trigger API call if the input value is not empty
      if (inputValue.trim() !== '') {
         debouncedResults(inputValue);
      } else {
         // Clear search data if input value is empty
         setSearchData([]);
      }
   };

   const debouncedResults = useMemo(() => {
      setLoading(true);
      return debounce(handleInputChange, 300);
   }, []);

   useEffect(() => {
      return () => {
         debouncedResults.cancel();
      };
   });

   const clearSearchField = () => {
      setSearchQuery('');
      setSearchData([]);
      setShowClearIcon(false); // Hide the clear icon
   };

   const clearDropdown = () => {
      setSearchQuery('');
      setSearchData([]);
      setShowClearIcon(false); // Hide the clear icon
   };

   // Define a mapping object for categories to routes
   const categoryToRoute = {
      "Work Profile": "profile",
      "Personal Info": "personalInfo",
      "Emergency Contact": "emergencyContact",
      "Passport": "passport",
      "Documents": "documents",
      "Bank": "bank",
      "Visa": "visa",
      "RightToWork": "rtw",
      "COS": "cos",
      "Position": "profile",
      "Project": "profile",
      // Add more categories and their corresponding routes as needed
   };

   const dropdownRef = useRef(null);

   useEffect(() => {
      document.addEventListener("click", handleClickOutside);
      return () => {
         document.removeEventListener("click", handleClickOutside);
      };
   }, []);

   const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
         // Click occurred outside the dropdown
         clearSearchField();
      }
   };

   var path = window.location.pathname.split("/");
   var name = path.length >= 3 ? path[path.length - 2].split("-") : path[path.length - 1].split("-");
   var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
   var finalName = filterName.includes("app")
      ? filterName.filter((f) => f !== "app")
      : filterName.includes("ui")
         ? filterName.filter((f) => f !== "ui")
         : filterName.includes("uc")
            ? filterName.filter((f) => f !== "uc")
            : filterName.includes("basic")
               ? filterName.filter((f) => f !== "basic")
               : filterName.includes("form")
                  ? filterName.filter((f) => f !== "form")
                  : filterName.includes("table")
                     ? filterName.filter((f) => f !== "table")
                     : filterName.includes("page")
                        ? filterName.filter((f) => f !== "page")
                        : filterName.includes("email")
                           ? filterName.filter((f) => f !== "email")
                           : filterName.includes("ecom")
                              ? filterName.filter((f) => f !== "ecom")
                              : filterName.includes("chart")
                                 ? filterName.filter((f) => f !== "chart")
                                 : filterName.includes("editor")
                                    ? filterName.filter((f) => f !== "editor")
                                    : filterName;
   return (
      <div className="header">
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div
                        className="dashboard_bar"
                        style={{ textTransform: "capitalize" }}
                     >
                        {finalName.join(" ")}
                     </div>
                  </div>

                  <ul className="navbar-nav header-right">
                     <li className="nav-item">
                        <div className="input-group search-area d-lg-inline-flex d-none">
                           <input
                              type="text"
                              className="form-control"
                              placeholder="Search here..."
                              onChange={debouncedResults}
                           />
                           <div className="input-group-append">
                              {showClearIcon ? (
                                 <span className="input-group-text" onClick={clearSearchField} style={{ cursor: 'pointer', height: "42px" }}>
                                    <i class="fa fa-times" ></i> {/* Use your cancel icon here */}
                                 </span>
                              ) : (
                                 <span className="input-group-text">
                                    <i className="flaticon-381-search-2"></i>
                                 </span>
                              )}
                           </div>
                           {searchQuery.trim() !== '' && ( // Render dropdown only if search query is not empty
                              <div className="dropdown-menu show widget-media dz-scroll p-3 height380 notification " Style="z-index-20; margin-top:50px; overflow:scroll; min-height:150px;" ref={dropdownRef}>
                                 {isLoading ? (
                                    <ul className="timeline" Style="width:250px;">
                                       <li className="text-center">
                                          <label style={{ fontSize: "14px", fontWeight: "bold" }}>Looking for results...</label>
                                       </li>
                                    </ul>
                                 ) : searchData.length > 0 ? (
                                    // Render search results if available
                                    <>
                                       {searchData.map((item, index) => (
                                          <ul className="timeline" key={index}>
                                             <li>
                                                {/* Conditionally render links based on the category */}
                                                {categoryToRoute[item.category] && (
                                                   <a href={`/employee-profile-view/${item?.employee_uuid}?${categoryToRoute[item.category]}=true`}
                                                      className="dropdown-item"
                                                      onClick={clearDropdown}
                                                      style={{ cursor: 'pointer' }}>
                                                      <div>
                                                         <div>
                                                            <label style={{ fontSize: "14px", fontWeight: "bold" }}>
                                                               Match Found:&nbsp;&nbsp;
                                                               <Badge pill bg="success" className='badge-xs text-capitalize'>{item?.result}</Badge>
                                                            </label>&nbsp;
                                                            <label>
                                                               <Badge pill bg="light" className='badge-xs text-capitalize'>{item?.category}</Badge>
                                                            </label>&nbsp;
                                                            <label>
                                                               <Badge pill bg="info" className='badge-xs text-capitalize'>{item?.employee_fullname}</Badge>
                                                            </label>
                                                         </div>
                                                      </div>
                                                   </a>
                                                )}
                                             </li>
                                             <hr />
                                          </ul>
                                       ))}
                                    </>
                                 ) : (
                                    // Render "Match not found" message if no results
                                    <ul className="timeline" Style="width:300px; height:100px;">
                                       <li className="text-center">
                                          <label style={{ fontSize: "14px", fontWeight: "bold" }}>Match not found</label>
                                       </li>
                                    </ul>
                                 )}
                              </div>
                           )}
                        </div>
                     </li>
                     {/* <li className="nav-item dropdown notification_dropdown">
                        <Link to={"#"}
                           className="nav-link bell bell-link"
                           onClick={() => onNote()}
                        >
                           <svg
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M22.5678 26.5202C22.8079 26.5202 23.0447 26.6115 23.2249 26.7856C24.3769 27.8979 26.0572 28.2683 27.551 27.8047C26.5897 25.802 26.4564 23.5075 27.2014 21.383C28.126 18.7398 28.3577 16.0905 27.3055 13.4334C26.381 11.0992 24.5971 9.15994 22.3395 8.05408C22.4784 8.79455 22.5484 9.54903 22.5484 10.3115C22.5484 13.5478 21.304 16.5916 19.0444 18.8823C16.7846 21.1733 13.7553 22.4615 10.5147 22.5097C9.91267 22.5191 9.31331 22.4837 8.72073 22.4056C10.5017 25.5274 13.8606 27.5606 17.5516 27.6153C19.1663 27.6403 20.7166 27.302 22.1604 26.6125C22.2904 26.5503 22.4296 26.5202 22.5678 26.5202Z"
                                 fill="#3E4954"
                              />
                              <path
                                 d="M10.541 0.00236249C4.79223 -0.111786 0.0134501 4.53885 -0.000411333 10.2863C-0.00380737 11.6906 0.270302 13.052 0.814361 14.3331C0.822262 14.3517 0.829608 14.3706 0.836262 14.3897C1.58124 16.5142 1.4481 18.8086 0.486678 20.8114C1.98059 21.2748 3.66073 20.9046 4.81275 19.7922C5.09656 19.518 5.5212 19.449 5.8773 19.6192C7.3209 20.3087 8.87143 20.648 10.486 20.6221C16.1898 20.5374 20.6576 16.0085 20.6576 10.3117C20.6576 4.73921 16.1193 0.114501 10.541 0.00236249ZM4.81898 11.8517C3.99305 11.8517 3.32348 11.1832 3.32348 10.3587C3.32348 9.53414 3.99305 8.86568 4.81898 8.86568C5.64492 8.86568 6.31449 9.53414 6.31449 10.3587C6.31442 11.1832 5.64492 11.8517 4.81898 11.8517ZM10.3286 11.8517C9.50268 11.8517 8.8331 11.1832 8.8331 10.3587C8.8331 9.53414 9.50268 8.86568 10.3286 8.86568C11.1545 8.86568 11.8241 9.53414 11.8241 10.3587C11.8241 11.1832 11.1545 11.8517 10.3286 11.8517ZM15.8383 11.8517C15.0124 11.8517 14.3428 11.1832 14.3428 10.3587C14.3428 9.53414 15.0124 8.86568 15.8383 8.86568C16.6642 8.86568 17.3338 9.53414 17.3338 10.3587C17.3338 11.1832 16.6642 11.8517 15.8383 11.8517Z"
                                 fill="#3E4954"
                              />
                           </svg>
                           <span className="badge light text-white bg-primary rounded-circle">
                              18
                           </span>
                        </Link>
                     </li> */}

                     <Notifications />

                     <Dropdown as="li" className={`nav-item header-profile `} Style="cursor:pointer; padding-left : 1px;">
                        <Dropdown.Toggle className="nav-link i-false" as="a" >
                           {/* <img src={profile} width="20" alt="" /> */}
                           <div className="header-info">
                              <span className="fs-14">{getEmployeeFullName}</span>
                              {isAdminUser === true && <p className="fs-12 mb-0 text-center">Admin</p>}
                              {isAdminUser === false && <p className="fs-12 mb-0 text-center">Employee</p>}
                           </div>
                           <i class="fa fa-caret-down mx-2" aria-hidden="true"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={`dropdown-menu-right`}>
                           <Link
                              to="/app-profile"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-user1"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-primary"
                                 width="18"
                                 height="18"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                 <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                              <span className="ms-2">Profile </span>
                           </Link>
                           {isAdminUser === true &&
                              <Link
                                 to="/users-list"
                                 className="dropdown-item ai-icon"
                              >
                                 <i style={{ transform: 'scale(1.5)' }} className='text-primary la la-users' />
                                 <span className="ms-2">Users </span>
                              </Link>
                           }
                           {/* <Link
                              to="/email-inbox"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-inbox"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-success"
                                 width="18"
                                 height="18"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                 <polyline points="22,6 12,13 2,6"></polyline>
                              </svg>
                              <span className="ms-2">Inbox </span>
                           </Link> */}
                           <LogoutPage />
                        </Dropdown.Menu>
                     </Dropdown>
                  </ul>
               </div>
            </nav>
         </div>
      </div>
   );
};

export default Header;
