import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { ServiceContext } from "../../context/ServiceContext";

function DashboardRoute() {
    const { isAdminUser, getEmployeeUUID } = useContext(ServiceContext);
    const navigate = useNavigate(); // Get the navigate function

    useEffect(() => {
        if (isAdminUser === true) {
            // Redirect to the admin dashboard
            navigate("/dashboard");
        } 
        if (isAdminUser === false) {
            // Redirect to the employee profile using getEmployeeUUID
            navigate(`/employee-profile-view/${getEmployeeUUID}`);
        }
    }, [isAdminUser, getEmployeeUUID, navigate]);

    return null; // You can return null, as you don't need to render anything in this component
}

export default DashboardRoute;
