import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// API GET Method Using UUID

const getAddressLogByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/change_logs/address_logs/${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetAddressLogByUUID = (employee_uuid) => {
    return useQuery(["get-employee-addresslog-by-uuid", employee_uuid], getAddressLogByUUIDFn, { enabled: !!employee_uuid });
};

//PhoneNumber
const getPhoneNumberLogByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/change_logs/primary_phone_change_logs/${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetPhoneNumberLogByUUID = (employee_uuid) => {
    return useQuery(["get-employee-phonelog-by-uuid", employee_uuid], getPhoneNumberLogByUUIDFn, { enabled: !!employee_uuid });
};

//Email
const getEmailLogByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/change_logs/primary_email_change_logs/${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetEmailLogByUUID = (employee_uuid) => {
    return useQuery(["get-employee-email-log-by-uuid", employee_uuid], getEmailLogByUUIDFn, { enabled: !!employee_uuid });
};

//  WorkPhone

const getWorkPhoneLogByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/change_logs/work_phone_change_logs/${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetWorkPhoneLogByUUID = (employee_uuid) => {
    return useQuery(["get-employee-workphone-log-by-uuid", employee_uuid], getWorkPhoneLogByUUIDFn, { enabled: !!employee_uuid });
};

//Bank 

const getBankLogByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/change_logs/bank_account_change_logs/${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetBankLogByUUID = (employee_uuid) => {
    return useQuery(["get-employee-bank-log-by-uuid", employee_uuid], getBankLogByUUIDFn, { enabled: !!employee_uuid });
};


// EmergencyEmail

const getEmergencyEmailLogByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/change_logs/emergency_email_change_logs/${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetEmergencyEmailLogByUUID = (employee_uuid) => {
    return useQuery(["get-emergency-email-log-by-uuid", employee_uuid], getEmergencyEmailLogByUUIDFn, { enabled: !!employee_uuid });
};

// EmergencyAddress

const getEmergencyAddressLogByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/change_logs/emergency_contact_address_logs/${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetEmergencyAddressLogByUUID = (employee_uuid) => {
    return useQuery(["get-emergency-address-log-by-uuid", employee_uuid], getEmergencyAddressLogByUUIDFn, { enabled: !!employee_uuid });
};

// EmergencyPhone

const getEmergencyPhoneLogByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {  
        method: "get",
        url: `/change_logs/emergency_phone_change_logs/${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetEmergencyPhoneLogByUUID = (employee_uuid) => {
    return useQuery(["get-emergency-phone-log-by-uuid", employee_uuid], getEmergencyPhoneLogByUUIDFn, { enabled: !!employee_uuid });
};


