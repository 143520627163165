import React, { Fragment, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import { useEmployeePostCosFn, useEmployeeCosUpdateFn, useGetEmployeeCosByID } from '../../../actions/cos_rtw-actions'
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import { COS_STATUS_CHOICES } from '../../../utils/Constance'
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { extractFileName } from '../../../utils/ComponentUtilse';
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import DatePicker from 'react-datepicker';
import LoadingOverlay from 'react-loading-overlay';


function EmployeeCos(props) {

    const client = useQueryClient();

    const location = useLocation()

    let id = props?.cosId;

    const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const [employeeCosData, setEmployeeCosData] = useState({});

    const [currentAttachmentData, setCurrentAttachmentData] = useState(null);

    const [selectedDate, setSelectedDate] = useState(null);

    const [parserDate, setParserDate] = useState(null);

    const {
        data: getEmployeeCosByID,
        isLoading: loadingGetEmployeeCosByID,
        refetch: refetchEmployeeCosByID,
    } = useGetEmployeeCosByID(id);

    const {
        mutate: postEmployeePostCosFn,
        isLoading: loadingEmployeePostCosFn,
    } = useEmployeePostCosFn();

    const {
        mutate: patchEmployeeCosUpdateFn,
        isLoading: loadingEmployeeCosUpdateFn,
    } = useEmployeeCosUpdateFn();


    const submitForm = () => {
        let FormValidity = FormRef.current.reportValidity();

        if (FormValidity === true) {
            if (id) {
                updateEmployeeCosHandeler();
            }
            else {
                postEmployeeCosHandeler();
            }
        }
    }

    const postEmployeeCosHandeler = () => {

        let userData = {
            sponsor_licence_number: employeeCosData.sponsor_licence_number,
            sponsor_name: employeeCosData.sponsor_name,
            certificate_number: employeeCosData.certificate_number,
            certificate_date: employeeCosData.certificate_date,
            assigned_date: employeeCosData.assigned_date,
            expiry_date: employeeCosData.expiry_date,
            sponsor_note: employeeCosData.sponsor_note,
            certificate_status: employeeCosData.certificate_status,
            employee_employee_uuid: employee_uuid,
            attachment: employeeCosData.attachment
        }

        postEmployeePostCosFn(
            userData,
            {
                onSuccess: (response) => {
                    toast.success('Employee COS-Data Added', TostifyConfig);
                    client.invalidateQueries(['get-employee-cos-by-uuid']);
                    client.invalidateQueries(['get-pending-action-by-uuid']);
                    props.setCardView({ cos: true });
                },
            }
        );
    }

    const updateEmployeeCosHandeler = () => {

        let userData = {
            sponsor_licence_number: employeeCosData.sponsor_licence_number,
            sponsor_name: employeeCosData.sponsor_name,
            certificate_number: employeeCosData.certificate_number,
            certificate_date: employeeCosData.certificate_date,
            assigned_date: employeeCosData.assigned_date,
            expiry_date: employeeCosData.expiry_date,
            sponsor_note: employeeCosData.sponsor_note,
            certificate_status: employeeCosData.certificate_status,
            employee_employee_uuid: employee_uuid
        }

        if (employeeCosData?.attachment?.name) {
            userData['attachment'] = employeeCosData.attachment
        }

        patchEmployeeCosUpdateFn(
            { id, userData },
            {
                onSuccess: (response) => {
                    //navigate('list-education')
                    toast.success('Employee COS-Data Updated', TostifyConfig);
                    // history.push(`/employee/employee_education_list/${employee_uuid}`);
                    client.invalidateQueries(['get-employee-cos-by-uuid']);
                    props.setCardView({ cos: true });
                },
            }
        );
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (event.target.type === "file") {
            setEmployeeCosData({
                ...employeeCosData,
                [name]: event.target.files[0], // Capture the selected file object
            });
            setCurrentAttachmentData(event.target.files[0].name);
        } else {
            setEmployeeCosData({
                ...employeeCosData,
                [name]: value,
            });
        }
    };

    const StatusChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        const selectedOptionLabel = e.target.options[selectedIndex].label;
        setEmployeeCosData({
            ...employeeCosData,
            [selectName]: selectValue,
        });
    };


    useEffect(() => {
        if (id) {
            refetchEmployeeCosByID();
        }
    }, [refetchEmployeeCosByID, id])


    useEffect(() => {
        if (getEmployeeCosByID) {
            setEmployeeCosData(getEmployeeCosByID);
            setCurrentAttachmentData(extractFileName(getEmployeeCosByID.attachment));
        }
    }, [getEmployeeCosByID])


    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>Employee Cos</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingGetEmployeeCosByID && id}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Sponsor Licence Number<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="sponsor_licence_number"
                                                    onChange={handleInputChange}
                                                    value={employeeCosData.sponsor_licence_number}
                                                    required
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">Sponsor Name<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="sponsor_name"
                                                    onChange={handleInputChange}
                                                    value={employeeCosData.sponsor_name}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>COS Number<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="certificate_number"
                                                    onChange={handleInputChange}
                                                    value={employeeCosData.certificate_number}
                                                    required
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>COS Status Date</label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Type Text"
                                                    name="certificate_date"
                                                    onChange={handleInputChange}
                                                    value={employeeCosData.certificate_date}

                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>COS Assigned Date<span className="required">*</span></label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Type Text"
                                                    name="assigned_date"
                                                    onChange={handleInputChange}
                                                    value={employeeCosData.assigned_date}
                                                    required
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>COS Expiry Date<span className="required">*</span></label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Type Text"
                                                    name="expiry_date"
                                                    onChange={handleInputChange}
                                                    value={employeeCosData.expiry_date}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Sponsor Note</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="sponsor_note"
                                                    onChange={handleInputChange}
                                                    value={employeeCosData.sponsor_note}
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>COS Status<span className="required">*</span></label>
                                                <Form.Select
                                                    name="certificate_status"
                                                    className="form-control"
                                                    value={employeeCosData.certificate_status}
                                                    onChange={StatusChangeHandler}
                                                    required
                                                >
                                                    <option value="">Status</option>
                                                    {COS_STATUS_CHOICES?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Attachment</label>
                                                <Form.Control
                                                    type="file"
                                                    placeholder="Type Text"
                                                    name="attachment"
                                                    onChange={handleInputChange}
                                                />
                                                <Row>
                                                    <label className='mt-3'>Selected File</label>
                                                    <label>{currentAttachmentData}</label>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mt-2'>
                                        <Row>
                                            <Col>
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            props.setCardView({ cos: true });
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="primary btn-sm"
                                                        onClick={submitForm}
                                                        disabled={loadingGetEmployeeCosByID && id || loadingEmployeePostCosFn || loadingEmployeeCosUpdateFn}
                                                    >
                                                        {(loadingEmployeePostCosFn || loadingEmployeeCosUpdateFn) ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    className="me-2"
                                                                />
                                                                Loading...
                                                            </>
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment >
    )
}

export default EmployeeCos