import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const createNewWorkLocation = async (data) => {
    let config = {
      method: "post",
      url: "employee/api/v1/employee_work_location/",
      headers: {
        "Content-Type": "application/json",
      },
      data:data
    };
    
    return await axios.create().request(config)
  };
  export const useCreateWorkLocation = () => {
    return useMutation(["announcement-register"], createNewWorkLocation);
  };

  
const getAllWorkLocation = async () => {
  let config = {
    method: "get",
    url: "employee/api/v1/employee_work_location/",
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const response = await axios.request(config);
  return response?.data;
};
export const useGetWorkLocationList = () => {
  return useQuery(["get-all-worklocation"], getAllWorkLocation);
};


const getWorkLocationDetail = async (query) => {
  let [, id] = query.queryKey;
  let config = {
    method: "get",
    url: `employee/api/v1/employee_work_location/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const response = await axios.request(config);
  return response?.data;
};
export const useGetWorkLocationDetail = (id) => {
  return useQuery(["get-worklocation-by-id", id], getWorkLocationDetail,{enabled: id?true:false});
};

const worklocationUpdate = async (data) => {
  let {id, worklocation} = data;
  let config = {
    method: "patch",
    url: `employee/api/v1/employee_work_location/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: worklocation,
  };
  return await axios.request(config);
};
export const useWorkLocationUpdate = () => {
  return useMutation(["worklocation-update"], worklocationUpdate);
};

//API DELETE METHOD

const workLocationDeleteById = async (id) => {
  try {
    const response = await axios.delete(`/employee/api/v1/employee_work_location/${id}/`);
    return response.data;
  } catch (error) {
    // Handle error or throw it again
    throw error;
  }
};

export const useWorkLocationDeleteById = () => {
  return useMutation(["work-location-delete"], workLocationDeleteById);
};

