import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";


//API DELETE METHOD
const payslipDeleteById = async (id) => {
  try {
      const response = await axios.delete(`/payslips/api/v1/payslip/${id}/`);
      return response.data;
  } catch (error) {
      // Handle error or throw it again
      throw error;
  }
};

export const usePayslipDeleteById = () => {
  return useMutation(["payslip-delete"], payslipDeleteById);
};


const createNewPayslips = async (team) => {
    let config = {
      method: "post",
      url: "payslips/api/v1/payslip/",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data:team
    };
    
    return await axios.create().request(config)
  };
  export const useCreatePayslips = () => {
    return useMutation(["payslips-register"], createNewPayslips);
  };

  const getAllPayslips = async () => {
    let config = {
      method: "get",
      url: "/payslips/api/v1/payslip/",
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const usePayslipsList = () => {
    return useQuery(["get-all-payslips"], getAllPayslips);
  };

  const getPayslipDetail = async (query) => {
    let [, id] = query.queryKey;
    let config = {
      method: "get",
      url: `payslips/api/v1/payslip/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetPayslipDetail = (id) => {
    return useQuery(["get-payslip-by-id", id], getPayslipDetail,{enabled: id?true:false});
  };

  const payslipUpdate = async (data) => {
    let {id, payslipdata} = data;
    let config = {
      method: "patch",
      url: `payslips/api/v1/payslip/${id}/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: payslipdata,
    };
    return await axios.request(config);
  };
  export const usePayslipUpdate = () => {
    return useMutation(["payslip-update"], payslipUpdate);
  };
  