import React, { Fragment } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useRef } from "react";
import { useLocation } from "react-router-dom/dist";
import {
  useWorkLocationUpdate,
  useCreateWorkLocation,
  useGetWorkLocationDetail,
} from "../../actions/worklocation-action";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from "../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";
import LoadingOverlay from 'react-loading-overlay';


function AddWorkLocation() {
  const navigate = useNavigate();

  const FormRef = useRef(null);

  const location = useLocation();
  let id = location?.state?.rowId;

  const [worklocation, setWorkLocation] = useState({
    location_name: "",
    location_code: "",
    description: "",
  });

  const handleChange = (e) => {
    setWorkLocation((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const { data: worklocationDetail, isLoading: loadingWorkLocationFn } = useGetWorkLocationDetail(
    location?.state?.rowId
  );

  const {
    mutate: postNewWorkLocation,
    isLoading: loadingpostNewWorkLocationFn,
  } = useCreateWorkLocation();
  const {
    mutate: patchWoklocation,
    isLoading: loadingpatchWorkLocationInfoFn,
  } = useWorkLocationUpdate();

  useEffect(() => {
    if (location?.state?.rowId) {
      setWorkLocation(worklocationDetail);
    }
  }, [worklocationDetail]);

  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();
    if (FormValidity === true && location?.state?.rowId) {
      try {
        patchWoklocation(
          { id, worklocation },
          {
            onSuccess: (response) => {
              toast.success("Work Location  Updated", TostifyConfig);
              navigate("/list-worklocation/");
            },
          }
        );
      } catch (error) {
        console.error(error);
      }
    } else if (FormValidity === true) {
      try {
        postNewWorkLocation(worklocation, {
          onSuccess: (response) => {
            toast.success("Work Location  Added", TostifyConfig);
            navigate("/list-worklocation/");
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Fragment>
      <PageTitle activeMenu="Settings" motherMenu="Home" />
      <div>
        <div className="col-xl-12 col-xxl-12">
          <Card className="az-card">
            <Card.Header>
              <h3>{id ? "Edit Work Location" : "New Work Location"}</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingWorkLocationFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            ></LoadingOverlay>
            <Card.Body>
              <form ref={FormRef}>
                <Form.Group className="mb-3">
                  <Row>
                    <Col className="col-lg-6 mb-2">
                      <label className="text-label">
                        Location Name<span className="required">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        placeholder="Type Text"
                        name="location_name"
                        value={worklocation?.location_name}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </Col>
                    <Col className="col-lg-6 mb-2">
                      <label className="text-label">
                        Location Code
                      </label>
                      <Form.Control
                        type="text"
                        placeholder="Type Text"
                        name="location_code"
                        value={worklocation?.location_code}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col className="col-md-6 mb-2">
                      <label className="text-label">
                        Description
                      </label>
                      <Form.Control
                        Style="height:70px"
                        as="textarea"
                        rows={5}
                        placeholder="Type Text"
                        name="description"
                        value={worklocation?.description}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mt-2">
                  <Row>
                    <Col className="col-md-12">
                      <hr />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="danger light btn-sm"
                          onClick={() => {
                            navigate("/list-worklocation/");
                          }}
                        >
                          Cancel
                        </Button>
                        &nbsp;&nbsp;
                        <Button variant="primary btn-sm" disabled={loadingWorkLocationFn && id || loadingpostNewWorkLocationFn || loadingpatchWorkLocationInfoFn } onClick={handleSubmit}>
                          {loadingpostNewWorkLocationFn ||
                            loadingpatchWorkLocationInfoFn ? (
                            <>
                              <Spinner
                                animation="border"
                                size="sm"
                                className="me-2"
                              />
                              Loading...
                            </>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              </form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default AddWorkLocation;
