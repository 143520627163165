import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import ThemeContext from "./context/ThemeContext";
import ServiceContext from "./context/ServiceContext";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import '../src/config/axios.config'


const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter basename='/'>
          <ThemeContext>
            <ServiceContext>
              <App />
            </ServiceContext>
          </ThemeContext>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);
reportWebVitals();