import React, { useMemo, useEffect, useState, useContext } from "react";
import PageTitle from "../layouts/PageTitle";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { GlobalFilter } from "../components/table/FilteringTable/GlobalFilter";
import "../components/table/FilteringTable/filtering.css";
import { ColumnFilter } from "../components/table/FilteringTable/ColumnFilter";
import { Link } from "react-router-dom";
import { Button, Alert, Modal, Badge } from "react-bootstrap";
import { dateHandler } from "../../utils/ComponentUtilse";
import { IsValidPermissions } from "../../services/AuthService";
import DataTable from "../pages/commonPages/DataTable";
import { ServiceContext } from "../../context/ServiceContext";
import { useGetLeavesByUUID, useLeaveUpdateFn } from "../../actions/leave-actions";
import LeaveSummary from "./LeaveSummary";
import LoadingOverlay from 'react-loading-overlay';
import { LEAVE_APPROVED_STATUS_COLOR } from "../../utils/Constance";
import CustomTooltip from "../../utils/CommonUtils";


export const ListLeaves = () => {
  const { getEmployeeUUID } = useContext(ServiceContext);

  const [basicModal, setBasicModal] = useState(false);

  const [modalData, setModalData] = useState({});

  const [data, setData] = useState([]);

  const { data: getAllLeaves, isLoading: loadingGetLeavesFn, refetch: refetchLeaves } =
    useGetLeavesByUUID(getEmployeeUUID);

  const {
    mutate: patchLeaveFn,
    isLoading: loadingLeaveFn,
  } = useLeaveUpdateFn();

  const updateLeaveBalanceHandeler = (id, status_id) => {

    let userData = {
      approved_status: status_id,
    }

    patchLeaveFn(
      { id, userData },
      {
        onSuccess: (response) => {
          setBasicModal(false)
          refetchLeaves()
        },
      }
    );
  }


  useEffect(() => {
    if (getAllLeaves) {
      setData(getAllLeaves);
    }
  }, [getAllLeaves]);

  const getStatusColor = (status) => {
    const statusObj = LEAVE_APPROVED_STATUS_COLOR.find(item => item.status === status);
    return statusObj ? statusObj.color : 'default'; // default color if status is not found
  };


  const columns = useMemo(
    () => [
      {
        Header: "Type",
        Footer: "Type",
        accessor: "policy_name_name",
        Filter: ColumnFilter,
      },
      {
        Header: "Duration",
        Footer: "Duration",
        accessor: "start_date",
        Cell: ({ row }) => {
          const { original } = row;
          return <span><i class="fa fa-calendar" aria-hidden="true"></i>&nbsp; {dateHandler(original.start_date)}&nbsp;<i class="fa fa-minus" aria-hidden="true"></i>&nbsp;{dateHandler(original.end_date)}</span>;
        },
        Filter: ColumnFilter,
        className: 'width280'
      },
      {
        Header: "Days",
        Footer: "Days",
        accessor: "number_of_days",
        Filter: ColumnFilter,
        className: 'width20'
      },
      {
        Header: "Reason",
        Footer: "Reason",
        accessor: "reason",
        Filter: ColumnFilter,
      },
      {
        Header: "Status",
        Footer: "Status",
        accessor: "approved_status_name",
        Filter: ColumnFilter,
        Cell: ({ row }) => { return <Badge pill bg={getStatusColor(row?.original?.approved_status_name)} className='badge-sm text-capitalize'> {row?.original?.approved_status_name}</Badge> }
      },
      {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => (
          <CustomTooltip id={`edit-${row.index}`} message="Edit">
            <Button disabled={row.original.approved_status === 5} className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleModal(row.original)}>
              <i className="fa fa-ban"></i>
            </Button>
          </CustomTooltip>
        )
      },
    ],
    []
  );

  const handleModal = (data) => {
    setBasicModal(true)
    setModalData(data)
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <>
      {IsValidPermissions("leave_management.can_view_employee_leave_policy") && (
        <>
          <LeaveSummary employeeUUID={getEmployeeUUID} />
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Leave List</h4>
            </div>
            <div className="card-body">
              <DataTable
                isLoading={loadingGetLeavesFn}
                columns={columns}
                tableInstance={tableInstance}
              />
            </div>
            <Modal className="fade" show={basicModal} onHide={setBasicModal}>
              <Modal.Header>
                <Modal.Title>Leave Request</Modal.Title>
                <Button
                  variant=""
                  className="btn-close"
                  onClick={() => setBasicModal(false)}
                >


                </Button>
              </Modal.Header>
              <LoadingOverlay
                active={loadingLeaveFn}
                spinner
                text='Updating...'
                className="custom-overlay"
              >
                <Modal.Body>
                  <div className="card-body">
                    <div className="form-validation">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group mb-3 row">
                            <label className="col-lg-4 col-form-label">
                              Employee:
                            </label>
                            <label className="col-lg-8 col-form-label">
                              {modalData.employee_fullname}
                            </label>
                            <label className="col-lg-4 col-form-label">
                              Type:
                            </label>
                            <label className="col-lg-8 col-form-label">
                              {modalData.policy_name_name}
                            </label>
                            <label className="col-lg-4 col-form-label">
                              Duration:
                            </label>
                            <label className="col-lg-8 col-form-label">
                              {`${modalData.start_date} - ${modalData.end_date}`}
                            </label>
                            <label className="col-lg-4 col-form-label">
                              Days:
                            </label>
                            <label className="col-lg-8 col-form-label">
                              {modalData.number_of_days}
                            </label>
                            <label className="col-lg-4 col-form-label">
                              Reason:
                            </label>
                            <label className="col-lg-8 col-form-label">
                              {modalData.reason}
                            </label>
                            <label className="col-lg-4 col-form-label">
                              Status:
                            </label>
                            <label className="col-lg-8 col-form-label">
                              <Badge pill bg={getStatusColor(modalData?.approved_status_name)} className='badge-sm text-capitalize'> {modalData.approved_status_name}</Badge>
                            </label>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    disabled={loadingLeaveFn}
                    onClick={() => updateLeaveBalanceHandeler(modalData.id, 5)}
                    variant="danger light btn-sm"
                  >
                    Cancel Request
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    disabled={loadingLeaveFn}
                    onClick={() => setBasicModal(false)}
                    variant="danger light btn-sm"
                  >
                    Close
                  </Button>

                </Modal.Footer>
              </LoadingOverlay>
            </Modal>
          </div>
        </>
      )}

      {(!loadingGetLeavesFn && !IsValidPermissions("leave_management.can_view_employee_leave_policy")) &&
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <Alert variant="warning" show={true}>

                <svg
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  stroke='currentColor'
                  strokeWidth='2'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='me-2'
                >
                  <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                  <line x1='12' y1='9' x2='12' y2='13'></line>
                  <line x1='12' y1='17' x2='12.01' y2='17'></line>
                </svg>

                <strong>Info!  </strong> You don't have permission to view Leaves.

              </Alert>
            </div>
          </div>
        </div>
      }
    </>
  );
};
export default ListLeaves;
