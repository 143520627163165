import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { GlobalFilter } from '../components/table/FilteringTable/GlobalFilter';
import '../components/table/FilteringTable/filtering.css';
import { usePayslipsList } from '../../actions/payslip-actions'
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IsValidPermissions } from '../../services/AuthService';
import { handleFileDownload } from '../../utils/ComponentUtilse';
import DataTable from '../pages/commonPages/DataTable';
import { usePayslipDeleteById } from '../../actions/payslip-actions';
import { deleteConfirmation } from '../../utils/ComponentUtilse';
import CustomTooltip from '../../utils/CommonUtils';
import { dateHandler } from '../../utils/ComponentUtilse';

export const PayslipsListView = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([])

    const { data: getAllPayslips, isLoading: loadingGetPayslipFn, refetch: refetchPayslip } = usePayslipsList()

    const { mutate: deletePayslipInfo, isLoading: loadingdeletePayslipFn } = usePayslipDeleteById();

    useEffect(() => {
        if (getAllPayslips) {
            setData(getAllPayslips);
        }
    }, [getAllPayslips]);

    const columns = useMemo(
        () => [
            {
                Header: "Employee Name",
                Footer: "Resources",
                accessor: "employee_resource",
                Filter: ColumnFilter,
            },
            {
                Header: "Start Date",
                Footer: "Start Date",
                accessor: "start_date",
                Cell: ({ row }) => {
                    const { original } = row;
                    return <span>{dateHandler(original.start_date)}</span>;
                },
                Filter: ColumnFilter,
                className: "minWidth100"
            },
            {
                Header: "End Date",
                Footer: "End Date",
                accessor: "end_date",
                Cell: ({ row }) => {
                    const { original } = row;
                    return <span>{dateHandler(original.end_date)}</span>;
                },
                Filter: ColumnFilter,
                className: "minWidth100"
            },
            {
                Header: "Total Hours",
                Footer: "Total Hours",
                accessor: "total_hours",
                Filter: ColumnFilter,
                className: "minWidth50"
            },
            {
                Header: "Number Of days",
                Footer: "Number Of days",
                accessor: "number_of_days",
                Filter: ColumnFilter,
                className: "minWidth50"
            },
            {
                Header: "Notes",
                Footer: "Notes",
                accessor: "notes",
                Filter: ColumnFilter,
                className: "minWidth160"
            },
            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                disableFilters: true,
                className: "minWidth140",
                //Cell: ({ cell, row }) => {return <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(row.original.id)}><i className="fas fa-pencil-alt"></i></Button>},
                Cell: ({ row }) => (
                    <>
                        {IsValidPermissions('payslips.delete_payslip') &&
                            <CustomTooltip id={`delete-${row.index}`} message="Delete">
                                <Button
                                    className="shadow btn-xs sharp me-1"
                                    variant="danger light"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => deleteConfirmation(row.original.id, `Payslip - ${row.original.employee_resource}`, deletePayslipInfo, refetchPayslip)}
                                >
                                    <i className="fa fa-trash"></i>
                                </Button>
                            </CustomTooltip>
                        }&nbsp;


                        {IsValidPermissions('payslips.change_payslip') &&

                            <CustomTooltip id={`edit-${row.index}`} message="Edit">

                                <Button
                                    className="shadow btn-xs sharp me-1"
                                    variant='primary light'
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleEdit(row.original.id)}
                                >
                                    <i className="fa fa-pencil-alt"></i>
                                </Button>
                            </CustomTooltip>

                        }
                        {row.original.attachment_url ? <CustomTooltip id={`download-${row.index}`} message="Download">
                            <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleFileDownload(row.original.attachment_url)}><i className="fa fa-download"></i></Button>
                        </CustomTooltip> : null}
                    </>
                )

            },
        ],
        []
    );



    const handleView = (id) => {

    }

    const handleEdit = (id) => {
        navigate('/add-payslips/', { state: { rowId: id } })
    }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const { globalFilter, pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Payslip" motherMenu="Payslip" />
            {(IsValidPermissions('payslips.view_payslip') || IsValidPermissions('payslips.can_view_employee_payslip')) &&
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Payslip</h4>
                        {IsValidPermissions('payslips.add_payslip') &&
                            <Button
                                className="me-2 btn-sm"
                                variant="primary"
                                onClick={() => {
                                    navigate("/add-payslips/");
                                }}
                            >
                                <i className="fas fa-plus"></i>&nbsp;&nbsp;Add
                            </Button>
                        }
                    </div>
                    <div className="card-body">
                        <DataTable
                            isLoading={loadingGetPayslipFn}
                            columns={columns}
                            tableInstance={tableInstance}
                        />
                    </div>
                </div>
            }


            {(!loadingGetPayslipFn && (!IsValidPermissions('payslips.view_payslip') && !IsValidPermissions('payslips.can_view_employee_payslip'))) &&
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <Alert variant="warning" show={true}>

                                <svg
                                    viewBox='0 0 24 24'
                                    width='24'
                                    height='24'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='me-2'
                                >
                                    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                    <line x1='12' y1='9' x2='12' y2='13'></line>
                                    <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                </svg>

                                <strong>Info!  </strong> You don't have permission to view Payslips.

                            </Alert>
                        </div>
                    </div>
                </div>
            }



        </>
    )

}
export default PayslipsListView;