import React, { Fragment, } from 'react'
import { Button, Col, Form, Row, Alert } from 'react-bootstrap'
import { useRef } from 'react'
import { useLocation, Link } from 'react-router-dom/dist'
import { useEmployeeRtwPostFn } from '../../../actions/cos_rtw-actions'
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import { RTW_STATUS_CHOICES } from '../../../utils/Constance'
import Spinner from "react-bootstrap/Spinner";
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import LoadingOverlay from 'react-loading-overlay';
import { Alertmsg } from '../../../utils/CommonUtils'



function EmployeeRtwSharecode(props) {

    const client = useQueryClient();

    const location = useLocation()

    let id = props?.rtwId;

    const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const [employeeRtwData, setEmployeeRtwData] = useState({});

    const [startDate, setStartDate] = useState("");

    const {
        mutate: postEmployeePostRtwFn,
        isLoading: loadingEmployeePostRtwFn,
    } = useEmployeeRtwPostFn();

    const submitForm = () => {
        let FormValidity = FormRef.current.reportValidity();

        if (FormValidity === true) {
            postEmployeeRtwHandeler();
        }
    }

    const postEmployeeRtwHandeler = () => {
        const formattedDate = format(employeeRtwData.emp_share_code_expiry, 'yyyy-MM-dd');
        let userData = {
            emp_share_code: employeeRtwData.emp_share_code,
            emp_share_code_expiry: formattedDate,
            employee_employee_uuid: employee_uuid
        }

        postEmployeePostRtwFn(
            userData,
            {
                onSuccess: (response) => {
                    //navigate('list-education')
                    toast.success('Employee RTW-Data Added', TostifyConfig);
                    client.invalidateQueries(['get-employee-rtw-by-uuid']);
                    // client.invalidateQueries(['get-pending-action-by-uuid']);
                    props.setCardView({ employeertw: true });
                },
            }
        );
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEmployeeRtwData({
            ...employeeRtwData,
            [name]: value,
        });
    };

    const [showAlert, setShowAlert] = useState(true);

    const closeAlert = () => {
        setShowAlert(false);
    };


    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>Employee RTW</h3>
                        </Card.Header>
                        <Card.Body>
                            {showAlert ? (
                                <Alertmsg closeAlert={closeAlert} />
                            ) : null
                            }
                            <form ref={FormRef}>
                                <Form.Group className='mb-3'>
                                    <Row>
                                        <Col className='col-lg-6 mb-2'>
                                            <label className="text-label">Share Code</label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Type Text"
                                                name="emp_share_code"
                                                onChange={handleInputChange}
                                                value={employeeRtwData.emp_share_code}
                                            />
                                        </Col>
                                        <Col className='col-md-6 mb-2'>
                                            <label className="text-label">Share Code Expiry</label><br />
                                            <DatePicker
                                                selected={startDate}
                                                onChange={date => {
                                                    setStartDate(date);
                                                    setEmployeeRtwData({
                                                        ...employeeRtwData,
                                                        emp_share_code_expiry: date
                                                    });
                                                }}
                                                minDate={new Date()}
                                                showDisabledMonthNavigation
                                                className="form-control"
                                            />
                                            {/* <Form.Control
                                                type="date"
                                                placeholder="Type Text"
                                                name="emp_share_code_expiry"
                                                onChange={handleInputChange}
                                                value={employeeRtwData.emp_share_code_expiry}
                                            /> */}
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group className='mt-2'>
                                    <Row>
                                        <Col>
                                            <hr />
                                            <div
                                                style={{ display: "flex", justifyContent: "center" }}
                                            >
                                                <Button
                                                    variant="danger light btn-sm"
                                                    onClick={() => {
                                                        props.setCardView({ employeertw: true });
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button
                                                    variant="primary btn-sm"
                                                    onClick={submitForm}
                                                    disabled={loadingEmployeePostRtwFn}
                                                >
                                                    {(loadingEmployeePostRtwFn) ? (
                                                        <>
                                                            <Spinner
                                                                animation="border"
                                                                size="sm"
                                                                className="me-2"
                                                            />
                                                            Loading...
                                                        </>
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </form>
                        </Card.Body>
                    </Card>

                </div>
            </div>
        </Fragment >
    )
}

export default EmployeeRtwSharecode;