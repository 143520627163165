import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";


// API POST Method
const leaveBalancePostFn = async (employeeData) => {
    let config = {
        method: "post",
        url: `/leave_management/api/v1/leave_balance/ `,
        headers: {
            "Content-Type": "application/json",
        },
        data: employeeData,
    };

    return await axios.request(config);
};
export const useLeaveBalancePostFn = () => {
    return useMutation(["leave-balance-post"], leaveBalancePostFn);
};

// API PATCH Method
const leaveBalanceUpdateFn = async (employeeData) => {

    let { id, userData } = employeeData;
    let config = {
        method: "patch",
        url: `/leave_management/api/v1/leave_balance/${id}/`,
        headers: {
            "Content-Type": "application/json",
        },
        data: userData,
    };

    return await axios.request(config);
};
export const useLeaveBalanceUpdateFn = () => {
    return useMutation(["leave-balance-update"], leaveBalanceUpdateFn);
};

// API GET Method Using UUID

const getLeaveBalanceByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
        method: "get",
        url: `/leave_management/api/v1/leave_balance/?employee__employee_uuid=${employee_uuid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetLeaveBalanceByUUID = (employee_uuid) => {
    return useQuery(["get-leave-balance-by-uuid", employee_uuid], getLeaveBalanceByUUIDFn, { enabled: !!employee_uuid });
};


const getAllLeaveBalanceByUUIDFn = async () => {
    let config = {
        method: "get",
        url: `/leave_management/api/v1/leave_balance/`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetAllLeaveBalanceByUUID = () => {
    return useQuery(["get-all-leave-balance",], getAllLeaveBalanceByUUIDFn);
};



// API GET Method 

const getLeaveBalanceByIDFn = async (query) => {
    let [, id] = query.queryKey;
    let config = {
        method: "get",
        url: `/leave_management/api/v1/leave_balance/${id}/`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetLeaveBalanceByID = (id) => {
    return useQuery(["get-leave-balance-by-id", id], getLeaveBalanceByIDFn, { enabled: false });
};

const getLeavePolicyFn = async () => {
    let config = {
        method: "get",
        url: "/leave_management/api/v1/leave_policy/",
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useLeavePolicy = () => {
    return useQuery(["get-leave-policy"], getLeavePolicyFn);
};

//API DELETE METHOD

const leaveBalanceDeleteById = async (id) => {
    try {
        const response = await axios.delete(`/leave_management/api/v1/leave_balance/${id}/`);
        return response.data;
    } catch (error) {
        // Handle error or throw it again
        throw error;
    }
};

export const useLeaveBalanceDeleteById = () => {
    return useMutation(["leavebalance-delete"], leaveBalanceDeleteById);
};