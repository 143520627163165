import React, { Fragment, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import { useCreateTaskPriority, usePriorityDetails, usePriorityUpdate } from '../../actions/task-action';
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";
import LoadingOverlay from 'react-loading-overlay';


const priorityOption = [
    {
        name: "low",
        id: 1
    },
    {
        name: "Medium",
        id: 2
    },
    {
        name: "High",
        id: 3
    },
]

function AddPriority() {

    const navigate = useNavigate();

    const FormRef = useRef(null);

    const location = useLocation()

    let id = location?.state?.rowId

    const { mutate: postNewPriority, isLoading: loadingpostNewPriorityFn } = useCreateTaskPriority()
    const { mutate: patchpriority, isLoading: loadingpatchpriorityFn } = usePriorityUpdate()
    const { data: priorityById, isLoading: loadingpriorityFn } = usePriorityDetails(location?.state?.rowId)
    const [priorityTask, setPriorityTask] = useState({})
    //     priority_range: 0,
    //     description: '',
    //     priority_name: ""


    const handleSubmit = () => {
        let FormValidity = FormRef.current.reportValidity();
        if (FormValidity === true && location?.state?.rowId) {
            try {
                let prioritytask = {
                    priority_range: priorityTask.priority_range,
                    description: priorityTask.description,
                    priority_name: priorityTask.priority_name,
                }
                patchpriority({ id, prioritytask }, {
                    onSuccess: (response) => {
                        toast.success("Task Priority Updated", TostifyConfig);
                        navigate("/list-Priority/")
                    }
                })
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                let prioritytask = {
                    priority_range: priorityTask.priority_range,
                    description: priorityTask.description,
                    priority_name: priorityTask.priority_name,
                }
                postNewPriority(prioritytask, {
                    onSuccess: (response) => {
                        toast.success("Task Priority Added", TostifyConfig);
                        navigate("/list-Priority/")
                    }
                })
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPriorityTask({
            ...priorityTask,
            [name]: value,
        });
    };

    const SelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        const selectedOptionLabel = e.target.options[selectedIndex].label;
        setPriorityTask({
            ...priorityTask,
            [selectName]: selectValue,
        });
    };

    useEffect(() => {
        if (location?.state?.rowId) {
            setPriorityTask(priorityById)
        }
    }, [priorityById])

    return (
        <Fragment>
            <PageTitle activeMenu="Settings" motherMenu="Home" />
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>{id ? "Edit Task Priority" : "New Priority"}</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingpriorityFn && id}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group>
                                        <Row>
                                            <Col className='col-md-6'>
                                                <label>Priority Range</label>
                                                {/* <Select options={priorityOption}
                                                value={priorityOption.filter((list) => list.value === priorityTask?.priority_range)}
                                                onChange={(e) => setPriorityTask({ ...priorityTask, priority_range: e.value })}
                                            /> */}
                                                <Form.Select
                                                    name='priority_range'
                                                    className="form-control"
                                                    value={priorityTask?.priority_range}
                                                    onChange={SelectChangeHandler}
                                                    required
                                                >
                                                    <option value="">Select Priority Range</option>
                                                    {priorityOption?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col className='col-md-6'>
                                                <label>Priority Name</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="priority_name"
                                                    value={priorityTask?.priority_name}
                                                    onChange={handleInputChange}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row>
                                            <Col className='col-md-6'>
                                                <label>Description</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="description"
                                                    value={priorityTask?.description}
                                                    onChange={handleInputChange}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mt-2'>
                                        <Row>
                                            <Col className="col-md-12">
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            navigate("/list-Priority/");
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="primary btn-sm"
                                                        onClick={handleSubmit}
                                                        disabled={loadingpriorityFn && id || loadingpostNewPriorityFn || loadingpatchpriorityFn }
                                                    >{(loadingpostNewPriorityFn || loadingpatchpriorityFn) ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" className="me-2" />
                                                            Loading...
                                                        </>
                                                    ) : (
                                                        'Submit'
                                                    )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default AddPriority