import React from "react";

const Footer = () => {
  const d = new Date();
  return (
    // <div className="footer">
    //   <div className="copyright">
    //   <p>
    //       Copyright © by{" "}
    //       <a href="http://web.ukvicas.com/" target="_blank"  rel="noreferrer">
    //       ukvicas.com
    //       </a>{" "}
    //       {d.getFullYear()}
    //     </p>
    //   </div>
    // </div>
    <></>
  );
};

export default Footer;
