import React, { useContext } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { isValidPermission } from "../../../../services/AuthService";
import { handleFileDownload } from '../../../../utils/ComponentUtilse';
import { dateHandler } from "../../../../utils/ComponentUtilse";
import CustomTooltip from "../../../../utils/CommonUtils";
import { ServiceContext } from "../../../../context/ServiceContext";

function Certification(props) {

    const { canEditEmployee } = useContext(ServiceContext);

    const handleEdit = (id) => {
        props.setCardView({ certificationEdit: true, certificateId: id })
    }

    return (
        <div className="card" id="certification">
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">
                    <i className="fa fa-certificate font-18 align-middle me-2 text-primary"></i>
                    &nbsp; Certification
                </h4>
                {canEditEmployee && isValidPermission('employee.add_employeecertification', props.getPermissionList) &&
                    <Button
                        className="me-2 btn-sm"
                        variant="primary"
                        onClick={() => {
                            props.setCardView({ certificationEdit: true });
                        }}
                    >
                        <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Add
                    </Button>
                }
            </div>
            <div className="card-body">
                <Table responsive>
                    <thead>
                        <tr>
                            <th className="minWidth200">Institution</th>
                            <th className="minWidth220">Name</th>
                            <th className="minWidth150">Issue Date</th>
                            <th className="minWidth150">Expiration Date</th>
                            <th className="minWidth220">Descripition</th>
                            {isValidPermission('employee.change_employeecertification', props.getPermissionList) &&
                                <th className="minWidth100">Action</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {props.isLoading ?
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={6}>
                                    <Spinner animation="border" size="lg" className="me-2" />
                                </td>
                            </tr>
                            : props.employeeCertificationList.length > 0
                                ? props.employeeCertificationList.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item.institution}</td>
                                            <td>{item.name}</td>
                                            <td>{dateHandler(item.issued_date)}</td>
                                            <td>{dateHandler(item.expiration_date)}</td>
                                            <td>{item.description}</td>
                                            {canEditEmployee && isValidPermission('employee.change_employeecertification', props.getPermissionList) &&
                                                <td>
                                                    <CustomTooltip id={`edit-${item.index}`} message="Edit">
                                                        <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i></Button>
                                                    </CustomTooltip>
                                                    {item.attachment_url ?
                                                        <CustomTooltip id={`download-${item.index}`} message="Download">
                                                            <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleFileDownload(item.attachment_url)}><i className="fa fa-download"></i></Button>
                                                        </CustomTooltip> : null}
                                                </td>
                                            }
                                        </tr>
                                    );
                                })
                                : <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={6}>No records found</td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </div>
        </div>

    );

}
export default Certification;