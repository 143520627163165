import React, { useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import {
    Row,
    Col,
    Button,
    Modal,
    Container,
    Form,
    Spinner,
} from "react-bootstrap";
import TagsInput from "react-tagsinput";
import { useNavigate } from "react-router-dom";
import { TostifyConfig, JOB_TYPE, EMPLOYMENT_TYPE } from "../../utils/Constance";
import { useEmployeePosition } from "../../actions/employee-actions";
import { Select2Select } from "../pages/commonPages/Select2Select";

import { useRecruitmentPostFn, useRecruitmentUpdateFn, useRecruitments } from "../../actions/recruitment-actions";

import { useEmployeeDropdown } from "../../actions/employee-actions";

import { ServiceContext } from "../../context/ServiceContext";

function RecruitmentModal() {

    const { recruitmentModal, setRecruitmentModal } = useContext(ServiceContext);

    const step1FormRef = useRef(null);

    const navigate = useNavigate();

    const { mutate: postRecruitment, isLoading: loadingRecruitment } =
        useRecruitmentPostFn();

    const { data: getRecruitments, isLoading: loadingGetRecruitments, refetch: RecruitmentsFetchFn } =
        useRecruitments();

    const { data: getEmployeeInfo, isLoading: loadingEmployeeInfo } =
        useEmployeeDropdown();

    const { data: getEmployeePosition } = useEmployeePosition();

    const [recruitmentData, setRecruitmentData] = useState({});

    const [primaryskills, setPrimarySkills] = useState([]);

    const [secondaryskills, setSecondarySkills] = useState([]);


    const SelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        // const selectedOptionLabel = e.target.options[selectedIndex].label;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;

        setRecruitmentData({
            ...recruitmentData,
            [selectName]: selectValue,
        });
    };

    const handleKeyDown = (event) => {
        // Prevent the dot from being entered
        if (event.key === '.' || event.key === 'e' || event.key === '-') {
            event.preventDefault();
        }
    };

    const handleInputChange = (e) => {
        const { type, name, value } = e.target;

        const isValidInteger = /^\d+$/.test(value) || value === '';

        if (type === 'number') {
            if (isValidInteger) {
                setRecruitmentData({
                    ...recruitmentData,
                    [name]: value,
                });
            }
        } else {
            setRecruitmentData({
                ...recruitmentData,
                [name]: value,
            });
        }
    };


    const handleFormSubmit = async () => {
        let stepFormValidity = step1FormRef.current.reportValidity();
        if (stepFormValidity === true) {
            //Perform form submission logic here
            try {
                let final_data = {
                    position: recruitmentData.position,
                    no_of_vacancies: recruitmentData.no_of_vacancies,
                    job_type: recruitmentData.job_type,
                    employment_type: recruitmentData.employment_type,
                    weekly_working_hours: recruitmentData.weekly_working_hours,
                    job_description: recruitmentData.job_description,
                    minimum_years_of_experience: recruitmentData.minimum_years_of_experience,
                    work_location: recruitmentData.work_location,
                    primary_skills: recruitmentData.primaryskills,
                    secondary_skills: recruitmentData.secondaryskills,
                    soc_code: recruitmentData.soc_code,
                    salary_range_min: recruitmentData.salary_range_min,
                    salary_range_max: recruitmentData.salary_range_max,
                    interviewer_name: recruitmentData.interviewer_name,
                };

                postRecruitment(final_data, {
                    onSuccess: (response) => {
                        RecruitmentsFetchFn();
                        setRecruitmentData({});
                        setPrimarySkills([]);
                        setSecondarySkills([]);
                        setRecruitmentModal(false);
                        toast.success("New Job Vaccancy Added!", TostifyConfig);
                        navigate("/recuritments-list/");
                    },
                });
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <>
            {/* <!-- Modal --> */}
            <Modal
                className="fade  bd-example-modal-lg"
                show={recruitmentModal}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>
                        <i class="fa fa-user-plus text-primary" aria-hidden="true"></i>
                        &nbsp;&nbsp;&nbsp;&nbsp; New Recruitment
                    </Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setRecruitmentModal(false)}
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <form ref={step1FormRef}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Group>
                                        <Col className="col-md-12">
                                            <label className="col-form-label">Job Position <span className="text-danger">*</span></label>
                                            <Select2Select
                                                name='position'
                                                className="mb-3"
                                                required
                                                selectOptions={getEmployeePosition}
                                                value={recruitmentData.position}
                                                element_id={'id'}
                                                element_name={'position_name'}
                                                getState={recruitmentData}
                                                setStatet={setRecruitmentData}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group>
                                        <Col className="col-md-14">
                                            <label className="col-form-label">No of vacancies <span className="text-danger">*</span></label>
                                            <Form.Control
                                                type="number"
                                                min="0"
                                                step="1"
                                                className="form-control mb-3"
                                                name="no_of_vacancies"
                                                value={recruitmentData.no_of_vacancies}
                                                onChange={handleInputChange}
                                                onKeyDown={handleKeyDown}
                                                required
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group>
                                        <label className="col-form-label">
                                            Salary Range Minimum(£) <span className="text-danger">*</span>
                                        </label>
                                        <Form.Control
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="form-control mb-3"
                                            name="salary_range_min"
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                            value={recruitmentData.salary_range_min}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <label className="col-form-label">
                                            Job Type <span className="text-danger">*</span>
                                        </label>
                                        <Form.Select
                                            name="job_type"
                                            className="mb-4 form-control"
                                            value={recruitmentData.job_type}
                                            onChange={SelectChangeHandler}
                                            required
                                        >
                                            <option value="">Select Job Type</option>
                                            {JOB_TYPE?.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group>
                                        <label>Employment Type <span className="text-danger">*</span></label>
                                        <Form.Select
                                            name="employment_type"
                                            className="form-control mb-4"
                                            value={recruitmentData.employment_type}
                                            onChange={SelectChangeHandler}
                                            required
                                        >
                                            <option value="">Select Employment Type</option>
                                            {EMPLOYMENT_TYPE?.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group>
                                        <label>Interviewer Name <span className="text-danger">*</span></label>
                                        <Select2Select
                                            name='interviewer_name'
                                            className="mb-3"
                                            required
                                            selectOptions={getEmployeeInfo}
                                            value={recruitmentData.interviewer_name}
                                            element_id={'id'}
                                            element_name={'first_name'}
                                            getState={recruitmentData}
                                            setStatet={setRecruitmentData}
                                        />
                                    </Form.Group>
                                </div>

                                <div className="col-md-6">

                                    <Form.Group>
                                        <label className="col-form-label">
                                            Minimum Years Of Experience <span className="text-danger">*</span>
                                        </label>
                                        <Form.Control
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="form-control mb-3"
                                            name="minimum_years_of_experience"
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                            value={recruitmentData.minimum_years_of_experience}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <label className="col-form-label">
                                            Soc Code
                                        </label>
                                        <Form.Control
                                            type="text"
                                            className="form-control mb-3"
                                            name="soc_code"
                                            onChange={handleInputChange}
                                            value={recruitmentData.soc_code}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <label className="col-form-label">
                                            Salary Range Maximum(£) <span className="text-danger">*</span>
                                        </label>
                                        <Form.Control
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="form-control mb-3"
                                            name="salary_range_max"
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                            value={recruitmentData.salary_range_max}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <label className="col-form-label">
                                            Weekly Working Hours <span className="text-danger">*</span>
                                        </label>
                                        <Form.Control
                                            type="number"
                                            min="0"
                                            step="1"
                                            className="form-control mb-3"
                                            name="weekly_working_hours"
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                            value={recruitmentData.weekly_working_hours}
                                            required
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <label className="col-form-label">
                                            Primary Skills <span className="text-danger">*</span>
                                        </label>
                                        <TagsInput
                                            className="form-control mb-3"
                                            name="primary_skills"
                                            addOnBlur={true}
                                            onlyUnique={true}
                                            value={primaryskills}
                                            onChange={(tags) => {
                                                setPrimarySkills(tags);
                                                setRecruitmentData({
                                                    ...recruitmentData,
                                                    primaryskills: tags.toString(),
                                                });
                                            }}
                                            addKeys={[",", ";"]}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        <label className="col-form-label">
                                            Secondry Skills
                                        </label>
                                        <TagsInput
                                            className="form-control mb-3"
                                            name="secondary_skills"
                                            addOnBlur={true}
                                            onlyUnique={true}
                                            value={secondaryskills}
                                            onChange={(tags) => {
                                                setSecondarySkills(tags);
                                                setRecruitmentData({
                                                    ...recruitmentData,
                                                    secondaryskills: tags.toString(),
                                                });
                                            }}
                                            addKeys={[",", ";"]}
                                        />
                                    </Form.Group>


                                </div>

                                <Form.Group>
                                    <label className="col-form-label">Job Description</label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        className="mb-3"
                                        name="job_description"
                                        onChange={handleInputChange}
                                        value={recruitmentData.job_description}
                                    />
                                </Form.Group>
                            </div>
                        </form>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger light btn-sm"
                        onClick={() => {
                            setRecruitmentData({});
                            setPrimarySkills([]);
                            setSecondarySkills([]);
                            setRecruitmentModal(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary btn-sm"
                        onClick={handleFormSubmit}
                        disabled={
                            loadingRecruitment
                        }
                    >
                        {loadingRecruitment ? (
                            <>
                                <Spinner animation="border" size="sm" className="me-2" />
                                Loading...
                            </>
                        ) : (
                            "Submit"
                        )}
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default RecruitmentModal;
