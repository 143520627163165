import Swal from 'sweetalert2';


export const extractFileName = (url) => {
    if (url) {
        const parts = url.split('/');
        return parts.pop();
    }
    return;
};

export const handleFileDownload = (url) => {
    // Create a URL object
    const urlObject = new URL(url);

    // Get the pathname from the URL
    const pathname = urlObject.pathname;

    // Split the pathname to get the file name
    const filename = pathname.split('/').pop();

    // using Java Script method to get PDF file
    fetch(url).then((response) => {
        response.blob().then((blob) => {

            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);

            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = filename;
            alink.click();
        });
    });
};

export const dateTimeHandler = (isoTimestamp) => {
    if (isoTimestamp) {
        const date = new Date(isoTimestamp);

        // Formatting options
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' };

        const formattedDate = date.toLocaleString('en-US', options);
        const formattedTime = date.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

        return `${formattedDate.replace(/\//g, '-')}`;
    }
};

export const dateHandler = (inputDate) => {
    if (inputDate) {
        // Create a new Date object with the input date string
        const date = new Date(inputDate);

        // Extract day, month, and year
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();

        // Format the date as dd-mm-yyyy
        const formattedDate = `${day}-${month}-${year}`;

        return formattedDate;
    }
};


export const deleteConfirmation = async (id, key, deleteAction, refetchQuery) => {

    Swal.fire({
        title: 'Delete!',
        text: `Are you sure you want to delete ${key} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
            try {
                deleteAction(id, {
                    onSuccess: async (response) => {
                        refetchQuery();
                        return response;
                    }
                });
            } catch (error) {
                console.error('Error:', error);
            }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire({
                title: "Deleted!",
                text: `Successfully deleted ${key}!`,
                icon: "success"
                });
        }
      });
};