import React, { useContext, useState, useEffect } from "react";
import { ServiceContext } from "../../../context/ServiceContext";
import { Link } from "react-router-dom";
import { Dropdown, Spinner, Tab, Nav, ListGroup, Col, Card, Badge } from "react-bootstrap";
import { useGetNotificationsFn, useNotificationsUpdateFn } from "../../../actions/notifications-actions";
import { useNavigate } from "react-router-dom";
import { dateTimeHandler } from "../../../utils/ComponentUtilse";


function NotificationsList(toggle) {

    const navigate = useNavigate();

    const { getEmployeeUUID, setEmployeeUUID } = useContext(ServiceContext);

    const [count, setCount] = useState('');

    const tabData = [
        {
            name: "UnRead",
            icon: "envelope",
            color: "text-primary"
        },
        {
            name: "Read",
            icon: "inbox",
            color: "text-success"
        },
    ];

    const [notificationsData, setData] = useState([]);

    const {
        data: getNotificationsFn,
        isLoading: loadingNotificationsFn,
        refetch: refetchNotificationsFn,
    } = useGetNotificationsFn(getEmployeeUUID);

    const {
        mutate: patchNotificationsFn,
        isLoading: loadingNotifications,
    } = useNotificationsUpdateFn();


    useEffect(() => {
        if (getNotificationsFn) {
            const unreadNotifications = getNotificationsFn.filter((n) => n.is_read === false);
            setData(unreadNotifications);
            setCount(unreadNotifications.length);
        }
    }, [getNotificationsFn]);


    const handleTabClick = (name) => {
        if (name === "UnRead") {
            const unreadNotifications = getNotificationsFn.filter((n) => n.is_read === false);
            setData(unreadNotifications);
            setCount(unreadNotifications.length);
        } else if (name === "Read") {
            const readNotifications = getNotificationsFn.filter((n) => n.is_read === true);
            setData(readNotifications);
        }
    };

    const handleNotificationClick = (item, id) => {
        let userData = {
            is_read: true,
        }
        patchNotificationsFn(
            { id, userData },
            {
                onSuccess: (response) => {
                    refetchNotificationsFn()
                },
            }
        );

        switch (item.code) {
            case "EmployeeCreation":
                navigate(`/employee-profile-view/${item?.notify_id}`);
                break;
            case "DocumentExpiry":
                navigate("/expiry-dashboard/");
                break;
            case "OnBoardEmployee":
                navigate(`/employee-profile-view/${item?.notify_id}`);
                break;
            case "NewAnnouncement":
                navigate("/list-announcements/");
                break;
            case "NewTask":
                navigate("/list-task/");
                break;
            case "NewPayslip":
                navigate("/list-payslips/");
                break;
            case "NewLeaveRequest":
                navigate("/admin-leave-list/");
                break;
            default:
                break;
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="default-tab">
                        <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                            <div
                                style={{ padding: "0" }}
                                className="card-header d-flex justify-content-between"
                            >
                                <Nav as="ul" className="nav-tabs">
                                    {tabData.map((data, i) => (
                                        <Nav.Item as="li" key={i}>
                                            <Nav.Link
                                                eventKey={data.name.toLowerCase()}
                                                onClick={() => handleTabClick(data.name)}
                                            >
                                                <i className={`${data.color} la la-${data.icon} me-2`} />
                                                {data.name}&nbsp;
                                                {data.name === "UnRead" && <Badge className="badge-xs" variant="primary" pill>
                                                    {count}
                                                </Badge>}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </div>
                            <Tab.Content className="pt-4">
                                {tabData.map((data, i) => (
                                    <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                        <Col xl="12">
                                            <Card>
                                                {/* <Card.Header>
                                            <Card.Title>Custom content</Card.Title>
                                        </Card.Header> */}
                                                <Card.Body>
                                                    <div className="basic-list-group">
                                                        <ListGroup>
                                                            {loadingNotificationsFn ?

                                                                <ListGroup.Item
                                                                    action
                                                                    className="flex-column align-items-start"
                                                                >
                                                                    <Spinner animation="border" size="lg" className="me-2" />
                                                                </ListGroup.Item>

                                                                : notificationsData.length > 0
                                                                    ? notificationsData.map((item, i) => {
                                                                        return (
                                                                            <ListGroup.Item
                                                                                action
                                                                                Style="cursor:pointer"
                                                                                className="align-items-start"
                                                                                onClick={() => handleNotificationClick(item, item?.id)}
                                                                            >
                                                                                <div className="d-flex  w-100" style={{ justifyContent: "space-between" }}>


                                                                                    <div>

                                                                                        <p className="mb-3">
                                                                                            <i className="fa fa-bell text-primary" style={{ fontSize: "18px" }} ></i>&nbsp;&nbsp; {item?.message}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div>{dateTimeHandler(item?.created_at)}</div>
                                                                                </div>

                                                                                {/* <small>Donec id elit non mi porta.</small> */}
                                                                            </ListGroup.Item>


                                                                        );
                                                                    })

                                                                    :
                                                                    <ListGroup.Item
                                                                        action
                                                                        className="flex-column align-items-start"
                                                                    >
                                                                        <h6 className="mb-1">
                                                                            No Notifications
                                                                        </h6>
                                                                    </ListGroup.Item>
                                                            }
                                                        </ListGroup>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>

                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>

        </>
    );
}

export default NotificationsList;