import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ServiceContext } from "../../../context/ServiceContext";
import { isValidPermission } from "../../../services/AuthService";

function JobSubMenu(props) {

  const { getPermissionList } = useContext(ServiceContext);

  return (
    <div className="card">
      <div className="mail-list">
        <Link
          to="#"
          id="menu-summary"
          className={`list-group-item profile-list ${props.cardView?.summary ? "active" : ""
            }`}
          onClick={() => {
            props.setCardView({ summary: true });
          }}
        >
          <i className="fa fa-indent font-18 align-middle me-2 text-primary"></i>
          Summary
        </Link>

        {isValidPermission('employee.view_employee', getPermissionList) &&
          <Link
            to="#"
            id="menu-work-profile"
            className={`list-group-item profile-list ${props.cardView?.jobVacancy || props.cardView?.jobVacancyEdit
              ? "active"
              : ""
              }`}
            onClick={() => {
              props.setCardView({ jobVacancy: true });
            }}
          >
            <i className="fas fa-briefcase font-18 align-middle me-2 text-primary"></i>
            Job Vacancy
          </Link>
        }

        {isValidPermission('employee.view_employeebankdetail', getPermissionList) &&
          <Link
            to="#"
            id="menu-bank"
            className={`list-group-item profile-list ${props.cardView?.advertisement || props.cardView?.advertisementEdit ? "active" : ""
              }`}
            onClick={() => {
              props.setCardView({ advertisement: true });
            }}
          >
            <i class="fas fa-bullhorn font-18 align-middle me-2 text-primary"></i>
            Advertisement
          </Link>
        }

        {isValidPermission('employee.view_employeebankdetail', getPermissionList) &&
          <Link
            to="#"
            id="menu-bank"
            className={`list-group-item profile-list ${props.cardView?.application || props.cardView?.applicationEdit ? "active" : ""
              }`}
            onClick={() => {
              props.setCardView({ application: true });
            }}
          >
            <i class="fas fa-file-alt font-18 align-middle me-2 text-primary"></i>
            Candidates
          </Link>
        }

      </div>
    </div>
  );
}

export default JobSubMenu;
