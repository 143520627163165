import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { GlobalFilter } from '../../components/table/FilteringTable/GlobalFilter';
import '../../components/table/FilteringTable/filtering.css';
import { useGetEmployeeVisaByUUID } from "../../../actions/employee-visa-actions";
import { ColumnFilter } from '../../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../pages/commonPages/DataTable';

export const ListEmployeeVisa = () => {

    const navigate = useNavigate();

    const employee_uuid = '0cc5bf30-cc61-41ef-99c7-cbb3c7a820d9';

    const [data, setData] = useState([]);

    const {
        data: getEmployeeVisaFn,
        isLoading: loadingGetEmployeeVisaFn,
    } = useGetEmployeeVisaByUUID(employee_uuid);

    useEffect(() => {
        if (getEmployeeVisaFn) {
            setData(getEmployeeVisaFn);
        }
    }, [getEmployeeVisaFn]);

    const columns = useMemo(
        () => [
            {
                Header: "Visa Number",
                Footer: "Visa Number",
                accessor: "visa_number",
                Filter: ColumnFilter,
            },
            {
                Header: "Visa Type",
                Footer: "Visa Type",
                accessor: "visa_type",
                Filter: ColumnFilter,
            },
            {
                Header: "Country Of Issue",
                Footer: "Country Of Issue",
                accessor: "country_of_issue",
                Filter: ColumnFilter,
            },
            {
                Header: "Issued Date",
                Footer: "Issued Date",
                accessor: "issue_date",
                Filter: ColumnFilter,
            },
            {
                Header: "Expiration Date",
                Footer: "Expiration Date",
                accessor: "expiration_date",
                Filter: ColumnFilter,
            },
            {
                Header: "Issuing Authority",
                Footer: "Issuing Authority",
                accessor: "issuing_authority",
                Filter: ColumnFilter,
            },
            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                disableFilters: true,
                Cell: ({ cell, row }) => { return <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(row.original.id)}><i className="fas fa-pencil-alt"></i></Button> },

            },
        ],
        []
    );



    const handleView = (id) => {

    }

    const handleEdit = (id) => {
        navigate('/add-visa/', { state: { rowId: id } })
    }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const { globalFilter, pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Filtering" motherMenu="Table" />
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Table Filtering</h4>
                </div>
                <div className="card-body">
                    <DataTable
                        isLoading={loadingGetEmployeeVisaFn}
                        columns={columns}
                        tableInstance={tableInstance}
                    />
                </div>
            </div>
        </>
    )

}
export default ListEmployeeVisa;