import React, { createContext, useEffect, useState } from "react";
import { navtoggle } from "../store/actions/AuthActions";
import { useDispatch } from 'react-redux';
export const ThemeContext = createContext();

const ThemeContextProvider = (props) => {

  const dispatch = useDispatch();

    const [background, setBackground] = useState({
      value: "light",
      label: "Light",
    });

    const body = document.querySelector("body");
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
  

    const changeBackground = (name) => {
      body.setAttribute("data-theme-version", name.value);
      setBackground(name);
    };

    useEffect(() => {
		const body = document.querySelector("body");
		let resizeWindow = () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);
			window.innerWidth >= 768 && window.innerWidth < 1300
			? body.setAttribute("data-sidebar-style", "full")
			: window.innerWidth <= 768
			? body.setAttribute("data-sidebar-style", "overlay")
			: body.setAttribute("data-sidebar-style", "full");

    (window.innerWidth >= 768 && window.innerWidth < 1300) && dispatch(navtoggle(true));
    
		};
      body.setAttribute("data-typography", "poppins");
      body.setAttribute("data-theme-version", "light");
      // body.setAttribute("data-theme-version", "dark");
      body.setAttribute("data-layout", "vertical");
      body.setAttribute("data-nav-headerbg", "color_3");
      body.setAttribute("data-headerbg", "color_1");
      body.setAttribute("data-sidebar-style", "full");
      body.setAttribute("data-sibebarbg", "color_3");
      body.setAttribute("data-primary", "color_1");
      body.setAttribute("data-sidebar-position", "fixed");
      body.setAttribute("data-header-position", "fixed");
      body.setAttribute("data-container", "wide");
      body.setAttribute("direction", "ltr");
      resizeWindow();
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    }, []);
    return (
    <ThemeContext.Provider
      value={{
        body,
          windowWidth,
          windowHeight,
          changeBackground
        }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
