import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

const loginFn = async (user_data) => {
  const config = {
    method: 'post',
    url: '/auth/token/',
    headers: {
      'Content-Type': 'application/json'
    },
    data: user_data
  };
  return axios.request(config);
};

export const useLogin = () => {
  return useMutation(['login'], loginFn);
};


const getUserTokenFn = async (token) => {
    const config = {
      method: 'post',
      url: `/auth/token/refresh/`,
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    try {
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      // Handle the error (e.g., log, display an error message)
      console.error('Error retrieving user token:', error);
      throw error;
    }
  };
  
  export const useUserToken = () => {
    return useMutation(['user-token'], getUserTokenFn);
};

  const getPermission = async () => {
    let config = {
      method: "get",
      url: "user_management/user-permissions/",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios.request(config);
    return response?.data;
  };
  export const usePermission = () => {
    return useQuery(["get-user-permission"], getPermission, {enabled: false});
};


const resetPasswordFn = async (user_data) => {
    const config = {
      method: 'post',
      url: '/user_management/password-reset/',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ''
      },
      data: user_data
    };
    return axios.create().request(config);
  };
  
  export const useResetPassword = () => {
    return useMutation(['reset-password'], resetPasswordFn);
  };


const confirmPasswordFn = async (user_data) => {
    const {password, id, token} = user_data;
    const config = {
      method: 'post',
      url: `/user_management/password-reset-confirm/${id}/${token}/`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': ''
      },
      data: {'password': password}
    };
    return axios.create().request(config);
  };
  
  export const useConfirmPassword = () => {
    return useMutation(['confirm-password'], confirmPasswordFn);
  };