import { useState, useEffect } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { dateTimeHandler } from "../../../../../utils/ComponentUtilse";
import {
    useGetBankLogByUUID
} from "../../../../../actions/changelog-actions";
import { useParams } from "react-router-dom";

function BankAccountChangeLog(props) {


    const { employee_uuid } = useParams();

    const [employeeUUID] = useState(employee_uuid);

    const [bankLog, setBankLog] = useState([]);

    const {
        data: getbankLogByUUID,
        isLoading: loadingbankLog
    } = useGetBankLogByUUID(employeeUUID);

    useEffect(() => {
        if (getbankLogByUUID) {
            setBankLog(getbankLogByUUID);
        }
    }, [getbankLogByUUID]);

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">
                    <i className="fa fa-id-badge font-18 align-middle me-2 text-primary"></i>
                    &nbsp; Bank Account Change History
                </h4>
            </div>
            <div className="card-body">
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>Account Number</th>
                            <th>Bank Name</th>
                            {/* <th>Address</th> */}
                            <th>Sort Code</th>
                            {/* <th>Primary Account</th> */}
                            <th>Date Changed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loadingbankLog ?
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={5}>
                                    <Spinner animation="border" size="lg" className="me-2" />
                                </td>
                            </tr>
                            : bankLog.length > 0
                                ?bankLog.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item.account_number}</td>
                                            <td>{item.bank_name}</td>
                                            {/* <td>{item.address}</td> */}
                                            <td>{item.sort_code}</td>
                                            {/* <td className="text-center">{item.primary_account ?
                                                <Link to="" className="btn btn-success shadow btn-xs sharp me-1"><i class="fa fa-check"></i></Link> : ''}
                                            </td> */}
                                            <td>{dateTimeHandler(item.created_at)}</td>
                                        </tr>
                                    );
                                })
                                : <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={5}>No records found</td>
                                </tr>
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )

}
export default BankAccountChangeLog;