import React, { Fragment } from "react";
// import ComponentsClientSidebar from '../shared/ComponentsClientSidebar'
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useRef } from "react";
// import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation } from "react-router-dom/dist";
import {
  useCreateProject,
  useGetProjectById,
  useProjectUpdateById,
} from "../../actions/project-action";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from "../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { useGetClientList } from "../../actions/client-actions";
import { GENERIC_STATUS_CHOICES as statusOption } from "../../utils/Constance";

import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";
import LoadingOverlay from 'react-loading-overlay';

function AddProject() {
  const navigate = useNavigate();

  const FormRef = useRef(null);

  const location = useLocation();

  let id = location?.state?.rowId;

  const [clientList, setClientList] = useState([]);

  const { data: getAllClient } = useGetClientList();

  useEffect(() => {
    if (getAllClient) {
      setClientList(getAllClient);
    }
  }, [getAllClient]);

  const clientOptions = clientList.map((e) => {
    return {
      label: `${e.client_name}`,
      value: e.id,
    };
  });
  const { mutate: postNewProject, isLoading: loadingpostNewProjectFn } =
    useCreateProject();
  const { mutate: patchProjectInfo, isLoading: loadingpatchProjectInfoFn } =
    useProjectUpdateById();
  const [newProject, setNewProject] = useState({
    project_name: "",
    project_short_name: "",
    description: "",
    start_date: null,
    end_date: null,
    status: 1,
    client: "",
  });

  const handleChange = (e) => {
    setNewProject((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();
    if (FormValidity) {
      if (location?.state?.rowId) {
        try {
          patchProjectInfo(
            { id, newProject },
            {
              onSuccess: (response) => {
                toast.success("Project Profile Updated", TostifyConfig);
                navigate("/list-project/");
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          postNewProject(newProject, {
            onSuccess: (response) => {
              toast.success("Project Profile Added!", TostifyConfig);
              navigate("/list-project/");
            },
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const { data: projectDetailsById, refetch: projectRefetchById, isLoading: loadingProjectInfoFn } =
    useGetProjectById(id);
  useEffect(() => {
    if (id) {
      projectRefetchById();
    }

    if (projectDetailsById) {
      setNewProject(projectDetailsById);
    }
  }, [projectDetailsById, projectRefetchById, id]);

  return (
    <Fragment>
      <PageTitle
        activeMenu={id ? "Edit Project" : "Add Project"}
        motherMenu="Settings"
      />
      <div>
        <div className="col-xl-12 col-xxl-12">
          <Card className="az-card">
            <Card.Header>
              <h3>{id ? "Edit" : "Add"} Project</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingProjectInfoFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-lg-6 mb-2">
                        <label>Project Name <span className="required">*</span></label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="project_name"
                          value={newProject?.project_name}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>Short Name </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="project_short_name"
                          value={newProject?.project_short_name}
                          onChange={(e) => handleChange(e)}

                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>Start Date</label>
                        <Form.Control
                          type="date"
                          name="start_date"
                          value={newProject?.start_date}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>End Date </label>
                        <Form.Control
                          type="date"
                          name="end_date"
                          value={newProject?.end_date}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group>
                    <Row>
                      {/* <Col className="col-md-6 mb-2">
                        <label>Client</label>
                        <Select
                          options={clientOptions}
                          value={clientOptions.filter(
                            (e) => e.value === newProject?.client
                          )}
                          onChange={(e) =>
                            setNewProject({ ...newProject, client: e.value })
                          }
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              height: "55px",
                              "border-color": "hsl(228deg 20% 95.1%)",
                            }),
                          }}
                        />
                      </Col> */}

                      <Col className="col-md-6 mb-2">
                        <label>Status</label>

                        <Select
                          options={statusOption}
                          value={statusOption.filter(
                            (e) => e.value === newProject?.status
                          )}
                          onChange={(e) =>
                            setNewProject({ ...newProject, status: e.value })
                          }
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              height: "55px",
                              "border-color": "hsl(228deg 20% 95.1%)",
                            }),
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3 mt-3">
                    <Row>
                      <Col className="col-md-12 mb-2">
                        <label>Description</label>
                        <Form.Control
                          as="textarea"
                          name="description"
                          placeholder="Project Description"
                          value={newProject?.description}
                          style={{ height: "100px" }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Row>
                      <Col className="col-md-12">
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              navigate("/list-project");
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button variant="primary btn-sm" disabled={(loadingProjectInfoFn && id) || loadingpostNewProjectFn || loadingpatchProjectInfoFn} onClick={handleSubmit}>
                            {loadingpostNewProjectFn ||
                              loadingpatchProjectInfoFn ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                />
                                Loading...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default AddProject;
