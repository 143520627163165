import axios from 'axios';
import React, { useState, useEffect, useContext } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { Link, useNavigate } from 'react-router-dom';
import { useLogin, usePermission } from '../../actions/auth-actions';
import { getUserPermission, getUserCompany } from '../../services/AuthService';

import logo from '../../images/logo/white_logo.PNG';
//import logo from '../../images/logo.png'
// import logotext from '../../images/logo-text.png'
import ForgotPassword from './ForgotPassword';
import ResetPassword from '../components/ResetPassword';

function Login(props) {
	const { setIsAuthenticated, setEmployeeUUID, setPermissionsList, setIsAdminUser, setCompanyList, setEmployeeFullName, setIsSubsriptionActive, setMaxNumberOfUser, setIsRecruitmentEnabled, setCanEditEmployee } = useContext(ServiceContext);
	const [email, setEmail] = useState('');
	let errorsObj = { email: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState('');
	const [isLoadingUserLogin, setLoadingUserLogin] = useState(false);
	const [showSignInPage, setShowSignInPage] = useState(true);
	const [showResetPasswordPage, setShowResetPasswordPage] = useState(false);

	const { mutate: login, isLoading: isLoadingLogin } = useLogin();
	const { data: permissionData, isLoading: isLoadingPermission, refetch: refetchPermission } = usePermission();

	const nav = useNavigate();

	useEffect(() => {
		if (permissionData) {
			console.log('permissions updated')
		}
	}, [permissionData]);

	function onLogin(e) {
		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };
		if (email === '') {
			errorObj.email = 'Email is Required';
			error = true;
		}
		if (password === '') {
			errorObj.password = 'Password is Required';
			error = true;
		}
		setErrors(errorObj);
		if (error) {
			return;
		}

		const loginData = {
			"email": email,
			"password": password,
		};

		// Submit the form if there are no errors
		if (email && password) {
			// Clear local storage
			localStorage.clear();
			/// Call the registration API

			login(loginData, {
				onSuccess: async (response) => {
					axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
					// Save the token in localStorage or a secure cookie
					localStorage.setItem('access_token', response?.data?.access);
					localStorage.setItem('refresh_token', response?.data?.refresh);
					localStorage.setItem('email', response?.data?.email);
					localStorage.setItem('employee_uuid', response?.data?.employee_uuid);
					localStorage.setItem('employee_fullname', response?.data?.employee_full_name);
					setEmployeeUUID(response?.data?.employee_uuid);
					setIsAdminUser(response?.data?.is_admin_account);
					setEmployeeFullName(response?.data?.employee_full_name);
					setIsAuthenticated(true);
					setIsRecruitmentEnabled(response?.data?.is_recruitment_enabled);

					try {
						// Use Promise.all to wait for both requests to complete
						await Promise.all([getUserPermission(setPermissionsList, setIsAdminUser, setIsSubsriptionActive, setMaxNumberOfUser, setIsRecruitmentEnabled, setCanEditEmployee), getUserCompany(setCompanyList)]);

						setLoadingUserLogin(false);
						nav('/');
					} catch (error) {
						// Handle any errors that may occur in the getUserPermission or getUserCompany functions
						console.error('Error:', error);
						setLoadingUserLogin(false);
					}

				}, onError: (error) => {
					setLoadingUserLogin(false);
					errorObj.email = error?.detail;
					error = true;
				}
			});
		}
	}

	return (
		<>
			{!showResetPasswordPage && <div className="login-form-bx">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-6 col-md-7 box-skew d-flex">
							{showSignInPage ? <div className="authincation-content">
								<div className="mb-4">
									<h3 className="mb-1 font-w600">Welcome to UKVICAS.COM</h3>
									<p className="">Sign In & Stay Complaint</p>
								</div>

								{props?.errorMessage && (
									<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
										{props?.errorMessage}
									</div>
								)}
								{props?.successMessage && (
									<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
										{props?.successMessage}
									</div>
								)}
								<form onSubmit={onLogin}>
									<div className="form-group">
										<label className="mb-2 ">
											<strong className="">Email</strong>
										</label>
										<input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
										{errors.email && <div className="text-danger fs-12 mt-2">{errors.email}</div>}
									</div>
									<div className="form-group">
										<label className="mb-2 "><strong className="">Password</strong></label>
										<input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
										{errors.password && <div className="text-danger fs-12 mt-2">{errors.password}</div>}
									</div>
									<div className="form-row d-flex justify-content-between mt-4 mb-2">
										<div className="form-group">
											<div className="form-check custom-checkbox ms-1 ">
												<input type="checkbox" className="form-check-input" id="basic_checkbox_1" />
												<label className="form-check-label" htmlFor="basic_checkbox_1">Remember my preference</label>
											</div>
										</div>
									</div>
									<div className="text-center">
										<button type="submit" className="btn btn-primary btn-block" disabled={isLoadingLogin}>
											{isLoadingLogin ? (
												<>
													Loading...
												</>
											) : (
												'Sign In'
											)}
										</button>
									</div>
								</form>
								<div className="mt-2">
									<span onClick={() => setShowSignInPage(false)} className="mb-0 c-pointer btn-link">Forgot password?</span>
									{/* <button onClick={() => setShowSignInPage(false)} type="button" class="pl-0 mb-0 btn btn-link">Forgot password?</button> */}
								</div>
							</div>
								: <ForgotPassword
									isLoadingLogin={isLoadingLogin}
									handleShowSignInPage={setShowSignInPage}
								/>}
						</div>
						<div className="col-lg-6 col-md-5 d-md-flex box-skew1 d-none">
							<div className="inner-content align-self-center">
								<Link to="/" className="login-logo mb-2 ms-5">
									<img src={logo} alt="logo" className="logo-text ms-5" />
									{/* <img src={logotext} alt="" className="logo-text ms-1"/> */}
								</Link>
								{/* <h2 className="m-b10 text-white"> UKVICAS.COM</h2> */}
								<p className="m-b40 text-white">UKVI Compliance and Sponsorship Solutions</p>
								<ul className="social-icons mt-4">
									<li><Link to={"#"}><i className="fab fa-facebook-f"></i></Link></li>
									<li><Link to={"#"}><i className="fab fa-twitter"></i></Link></li>
									<li><Link to={"#"}><i className="fab fa-linkedin-in"></i></Link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>}
			{showResetPasswordPage && <ResetPassword setIsAuthenticated={setIsAuthenticated} />}
		</>



	)
}

export default Login;