import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";


const Error403 = () => {
  const navigate = useNavigate();

  const logout = (()=>{
    localStorage.clear();
    navigate('/login');
  })

  return (
    <div className='authincation'>
      <div className='container '>
        <div className='row justify-content-center h-100 align-items-center'>
          <div className='col-md-5'>
            <div className='form-input-content text-center error-page'>
              <h1 className='error-text  fw-bold'>403</h1>
              <h4>
                <i className='fa fa-times-circle text-danger' /> Forbidden
                Error!
              </h4>
              <p>You do not have permission to view this resource.</p>
              <div>
                <Link className='btn btn-primary' to='/dashboard'>
                  Back to Home
                </Link>
                &nbsp;&nbsp;
                <Button className='btn btn-danger' onClick={logout}>
                  Logout
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error403
