import { Button, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactToPdf from 'react-to-pdf'
import { Margin, usePDF } from "react-to-pdf";
import { isValidPermission } from "../../../services/AuthService";


const JobVacancyDetail = (props) => {
  const { toPDF, targetRef } = usePDF({
    filename: "usepdf-example.pdf",
    page: { margin: Margin.MEDIUM }
  });
  return (
    <div className="card" id="work-profile">
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title">
          <i class="fas fa-briefcase text-primary"></i> &nbsp; Job Vacancy
        </h4>
        {isValidPermission('employee.change_employee', props.getPermissionList) &&
          <Button
            className="me-2 btn-sm"
            variant="primary"
            onClick={() => {
              props.setCardView({ jobVacancyEdit: true });
            }}
          >
            <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Edit
          </Button>
        }
        {/* <Button className="me-2 btn-sm" onClick={toPDF}>Download PDF</Button> */}

      </div>
      <div className="card-body" ref={targetRef}>
        <div className="form-validation">
          <div className="row">
            <div className="col-xl-12">
              <div className="form-group mb-3 row">
                <label className="col-lg-4 col-form-label">
                  Position Name:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.jobData?.position_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  Number of Vacancies:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.jobData?.no_of_vacancies}
                </label>
                <label className="col-lg-4 col-form-label">
                  Weekly working hours:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.jobData?.weekly_working_hours}
                </label>
                <div className='d-flex'>
                  <label className="col-lg-4 col-form-label">
                    Primary Skills:
                  </label>
                  <span className='d-flex'>
                    {props?.skills?.map((tag, index) => (
                      <Badge key={index} pill variant="primary" className="me-2 mb-3 d-block">
                        {tag}
                      </Badge>
                    ))}
                  </span>

                </div>
                <div className='d-flex'>
                  <label className="col-lg-4 col-form-label">
                    Secondary Skills:
                  </label>
                  <span className='d-flex'>
                    {props?.secondarySkills?.map((tag, index) => (
                      <Badge key={index} pill variant="primary" className="me-2 mb-3 d-block">
                        <span>
                          {tag}
                        </span>
                      </Badge>
                    ))}
                  </span>
                </div>
                <label className="col-lg-4 col-form-label">
                  Salary Range:
                </label>
                <label className="col-lg-8 col-form-label">
                  £{props.jobData?.salary_range_min} - £{props.jobData?.salary_range_max}
                </label>
                <label className="col-lg-4 col-form-label">
                  Interviewer Name:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.jobData?.interviewer_name_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  Job Type:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.jobData?.job_type_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  Employment Type:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.jobData?.employment_type_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  Job Description:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.jobData?.job_description}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobVacancyDetail;