import { PASSWORD_MESSAGES } from "../../../../utils/ConstantMessages"
import { Tooltip } from "react-tooltip";
import Spinner from "react-bootstrap/Spinner";

const ChangePassword = (props) => {
	const { new_password, setNewPassword, current_password, setCurrentPassword, confirmPassword, setConfirmPassword, handleSubmit, handleOnChange, isOpen, handleFocus, tooltipContent, handleBlur, handlePassword, passwordError, currentPasswordError, loadingPasswordFn} = props
	return (
		<div className="card mb-4 h-auto">
			<div className="card-header">{PASSWORD_MESSAGES.changePassword}</div>
			<div className="card-body">
				<form onSubmit={handleSubmit}>
					{/* <div className="row gx-3 mb-3"> */}
					<div className="mb-3">
						<label className="small mb-1" for="currentPassword">{PASSWORD_MESSAGES.currentPassword}<span className="required">*</span></label>
						<input className="form-control" id="currentPassword" required name="current_password" type="password" onChange={(e) => setCurrentPassword(e.target.value)} placeholder="Enter your current password" value={current_password} />
						{currentPasswordError && <span className="required">{currentPasswordError}</span>}

					</div>
					<div className="mb-3">
						<label className="small mb-1" for="newPassword">{PASSWORD_MESSAGES.newPassword}<span className="required">*</span>&nbsp;
							<Tooltip
								id="password-tooltip"
								style={{
									backgroundColor: "#f6f6f6",
									color: "#222",
									border: "1px solid #e7e7e7",
								}}
								events={['click']}
								isOpen={isOpen}
							/>
						</label>
						<input className="form-control"
							id="newPassword"
							required
							name="new_password"
							type="password"
							// onChange={handleOnChange}
							placeholder="Enter your new password"
							value={new_password}
							data-tooltip-id="password-tooltip"
							data-tooltip-html={tooltipContent}
							data-tooltip-place="left"
							onChange={({ target }) => {
								handlePassword(target.value);
								setNewPassword(target.value);
							}}
							onFocus={handleFocus}
							onBlur={handleBlur} />

					</div>
					{/* </div> */}
					<div className="mb-3">
						<label className="small mb-1" for="confirmNewPassword">{PASSWORD_MESSAGES.confirmPassword}<span className="required">*</span></label>
						<input className="form-control" id="confirmNewPassword" required name="confirmPassword" type="password" onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Enter your confirm new password" value={confirmPassword} />
						{passwordError && <span className="required">{passwordError}</span>}
					</div>
					<button disabled={loadingPasswordFn} className="btn btn-primary" type="submit">
						{(loadingPasswordFn) ? (
											<>
												<Spinner
													animation="border"
													size="sm"
													className="me-2"
												/>
												Loading...
											</>
										) : (
											<>{PASSWORD_MESSAGES.changePasswordButton}</>
										)}
					</button>
				</form>
			</div>
		</div>
	)
}

export default ChangePassword