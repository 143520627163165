import { useQuery } from 'react-query';
import { lazy, Suspense, useEffect, useState, useContext } from 'react';
/// Components
import Index from './jsx/index';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ServiceContext } from "../src/context/ServiceContext";
// action
import { checkAutoLogin } from './services/AuthService';
/// Style
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { setupAxiosInterceptors } from '../src/config/axios.config';

const ConfirmPassword = lazy(() => import('./jsx/pages/ConfirmPassword'));
const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
  });
});
// ...

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated, setIsAuthenticated, getPermissionList, setPermissionsList, setEmployeeUUID, getCompanyList, setCompanyList, setEmployeeFullName, setIsAdminUser, setIsSubsriptionActive, setMaxNumberOfUser, setIsRecruitmentEnabled, setCanEditEmployee } = useContext(ServiceContext);

  useEffect(() => {
    setupAxiosInterceptors(navigate);
  }, []);

  useEffect(() => {
    checkAutoLogin(navigate, setIsAuthenticated, getPermissionList, setPermissionsList, setEmployeeUUID, getCompanyList, setCompanyList, setEmployeeFullName, setIsAdminUser, setIsSubsriptionActive, setMaxNumberOfUser, setIsRecruitmentEnabled, setCanEditEmployee);
  }, [location.pathname]);

  let routeblog = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/confirm-password/:id/:token/" element={<ConfirmPassword />} />
    </Routes>
  );

  return (
    <div className="vh-100">
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {isAuthenticated ? <Index /> : routeblog}
      </Suspense>
    </div>
  );
}
export default withRouter(App)