import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const PageTitle = (props) => {
	const { pageHeading, motherMenu, activeMenu } = props
	let path = window.location.pathname.split("/");

	return (
		<div className="page-titles d-flex justify-content-between">
			{/* <h4>{activeMenu}{pageHeading}</h4>	 */}
			<ol className="breadcrumb">
				<li className="breadcrumb-item">
					<Link to={`/${path[path.length - 1]}`}>{motherMenu}</Link>
				</li>
				<li className="breadcrumb-item active">
					<Link to={`/${path[path.length - 1]}`}>{activeMenu}</Link>
				</li>
			</ol>
			{props.showButton &&
				<Button className="d-flex"
					style={{ height: '20px', textAlign: 'center', alignItems: 'center' }}
					onClick={props.handleOnClick}
				>
					{props.buttonLabel}
				</Button>
			}
		</div>
	);
};

export default PageTitle;
