import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useGetClientList } from '../../actions/client-actions'
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../components/table/FilteringTable/filtering.css';
import { IsValidPermissions } from '../../services/AuthService';
import DataTable from '../pages/commonPages/DataTable';
import { useLeavePolicy } from '../../actions/leavebalance-actions';
import { deleteConfirmation } from '../../utils/ComponentUtilse';
import { useLeavePolicyDeleteById } from '../../actions/leave-policy-action';
import CustomTooltip from '../../utils/CommonUtils';

export const LeavePolicyView = () => {

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const { data: getAllPolicy, isLoading: loadingPolicyFn, refetch: refetchPolicy } = useLeavePolicy();

  const { mutate: deletePolicyInfo, isLoading: loadingdeletePolicyFn } = useLeavePolicyDeleteById();


  useEffect(() => {
    if (getAllPolicy) {
      setData(getAllPolicy);
    }
  }, [getAllPolicy]);

  const columns = useMemo(
    () => [
      {
        Header: "Policy Name",
        Footer: "Policy Name",
        accessor: "policy_name",
        Filter: ColumnFilter,
        className: "minWidth200"
      },
      {
        Header: "Carry Over",
        Footer: "Carry Over",
        accessor: "do_carry_over",
        Cell: ({ value }) => {
          return <span>{value ? 'Yes' : 'No'}</span>;
        },
        Filter: ColumnFilter,
        className: "minWidth50"
      },
      {
        Header: "Show in Employee View",
        Footer: "Show in Employee View",
        accessor: "is_show_in_employee_calender",
        Filter: ColumnFilter,
        className: "minWidth50",
        Cell: ({ value }) => {
          return <span>{value ? 'Yes' : 'No'}</span>;
        },

      },
      {
        Header: "Description",
        Footer: "Description",
        accessor: "description",
        Filter: ColumnFilter,
        className: "minWidth200"
      },
      {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
        Filter: ColumnFilter,
        disableFilters: true,
        className: "minWidth100",
        Cell: ({ row }) => (
          <>
            <CustomTooltip id={`delete-${row.index}`} message="Delete">
              <Button
                className="shadow btn-xs sharp me-1"
                variant="danger light"
                style={{ cursor: "pointer" }}
                onClick={() => deleteConfirmation(row.original.id, `Leave Policy - ${row.original.policy_name}`, deletePolicyInfo, refetchPolicy)}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </CustomTooltip>&nbsp;

            {IsValidPermissions('leave_management.view_leavepolicy') &&
              <CustomTooltip id={`edit-${row.index}`} message="Edit">
                <Button
                  className="shadow btn-xs sharp me-1"
                  variant='primary light'
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEdit(row.original.id)}
                >
                  <i className="fa fa-pencil-alt"></i>
                </Button>
              </CustomTooltip>
            }
          </>
        )

      },
    ],
    []
  );

  const handleEdit = (id) => {
    navigate('/add-leave-policy/', { state: { rowId: id } })
  }
  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  }, useFilters, useGlobalFilter, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = tableInstance

  const { pageIndex } = state

  return (
    <>
      <PageTitle activeMenu="Leave Policy" motherMenu="Leave" />
      {IsValidPermissions('leave_management.view_leavepolicy') &&
        <div className="card">
          <div className="card-header">
            <h4 className="card-title"><i class="fa fa-users" aria-hidden="true"></i> Leave Policy</h4>
            {IsValidPermissions('leave_management.view_leavepolicy') &&
              <div className="d-inline-flex">
                <Link to={`/add-leave-policy/`}>
                  <Button className='me-2' variant='primary btn-sm'>
                    <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; Leave Policy
                  </Button>
                </Link>
              </div>
            }

          </div>
          <div className="card-body">
            <DataTable
              isLoading={loadingPolicyFn}
              columns={columns}
              tableInstance={tableInstance}
            />
          </div>
        </div>
      }

      {(!loadingPolicyFn && !IsValidPermissions('leave_management.view_leavepolicy')) &&
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <Alert variant="warning" show={true}>

                <svg
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  stroke='currentColor'
                  strokeWidth='2'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='me-2'
                >
                  <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                  <line x1='12' y1='9' x2='12' y2='13'></line>
                  <line x1='12' y1='17' x2='12.01' y2='17'></line>
                </svg>

                <strong>Info!  </strong> You don't have permission to view Clients.

              </Alert>
            </div>
          </div>
        </div>
      }
    </>
  )

}
export default LeavePolicyView;