import axios from 'axios';

let baseUrl = ''

if (window.__RUNTIME_CONFIG__.SERVICE_BASE_URL === "None"){
    baseUrl = window.location.origin;
    const urlParts = baseUrl.split(':');
    const [protocol, domain] = urlParts;

    if (window.__RUNTIME_CONFIG__.SERVICE_PORT === "None"){
      baseUrl = `${protocol}:${domain}`;
    }else{
      baseUrl = `${protocol}:${domain}:${window.__RUNTIME_CONFIG__.SERVICE_PORT}`;
    }
}
else {
  baseUrl = window.__RUNTIME_CONFIG__.SERVICE_BASE_URL;
}

axios.defaults.baseURL = baseUrl
const token = localStorage.getItem('access_token');
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const setupAxiosInterceptors = (navigate) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error?.response?.data || error?.message || error;
      console.error(error?.response);
      if ((error.response && error.response.status === 401) && !(window.location.pathname.includes('/login'))) {
        navigate('/page-error-403');
      }
      return Promise.reject(message);
    }
  );
}