import React, { Fragment } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useRef } from "react";
import { useLocation } from "react-router-dom/dist";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from "../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";
import LoadingOverlay from 'react-loading-overlay';
import { useCreateLeavePolicy, useGetLeavePolicyById, useLeavePolicyUpdateById } from "../../actions/leave-policy-action";

const AddLeavePolicy = () => {
  const navigate = useNavigate();

  const FormRef = useRef(null);
  const location = useLocation();

  let id = location?.state?.rowId;

  const { mutate: postNewPolicy, isLoading: loadingPostPolicyFn } = useCreateLeavePolicy();

  const { mutate: patchPolicyInfo, isLoading: loadingPatchPolicyFn } = useLeavePolicyUpdateById();

  const [policyData, setPolicyData] = useState({
    description: "",
    policy_name: "",
    do_carry_over: false,
    is_show_in_employee_calender: false
  });

  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
        if (event.target.type === "checkbox") {
          setPolicyData({
                ...policyData,
                [name]: event.target.checked,
            });
            setIsChecked(event.target.checked);
        }
        else {
            setPolicyData({
                ...policyData,
                [name]: value,
            });
        }
    // setPolicyData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();
    if (FormValidity === true && location?.state?.rowId) {

      try {
        patchPolicyInfo(
          { id, policyData },
          {
            onSuccess: (response) => {
              toast.success("Leave Policy Updated", TostifyConfig);
              navigate("/admin-leave-policy/");
            },
          }
        );
      } catch (error) {
        console.error(error);
      }
    } else if (FormValidity === true) {
      try {
        postNewPolicy(policyData, {
          onSuccess: (response) => {
            toast.success("Leave Policy Added", TostifyConfig);
            navigate("/admin-leave-policy/");
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const { data: policyDetailsById, isLoading: loadingPolicyFn } = useGetLeavePolicyById(location?.state?.rowId);

  useEffect(() => {
    if (location?.state?.rowId) {
      setPolicyData(policyDetailsById);
    }
  }, [policyDetailsById]);
  return (
    <Fragment>
      <PageTitle
        activeMenu={id ? "Edit Leave Policy" : "Add Leave Policy"}
        motherMenu="Leave"
      />
      <div>
        <div className="col-xl-12 col-xxl-12">
          <Card className="az-card">
            <Card.Header>
              <h3>{id ? "Edit Leave Policy" : "New Leave Policy"}</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingPolicyFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>Policy Name<span className="required">*</span></label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Leave Policy name"
                          name="policy_name"
                          value={policyData?.policy_name}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>Description</label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Description"
                          name="description"
                          value={policyData?.description}
                          onChange={(e) => handleChange(e)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label><strong>Show in Employee View</strong></label>
                        <div className="form-check custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            name="is_show_in_employee_calender"
                            className="form-check-input"
                            id="is_show_in_employee_calender"
                            checked={policyData?.is_show_in_employee_calender}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </Col>
                      <Col className="col-lg-6 mb-2">
                        <label><strong>Carry Over Balance</strong></label>
                        <div className="form-check custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            name="do_carry_over"
                            className="form-check-input"
                            id="do_carry_over"
                            checked={policyData?.do_carry_over}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Row>
                      <Col className="col-md-12">
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              navigate("/admin-leave-policy");
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="primary btn-sm"
                            onClick={handleSubmit}
                            disabled={loadingPolicyFn && id || loadingPostPolicyFn || loadingPatchPolicyFn}
                          >
                            {loadingPostPolicyFn || loadingPatchPolicyFn ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                />
                                Loading...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default AddLeavePolicy;
