import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { isValidPermission } from "../../../../services/AuthService";
import { dateHandler } from "../../../../utils/ComponentUtilse";
import { ServiceContext } from "../../../../context/ServiceContext";

function PersonalInfoList(props) {
  const { canEditEmployee } = useContext(ServiceContext);

  return (
    <div className="card" id="personal-info">
      <div className="card-header d-flex justify-content-between">
        <h4 className="card-title">
          <i className="fa fa-id-badge font-18 align-middle me-2 text-primary"></i>
          &nbsp; Personal Info
        </h4>

        {canEditEmployee && isValidPermission('employee.change_employeepersonalinfo', props.getPermissionList) &&
          <Button
            className="me-2 btn-sm "
            variant="primary"
            onClick={() => {
              props.setCardView({ personalInfoEdit: true });
            }}
          >
            <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Edit
          </Button>
        }
      </div>
      <div className="card-body">
        <div className="form-validation">
          <div className="row">
            <div className="col-xl-12">
              <div className="form-group mb-3 row">
                <label className="col-lg-4 col-form-label">
                  Date Of Birth:
                </label>
                <label className="col-lg-8 col-form-label">
                  {dateHandler(props.employeeProfileInfoData.date_of_birth)}
                </label>
                {/* <label className="col-lg-4 col-form-label">
                  Mobile Number:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.mobile_phone}
                </label> */}
                <label className="col-lg-4 col-form-label">
                  Home Phone:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.home_phone ?
                    props.employeeProfileInfoData.home_phone.startsWith('+44') ?
                      props.employeeProfileInfoData.home_phone.substring(3) :
                      '+44 ' + props.employeeProfileInfoData.home_phone
                    :
                    null}
                </label>
                <label className="col-lg-4 col-form-label">
                  Personal Email:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.personal_email}
                </label>
                <label className="col-lg-4 col-form-label">
                  Gender:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.gender_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  Marital Status:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.marital_status_name}
                </label>
                <label className="col-lg-4 col-form-label">
                  Nationality:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.nationality}
                </label>
                <label className="col-lg-4 col-form-label">
                  Address1:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.address1}
                </label>
                <label className="col-lg-4 col-form-label">
                  Address2:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.address2}
                </label>
                <label className="col-lg-4 col-form-label">
                  City:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.city}
                </label>
                <label className="col-lg-4 col-form-label">
                  County:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.state}
                </label>
                <label className="col-lg-4 col-form-label">
                  PostCode:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.zip_code}
                </label>
                <label className="col-lg-4 col-form-label">
                  Country:
                </label>
                <label className="col-lg-8 col-form-label">
                  {props.employeeProfileInfoData.country_name}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalInfoList;