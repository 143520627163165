import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useGetPositionsList } from "../../actions/Positions-actions";
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../components/table/FilteringTable/filtering.css';
import DataTable from '../pages/commonPages/DataTable';
import { deleteConfirmation } from '../../utils/ComponentUtilse';
import { usePositionsDeleteById } from '../../actions/Positions-actions';
import CustomTooltip from '../../utils/CommonUtils';


export const PositionsListView = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const { data: getAllPositions, isLoading: loadingPositionsFn, refetch: refetchPositions } = useGetPositionsList()

    const { mutate: deletePositionsInfo, isLoading: loadingdeletePositionsFn } = usePositionsDeleteById();


    useEffect(() => {
        if (getAllPositions) {
            setData(getAllPositions);
        }
    }, [getAllPositions]);

    const columns = useMemo(
        () => [
            {
                Header: "Position Name",
                Footer: "Position Name",
                accessor: "position_name",
                Filter: ColumnFilter,
                className: "minWidth200",
            },
            {
                Header: "Position Code",
                Footer: "Position Code",
                accessor: "position_code",
                Filter: ColumnFilter,
                className: "minWidth50",
            },
            {
                Header: "Description",
                Footer: "Description",
                accessor: "description",
                Filter: ColumnFilter,
                className: "minWidth200",
            },

            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                Filter: ColumnFilter,
                disableFilters: true,
                className: "minWidth100",
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip id={`delete-${row.index}`} message="Delete">
                            <Button
                                className="shadow btn-xs sharp me-1"
                                variant="danger light"
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteConfirmation(row.original.id, `Position - ${row.original.position_name}`, deletePositionsInfo, refetchPositions)}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </CustomTooltip>&nbsp;
                        <CustomTooltip id={`edit-${row.index}`} message="Edit">
                            <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(row.original.id)}><i className="fas fa-pencil-alt"></i></Button>
                        </CustomTooltip>

                    </>
                ),

            },
        ],
        []
    );


    const handleEdit = (id) => {
        navigate('/add-positions/', { state: { rowId: id } })
    }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    const { pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Position" motherMenu="Settings" />
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title"><i class="fa fa-users" aria-hidden="true"></i>Position</h4>
                    <div className="d-inline-flex">
                        <Link to={`/add-positions/`}>
                            <Button className='me-2' variant='primary btn-sm'>
                                <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; Position
                            </Button>
                        </Link>

                    </div>
                </div>
                <div className="card-body">
                    <DataTable
                        isLoading={loadingPositionsFn}
                        columns={columns}
                        tableInstance={tableInstance}
                    />

                </div>
            </div>
        </>
    )

}
export default PositionsListView;