import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import '../components/table/FilteringTable/filtering.css';
import { Link } from 'react-router-dom';
import { Button, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetAnnouncementList } from '../../actions/announcement-action'
import { IsValidPermissions } from '../../services/AuthService';
import { Nav, Tab, Dropdown } from 'react-bootstrap';
import { RiseLoader } from "react-spinners";
import { dateHandler } from '../../utils/ComponentUtilse';
import CustomTooltip from '../../utils/CommonUtils';


export const ListAnnouncements = () => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filterType, setFilterType] = useState("all");

    const { data: getAllAnnouncement, isLoading: loadingGetAnnouncementFn } = useGetAnnouncementList();


    useEffect(() => {
        let filteredData = getAllAnnouncement;

        if (filteredData && filterType === "Employee") {
            // Filter data based on approved_status_name
            filteredData = getAllAnnouncement.filter(
                (announcement) => announcement.announcement_type === "employee"
            );
            setData(filteredData);

        }
        // else if (filteredData && filterType === "Project") {
        //     // Filter data based on approved_status_name
        //     filteredData = getAllAnnouncement.filter(
        //         (announcement) => announcement.announcement_type === "project"
        //     );
        //     setData(filteredData);

        // }
        else if (filteredData && filterType === "Company") {
            // Filter data based on approved_status_name
            filteredData = getAllAnnouncement.filter(
                (announcement) => announcement.announcement_type === "company"
            );
            setData(filteredData);

        }
        else if (filteredData && filterType === "all") {
            filteredData = getAllAnnouncement;
            setData(filteredData);

        }


    }, [getAllAnnouncement, filterType]);


    return (
        <>
            <PageTitle activeMenu="Filtering" motherMenu="Table" />

            <Tab.Container defaultActiveKey={'grid'}>
                <div className="d-flex flex-wrap mb-0 align-items-center" style={{ justifyContent: "space-between" }}>
                    <div className="mb-1 pr-2">
                        <h6 className="text-black fs-16 font-w600 mb-3">Filters:</h6>

                        <Button
                            className={`btn btn-primary light btn-rounded me-2 mb-2 btn-sm ${filterType === "all" && "btn-primary-active"}`}
                            onClick={() => setFilterType("all")}
                        >
                            All
                        </Button>
                        <Button
                            className={`btn btn-primary light btn-rounded me-2 mb-2 btn-sm ${filterType === "myLeave" && "btn-primary-active"}`}
                            onClick={() => setFilterType("Company")}>
                            Company
                        </Button>
                        {/* <Button
                            className={`btn btn-primary light btn-rounded me-2 mb-2 btn-sm ${filterType === "myLeave" && "btn-primary-active"}`}
                            onClick={() => setFilterType("Project")}>
                            Project
                        </Button> */}
                        <Button
                            className={`btn btn-primary light btn-rounded me-2 mb-2 btn-sm ${filterType === "myLeave" && "btn-primary-active"}`}
                            onClick={() => setFilterType("Employee")}>
                            Employee
                        </Button>
                    </div>
                    <div className="mb-1 mt-2">
                        {IsValidPermissions('announcement.add_announcement') &&
                            <Link to={`/add-announcements/`}><Button className='me-2 btn-sm' variant='primary'>
                                <i className="fas fa-plus"></i>&nbsp;Announcement
                            </Button></Link>
                        }
                    </div>
                </div>
                <hr />
                {!loadingGetAnnouncementFn && (IsValidPermissions('announcement.view_announcement') || IsValidPermissions('announcement.can_view_employee_announcement')) &&
                    <Tab.Content>
                        <Tab.Pane eventKey={'grid'}>
                            <div className="row">
                                {data.map((item, i) => {
                                    return (
                                        <div className="col-xl-4 col-md-6">
                                            <div className="card shadow_1">
                                                <div className="card-header d-flex justify-content-between">
                                                    <div>
                                                        <h4 className='text-center'><i className="fa fa-bullhorn"></i>&nbsp; {item?.title}</h4>
                                                        <span className="fs-14"> <Badge pill bg="primary" className='badge-xs text-capitalize'>{item?.announcement_type}</Badge> &nbsp; {item?.get_expiry_date_text}</span>
                                                    </div>
                                                    {IsValidPermissions('announcement.change_announcement') &&
                                                        <CustomTooltip id={`edit-${item.index}`} message="Edit">
                                                            <Button style={{ padding: "6px 8px 6px 8px" }} className="btn btn-primary  btn-sm" onClick={() => navigate('/add-announcements/', { state: { rowId: item?.id } })}><i className="fas fa-pencil-alt"></i></Button>
                                                        </CustomTooltip>
                                                    }
                                                </div>
                                                <div className="card-body pb-1 pt-3">
                                                    <div className="row">
                                                        <div className="form-group mb-3 row" style={{ fontSize: "14px" }}>
                                                            <label className="col-lg-3 col-form-label">
                                                                Topic:
                                                            </label>
                                                            <label className="col-lg-9 text-black col-form-label">
                                                                {item?.get_topic_name}
                                                            </label>
                                                            <label className="col-lg-4 col-form-label">Message:</label>
                                                            <label className="col-lg-8 text-black col-form-label">
                                                                {item?.message}
                                                            </label>
                                                            <label className="col-lg-12 col-form-label"><i className="fa fa-calendar"></i> &nbsp;&nbsp; {dateHandler(item?.publish_date)} to {dateHandler(item?.expiry_date)}</label>


                                                            <label className="col-lg-12  col-form-label"><i class="fa fa-user" aria-hidden="true"></i> &nbsp; &nbsp; {item?.get_author_name}</label>


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                }

                {loadingGetAnnouncementFn &&
                    <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "calc(100vh - 200px)" }}>
                        <RiseLoader color="#40189D" />
                    </div>
                }
            </Tab.Container>

        </>
    )

}
export default ListAnnouncements;