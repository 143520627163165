import React, { useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import {
  Row,
  Col,
  Button,
  Modal,
  Container,
  Form,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Title, TostifyConfig } from "../../utils/Constance";
import {
  useEmployeePosition,
  useEmployeeWorkLocation,
  useEmployeeInfo,
  useVerifyEmployeeEmail,
  useVerifyEmployeeNumber,
  useEmployeeOnboardFn,
} from "../../actions/employee-actions";

import { ServiceContext } from "../../context/ServiceContext";

function EmployeeOnboarding() {
  const { employeeOnboardModal, setEmployeeOnboardModal } =
    useContext(ServiceContext);
  const step1FormRef = useRef(null);

  const navigate = useNavigate();

  const [verifyUser, setVerifyUser] = useState({});

  const [emailValidationError, setEmailValidationError] = useState("");

  const [phoneError, setPhoneError] = useState("");

  const { data: getEmployeePosition } = useEmployeePosition();

  const { data: getEmployeeWorkLocation } = useEmployeeWorkLocation();

  const { refetch: employeeInfoRefetch } = useEmployeeInfo();

  const { data: getVerifyEmployeeEmail } = useVerifyEmployeeEmail(
    verifyUser?.work_email
  );

  const { data: getVerifyEmployeeNumber } = useVerifyEmployeeNumber(
    verifyUser?.employee_number
  );

  const { mutate: postEmployeeOnboard, isLoading: loadingEmployeeOnboard } =
    useEmployeeOnboardFn();

  const [employeeProfileData, setemployeeProfileData] = useState({});

  const employeeSelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    // const selectedOptionLabel = e.target.options[selectedIndex].label;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;

    setemployeeProfileData({
      ...employeeProfileData,
      [selectName]: selectValue,
    });
  };

  const handlePhoneInputChange = (e) => {
    const { name, value } = e.target;

    // Remove leading +44 from the existing value
    let newValue = value.replace(/^\+44/, '');

    // Allow only numbers and the backspace key
    const onlyNumbers = /^[0-9\b]+$/;
    if (newValue !== "" && !onlyNumbers.test(newValue)) {
      // Display an error message or handle invalid input
      setPhoneError("Phone number must contain only numbers");
      return;
    }

    // Check if the number starts with zero and has exactly 10 digits after it
    const startsWithZero = /^0/;
    const tenDigits = /^0\d{0,9}$/;

    // Allow backspace and exactly 10 digits after zero
    if (newValue !== "" && !onlyNumbers.test(newValue) && (!startsWithZero.test(newValue) || !tenDigits.test(newValue))) {
      // Display an error message or handle invalid input

      setPhoneError("Mobile Number Format is: 07800123456");
      return;
    }

    setPhoneError("");
    setemployeeProfileData({ ...employeeProfileData, [name]: newValue });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setemployeeProfileData({
      ...employeeProfileData,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setVerifyUser({
      ...verifyUser,
      [name]: value,
    });
  };

  const handleFormSubmit = async () => {
    let stepFormValidity = step1FormRef.current.reportValidity();

    // Check if phone number is valid
    const startsWithZero = /^0/;
    const tenDigits = /^0\d{10}$/;
    if (!startsWithZero.test(employeeProfileData.work_phone) || !tenDigits.test(employeeProfileData.work_phone)) {
      setPhoneError("Mobile Number Format is: 07800123456");
      return;
    }

    // Validate email format
    const emailFormatRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailFormatRegex.test(employeeProfileData.work_email)) {
      setEmailValidationError("Invalid email format");
      return; // Stop form submission
    }
    if (stepFormValidity === true) {
      //Perform form submission logic here
      try {
        let final_data = {
          title: employeeProfileData.title || null,
          first_name: employeeProfileData.first_name,
          middle_name: employeeProfileData.middle_name,
          last_name: employeeProfileData.last_name,
          work_email: employeeProfileData.work_email,
          position: employeeProfileData.position || null,
          work_phone: employeeProfileData.work_phone,
          work_location: employeeProfileData.work_location,
          employee_start_date: employeeProfileData.employee_start_date,
          employee_number: employeeProfileData.employee_number,
          onboarding_mentor: null,
          direct_manager: null,
          skills: null,
          clients: null,
          projects: null,
          teams: null,
        };

        postEmployeeOnboard(final_data, {
          onSuccess: (response) => {
            employeeInfoRefetch();
            setemployeeProfileData({});
            setVerifyUser({});
            setEmployeeOnboardModal(false);
            setEmailValidationError("")
            toast.success("New User Added!!", TostifyConfig);
            navigate("/list-employee");
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* <!-- Modal --> */}
      <Modal
        className="fade  bd-example-modal-lg"
        show={employeeOnboardModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            <i class="fa fa-user-plus text-primary" aria-hidden="true"></i>
            &nbsp;&nbsp;&nbsp;&nbsp; Employee Onboard
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setEmployeeOnboardModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <form ref={step1FormRef}>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group>
                    <label className="col-form-label">
                      Title
                      <span className="text-danger">*</span>
                    </label>
                    <Form.Select
                      name="title"
                      className="mb-3 form-control"
                      value={employeeProfileData.title}
                      onChange={employeeSelectChangeHandler}
                      required
                    >
                      <option value="">Select Title</option>
                      {Title?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group>
                    <Col className="col-md-14">
                      <label className="col-form-label">Middle Name</label>
                    </Col>
                    <Form.Control
                      type="text"
                      className="mb-3"
                      placeholder="Type Text"
                      name="middle_name"
                      onChange={handleInputChange}
                      value={employeeProfileData.middle_name}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Col className="col-md-14">
                      <label className="col-form-label">
                        Employee No <span className="text-danger">*</span>
                        {getVerifyEmployeeNumber?.[0]?.employee_number ? (
                          <span className="text-danger">
                            {" "}
                            Employee No already exists!
                          </span>
                        ) : null}
                      </label>
                      <Form.Control
                        type="text"
                        autoComplete="nope"
                        className="mb-3"
                        name="employee_number"
                        placeholder="Employee Number"
                        value={employeeProfileData.employee_number}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        required
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group>
                    <div class="row">
                      <div className="col-md-6"> </div>
                      <div
                        className={`form-group mb-3`}
                      >
                        <label className="text-label">Work Phone<span className="required">*</span></label>
                        <div className="input-group">
                          <span className="input-group-text ">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_United_Kingdom.png" Style="width: 30px; margin-right: 8px;" />
                            +44
                            {/* <i className="fa fa-user" />{" "} */}
                          </span>
                          <Form.Control
                            type="text"
                            autoComplete="nope"
                            className="form-control"
                            placeholder="07800123456"
                            name="work_phone"
                            onChange={handlePhoneInputChange}
                            value={employeeProfileData.work_phone}
                            required
                          />
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {phoneError && phoneError}
                          </div>

                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          />
                        </div>
                      </div>

                      {/* <Form.Group>
                        <label className="col-form-label">
                          Work Phone<span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          className="mb-3"
                          placeholder="Type Text"
                          name="work_phone"
                          onChange={handleInputChange}
                          value={employeeProfileData.work_phone}
                          required
                        />
                      </Form.Group> */}
                    </div>

                    <label className="col-form-label">
                      Work Location
                    </label>
                    <Form.Select
                      name="work_location"
                      className="mb-3 form-control"
                      value={employeeProfileData.work_location}
                      onChange={employeeSelectChangeHandler}
                    >
                      <option value="">Select Location</option>
                      {getEmployeeWorkLocation?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.location_name}
                        </option>
                      ))}
                    </Form.Select>

                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group>
                    <Form.Group>
                      <label className="col-form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="mb-3"
                        placeholder="Type Text"
                        name="first_name"
                        onChange={handleInputChange}
                        value={employeeProfileData.first_name}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <label className="col-form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        type="text"
                        className="mb-3"
                        placeholder="Type Text"
                        name="last_name"
                        onChange={handleInputChange}
                        value={employeeProfileData.last_name}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <label className="col-form-label">
                        Work Email <span className="text-danger">*</span>
                        {getVerifyEmployeeEmail?.[0]?.work_email ? (
                          <span className="text-danger">
                            {" "}
                            Email Id already exists
                          </span>
                        ) : null}

                        {emailValidationError && (
                          <span className="text-danger">{emailValidationError}</span>
                        )}
                      </label>
                      <Form.Control
                        type="email"
                        autoComplete="nope"
                        className="mb-3"
                        placeholder="Work Email"
                        name="work_email"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        value={employeeProfileData.work_email}
                        required
                      />
                    </Form.Group>

                    <Row>
                      <Col className="col-md-14">
                        <label className="col-form-label">Position</label>

                        <Form.Select
                          name="position"
                          className="mb-3 form-control"
                          value={employeeProfileData.position}
                          onChange={employeeSelectChangeHandler}
                        >
                          <option value="">Select Position</option>
                          {getEmployeePosition?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.position_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>



                    <Form.Group>
                      <Row>
                        <Col className="col-md-14">
                          <label className="col-form-label">Start Date</label>
                          <span className="text-danger">*</span>
                          <Form.Control
                            type="date"
                            className="mb-3"
                            name="employee_start_date"
                            onChange={handleInputChange}
                            value={employeeProfileData.employee_start_date}
                            required
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form.Group>
                </div>
              </div>
            </form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light btn-sm"
            onClick={() => {
              setemployeeProfileData({});
              setVerifyUser({});
              setEmployeeOnboardModal(false);
              setEmailValidationError("");
            }}
          >
            Close
          </Button>
          <Button
            variant="primary btn-sm"
            onClick={handleFormSubmit}
            disabled={
              loadingEmployeeOnboard ||
              getVerifyEmployeeEmail?.[0]?.work_email ||
              getVerifyEmployeeNumber?.[0]?.employee_number
            }
          >
            {loadingEmployeeOnboard ? (
              <>
                <Spinner animation="border" size="sm" className="me-2" />
                Loading...
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmployeeOnboarding;
