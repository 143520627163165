import React, { Fragment, } from 'react'
// import ComponentsClientSidebar from '../shared/ComponentsClientSidebar'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import {
    useGetEmergencyContactByUUID,
    useEmergencyContactUpdateFn,
} from "../../actions/employee-actions";
import { useCountryofIssueFn } from '../../actions/employee-passport-actions';
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { TostifyConfig } from "../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import LoadingOverlay from 'react-loading-overlay';


function EmergencyContact(props) {

    const client = useQueryClient();

    const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const [emergencyContactData, setEmergencyContactData] = useState({});

    const [phoneError, setPhoneError] = useState("");

    const [mobileError, setMobileError] = useState("");

    const {
        data: getEmergencyContactByUUID,
        isLoading: loadingEmergencyContactByUUID,
        refetch: refetchEmergencyContactByUUID,
    } = useGetEmergencyContactByUUID(employee_uuid);

    const { data: getCountryofIssue, isLoading: loadingCountryofIssue } = useCountryofIssueFn();

    const {
        mutate: patchEmergencyContactFn,
        isLoading: loadingEmergencyContactFn,
    } = useEmergencyContactUpdateFn();

    useEffect(() => {
        if (getEmergencyContactByUUID) {
            setEmergencyContactData(getEmergencyContactByUUID);
        }
    }, [getEmergencyContactByUUID]);

    const handlePhoneInputChange = (e) => {
        const { name, value } = e.target;

        // Remove leading +44 from the existing value
        let newValue = value.replace(/^\+44/, '');

        // Allow only numbers and the backspace key
        const onlyNumbers = /^[0-9\b]+$/;
        if (newValue !== "" && !onlyNumbers.test(newValue)) {
            // Display an error message or handle invalid input
            setPhoneError("Phone number must contain only numbers");
            return;
        }

        // Check if the number starts with zero and has exactly 10 digits after it
        const startsWithZero = /^0/;
        const tenDigits = /^0\d{0,9}$/;

        // Allow backspace and exactly 10 digits after zero
        if (newValue !== "" && !onlyNumbers.test(newValue) && (!startsWithZero.test(newValue) || !tenDigits.test(newValue))) {
            // Display an error message or handle invalid input
            setPhoneError("Mobile Number Format is: 07800123456");
            return;
        }
        setPhoneError("");
        setEmergencyContactData({ ...emergencyContactData, [name]: newValue });
    };

    const handleMobileInputChange = (e) => {
        const { name, value } = e.target;

        // Remove leading +44 from the existing value
        let newValue = value.replace(/^\+44/, '');

        // Allow only numbers and the backspace key
        const onlyNumbers = /^[0-9\b]+$/;
        if (newValue !== "" && !onlyNumbers.test(newValue)) {
            // Display an error message or handle invalid input
            setMobileError("Phone number must contain only numbers");
            return;
        }

        // Check if the number starts with zero and has exactly 10 digits after it
        const startsWithZero = /^0/;
        const tenDigits = /^0\d{0,9}$/;

        // Allow backspace and exactly 10 digits after zero
        if (newValue !== "" && !onlyNumbers.test(newValue) && (!startsWithZero.test(newValue) || !tenDigits.test(newValue))) {
            // Display an error message or handle invalid input
            setMobileError("Mobile Number Format is: 07800123456");
            return;
        }
        setMobileError("");
        setEmergencyContactData({ ...emergencyContactData, [name]: newValue });
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmergencyContactData({ ...emergencyContactData, [name]: value });
    };

    const updateEmergencyContactHandeler = () => {
        let FormValidity = FormRef.current.reportValidity();

        // Check if phone number is valid
        const startsWithZero = /^0/;
        const tenDigits = /^0\d{10}$/;
        if (!startsWithZero.test(emergencyContactData.home_phone) || !tenDigits.test(emergencyContactData.home_phone)) {
            setPhoneError("Mobile Number Format is: 07800123456");
            return;
        }

        // Check if phone number is valid
        const startWithZero = /^0/;
        const tenDigit = /^0\d{10}$/;
        if (!startWithZero.test(emergencyContactData.work_phone) || !tenDigit.test(emergencyContactData.work_phone)) {
            setMobileError("Mobile Number Format is: 07800123456");
            return;
        }

        if (FormValidity === true) {
            let userData = {
                full_name: emergencyContactData.full_name,
                email: emergencyContactData.email,
                relationship: emergencyContactData.relationship,
                mobile_phone: emergencyContactData.mobile_phone,
                address1: emergencyContactData.address1,
                address2: emergencyContactData.address2,
                state: emergencyContactData.state,
                zip_code: emergencyContactData.zip_code,
                work_phone: emergencyContactData.work_phone,
                home_phone: emergencyContactData.home_phone,
                city: emergencyContactData.city,
                country: emergencyContactData.country ? parseInt(emergencyContactData.country) : null
            };

            // Validate email format
            const emailPattern = /^\S+@\S+\.\S+$/;
            if (userData.email && !emailPattern.test(userData.email)) {
                toast.error("Invalid email format", TostifyConfig);
                return;
            }

            patchEmergencyContactFn(
                { employee_uuid, userData },
                {
                    onSuccess: (response) => {
                        toast.success("Employee Profile Updated", TostifyConfig);
                        refetchEmergencyContactByUUID();
                        client.invalidateQueries(['get-emergency-contact-by-uuid']);
                        client.invalidateQueries(['get-pending-action-by-uuid']);
                        props.setCardView({ emergencyContact: true });
                    },
                }
            );
        }
    };

    const employeeSelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        setEmergencyContactData({
            ...emergencyContactData,
            [selectName]: selectValue,
        });
    };

    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>Emergency Contact</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingEmergencyContactByUUID}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Full Name<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="full_name"
                                                    onChange={handleInputChange}
                                                    value={emergencyContactData.full_name}
                                                    required
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">Relationship<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="relationship"
                                                    onChange={handleInputChange}
                                                    value={emergencyContactData.relationship}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <div
                                                    className={`form-group mb-3`}
                                                >
                                                    <label className="text-label">Primary Contact <span className="required">*</span></label>
                                                    <div className="input-group">
                                                        <span className="input-group-text ">
                                                            <img src="https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_United_Kingdom.png" Style="width: 30px; margin-right: 8px;" />
                                                            +44
                                                            {/* <i className="fa fa-user" />{" "} */}
                                                        </span>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="07800123456"
                                                            name="home_phone"
                                                            onChange={handlePhoneInputChange}
                                                            value={emergencyContactData.home_phone}
                                                            required
                                                        />
                                                        <div
                                                            id="val-username1-error"
                                                            className="invalid-feedback animated fadeInUp"
                                                            style={{ display: "block" }}
                                                        >
                                                            {phoneError && phoneError}
                                                        </div>

                                                        <div
                                                            id="val-username1-error"
                                                            className="invalid-feedback animated fadeInUp"
                                                            style={{ display: "block" }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <label className="text-label">Primary Contact <span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="home_phone"
                                                    onChange={handleInputChange}
                                                    value={emergencyContactData.home_phone}
                                                    required
                                                /> */}
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <div
                                                    className={`form-group mb-3`}
                                                >
                                                    <label className="text-label">Secondary Contact<span className="required">*</span></label>
                                                    <div className="input-group">
                                                        <span className="input-group-text ">
                                                            <img src="https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_United_Kingdom.png" Style="width: 30px; margin-right: 8px;" />
                                                            +44
                                                            {/* <i className="fa fa-user" />{" "} */}
                                                        </span>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="07800123456"
                                                            name="work_phone"
                                                            onChange={handleMobileInputChange}
                                                            value={emergencyContactData.work_phone}
                                                            required
                                                        />
                                                        <div
                                                            id="val-username1-error"
                                                            className="invalid-feedback animated fadeInUp"
                                                            style={{ display: "block" }}
                                                        >
                                                            {mobileError && mobileError}
                                                        </div>

                                                        <div
                                                            id="val-username1-error"
                                                            className="invalid-feedback animated fadeInUp"
                                                            style={{ display: "block" }}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="work_phone"
                                                    onChange={handleInputChange}
                                                    value={emergencyContactData.work_phone}
                                                /> */}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">Email<span className="required">*</span></label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="eg: jhon@yahoo.com"
                                                    name="email"
                                                    onChange={handleInputChange}
                                                    value={emergencyContactData.email}
                                                    pattern="^\S+@\S+\.[a-zA-Z]{2,}$"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid email address.
                                                </Form.Control.Feedback>
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">Address 1<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="address1"
                                                    onChange={handleInputChange}
                                                    value={emergencyContactData.address1}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">Address 2</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="address2"
                                                    onChange={handleInputChange}
                                                    value={emergencyContactData.address2}

                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">City<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="city"
                                                    onChange={handleInputChange}
                                                    value={emergencyContactData.city}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>County</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="state"
                                                    onChange={handleInputChange}
                                                    value={emergencyContactData.state}

                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Country<span className="required">*</span></label>
                                                <Form.Select
                                                    name="country"
                                                    value={emergencyContactData.country}
                                                    onChange={employeeSelectChangeHandler}
                                                    required
                                                    className='form-control'
                                                >
                                                    <option value="">Select Country</option>
                                                    {getCountryofIssue?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.country_of_issue}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Post code<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="zip_code"
                                                    onChange={handleInputChange}
                                                    value={emergencyContactData.zip_code}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mt-2'>
                                        <Row>
                                            <Col>
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            props.setCardView({ emergencyContact: true });
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="primary btn-sm"
                                                        onClick={updateEmergencyContactHandeler}
                                                        disabled={loadingEmergencyContactByUUID || loadingEmergencyContactFn}
                                                    >
                                                        {loadingEmergencyContactFn ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    className="me-2"
                                                                />
                                                                Loading...
                                                            </>
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                            {/* <Col className='col-md-8'>
                                            <div className="col-sm-6 col-md-3 mg-t-10 mg-md-t-0">
                                                <Button
                                                    variant="primary"
                                                    onClick={updateEmergencyContactHandeler}
                                                >{(loadingEmergencyContactFn) ? (
                                                    <>
                                                        <Spinner animation="border" size="sm" className="me-2" />
                                                        Loading...
                                                    </>
                                                ) : (
                                                    'Submit'
                                                )}
                                                </Button>
                                            </div>
                                        </Col> */}
                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default EmergencyContact