import React, { useContext } from "react";
import { Button, Spinner, Table, Badge } from "react-bootstrap";
import { isValidPermission } from "../../../../services/AuthService";
import { handleFileDownload } from '../../../../utils/ComponentUtilse';
import { dateHandler } from "../../../../utils/ComponentUtilse";
import CustomTooltip from "../../../../utils/CommonUtils";
import { ServiceContext } from "../../../../context/ServiceContext";

function Visa(props) {

    const { canEditEmployee } = useContext(ServiceContext);

    const handleEdit = (id) => {
        props.setCardView({ visaEdit: true, visaId: id })
    }

    return (
        <div className="card" id="visa">
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">
                    <i className="fa fa-newspaper font-18 align-middle me-2 text-primary"></i>
                    &nbsp; Visa
                </h4>
                {canEditEmployee && isValidPermission('employee.add_employeevisa', props.getPermissionList) &&
                    <Button className="me-2 btn-sm"
                        variant="primary"
                        onClick={() => {
                            props.setCardView({ visaEdit: true });
                        }}>
                        <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Add
                    </Button>
                }
            </div>
            <div className="card-body">
                <Table responsive>
                    <thead>
                        <tr>
                            <th className="minWidth200">Visa Number</th>
                            <th className="minWidth200">Visa Type</th>
                            <th className="minWidth250">Country Of Issue</th>
                            <th className="minWidth150">Issue Date</th>
                            <th className="minWidth150">Expiration Date</th>
                            <th className="minWidth50">Current Active</th>
                            {isValidPermission('employee.change_employeevisa', props.getPermissionList) &&
                                <th className="minWidth100">Action</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {props.isLoading ?
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={7}>
                                    <Spinner animation="border" size="lg" className="me-2" />
                                </td>
                            </tr>
                            : props.employeeVisaList.length > 0 ?
                                props.employeeVisaList.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item.visa_number}</td>
                                            <td>{item.visa_type_name}</td>
                                            <td>{item.country_of_issue_name}</td>
                                            <td>{dateHandler(item.issue_date)}</td>
                                            <td>{dateHandler(item.expiration_date)}</td>
                                            <td className="text-center">{item.current_active ?
                                                <Badge pill bg="success" className='badge-sm text-capitalize'>Yes</Badge> :
                                                <Badge pill bg="danger" className='badge-sm text-capitalize'>No</Badge>
                                            }
                                            </td>
                                            {canEditEmployee && isValidPermission('employee.change_employeevisa', props.getPermissionList) &&
                                                <td>
                                                    <CustomTooltip id={`edit-${item.index}`} message="Edit">
                                                        <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i></Button>
                                                    </CustomTooltip>
                                                    {item.attachment_url ?
                                                        <CustomTooltip id={`download-${item.index}`} message="Download">
                                                            <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleFileDownload(item.attachment_url)}><i className="fa fa-download"></i></Button>
                                                        </CustomTooltip> : null}
                                                </td>
                                            }
                                        </tr>
                                    );
                                })
                                : <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={7}>No records found</td>
                                </tr>}
                    </tbody>
                </Table>
            </div>
        </div>
    )

}

export default Visa;