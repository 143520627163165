import React, { useMemo, useEffect, useState, useContext } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import '../components/table/FilteringTable/filtering.css'
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Button, Alert, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ServiceContext } from '../../context/ServiceContext';
import { Link } from "react-router-dom";
import { IsValidPermissions } from '../../services/AuthService';
import DataTable from '../pages/commonPages/DataTable';
import { useRecruitments } from '../../actions/recruitment-actions';
import RecruitmentModal from './RecruitmentModal';


const RecruitmentList = () => {

    const navigate = useNavigate();

    const { recruitmentModal, setRecruitmentModal } = useContext(ServiceContext);

    const [data, setData] = useState([]);

    const { data: getRecruitments, isLoading: loadingGetRecruitments, isFetching: loadingGeRecruitmentsFetchFn } =
        useRecruitments();

    useEffect(() => {
        if (getRecruitments) {
            setData(getRecruitments);
        }
    }, [getRecruitments]);

    const TagList = ({ tags }) => {
        const tagArray = tags?.split(',');

        return (
            <div>
                {tagArray?.map((tag, index) => (
                    <Badge key={index} className='badge-sm text-capitalize' variant="primary">
                        {tag.trim()}
                    </Badge>
                ))}
            </div>
        );
    };

    const truncateText = (text, maxLength) => {
        if (text?.length <= maxLength) {
            return text;
        } else {
            // Truncate the text and add "..."
            return text?.slice(0, maxLength) + "...";
        }
    };


    const columns = useMemo(
        () => [
            {
                Header: "Job ID",
                Footer: "Job ID",
                accessor: "job_id",
                Filter: ColumnFilter,
                className: "minWidth100",
                Cell: ({ cell, row }) => {
                    return (
                        <div>
                            <Link className='table-icon-row' to={`/job-view/${row?.original?.id}`}>
                                <h6 className="text-black  fs-16 mb-0">
                                    {row?.original?.job_id}
                                </h6>
                            </Link>
                        </div>
                    )
                }
            },

            {
                Header: "Status",
                Footer: "Status",
                accessor: "job_status_name",
                Filter: ColumnFilter,
                className: "minWidth120",
                Cell: ({ row }) => {
                    return (
                        <Badge className='badge-sm text-capitalize' variant="primary">
                            {row?.original?.job_status_name}
                        </Badge>
                    )
                }
            },

            {
                Header: "Position name",
                Footer: "Position name",
                accessor: "position_name",
                Filter: ColumnFilter,
                className: "minWidth200",
                Cell: ({ cell, row }) => {
                    return (
                        <div>
                            <Link className='table-icon-row' to={`/job-view/${row?.original?.id}`}>
                                <h6 className="text-black  fs-16 mb-0">
                                    {row?.original?.position_name}
                                </h6>
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: "Job Type",
                Footer: "Job Type",
                accessor: "job_type_name",
                Filter: ColumnFilter,
                className: "minWidth120",
            },
            {
                Header: "Salary Range",
                Footer: "Salary Range",
                accessor: "salary_range_max",
                Filter: ColumnFilter,
                className: "minWidth220",
                Cell: ({ cell, row }) => {
                    return (
                        <div>
                            £{row?.original?.salary_range_min} - £{row?.original?.salary_range_max}
                        </div>
                    )
                }
            },

            // {
            //     Header: "experience",
            //     Footer: "experience",
            //     accessor: "minimum_years_of_experience",
            //     Filter: ColumnFilter,
            // },
            {
                Header: "Skills",
                Footer: "Skills",
                accessor: "primary_skills",
                Filter: ColumnFilter,
                className: "minWidth180",
                Cell: ({ cell, row }) => {
                    const primarySkills = row?.original?.primary_skills;

                    const secondarySkills = row?.original?.secondary_skills;

                    return (
                        <div>
                            <TagList tags={primarySkills} />
                            <TagList tags={secondarySkills} />
                        </div>
                    )
                }
            },
            {
                Header: "Job description",
                Footer: "Job description",
                accessor: "job_description",
                Filter: ColumnFilter,
                className: "minWidth400",
                Cell: ({ cell, row }) => {
                    const description = row?.original?.job_description;
                    const truncatedDescription = truncateText(description, 50);
                    const [showFullDescription, setShowFullDescription] = useState(false);

                    return (
                        <div>
                            <div>
                                {description ? (
                                    <span>
                                        {showFullDescription ? description : truncatedDescription}
                                    </span>
                                ) : (
                                    <span></span>
                                )}
                            </div>
                            {description?.length > 50 && (
                                <span
                                    className="read-more-link text-primary"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setShowFullDescription(!showFullDescription)}
                                >
                                    {showFullDescription ? "Read Less" : "Read More"}
                                </span>
                            )}
                        </div>
                    );
                },
            },


            // {
            //     Header: "Job description",
            //     Footer: "Job description",
            //     accessor: "job_description",
            //     Filter: ColumnFilter,
            // },
            {
                Header: "No of vacancies",
                Footer: "No of vacancies",
                accessor: "no_of_vacancies",
                Filter: ColumnFilter,
                className: "minWidth50",

            },

        ],
        []
    );


    // const handleEdit = (id) => {
    //     console.log(id)
    //     navigate(`/employee-profile-view/${id}`)
    // }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance


    const { pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Filtering" motherMenu="Table" />
            {IsValidPermissions('employee.can_view_all_employee') &&
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Recruitments</h4>
                        <div className="d-inline-flex">
                            <Button className='me-2' variant='primary btn-sm' onClick={() => { setRecruitmentModal(true) }}>
                                <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; Job Vaccancy
                            </Button>
                        </div>
                        {/* {IsValidPermissions('employee.can_add_all_employee') &&
                            <div className="d-inline-flex">
                                <Button className='me-2' variant='primary btn-sm' onClick={() => {setRecruitmentModal(true) }}>
                                    <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; Job Vaccancy
                                </Button>
                            </div>
                        } */}
                    </div>
                    <div className="card-body" style={{ overflowX: "auto" }}>
                        <DataTable
                            isLoading={loadingGetRecruitments}
                            columns={columns}
                            tableInstance={tableInstance}
                        />
                        <RecruitmentModal />
                    </div>
                </div>
            }

            {(!loadingGetRecruitments && !IsValidPermissions('employee.can_view_all_employee')) &&
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <Alert variant="warning" show={true}>

                                <svg
                                    viewBox='0 0 24 24'
                                    width='24'
                                    height='24'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='me-2'
                                >
                                    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                    <line x1='12' y1='9' x2='12' y2='13'></line>
                                    <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                </svg>

                                <strong>Info!  </strong> You don't have permission to view Recuritment Information.

                            </Alert>
                        </div>
                    </div>
                </div>
            }

        </>
    )

}
export default RecruitmentList;