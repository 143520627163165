import React, { useMemo, useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { GlobalFilter } from "../components/table/FilteringTable/GlobalFilter";
import "../components/table/FilteringTable/filtering.css";
import { useGetCompanyList } from "../../actions/company-action";
import { ColumnFilter } from "../components/table/FilteringTable/ColumnFilter";
import { Link } from "react-router-dom";
import { Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IsValidPermissions } from "../../services/AuthService";

export const CompanyListView = () => {
  const navigate = useNavigate();
  const { data: getAllCompany, isLoading: loadingGetCompanyFn } =
    useGetCompanyList();

  return (
    <>
      {IsValidPermissions('organization.view_company') &&
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h4 className="card-title">
              <i className="fa fa-building font-18 align-middle me-2 text-primary"></i>
              &nbsp; Company
            </h4>

            {IsValidPermissions('organization.change_company') &&
              <>
                {!loadingGetCompanyFn && getAllCompany?.length > 0 ? (
                  <Button
                    className="me-2 btn-sm"
                    variant="primary"
                    onClick={() => {
                      navigate("/add-company/", {
                        state: { rowId: getAllCompany?.[0].id },
                      });
                    }}
                  >
                    <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Edit
                  </Button>
                ) : (
                  <></>
                )}
              </>
            }

            {IsValidPermissions('organization.add_company') &&
              <>
                {!loadingGetCompanyFn && getAllCompany?.length === 0 ? (
                  <Button
                    className="me-2 btn-sm"
                    variant="primary"
                    onClick={() => {
                      navigate("/add-company/");
                    }}
                  >
                    <i className="fas fa-plus"></i>&nbsp;&nbsp;Add
                  </Button>
                ) : (
                  <></>
                )}
              </>
            }
          </div>
          <div className="card-body">
            <div className="form-validation">
              <div className="row">
                {!loadingGetCompanyFn && getAllCompany?.length > 0 ? (
                  <div className="col-xl-12">
                    <div className="form-group mb-3 row">
                      <label className="col-lg-3 col-form-label">
                        Company Name:
                      </label>
                      <label className="col-lg-9 col-form-label">
                        {getAllCompany?.[0]?.company_name}
                      </label>
                      <label className="col-lg-3 col-form-label">Email:</label>
                      <label className="col-lg-9 col-form-label">
                        {getAllCompany?.[0]?.email}
                      </label>
                      <label className="col-lg-3 col-form-label">
                        Contact Number:
                      </label>
                      <label className="col-lg-9 col-form-label">
                        {getAllCompany?.[0]?.contact_number}
                      </label>

                      <label className="col-lg-3 col-form-label">Address1:</label>
                      <label className="col-lg-9 col-form-label">
                        {getAllCompany?.[0]?.address1}
                      </label>
                      <label className="col-lg-3 col-form-label">Address2:</label>
                      <label className="col-lg-9 col-form-label">
                        {getAllCompany?.[0]?.address2}
                      </label>
                      <label className="col-lg-3 col-form-label">City:</label>
                      <label className="col-lg-9 col-form-label">
                        {getAllCompany?.[0]?.city}
                      </label>
                      <label className="col-lg-3 col-form-label">Country:</label>
                      <label className="col-lg-9 col-form-label">
                        {getAllCompany?.[0]?.country}
                      </label>
                      <label className="col-lg-3 col-form-label">State:</label>
                      <label className="col-lg-9 col-form-label">
                        {getAllCompany?.[0]?.state}
                      </label>
                      <label className="col-lg-3 col-form-label">ZipCode:</label>
                      <label className="col-lg-9 col-form-label">
                        {getAllCompany?.[0]?.zip_code}
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {!loadingGetCompanyFn && getAllCompany?.length === 0 ? (
                  <div className="col-xl-12">
                    <h4 className="text-center mt-5">No Records</h4>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      }

      {(!loadingGetCompanyFn && !IsValidPermissions('organization.view_company')) &&
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <Alert variant="warning" show={true}>

                <svg
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  stroke='currentColor'
                  strokeWidth='2'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='me-2'
                >
                  <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                  <line x1='12' y1='9' x2='12' y2='13'></line>
                  <line x1='12' y1='17' x2='12.01' y2='17'></line>
                </svg>

                <strong>Info!  </strong> You don't have permission to view Company.

              </Alert>
            </div>
          </div>
        </div>
      }


    </>
  );
};
export default CompanyListView;
