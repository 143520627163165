export const PROFILE_MESSAGES = {
  profileDetails: 'Profile Details',
  profilePicture: 'Profile Picture',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  saveButton: 'Save',
  uploadButton: 'Upload'
}

export const PASSWORD_MESSAGES = {
  changePassword: 'Change Password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  changePasswordButton: 'Change Password',
  resetPassword: 'Reset Password'
}

export const FORGOT_PASSWORD_MESSAGES = {
  forgotPasswordTitle: 'Forgot password',
  temporaryPwdContent: 'We’ll send a temporary password to this email',
  submitButton: 'Submit',
  backButton: 'Back'
}

export const VALIDATION_MESSAGES = {
  enterValidEmail: 'Please enter valid email',
  emailRequired: 'Email is Required',
  passwordMisMatch: 'Passwords do not match.',
  newPasswordRequired: 'New password is Required',
  confirmPasswordRequired: 'Confirm password is Required'
}

export const COMMON_MESSAGES = {
  loading: 'Loading...'
}
