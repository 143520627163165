import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const createNewCountry = async (data) => {
    let config = {
      method: "post",
      url: "employee/api/v1/employee_country_of_issue/",
      headers: {
        "Content-Type": "application/json",
      },
      data:data
    };
    
    return await axios.create().request(config)
  };
  export const useCreateCountry = () => {
    return useMutation(["announcement-register"], createNewCountry);
  };

  
const getAllCountry = async () => {
  let config = {
    method: "get",
    url: "employee/api/v1/employee_country_of_issue/",
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const response = await axios.request(config);
  return response?.data;
};
export const useGetCountryList = () => {
  return useQuery(["get-all-country"], getAllCountry);
};


const getCountryDetail = async (query) => {
  let [, id] = query.queryKey;
  let config = {
    method: "get",
    url: `employee/api/v1/employee_country_of_issue/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const response = await axios.request(config);
  return response?.data;
};
export const useGetCountryDetail = (id) => {
  return useQuery(["get-country-by-id", id], getCountryDetail,{enabled: id?true:false});
};

const countryUpdate = async (data) => {
  let {id, country} = data;
  let config = {
    method: "patch",
    url: `employee/api/v1/employee_country_of_issue/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: country,
  };
  return await axios.request(config);
};
export const useCountryUpdate = () => {
  return useMutation(["country-update"], countryUpdate);
};


//API DELETE METHOD
const countryDeleteById = async (id) => {
  try {
    const response = await axios.delete(`/employee/api/v1/employee_country_of_issue/${id}/`);
    return response.data;
  } catch (error) {
    // Handle error or throw it again
    throw error;
  }
};

export const useCountryDeleteById = () => {
  return useMutation(["country-delete"], countryDeleteById);
};

