import Select from "react-select";

export const Select2Select = (props) => {
  const options = props?.selectOptions?.map((e) => {
    return {
      label: `${e[props.element_name]}`,
      value: e[props.element_id],
    };
  });

  return (
    <Select
      {...props}
      options={options}
      value={options?.filter((e) => e.value === props?.value)}
      onChange={(e) =>
        props.setStatet({ ...props.getState, [props.name]: e.value })
      }
      styles={{
        control: (provided, state) => ({
          ...provided,
          height: "55px",
          "border-color": "hsl(228deg 20% 95.1%)",
        }),
      }}
    />
  );
};


export const Select2Employee = (props) => {
  const options = props?.selectOptions?.map((e) => {
    console.log('eeee', e)
    return {
      label: e?.employee_fullname,
      avatar: e?.employee_profile_url,
      value: e?.id,
    };
  });

  const CustomOption = ({ innerProps, label, data }) => (
    <div style={{padding: '10px', cursor: "pointer"}} 
    {...innerProps}>
      {data.avatar ? (
      <img
        className="ml-3 rounded-circle img-thumbnail"
        src={data.avatar}
        alt=''
        style={{ width: '30px', height: '30px', marginRight: '8px', borderRadius: '50%' }}
      />
      ) : (
        <span
          style={{padding: '4px 7px 4px 7px', textTransform: "uppercase"}}
          className="rounded-lg me-2 justify-content-center user-avator-letter">
          {label.charAt(0)}
        </span>
    )}
      {label}
    </div>
  );

  return (
    <Select
      {...props}
      options={options}
      components={{
        Option: CustomOption,
      }}
      value={options?.filter((e) => e.value === props?.value)}
      onChange={(e) =>
        props.setStatet({ ...props.getState, [props.name]: e.value })
      }
      styles={{
        control: (provided, state) => ({
          ...provided,
          height: "55px",
          "border-color": "hsl(228deg 20% 95.1%)",
        }),
      }}
    />
  );
};
