import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const postTimesheet = async (timesheet) => {
  let config = {
    method: "post",
    url: "timesheet/api/v1/timesheet/",
    headers: {
      "Content-Type": "application/json",
    },
    data: timesheet
  };

  return await axios.request(config)
};
export const usePostTimesheet = () => {
  return useMutation(["post-timesheet"], postTimesheet);
};

const getTimeSheet = async (query) => {
  let [, employee_uuid, project_name] = query.queryKey;
  project_name = encodeURIComponent(project_name);
  let config = {
    method: "get",
    url: `/timesheet/api/v1/timesheet/?resource__employee_uuid=${employee_uuid}&projects__project_name=${project_name}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetTimesheetByUUID = (employee_uuid, project_name) => {
  return useQuery(["get-employee-timesheet", employee_uuid, project_name], getTimeSheet, { enabled: false, cacheTime: 0 });
};


const getEmployeeTimeSheet = async (query) => {
  let [, employee_uuid, project_name, date] = query.queryKey;
  project_name = encodeURIComponent(project_name);
  let config = {
    method: "get",
    url: `/timesheet/api/v1/timesheet_employee_view/?resource__employee_uuid=${employee_uuid}&projects__project_name=${project_name}&date=${date}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetEmployeeTeamsheetByUUID = (employee_uuid, project_name, date) => {
  return useQuery(["get-employee-timesheet", employee_uuid, project_name, date], getEmployeeTimeSheet, { enabled: false });
};

//API DELETE METHOD

const timeSheetDeleteById = async (id) => {
  try {
    const response = await axios.delete(`/timesheet/api/v1/timesheet_update_view/${id}/`);
    return response.data;
  } catch (error) {
    // Handle error or throw it again
    throw error;
  }
};

export const useTimeSheetDeleteById = () => {
  return useMutation(["timesheet-delete"], timeSheetDeleteById);
};

// Employee Info Request
const timeSheetUpdateFn = async (timesheetData) => {
  let { rowId, patchData } = timesheetData;
  let config = {
    method: "patch",
    url: `/timesheet/api/v1/timesheet_update_view/${rowId}/`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: patchData,
  };
  return await axios.request(config);
};
export const useTimeSheetUpdateFn = () => {
  return useMutation(["timesheet-update"], timeSheetUpdateFn);
};


const getEmployeeTimeSheetSummary = async (query) => {
  let [, date] = query.queryKey;
  // project_name = encodeURIComponent(project_name);
  let config = {
    method: "get",
    url: `/timesheet/api/v1/timesheet_summary_report/?date=${date}`,
    // url: `/timesheet/api/v1/timesheet_summary_report/?resource__employee_uuid=${employee_uuid}&projects__project_name=${project_name}&date=${date}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetEmployeeTimemsheetSummary = (date) => {
  return useQuery(["get-employee-timesheet-summary", date], getEmployeeTimeSheetSummary);
};