import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

//API DELETE METHOD
const taskDeleteById = async (id) => {
  try {
      const response = await axios.delete(`/task/api/v1/task/${id}/`);
      return response.data;
  } catch (error) {
      // Handle error or throw it again
      throw error;
  }
};

export const useTaskDeleteById = () => {
  return useMutation(["Task-delete"], taskDeleteById);
};

const createNewtask = async (team) => {
    let config = {
      method: "post",
      url: "task/api/v1/task/",
      headers: {
        "Content-Type": "application/json",
      },
      data:team
    };
    
    return await axios.create().request(config)
  };
  export const useCreateTask = () => {
    return useMutation(["task-register"], createNewtask);
  };

  const getAllTask = async () => {
    let config = {
      method: "get",
      url: "/task/api/v1/task/",
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useTaskList = () => {
    return useQuery(["get-all-task"], getAllTask);
  };

  
  const getTaskDetails = async (query) => {
    let [, id] = query.queryKey;
    let config = {
      method: "get",
      url: `task/api/v1/task/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetTaskDetails = (id) => {
    return useQuery(["get-task-by-id", id], getTaskDetails,{enabled: id?true:false});
  };


  const taskUpdate = async (data) => {
    let {id, taskdata} = data;
    let config = {
      method: "patch",
      url: `task/api/v1/task/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: taskdata,
    };
    return await axios.request(config);
  };
  export const useTaskUpdate = () => {
    return useMutation(["task-update"], taskUpdate);
  };

  const createNewTaskPriority = async (team) => {
    let config = {
      method: "post",
      url: "task/api/v1/task_priority/",
      headers: {
        "Content-Type": "application/json",
      },
      data:team
    };
    
    return await axios.create().request(config)
  };
  export const useCreateTaskPriority = () => {
    return useMutation(["priority-register"], createNewTaskPriority);
  };

  
  const getAllTaskPriority = async () => {
    let config = {
      method: "get",
      url: "/task/api/v1/task_priority/",
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useTaskPriorityList = () => {
    return useQuery(["get-all-priority-task"], getAllTaskPriority);
  };

  const getPriorityDetils = async (query) => {
    let [, id] = query.queryKey;
    let config = {
      method: "get",
      url: `task/api/v1/task_priority/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const usePriorityDetails = (id) => {
    return useQuery(["get-priority-by-id", id], getPriorityDetils);
  };

  const priorityUpdate = async (data) => {
    let {id, priorityTask} = data;
    let config = {
      method: "patch",
      url: `task/api/v1/task_priority/${id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: priorityTask,
    };
    return await axios.request(config);
  };
  export const usePriorityUpdate = () => {
    return useMutation(["priority-update"], priorityUpdate);
  };
  