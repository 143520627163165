import React, { Fragment, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import {
    useEmployeeCertificationPostFn,
    useEmployeeCertificationUpdateFn,
    useGetEmployeeCertificationByID
} from '../../../actions/employee-certification-action';
import { useEmployeeDocumentPostFn, useEmployeeDocumentUpdateFn, useGetEmployeeDocumentByID } from '../../../actions/document-action';
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { extractFileName } from '../../../utils/ComponentUtilse';
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import LoadingOverlay from 'react-loading-overlay';


function EmployeeDocument(props) {

    const client = useQueryClient();

    let id = props?.documentId;

    const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const [employeeCertificationData, setEmployeeCertificationData] = useState({});

    const [currentAttachmentData, setCurrentAttachmentData] = useState(null);

    const {
        data: getEmployeeDocumentID,
        isLoading: loadingGetEmployeeDocumentFn,
        refetch: refetchEmployeeDocument,
    } = useGetEmployeeDocumentByID(id);

    const {
        mutate: postEmployeeDocumentFn,
        isLoading: loadingPostEmployeeDocumentFn,
    } = useEmployeeDocumentPostFn();

    const {
        mutate: patchEmployeeDocumentFn,
        isLoading: loadingEmployeeDocumentFn,
    } = useEmployeeDocumentUpdateFn();


    const submitForm = () => {
        let FormValidity = FormRef.current.reportValidity();

        if (FormValidity === true) {
            if (id) {
                updateEmployeeCertificationHandeler();
            }
            else {
                postEmployeeCertificationHandeler();
            }
        }
    }

    const postEmployeeCertificationHandeler = () => {

        let userData = {
            attachment: employeeCertificationData.attachment,
            name: employeeCertificationData.name,
            description: employeeCertificationData.description,
            expiry_date:employeeCertificationData.expiry_date,
            employee_employee_uuid: employee_uuid
        }

        postEmployeeDocumentFn(
            userData,
            {
                onSuccess: (response) => {
                    toast.success('Employee Document Added', TostifyConfig);
                    client.invalidateQueries(['get-employee-document-by-uuid']);
                    client.invalidateQueries(['get-pending-action-by-uuid']);
                    props.setCardView({ documents: true });
                },
            }
        );
    }


    const updateEmployeeCertificationHandeler = () => {

        let userData = {
            //attachment: employeeCertificationData.attachment,
            name: employeeCertificationData.name,
            description: employeeCertificationData.description,
            expiry_date:employeeCertificationData.expiry_date,
            employee_employee_uuid: employee_uuid
        }

        if (employeeCertificationData?.attachment?.name) {
            userData['attachment'] = employeeCertificationData.attachment
        }

        patchEmployeeDocumentFn(
            { id, userData },
            {
                onSuccess: (response) => {
                    //navigate('/list-certification')
                    toast.success('Employee Document Updated', TostifyConfig);
                    client.invalidateQueries(['get-employee-certification-by-uuid']);
                    props.setCardView({ documents: true });
                    props.refetchDocuments();
                },
            }
        );
    }


    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (event.target.type === "file") {
            setEmployeeCertificationData({
                ...employeeCertificationData,
                [name]: event.target.files[0], // Capture the selected file object
            });
            setCurrentAttachmentData(event.target.files[0].name);
        } else {
            setEmployeeCertificationData({
                ...employeeCertificationData,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        if (id) {
            refetchEmployeeDocument();
        }

        if (getEmployeeDocumentID) {
            setEmployeeCertificationData(getEmployeeDocumentID);
            setCurrentAttachmentData(extractFileName(getEmployeeDocumentID.attachment));
        }
    }, [getEmployeeDocumentID, refetchEmployeeDocument, id])


    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>Employee Documents</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingGetEmployeeDocumentFn && id}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        ></LoadingOverlay>
                        <Card.Body>
                            <form ref={FormRef}>
                                <Form.Group className='mb-3'>
                                    <Row>
                                        <Col className='col-md-6 mb-2'>
                                            <label className="text-label">File Name<span className="required">*</span></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Type Text"
                                                name="name"
                                                onChange={handleInputChange}
                                                value={employeeCertificationData.name}
                                                required />
                                        </Col>

                                        <Col className='col-md-6 mb-2'>
                                            <label>File Attachment<span className="required">*</span></label>
                                            <Form.Control
                                                type="file"
                                                placeholder="Type Text"
                                                name="attachment"
                                                onChange={handleInputChange}
                                                required={!employeeCertificationData.attachment}
                                            />

                                            <label>Selected File:</label>&nbsp;
                                            <label>{currentAttachmentData}</label>

                                        </Col>
                                    </Row>
                                </Form.Group>
                                {/* <Form.Group className='mb-3'>
                                    <Row>
                                        <Col className='col-md-6 mb-2'>
                                            <label>Issued Date<span className="required">*</span></label>
                                            <Form.Control
                                                type="date"
                                                ///placeholder="Type Text"
                                                name="issued_date"
                                                onChange={handleInputChange}
                                                value={employeeCertificationData.issued_date}
                                                required />
                                        </Col>
                                        <Col className='col-md-6 mb-2'>
                                            <label>Expiration Date<span className="required">*</span></label>
                                            <Form.Control
                                                type="date"
                                                //placeholder="Type Text"
                                                name="expiration_date"
                                                onChange={handleInputChange}
                                                value={employeeCertificationData.expiration_date}
                                                required />
                                        </Col>
                                    </Row>
                                </Form.Group> */}
                                <Form.Group className='mb-3'>
                                    <Row>
                                        <Col className='col-md-6 mb-2'>
                                            <label className="text-label">Expiry Date<span className="required">*</span></label>
                                            <Form.Control
                                                type="date"
                                                placeholder="Type Text"
                                                name="expiry_date"
                                                onChange={handleInputChange}
                                                value={employeeCertificationData.expiry_date}
                                                required />
                                        </Col>
                                        <Col className='col-md-6 mb-2'>
                                            <label>File Descripition</label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="description"
                                                onChange={handleInputChange}
                                                value={employeeCertificationData.description}
                                            />
                                        </Col>

                                    </Row>
                                </Form.Group>

                                <Form.Group className='mt-2'>
                                    <Row>
                                        <Col>
                                            <hr />
                                            <div
                                                style={{ display: "flex", justifyContent: "center" }}
                                            >
                                                <Button
                                                    variant="danger light btn-sm"
                                                    onClick={() => {
                                                        props.setCardView({ documents: true });
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button
                                                    variant="primary btn-sm"
                                                    onClick={submitForm}
                                                    disabled={loadingGetEmployeeDocumentFn && id || loadingPostEmployeeDocumentFn || loadingEmployeeDocumentFn}
                                                >
                                                    {(loadingPostEmployeeDocumentFn || loadingEmployeeDocumentFn) ? (
                                                        <>
                                                            <Spinner
                                                                animation="border"
                                                                size="sm"
                                                                className="me-2"
                                                            />
                                                            Loading...
                                                        </>
                                                    ) : (
                                                        "Submit"
                                                    )}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </form>
                        </Card.Body>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default EmployeeDocument;