import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ServiceContext } from "../../../../context/ServiceContext";
import { isValidPermission } from "../../../../services/AuthService";

function SubMenu(props) {

  const { getPermissionList } = useContext(ServiceContext);

  return (
    <div className="card">
      <div className="mail-list">
        <Link
          to="#"
          id="menu-summary"
          className={`list-group-item profile-list ${props.cardView?.summary ? "active" : ""
            }`}
          onClick={() => {
            props.setCardView({ summary: true });
          }}
        >
          <i className="fa fa-indent font-18 align-middle me-2 text-primary"></i>
          Summary
        </Link>

        {isValidPermission('employee.view_employee', getPermissionList) &&
          <Link
            to="#"
            id="menu-work-profile"
            className={`list-group-item profile-list ${props.cardView?.profile || props.cardView?.profileEdit
              ? "active"
              : ""
              }`}
            onClick={() => {
              props.setCardView({ profile: true });
            }}
          >
            <i className="fa fa-user font-18 align-middle me-2 text-primary"></i>
            Work Profile
          </Link>
        }

        {isValidPermission('employee.view_employeepersonalinfo', getPermissionList) &&
          <Link
            to="#"
            id="menu-personal-info"
            className={`list-group-item profile-list ${props.cardView?.personalInfo || props.cardView?.personalInfoEdit
              ? "active"
              : ""
              }`}
            onClick={() => {
              props.setCardView({ personalInfo: true });
            }}
          >
            <i className="fa fa-id-badge font-18 align-middle me-2 text-primary"></i>
            Personal Info
          </Link>
        }

        {isValidPermission('employee.view_emergencycontactinfo', getPermissionList) &&
          <Link
            to="#"
            id="menu-emergency-contact"
            className={`list-group-item profile-list ${props.cardView?.emergencyContact ||
              props.cardView?.emergencyContactEdit
              ? "active"
              : ""
              }`}
            onClick={() => {
              props.setCardView({ emergencyContact: true });
            }}
          >
            <i className="fa fa-phone font-18 align-middle me-2 text-primary"></i>
            Emergency Contact
          </Link>
        }

        {isValidPermission('employee.view_employeebankdetail', getPermissionList) &&
          <Link
            to="#"
            id="menu-bank"
            className={`list-group-item profile-list ${props.cardView?.bank || props.cardView?.bankEdit ? "active" : ""
              }`}
            onClick={() => {
              props.setCardView({ bank: true });
            }}
          >
            <i class="fas fa-university font-18 align-middle me-2 text-primary"></i>
            Bank
          </Link>
        }

        {isValidPermission('employee.view_employeeeducation', getPermissionList) &&
          <Link
            to="#"
            id="menu-education"
            className={`list-group-item profile-list ${props.cardView?.education || props.cardView?.educationEdit
              ? "active"
              : ""
              }`}
            onClick={() => {
              props.setCardView({ education: true });
            }}
          >
            <i className="fas fa-user-graduate font-18 align-middle me-2 text-primary"></i>
            Education
          </Link>
        }

        {isValidPermission('employee.view_employeecertification', getPermissionList) &&
          <Link
            to="#"
            id="menu-certification"
            className={`list-group-item profile-list ${props.cardView?.certification || props.cardView?.certificationEdit
              ? "active"
              : ""
              }`}
            onClick={() => {
              props.setCardView({ certification: true });
            }}
          >
            <i className="fa fa-certificate font-18 align-middle me-2 text-primary"></i>
            Certification
          </Link>
        }

        {isValidPermission('employee.view_employeepassport', getPermissionList) &&
          <Link
            to="#"
            id="menu-passport"
            className={`list-group-item profile-list ${props.cardView?.passport || props.cardView?.passportEdit
              ? "active"
              : ""
              }`}
            onClick={() => {
              props.setCardView({ passport: true });
            }}
          >
            <i className="fas fa-address-card font-18 align-middle me-2 text-primary"></i>
            Passport
          </Link>
        }

        {isValidPermission('employee.view_employeevisa', getPermissionList) &&
          <Link
            to="#"
            id="menu-visa"
            className={`list-group-item profile-list ${props.cardView?.visa || props.cardView?.visaEdit ? "active" : ""
              }`}
            onClick={() => {
              props.setCardView({ visa: true });
            }}
          >
            <i className="fa fa-newspaper font-18 align-middle me-2 text-primary"></i>
            Visa
          </Link>
        }




        {isValidPermission('employee.view_employeecos', getPermissionList) &&
          <Link
            to="#"
            id="menu-cos"
            className={`list-group-item profile-list ${props.cardView?.cos || props.cardView?.cosEdit ? "active" : ""
              }`}
            onClick={() => {
              props.setCardView({ cos: true });
            }}
          >
            <i className="fa fa-newspaper font-18 align-middle me-2 text-primary"></i>
            COS
          </Link>
        }

        {isValidPermission('employee.view_employeerighttowork', getPermissionList) &&
          <Link
            to="#"
            id="menu-rtw-admin"
            className={`list-group-item profile-list ${props.cardView?.rtw || props.cardView?.rtwEdit ? "active" : ""
              }`}
            onClick={() => {
              props.setCardView({ rtw: true });
            }}
          >
            <i className="fa fa-newspaper font-18 align-middle me-2 text-primary"></i>
            Right To Work
          </Link>
        }

        {isValidPermission('employee.can_view_employee_rtw', getPermissionList) &&
          <Link
            to="#"
            id="menu-rtw-employee"
            className={`list-group-item profile-list ${props.cardView?.employeertw || props.cardView?.rtemployeertwwEdit ? "active" : ""
              }`}
            onClick={() => {
              props.setCardView({ employeertw: true });
            }}
          >
            <i className="fa fa-newspaper font-18 align-middle me-2 text-primary"></i>
            Right To Work
          </Link>
        }

        <Link
          to="#"
          id="menu-leave"
          className={`list-group-item profile-list ${props.cardView?.employeeLeaves ? "active" : ""
            }`}
          onClick={() => {
            props.setCardView({ employeeLeaves: true });
          }}
        >
          <i className="fa fa-envelope font-18 align-middle me-2 text-primary"></i>
          Leaves
        </Link>

        {isValidPermission('employee.view_employeeprofileimage', getPermissionList) && (
          <Link
            to="#"
            id="menu-profile-image"
            className={`list-group-item profile-list ${props.cardView?.image ? "active" : ""
              }`}
            onClick={() => {
              props.setCardView({ image: true });
            }}
          >
            <i className="fa fa-camera font-18 align-middle me-2 text-primary"></i>
            Profile Image
          </Link>
        )}

        {isValidPermission('employee.view_employeecertification', getPermissionList) &&
          <Link
            to="#"
            id="menu-documents"
            className={`list-group-item profile-list ${props.cardView?.documents || props.cardView?.documentsEdit
              ? "active"
              : ""
              }`}
            onClick={() => {
              props.setCardView({ documents: true });
            }}
          >
            <i className="fa fa-paperclip font-18 align-middle me-2 text-primary"></i>
            Documents
          </Link>
        }

        <Link
          to="#"
          id="menu-change-history"
          className={`list-group-item profile-list ${props.cardView?.changelogs ? "active" : ""
            }`}
          onClick={() => {
            props.setCardView({ changelogs: true });
          }}
        >
          <i className="fa fa-newspaper font-18 align-middle me-2 text-primary"></i>
          Change History
        </Link>

      </div>
    </div>
  );
}

export default SubMenu;
