import React, { useMemo, useEffect, useState } from "react";
import "../components/table/FilteringTable/filtering.css";
import { Button, Alert, Badge, Table, Spinner } from "react-bootstrap";
import { IsValidPermissions } from "../../services/AuthService";
import { useSubscriptionList } from "../../actions/user-management-action";
import { dateTimeHandler } from "../../utils/ComponentUtilse";
export const SubscriptionList = ({ isLoading, subscriptionList, setPlanCardView }) => {

  const [subscription, setSubscription] = useState([]);

  const { data: getSubscriptionList, isLoading: getSubscriptionLoading } = useSubscriptionList()

  useEffect(() => {
    if (getSubscriptionList) {
      setSubscription(getSubscriptionList);
    }
  }, [getSubscriptionList]);

  return (
    <>
      {IsValidPermissions('organization.view_company') &&
        <div>
          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title">
                <i className="fa fa-building font-18 align-middle me-2 text-primary"></i>
                &nbsp; Subscription
              </h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <div className="row">
                  {!isLoading && subscriptionList?.length > 0 ? (
                    <>
                      <div className="col-xl-12">
                        <div className="form-group mb-3 row">
                          <label className="col-lg-3 col-form-label">
                            Plan Name:
                          </label>
                          <label className="col-lg-9 col-form-label">
                            {subscriptionList?.[0]?.plan_name}
                          </label>
                          <label className="col-lg-3 col-form-label">Price:</label>
                          <label className="col-lg-9 col-form-label">
                            £{subscriptionList?.[0]?.price}
                          </label>
                          <label className="col-lg-3 col-form-label">
                            Number of Users:
                          </label>
                          <label className="col-lg-9 col-form-label">
                            {subscriptionList?.[0]?.no_of_users}
                          </label>
                          <label className="col-lg-3 col-form-label">Plan Start Date:</label>
                          <label className="col-lg-9 col-form-label">
                            {subscriptionList?.[0]?.start_date}
                          </label>
                          <label className="col-lg-3 col-form-label">Plan End Date:</label>
                          <label className="col-lg-9 col-form-label">
                            {subscriptionList?.[0]?.end_date}
                          </label>
                          <label className="col-lg-3 col-form-label">Active:</label>
                          <label className="col-lg-9 col-form-label">
                            {subscriptionList?.[0]?.is_active ?
                              <Badge pill bg="success" className='badge-sm text-capitalize'>Yes</Badge> :
                              <Badge pill bg="danger" className='badge-sm text-capitalize'>No</Badge>
                            }
                          </label>
                          <label className="col-lg-3 col-form-label">Auto Renewal:</label>
                          <label className="col-lg-9 col-form-label">
                            {subscriptionList?.[0]?.auto_renew ?
                              <Badge pill bg="success" className='badge-sm text-capitalize'>Yes</Badge> :
                              <Badge pill bg="danger" className='badge-sm text-capitalize'>No</Badge>
                            }
                          </label>
                        </div>
                      </div>
                      <Button className="btn btn-primary d-block mx-auto mt-5"
                        style={{ width: '20%' }}
                        onClick={() => setPlanCardView(true)}
                      >View Other Plans</Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {!isLoading && subscriptionList?.length === 0 ? (
                    <div className="col-xl-12">
                      <h4 className="text-center mt-5">No Records</h4>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h4 className="card-title">
                <i className="fa fa-receipt font-18 align-middle me-2 text-primary"></i>
                &nbsp; Payments List
              </h4>
            </div>

            <div className="card-body">
              {/* <List /> */}
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>Plan Name</th>
                    <th>Plan Type</th>
                    <th>Price</th>
                    <th>Number of Users</th>
                    <th>Payment Date</th>
                    <th>Plan Start Date</th>
                    <th>Plan End Date</th>
                    <th>Platform</th>
                    <th>Active</th>
                    <th>Auto Renewal</th>
                    <th>Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {getSubscriptionLoading ?
                    <tr>
                      <td style={{ textAlign: 'center' }} colSpan={7}>
                        <Spinner animation="border" size="lg" className="me-2" />
                      </td>
                    </tr>
                    : subscription.length > 0
                      ? subscription.map((item, i) => {
                        return (
                          <tr>
                            <td>{item?.plan_name}</td>
                            <td>{item?.type}</td>
                            <td>£{item?.price}</td>
                            <td>{item?.no_of_users}</td>
                            <td>{dateTimeHandler(item?.payment_date)}</td>
                            <td>{item?.start_date}</td>
                            <td>{item?.end_date}</td>
                            <td>{item?.platform_name}</td>
                            <td>{item?.is_active ?
                              <Badge pill bg="success" className='badge-sm text-capitalize'>Yes</Badge> :
                              <Badge pill bg="danger" className='badge-sm text-capitalize'>No</Badge>}
                            </td>
                            <td>{item?.auto_renew ?
                              <Badge pill bg="success" className='badge-sm text-capitalize'>Yes</Badge> :
                              <Badge pill bg="danger" className='badge-sm text-capitalize'>No</Badge>}
                            </td>
                            <td>
                              {item?.invoice_pdf ?
                                <a href={item?.invoice_pdf} target="_blank">
                                  <i className="fa fa-download"></i>
                                </a>
                                : null
                              }

                            </td>
                          </tr>
                        );
                      })
                      : <tr>
                        <td style={{ textAlign: 'center' }} colSpan={7}>No records found</td>
                      </tr>
                  }
                </tbody>
              </Table>
            </div>

          </div>

        </div >
      }

      {
        (!isLoading && !IsValidPermissions('organization.view_company')) &&
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <Alert variant="warning" show={true}>

                <svg
                  viewBox='0 0 24 24'
                  width='24'
                  height='24'
                  stroke='currentColor'
                  strokeWidth='2'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='me-2'
                >
                  <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                  <line x1='12' y1='9' x2='12' y2='13'></line>
                  <line x1='12' y1='17' x2='12.01' y2='17'></line>
                </svg>

                <strong>Info!  </strong> You don't have permission to view Company.

              </Alert>
            </div>
          </div>
        </div>
      }


    </>
  );
};
export default SubscriptionList;
