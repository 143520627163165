import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { GlobalFilter } from '../../components/table/FilteringTable/GlobalFilter';
import '../../components/table/FilteringTable/filtering.css';
import {
    useGetEmployeeCertificationByUUID,
} from '../../../actions/employee-certification-action';
import { ColumnFilter } from '../../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../pages/commonPages/DataTable';

export const ListEmployeeCertification = () => {

    const navigate = useNavigate();

    const employee_uuid = '0cc5bf30-cc61-41ef-99c7-cbb3c7a820d9';

    const [data, setData] = useState([]);

    const {
        data: getEmployeeCertificationFn,
        isLoading: loadingGetEmployeeCertificationFn,
    } = useGetEmployeeCertificationByUUID(employee_uuid);

    useEffect(() => {
        if (getEmployeeCertificationFn) {
            setData(getEmployeeCertificationFn);
        }
    }, [getEmployeeCertificationFn]);

    const columns = useMemo(
        () => [
            {
                Header: "Instituation",
                Footer: "Instituation",
                accessor: "institution",
                Filter: ColumnFilter,
            },
            {
                Header: "Name",
                Footer: "Name",
                accessor: "name",
                Filter: ColumnFilter,
            },
            {
                Header: "Issued Date",
                Footer: "Issued Date",
                accessor: "issued_date",
                Filter: ColumnFilter,
            },
            {
                Header: "Expiration Date",
                Footer: "Expiration Date",
                accessor: "expiration_date",
                Filter: ColumnFilter,
            },
            {
                Header: "Description",
                Footer: "Description",
                accessor: "description",
                Filter: ColumnFilter,
            },
            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                disableFilters: true,
                Cell: ({ cell, row }) => { return <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(row.original.id)}><i className="fas fa-pencil-alt"></i></Button> },

            },
        ],
        []
    );



    const handleView = (id) => {

    }

    const handleEdit = (id) => {
        navigate('/add-certification/', { state: { rowId: id } })
    }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const { globalFilter, pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Filtering" motherMenu="Table" />
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Table Filtering</h4>
                </div>
                <div className="card-body">
                    <DataTable
                        isLoading={loadingGetEmployeeCertificationFn}
                        columns={columns}
                        tableInstance={tableInstance}
                    />
                </div>
            </div>
        </>
    )

}
export default ListEmployeeCertification;