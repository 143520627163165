import React, { Fragment, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import {
  useEmployeeBankPostFn,
  useEmployeeBankUpdateFn,
  useGetEmployeeBankByID,
} from '../../../actions/employee-bank-actions';
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { extractFileName } from '../../../utils/ComponentUtilse';
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import LoadingOverlay from 'react-loading-overlay';
import { useAdvertisementPostFn, useAdvertisementUpdateFn, useGetAdvertisementByID } from '../../../actions/recruitment-advertisement-action';

const AdvertisementDetail = (props) => {

  const client = useQueryClient();

  let id = props?.advertisementId

  const jobId = props?.jobId;

  const FormRef = useRef(null);

  const [advertisementData, setAdvertisementData] = useState({});

  const [isChecked, setIsChecked] = useState(false);

  const {
    data: getAdvertisementID,
    isLoading: loadingGetAdvertisementIDFn,
    refetch: refetchAdvertisement,
  } = useGetAdvertisementByID(id);

  const {
    mutate: postAdvertisementFn,
    isLoading: loadingPostAdvertisementFn,
  } = useAdvertisementPostFn();

  const {
    mutate: patchAdvertisementFn,
    isLoading: loadingAdvertisementFn,
  } = useAdvertisementUpdateFn();


  const submitForm = () => {
    let FormValidity = FormRef.current.reportValidity();

    if (FormValidity === true) {
      if (id) {
        updateAdvertisementHandeler();
      }
      else {
        postAdvertisementHandeler();
      }
    }
  }

  const postAdvertisementHandeler = () => {

    let userData = {
      //attachment: employeeVisaData.attachment,
      job_advertisement_link: advertisementData.job_advertisement_link,
      published_on: advertisementData.published_on,
      job_advertisement_platform: advertisementData.job_advertisement_platform,
      job_id: jobId
    }

    postAdvertisementFn(
      userData,
      {
        onSuccess: (response) => {
          toast.success('Advertisement Added', TostifyConfig);
          client.invalidateQueries(['get-rlmt-details-by-jobid']);
          props.setCardView({ advertisement: true });
        },
      }
    );
  }


  const updateAdvertisementHandeler = () => {

    let userData = {
      job_advertisement_link: advertisementData.job_advertisement_link,
      published_on: advertisementData.published_on,
      job_advertisement_platform: advertisementData.job_advertisement_platform,
      job_id: jobId
    }

    patchAdvertisementFn(
      { id, userData },
      {
        onSuccess: (response) => {
          toast.success('Advertisement Details Updated', TostifyConfig);
          client.invalidateQueries(['get-rlmt-details-by-jobid']);
          props.setCardView({ advertisement: true });
        },
      }
    );
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (event.target.type === "checkbox") {
      setAdvertisementData({
        ...advertisementData,
        [name]: event.target.checked,
      });
      setIsChecked(event.target.checked);
    }
    else {
      setAdvertisementData({
        ...advertisementData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (id) {
      refetchAdvertisement();
    }

    if (getAdvertisementID) {
      setAdvertisementData(getAdvertisementID);
      //setCurrentAttachmentData(extractFileName(getAdvertisementID.attachment));
    }
  }, [getAdvertisementID])

  return (
    <Fragment>
      <div>
        <div className='col-xl-12 col-xxl-12'>
          <Card className="az-card">
            <Card.Header>
              <h3>Advertisement</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingGetAdvertisementIDFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-lg-12 mb-2'>
                        <label className="text-label">Job advertisement platform<span className="required">*</span></label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="job_advertisement_platform"
                          onChange={handleInputChange}
                          value={advertisementData.job_advertisement_platform}
                          required />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-12 mb-2'>
                        <label>Job advertisement link<span className="required">*</span></label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="job_advertisement_link"
                          onChange={handleInputChange}
                          value={advertisementData.job_advertisement_link}
                          required />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-12 mb-2'>
                        <label>Published On<span className="required">*</span></label>
                        <Form.Control
                          type="date"
                          placeholder="Type Text"
                          name="published_on"
                          onChange={handleInputChange}
                          value={advertisementData.published_on}
                          required />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mt-2'>
                    <Row>
                      <Col>
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              props.setCardView({ advertisement: true });
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="primary btn-sm"
                            onClick={submitForm}
                            disabled={(loadingGetAdvertisementIDFn && id) || loadingPostAdvertisementFn || loadingAdvertisementFn}
                          >
                            {(loadingPostAdvertisementFn || loadingAdvertisementFn) ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                />
                                Loading...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  )
}

export default AdvertisementDetail;