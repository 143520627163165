import React, { useMemo, useEffect, useState, useContext } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import "../../components/table/FilteringTable/filtering.css";
import { useEmployeeInfo } from "../../../actions/employee-actions";
import { ColumnFilter } from "../../components/table/FilteringTable/ColumnFilter";
import { Button, Alert, ProgressBar, Badge, Tab, Nav, Modal, Form, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ServiceContext } from "../../../context/ServiceContext";
import { Link } from "react-router-dom";
import { IsValidPermissions } from "../../../services/AuthService";
import DataTable from "../../pages/commonPages/DataTable";
import { dateHandler } from "../../../utils/ComponentUtilse";
import { deleteConfirmation } from "../../../utils/ComponentUtilse";
import { useEmployeeDeleteById } from "../../../actions/employee-actions";
import CustomTooltip from "../../../utils/CommonUtils";

import { useGetEmployeeVisaByUUID } from "../../../actions/employee-visa-actions";
import { useGetEmployeePassPortByUUID } from "../../../actions/employee-passport-actions";
import { useGetEmployeeBankByUUID } from "../../../actions/employee-bank-actions";
import { useGetEmployeeCertificationByUUID } from "../../../actions/employee-certification-action";
import { useGetEmergencyContactByUUID } from "../../../actions/employee-actions";
import { useGetEmployeeEducationByUUID } from "../../../actions/employee-education-action";
import { useGetEmployeeDocumentByUUID } from "../../../actions/document-action";
import { useGetLeaveSummary } from "../../../actions/leave-actions";
import {
  useGetEmployeeInfoByUUID,
  useGetEmployeeInfoDetailByUUID,
} from "../../../actions/employee-actions";
import { usePendingActionByUUID } from "../../../actions/pending-actions";
import { useGetEmployeeCosByUUID, useGetEmployeeRtwByUUID } from "../../../actions/cos_rtw-actions";
import EmployeeProfileExportModal from "./EmployeeProfileExport";
import { EMPLOYEMENT_END_STATUS } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { TostifyConfig } from "../../../utils/Constance";

export const ListEmployee = () => {
  const navigate = useNavigate();

  const { setEmployeeOnboardModal, maxNumberOfUser } = useContext(ServiceContext);

  const [profileExportModal, setProfileExportModal] = useState(false)

  const [employeeUUID, setEmployeeUUID] = useState("");

  const [employeeProfileData, setEmployeeProfileData] = useState({});

  const [employeeProfileInfoData, setEmployeeProfileInfoData] = useState({});

  const [employeeEducationList, setEmployeeEducationList] = useState([]);

  const [employeeDocumentList, setEmployeeDocumentList] = useState([]);


  const [employeeCertificationList, setEmployeeCertificationList] = useState(
    []
  );

  const [employeeBankList, setEmployeeBankList] = useState([]);

  const [employeePassPortList, setEmployeePassPortList] = useState([]);

  const [employeeVisaList, setEmployeeVisaList] = useState([]);

  const [employeeCosList, setEmployeeCosList] = useState([]);

  const [employeeRtwList, setEmployeeRtwList] = useState([]);

  const [emergencyContactData, setEmergencyContactData] = useState({});

  const [isChecked, setIsChecked] = useState(false);


  const {
    data: getEmergencyContactByUUID,
    refetch: refetchEmergencyContactByUUID,
  } = useGetEmergencyContactByUUID(employeeUUID);

  const {
    data: getEmployeeDetailByUUID,
    isLoading: LoadingEmployeeDetailByUUID,
    refetch: refetchEmployeeDetailByUUID
  } = useGetEmployeeInfoDetailByUUID(employeeUUID);

  const { data: getEmployeeVisaFn,
    isLoading: loadingGetEmployeeVisaFn,
    refetch: refetchGetEmployeeVisaFn
  } =
    useGetEmployeeVisaByUUID(employeeUUID);

  const {
    data: getEmployeePassPortFn,
    isLoading: loadingGetEmployeePassPortFn,
    refetch: refetchGetEmployeePassPortFn
  } = useGetEmployeePassPortByUUID(employeeUUID);

  const { data: getEmployeeBankFn,
    isLoading: loadingGetEmployeeBankFn,
    refetch: refetchGetEmployeeBankFn

  } =
    useGetEmployeeBankByUUID(employeeUUID);

  const {
    data: getEmployeeCertificationFn,
    isLoading: loadingGetEmployeeCertificationFn,
    refetch: refetchGetEmployeeCertificationFn

  } = useGetEmployeeCertificationByUUID(employeeUUID);

  const {
    data: getEmployeeDocumentFn,
    isLoading: loadingGetEmployeeDocumentFn,
    refetch: refetchDocuments
  } = useGetEmployeeDocumentByUUID(employeeUUID);

  const {
    data: getEmployeeEducationFn,
    isLoading: loadingGetEmployeeEducationFn,
    refetch: refetchGetEmployeeEducationFn
  } = useGetEmployeeEducationByUUID(employeeUUID);

  const {
    data: getEmployeeCosFn,
    isLoading: loadingGetEmployeeCosFn,
    refetch: refetchGetEmployeeCosFn

  } = useGetEmployeeCosByUUID(employeeUUID);

  const {
    data: getEmployeeRtwFn,
    isLoading: loadingGetEmployeeRtwFn,
    refetch: refetchGetEmployeeRtwFn

  } = useGetEmployeeRtwByUUID(employeeUUID);


  const {
    data: getLeaveSummary,
    isLoading: loadingLeaveSummary
  } = useGetLeaveSummary(employeeUUID);

  const { data: getEmployeeInfoByUUID, refetch: refetchEmployeeInfoByUUID } =
    useGetEmployeeInfoByUUID(employeeUUID);

  const { mutate: deleteEmployeeInfo, isLoading: loadingdeleteEmployeeFn } =
    useEmployeeDeleteById();

  const handleProfileModal = (employee_uuid) => {
    setProfileExportModal(true)
    setEmployeeUUID(employee_uuid);
    refetchEmployeeDetailByUUID();
    refetchEmergencyContactByUUID();
    refetchGetEmployeeVisaFn();
    refetchGetEmployeeVisaFn();
    refetchGetEmployeePassPortFn();
    refetchGetEmployeeBankFn();
    refetchGetEmployeeCertificationFn();
    refetchDocuments();
    refetchGetEmployeeEducationFn();
    refetchGetEmployeeCosFn();
    refetchGetEmployeeRtwFn();
  }



  const tabData = [
    {
      name: "Active Employees",
      icon: "users",
      color: "text-primary"
    },
    {
      name: "Sponsored Employees",
      icon: "user-plus",
      color: "text-success"
    },
    {
      name: "Archived Employees",
      icon: "times-circle",
      color: "text-danger"
    },
  ];

  const handleTabClick = () => {
    if (tabName === "Archived Employees") {
      setData(getEmployeeListViewFn.filter((n) => n.active === false));
    } else if (tabName === "Sponsored Employees") {
      setData(
        getEmployeeListViewFn.filter(
          (n) => n.active === true && n.is_sponsored_employee === true
        )
      );
    } else {
      setData(getEmployeeListViewFn.filter((n) => n.active === true));
    }
  };

  const [data, setData] = useState([]);
  const [tabName, setTabName] = useState('');
  const [alertModal, setAlertModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false);
  const [uuId, setUUid] = useState("");
  const [initialData, setInitialData] = useState(null);


  const handleDeleteButtonClick = (employee_uuid) => {
    setDeleteModal(true);
    setUUid(employee_uuid)
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
  };

  const [formData, setFormData] = useState({
    employee_uuid: uuId,
    end_contract_status: "",
    end_contract_date: null,
    end_contract_notes: "",
    eligible_to_rehire: false
  });


  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (e.target.type === "checkbox") {
      setFormData({
        ...formData,
        [name]: e.target.checked,
      });
      setIsChecked(e.target.checked);
    }
    else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        employee_uuid: uuId,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call the useEmployeeDeleteById hook with the form data
      await deleteEmployeeInfo(
        formData,
        {
          onSuccess: (response) => {
            setDeleteModal(false);
            toast.success("Employee Profile Deleted", TostifyConfig);
            refetchEmployee();
            setFormData({}); // Clear the form data
          }
        }
      );


    } catch (error) {
      // Handle error (e.g., show an error message)
      console.error('Error deleting employee:', error);
    }
  };

  useEffect(() => {
    if (getEmployeeDetailByUUID) {
      setEmployeeProfileData(getEmployeeDetailByUUID);

      let initials = "";

      if (getEmployeeDetailByUUID.first_name) {
        // Extract the first letter and capitalize it
        const firstNameInitial = getEmployeeDetailByUUID.first_name
          .charAt(0)
          .toUpperCase();
        initials += firstNameInitial;
      }

      if (getEmployeeDetailByUUID.last_name) {
        // Extract the first letter and capitalize it
        const lastNameInitial = getEmployeeDetailByUUID.last_name
          .charAt(0)
          .toUpperCase();
        initials += lastNameInitial;
      }
      setInitialData(initials);
    }
  }, [getEmployeeDetailByUUID]);


  //UseEffect For pendingAction

  useEffect(() => {
    if (getEmployeeCosFn) {
      setEmployeeCosList(getEmployeeCosFn);
    }
  }, [getEmployeeCosFn]);

  useEffect(() => {
    if (getEmployeeRtwFn) {
      setEmployeeRtwList(getEmployeeRtwFn);
    }
  }, [getEmployeeRtwFn]);

  //UseEffect For personal Info
  useEffect(() => {
    if (getEmployeeInfoByUUID) {
      setEmployeeProfileInfoData(getEmployeeInfoByUUID);
    }
  }, [getEmployeeInfoByUUID]);

  //UseEffect For  Education

  useEffect(() => {
    if (getEmployeeEducationFn) {
      setEmployeeEducationList(getEmployeeEducationFn);
    }
  }, [getEmployeeEducationFn]);

  //UseEffect For Certification

  useEffect(() => {
    if (getEmployeeCertificationFn) {
      setEmployeeCertificationList(getEmployeeCertificationFn);
    }
  }, [getEmployeeCertificationFn]);


  useEffect(() => {
    if (getEmployeeDocumentFn) {
      setEmployeeDocumentList(getEmployeeDocumentFn);
    }
  }, [getEmployeeDocumentFn]);

  //UseEffect For BankDetails

  useEffect(() => {
    if (getEmployeeBankFn) {
      setEmployeeBankList(getEmployeeBankFn);
    }
  }, [getEmployeeBankFn]);

  //UseEffect For PassPort

  useEffect(() => {
    if (getEmployeePassPortFn) {
      setEmployeePassPortList(getEmployeePassPortFn);
    }
  }, [getEmployeePassPortFn]);

  //UseEffect For Visa

  useEffect(() => {
    if (getEmployeeVisaFn) {
      setEmployeeVisaList(getEmployeeVisaFn);
    }
  }, [getEmployeeVisaFn]);

  //UseEffect For Emergency Contact

  useEffect(() => {
    if (getEmergencyContactByUUID) {
      setEmergencyContactData(getEmergencyContactByUUID);
    }
  }, [getEmergencyContactByUUID]);


  const {
    data: getEmployeeListViewFn,
    isLoading: loadingGetEmployeeListFn,
    isFetching: loadingGetEmployeeFetchFn,
    refetch: refetchEmployee,
  } = useEmployeeInfo();


  useEffect(() => {
    if (getEmployeeListViewFn) {
      handleTabClick();
    }
  }, [getEmployeeListViewFn, loadingGetEmployeeFetchFn, tabName]);




  const columns = useMemo(
    () => {
      let updatedColumns = [
        {
          Header: "Employee",
          Footer: "",
          accessor: "employee_fullname",
          Filter: ColumnFilter,
          className: "minWidth220",
          Cell: ({ cell, row }) => {
            // Initialize initials to an empty string
            let initials = "";

            if (row?.original?.first_name) {
              // Extract the first letter and capitalize it
              const firstNameInitial = row?.original?.first_name
                .charAt(0)
                .toUpperCase();
              initials += firstNameInitial;
            }

            if (row?.original?.last_name) {
              // Extract the first letter and capitalize it
              const lastNameInitial = row?.original?.last_name
                .charAt(0)
                .toUpperCase();
              initials += lastNameInitial;
            }
            return (
              <div>
                {row?.original?.employee_profile_image ? (
                  <div className="media">
                    <img
                      src={
                        row?.original?.employee_profile_image_url
                          ?.profile_image_url
                      }
                      className="rounded-lg me-2 user-avator-img"
                      alt=""
                    />{" "}
                    <div className="media-body text-nowrap">
                      {row.original.active ? (
                        <Link
                          className="table-icon-row"
                          to={`/employee-profile-view/${row?.original?.employee_uuid}`}
                        >
                          <h6 className="text-black  fs-16 mb-0">
                            {row?.original?.first_name}
                          </h6>
                          <span className="text-black fs-14 mb-0">
                            {row?.original?.last_name}
                          </span>
                        </Link>
                      ) : (
                        <>
                          <h6 className="text-black  fs-16 mb-0">
                            {row?.original?.first_name}
                          </h6>
                          <span className="text-black fs-14 mb-0">
                            {row?.original?.last_name}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="media">
                    <div className="rounded-lg me-2 justify-content-center user-avator-letter">
                      {initials}
                    </div>{" "}
                    <div className="media-body text-nowrap">
                      {row.original.active ? (
                        <Link
                          className="table-icon-row"
                          to={`/employee-profile-view/${row?.original?.employee_uuid}`}
                        >
                          <h6 className="text-black  fs-16 mb-0">
                            {row?.original?.first_name}
                          </h6>
                          <span className="text-black fs-14 mb-0">
                            {row?.original?.last_name}
                          </span>
                        </Link>
                      ) : (
                        <>
                          <h6 className="text-black  fs-16 mb-0">
                            {row?.original?.first_name}
                          </h6>
                          <span className="text-black fs-14 mb-0">
                            {row?.original?.last_name}
                          </span>
                        </>
                      )}
                    </div>
                    <span className="w-space-no"></span>
                  </div>
                )}
              </div>
            );
          },
        },
        {
          Header: "Position",
          Footer: "Position",
          accessor: "position_name",
          Filter: ColumnFilter,
          className: "minWidth220",
        },
        {
          Header: "Work Location",
          Footer: "Work Location",
          accessor: "work_location_name",
          Filter: ColumnFilter,
          className: "minWidth160",
        },
        {
          Header: "Email",
          Footer: "Email",
          accessor: "work_email",
          Filter: ColumnFilter,
          className: "minWidth320",
        },
        {
          Header: "Is Sponsored Employee",
          Footer: "Is Sponsored Employee",
          accessor: "is_sponsored_employee",
          Cell: ({ cell, row }) => {
            return row?.original?.is_sponsored_employee ? (
              <Badge pill bg="success" className="badge-sm text-capitalize">
                Yes
              </Badge>
            ) : (
              <Badge pill bg="danger" className="badge-sm text-capitalize">
                No
              </Badge>
            );
          },
          Filter: ColumnFilter,
          className: "minWidth120",
        },
        {
          Header: "Passport",
          Footer: "Passport",
          accessor: "passport_expiry_day_count",
          Filter: ColumnFilter,
          className: "minWidth120",
        },
        {
          Header: "Visa",
          Footer: "Visa",
          accessor: "visa_expiry_day_count",
          Filter: ColumnFilter,
          className: "minWidth120",
        },
        {
          Header: "COS",
          Footer: "COS",
          accessor: "cos_expiry_day_count",
          Filter: ColumnFilter,
          className: "minWidth120",
        },
        {
          Header: "RTW",
          Footer: "RTW",
          accessor: "rtw_expiry_day_count",
          Filter: ColumnFilter,
          className: "minWidth120",
        },
        {
          Header: "Start Date",
          Footer: "Start Date",
          accessor: "employee_start_date",
          Cell: ({ row }) => {
            const { original } = row;
            return <span>{dateHandler(original.employee_start_date)}</span>;
          },
          Filter: ColumnFilter,
          className: "minWidth150",
        },
        {
          Header: "Profile Progress",
          Footer: "Profile Progress",
          accessor: "pending_action",
          disableFilters: true,
          Cell: ({ cell, row }) => {
            return (
              <>
                <ProgressBar
                  style={{ height: "20px" }}
                  variant={row?.original?.pending_action_average_percentage === 100 ? "success" : 'warning'}
                  animated
                  now={row?.original?.pending_action_average_percentage}
                  label={`${row?.original?.pending_action_average_percentage}%`}
                />
                {row?.original?.pending_action_average_percentage === 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "-19px",
                      fontSize: "14px",
                      color: "#f06300",
                    }}
                  >
                    0%
                  </div>
                )}
              </>
            );
          },
          className: "minWidth150",
        },
        {
          Header: "Action",
          Footer: "Action",
          accessor: "action",
          disableFilters: true,
          className: "minWidth150",
          Cell: ({ row }) => (
            <>
              {row.original.active && (
                <>
                  <CustomTooltip id={`delete-${row.index}`} message="Delete">
                    <Button
                      className="shadow btn-xs sharp me-1"
                      variant="danger light"
                      style={{ cursor: "pointer" }}
                      disabled={row.original.is_primary_account || loadingdeleteEmployeeFn}
                      onClick={() => handleDeleteButtonClick(row?.original?.employee_uuid)}
                    // onClick={() =>
                    //   deleteConfirmation(
                    //     row.original.employee_uuid,
                    //     `Employee - ${row.original.employee_fullname}`,
                    //     deleteEmployeeInfo,
                    //     refetchEmployee
                    //   )
                    // }
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  </CustomTooltip>
                  &nbsp;
                  <CustomTooltip id={`edit-${row.index}`} message="Edit">
                    <Button
                      className="shadow btn-xs sharp me-1"
                      variant="primary light"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEdit(row?.original?.employee_uuid)}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </Button>
                  </CustomTooltip>

                </>
              )}
              &nbsp;
              <CustomTooltip id={`edit-${row.index}`} message="Export">
                <Button
                  className="shadow btn-xs sharp me-1"
                  variant="primary light"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleProfileModal(row?.original?.employee_uuid)}
                >
                  <i className="fas fa-download"></i>
                </Button>
              </CustomTooltip>
            </>
          ),
        },
      ];
      if (tabName === "Archived Employees") {

        // Find the index of the "Action" column
        const actionColumnIndex = updatedColumns.findIndex(column => column.Header === "Work Location");

        // Insert the updated columns before the "Action" column
        updatedColumns.splice(actionColumnIndex, 0,

          {
            Header: "Contract End Status",
            Footer: "Email",
            accessor: "end_contract_status_name",
            Filter: ColumnFilter,
            className: "minWidth320",
          },
          {
            Header: "Contract End Date",
            Footer: "Email",
            accessor: "end_contract_date",
            Filter: ColumnFilter,
            className: "minWidth320",
          },
          {
            Header: "Contract End Reason",
            Footer: "Email",
            accessor: "end_contract_notes",
            Filter: ColumnFilter,
            className: "minWidth320",
          },
        );
      }
      return updatedColumns;
    },
    [tabName]
  );

  const handleEdit = (id) => {
    navigate(`/employee-profile-view/${id}`);
  };
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const handleAddEmployee = () => {
    if (Number(maxNumberOfUser) >= data?.length + 1) {
      setEmployeeOnboardModal(true)
    } else {
      setAlertModal(true)
    }
  }

  return (
    <>
      <PageTitle activeMenu="Filtering" motherMenu="Table" />
      {IsValidPermissions("employee.can_view_all_employee") && (
        <div className="card">
          <div className="card-body">
            <div className="default-tab">
              <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                <div
                  style={{ padding: "0" }}
                  className="card-header d-flex justify-content-between"
                >
                  <Nav as="ul" className="nav-tabs">
                    {tabData.map((data, i) => (
                      <Nav.Item as="li" key={i}>
                        <Nav.Link
                          eventKey={data.name.toLowerCase()}
                          onClick={() => setTabName(data.name)}
                        >
                          <i className={`${data.color} la la-${data.icon} me-2`} />
                          {data.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  {IsValidPermissions("employee.can_add_all_employee") && (
                    <div>
                      <Button
                        className="me-2"
                        style={{ marginTop: "-20px" }}
                        variant="primary btn-sm"
                        onClick={handleAddEmployee}
                        disabled={loadingGetEmployeeListFn}
                      >
                        <i class="fa fa-user-plus" aria-hidden="true"></i>{" "}
                        &nbsp; Employee

                      </Button>
                    </div>
                  )}
                </div>
                <Tab.Content className="pt-4">
                  {tabData.map((data, i) => (
                    <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                      <DataTable
                        isLoading={loadingGetEmployeeListFn}
                        columns={columns}
                        tableInstance={tableInstance}
                      />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}

      {!loadingGetEmployeeListFn &&
        !IsValidPermissions("employee.can_view_all_employee") && (
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <Alert variant="warning" show={true}>
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="me-2"
                  >
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                    <line x1="12" y1="9" x2="12" y2="13"></line>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                  <strong>Info! </strong> You don't have permission to view
                  Employee Information.
                </Alert>
              </div>
            </div>
          </div>
        )}

      {alertModal && <Modal show={alertModal} centered onHide={() => setAlertModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title><i class="fa fa-info-circle text-danger" aria-hidden="true"></i> &nbsp; Limit Exceeded!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You have exceeded the maximum number of user creation limit,
          Please upgrade your subscription plan to add more users.</Modal.Body>
        <Modal.Footer>
          <Link to={'/Subscription/'}>
            <Button className='me-2' variant='primary btn-sm'>
              <i class="fa fa-address-card" aria-hidden="true"></i> &nbsp; View Subscription
            </Button>

          </Link>
          <Button variant='danger light btn-sm' onClick={() => setAlertModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>}

      {deleteModal &&
        <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>End Of Employment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <Form.Group controlId="formTextField">
                <Row>
                  <Col className='col-lg-6 mb-2'>
                    <label className="text-label">Select Status<span className="required">*</span></label>
                    <Form.Control
                      as="select"
                      name="end_contract_status"
                      value={formData.end_contract_status}
                      onChange={handleFormChange}
                      required
                    >
                      <option value="">Select option...</option>
                      {EMPLOYEMENT_END_STATUS?.map((option) => (
                        <option key={option?.id} value={option?.id}>
                          {option?.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col className='col-lg-6 mb-2'>
                    <label className="text-label">End of employment date <span className="required">*</span></label>
                    <Form.Control
                      type="date"
                      name="end_contract_date"
                      value={formData.end_contract_date}
                      onChange={handleFormChange}
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group controlId="formTextAreaField">
                <label className="text-label">Reason <span className="required">*</span></label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="end_contract_notes"
                  value={formData.end_contract_notes}
                  onChange={handleFormChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Col className="col-md-6 mb-2">
                  <div className="form-check custom-checkbox mt-5">
                    <input
                      type="checkbox"
                      name="eligible_to_rehire"
                      className="form-check-input"
                      id="eligible_to_rehire"
                      checked={isChecked}
                      onChange={handleFormChange}
                      value={formData.eligible_to_rehire}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="eligible_to_rehire"
                    >
                      <b>Eligible to Rehire</b>
                    </label>
                  </div>
                </Col>

              </Form.Group>
              <Modal.Footer>
                <Button variant="primary btn-sm" type="submit">
                  {loadingdeleteEmployeeFn ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Loading...
                    </>
                  ) : (
                    "End employment"
                  )}

                </Button>
                <Button variant='danger light btn-sm' type="submit" onClick={() => setDeleteModal(false)}>
                  Cancel
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      }

      {profileExportModal && <EmployeeProfileExportModal
        loadingEmployeeDetailByUUID={LoadingEmployeeDetailByUUID}
        profileExportModal={profileExportModal}
        setProfileExportModal={setProfileExportModal}
        employeeProfileData={employeeProfileData}
        employeeProfileInfoData={employeeProfileInfoData}
        emergencyContactData={emergencyContactData}
        employeeEducationList={employeeEducationList}
        employeeCertificationList={employeeCertificationList}
        employeeBankList={employeeBankList}
        employeePassPortList={employeePassPortList}
        employeeVisaList={employeeVisaList}
        employeeCosList={employeeCosList}
        employeeRtwList={employeeRtwList}
        employeeLeaveList={getLeaveSummary}
      />}
    </>
  );
};


export default ListEmployee;
