import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";


const draftEmployeeFn = async (userData) => {
    let config = {
        method: "post",
        url: `/employee/api/v1/convert_to_draft_employee/`,
        headers: {
            "Content-Type": "application/json",
        },
        data: userData,
    };

    return await axios.create().request(config);
};
export const useDraftEmployeeFn = () => {
    return useMutation(["employee-draft-register"], draftEmployeeFn);
};


const getDraftEmployeeInfoFn = async (status, status1) => {

    let config = {
        method: "get",
        url: `/employee/api/v1/employee_info/?active=${status}&is_active_employee=${status1}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useDraftEmployeeInfo = (status, status1) => {
    return useQuery(["get-draft-employee-info", status, status1], () => getDraftEmployeeInfoFn(status, status1), { cacheTime: 0 });
};


const draftEmployeeUpdateFn = async (employeeData) => {
    let { employee_uuid, userData } = employeeData;
    let config = {
        method: "patch",
        url: `/employee/api/v1/convert_to_active_employee/${employee_uuid}/`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: userData,
    };
    return await axios.request(config);
};
export const useDraftEmployeeUpdateFn = () => {
    return useMutation(["draft-employee-update"], draftEmployeeUpdateFn);
};