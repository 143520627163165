import React, { useMemo, useEffect, useState, useContext } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import "../../components/table/FilteringTable/filtering.css";
import { useEmployeeInfo } from "../../../actions/employee-actions";
import { ColumnFilter } from "../../components/table/FilteringTable/ColumnFilter";
import { Button, Alert, ProgressBar, Badge, Tab, Nav, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ServiceContext } from "../../../context/ServiceContext";
import { Link } from "react-router-dom";
import { IsValidPermissions } from "../../../services/AuthService";
import DataTable from "../../pages/commonPages/DataTable";
import { dateHandler } from "../../../utils/ComponentUtilse";
import { deleteConfirmation } from "../../../utils/ComponentUtilse";
import { useEmployeeDeleteById } from "../../../actions/employee-actions";
import LoadingOverlay from "react-loading-overlay";
import { useUpdateUserRole, useUserInfo } from "../../../actions/user-management-action";
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import Switch from 'react-switch';

const EditCell = ({ row, updateUserRole }) => {
  const role = row.original.is_admin_account_name; // Assuming this is how you get the user's role
  const isEmployee = role === 'Employee';
  const initialCanEdit = row.original.can_edit_employee === 'Yes';
  const [canEdit, setCanEdit] = useState(initialCanEdit); // Assuming 'can_edit_employee' can be 'YES' or 'NO'

  const handleToggle = () => {
    const newValue = !canEdit;
    setCanEdit(newValue);

    // Prepare the data to be sent in the request
    const userData = {
      employee_uuid: row.original.employee_uuid,
      can_edit: newValue ? 'Yes' : 'No',
    };

    updateUserRole(userData);
  };

  return (
    <Switch
      checked={canEdit}
      onChange={handleToggle}
      onColor="#86d3ff"
      offColor="#adb5bd"
      checkedIcon={false}
      uncheckedIcon={false}
      disabled={!isEmployee}
    />
  );
};


export const UsersList = () => {
  const navigate = useNavigate();

  const [basicModal, setBasicModal] = useState(false);

  const [modalData, setModalData] = useState({});

  const [data, setData] = useState([]);

  const [alertModal, setAlertModal] = useState(false)

  const {
    data: getUsersInfoFn,
    isLoading: loadingGetUsersListFn,
    refetch: refetchUsersList
  } = useUserInfo();

  const { mutate: updateUserRole, isLoading: loadingUpdateUserRole } = useUpdateUserRole();

  useEffect(() => {
    if (getUsersInfoFn) {
      setData(getUsersInfoFn);
    }
  }, [getUsersInfoFn]);

  let badgName = (role) => {
    if (role === 'Primary') return <Badge bg="danger">{role}</Badge>
    if (role === 'Employee') return <Badge bg="info">{role}</Badge>
    if (role === 'Admin') return <Badge bg="primary">{role}</Badge>
  }

  const columns = useMemo(
    () => [
      {
        Header: "User",
        Footer: "",
        accessor: "employee_fullname",
        Filter: ColumnFilter,
        className: "minWidth220",
        Cell: ({ cell, row }) => {
          // Initialize initials to an empty string
          let initials = "";

          if (row?.original?.firstname) {
            // Extract the first letter and capitalize it
            const firstNameInitial = row?.original?.firstname
              .charAt(0)
              .toUpperCase();
            initials += firstNameInitial;
          }

          if (row?.original?.lastname) {
            // Extract the first letter and capitalize it
            const lastNameInitial = row?.original?.lastname
              .charAt(0)
              .toUpperCase();
            initials += lastNameInitial;
          }
          return (
            <div>
              {row?.original?.employee_profile_image ? (
                <div className="media">
                  <img
                    src={
                      row?.original?.employee_profile_url
                        ?.profile_image_url
                    }
                    className="rounded-lg me-2 user-avator-img"
                    alt=""
                  />{" "}
                  <div className="media-body text-nowrap">
                    {row.original.active ? (
                      <Link
                        className="table-icon-row"
                        to={`/employee-profile-view/${row?.original?.employee_uuid}`}
                      >
                        <h6 className="text-black  fs-16 mb-0">
                          {row?.original?.firstname}
                        </h6>
                        <span className="text-black fs-14 mb-0">
                          {row?.original?.lastname}
                        </span>
                      </Link>
                    ) : (
                      <>
                        <h6 className="text-black  fs-16 mb-0">
                          {row?.original?.firstname}
                        </h6>
                        <span className="text-black fs-14 mb-0">
                          {row?.original?.lastname}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className="media">
                  <div className="rounded-lg me-2 justify-content-center user-avator-letter">
                    {initials}
                  </div>{" "}
                  <div className="media-body text-nowrap">
                    {row.original.active ? (
                      <Link
                        className="table-icon-row"
                        to={`/employee-profile-view/${row?.original?.employee_uuid}`}
                      >
                        <h6 className="text-black  fs-16 mb-0">
                          {row?.original?.firstname}
                        </h6>
                        <span className="text-black fs-14 mb-0">
                          {row?.original?.lastname}
                        </span>
                      </Link>
                    ) : (
                      <>
                        <h6 className="text-black  fs-16 mb-0">
                          {row?.original?.firstname}
                        </h6>
                        <span className="text-black fs-14 mb-0">
                          {row?.original?.lastname}
                        </span>
                      </>
                    )}
                  </div>
                  <span className="w-space-no"></span>
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Position",
        Footer: "Position",
        accessor: "employee_position",
        Filter: ColumnFilter,
        className: "minWidth220",
      },
      {
        Header: "Role",
        Footer: "Role",
        accessor: "is_admin_account_name",
        Filter: ColumnFilter,
        className: "minWidth160",
        Cell: ({ row }) => { return badgName(row.original?.is_admin_account_name) }
      },
      {
        Header: 'Allow Edit Profile',
        Footer: 'Allow Edit Profile',
        accessor: 'can_edit_employee',
        Filter: ColumnFilter,
        className: 'minWidth160',
        disableFilters: true,
        Cell: ({ row }) => <EditCell row={row} updateUserRole={updateUserRole} />,
      },
      {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => { return <Button disabled={row.original?.is_admin_account_name === 'Primary'} className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleModal(row.original)}><i className="fa fa-ellipsis-h"></i></Button> }
      }
    ],
    [updateUserRole]
  );

  const handleModal = (data) => {
    setBasicModal(true)
    setModalData(data)
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const handleRole = (data) => {
    let payload = {}
    payload['employee_uuid'] = data.employee_uuid
    if (data.is_admin_account_name === 'Admin') {
      payload['role'] = 'Employee'
    } else payload['role'] = 'Admin'
    try {
      updateUserRole(
        { ...payload },
        {
          onSuccess: (response) => {
            toast.success("User Role Updated", TostifyConfig);
            refetchUsersList();
            setBasicModal(false)
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <PageTitle activeMenu="Filtering" motherMenu="Table" />
      {IsValidPermissions("employee.can_view_all_employee") && (
        <div className="card">
          <div className="card-body">
            <DataTable
              isLoading={loadingGetUsersListFn}
              columns={columns}
              tableInstance={tableInstance}
            />
          </div>
          <Modal className="fade" show={basicModal} onHide={setBasicModal} backdrop='static' keyboard={false}>
            <Modal.Header>
              <Modal.Title>User Details</Modal.Title>
              <Button
                variant=""
                className="btn-close"
                onClick={() => setBasicModal(false)}
              >
              </Button>
            </Modal.Header>
            <LoadingOverlay
              active={loadingUpdateUserRole}
              spinner
              text='Updating...'
              className="custom-overlay"
            >
              <Modal.Body>
                <div className="card-body">
                  <div className="form-validation">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Employee:
                          </label>
                          <label className="col-lg-8 col-form-label">
                            {modalData?.firstname} {modalData?.lastname}
                          </label>
                          <label className="col-lg-4 col-form-label">
                            Role:
                          </label>
                          <label className="col-lg-8 col-form-label">
                            {modalData?.is_admin_account_name}
                          </label>
                          <label className="col-lg-4 col-form-label">
                            Position:
                          </label>
                          <label className="col-lg-8 col-form-label">
                            {modalData?.employee_position}
                          </label>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={loadingUpdateUserRole}
                  onClick={() => setBasicModal(false)}
                  variant="danger light btn-sm"
                >
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button
                  disabled={loadingUpdateUserRole}
                  onClick={() => handleRole(modalData)}
                  variant="primary btn-sm"
                >
                  {modalData?.is_admin_account === 'Yes' ? 'Change Role to Employee' : 'Change Role to Admin'}
                </Button>

              </Modal.Footer>
            </LoadingOverlay>
          </Modal>
        </div>
      )}

      {!loadingGetUsersListFn &&
        !IsValidPermissions("employee.can_view_all_employee") && (
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <Alert variant="warning" show={true}>
                  <svg
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="me-2"
                  >
                    <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                    <line x1="12" y1="9" x2="12" y2="13"></line>
                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                  </svg>
                  <strong>Info! </strong> You don't have permission to view
                  User List.
                </Alert>
              </div>
            </div>
          </div>
        )}

      {alertModal && <Modal show={alertModal} centered onHide={() => setAlertModal(false)} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title><i class="fa fa-info-circle text-danger" aria-hidden="true"></i> &nbsp; Limit Exceeded!</Modal.Title>
        </Modal.Header>
        <Modal.Body>You have exceeded the maximum number of user creation limit,
          Please upgrade your subscription plan to add more users.</Modal.Body>
        <Modal.Footer>
          <Link to={'/Subscription/'}>
            <Button className='me-2' variant='primary btn-sm'>
              <i class="fa fa-address-card" aria-hidden="true"></i> &nbsp; View Subscription
            </Button>

          </Link>
          <Button variant='danger light btn-sm' onClick={() => setAlertModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>}
    </>
  );
};
export default UsersList;
