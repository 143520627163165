import React, { Fragment, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import { useCreateAnnouncementTopic, useAnnouncementTopicUpdate, useGetAnnouncementTopicDetail } from '../../actions/announcement-action';
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { TostifyConfig } from '../../utils/Constance';
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';


function AddAnnouncementsTopic() {

    const navigate = useNavigate();

    const FormRef = useRef(null);

    const location = useLocation()

    let id = location?.state?.rowId


    const { mutate: postNewAnnouncemnetTopic, isLoading: loadingpostAnnouncementTopicFn } = useCreateAnnouncementTopic();

    const { mutate: updateAnnouncemnetTopic, isLoading: loadingpatchAnnouncementTopicFn } = useAnnouncementTopicUpdate();

    const { data: announcementTopicDetail, isLoading: loadingAnnouncementTopicFn, refetch: refetchannouncementTopicDetail } = useGetAnnouncementTopicDetail(id);

    const [announcementTopic, setAnnouncementTopic] = useState({});

    const [isChecked, setIsChecked] = useState(false);


    const handleSubmit = () => {
        let FormValidity = FormRef.current.reportValidity();
        if (FormValidity === true) {
            if (id) {
                try {
                    let userData = {
                        topic: announcementTopic.topic,
                        description: announcementTopic.description,
                        is_to_every_one: announcementTopic.is_to_every_one
                    }
                    updateAnnouncemnetTopic(
                        { id, userData },
                        {
                            onSuccess: (response) => {

                                toast.success('Announcement Topic Added', TostifyConfig);
                                navigate('/list-announcementsTopic/')
                            }
                        })
                } catch (error) {
                    console.log(error)
                }
            }
            else {
                try {
                    let userData = {
                        topic: announcementTopic.topic,
                        description: announcementTopic.description,
                        is_to_every_one: announcementTopic.is_to_every_one
                    }
                    postNewAnnouncemnetTopic(userData, {
                        onSuccess: (response) => {
                            toast.success('Announcement Topic Added', TostifyConfig);
                            navigate('/list-announcementsTopic/')
                        }
                    })
                } catch (error) {
                    console.log(error)
                }
            }
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (event.target.type === "checkbox") {
            setAnnouncementTopic({
                ...announcementTopic,
                [name]: event.target.checked,
            });
            setIsChecked(event.target.checked);
        }
        else {
            setAnnouncementTopic({
                ...announcementTopic,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        if (id) {
            refetchannouncementTopicDetail();
        }
        if (announcementTopicDetail) {
            setAnnouncementTopic(announcementTopicDetail);
            setIsChecked(announcementTopicDetail.is_to_every_one)
        }
    }, [announcementTopicDetail])
    return (
        <Fragment>
            <PageTitle activeMenu="Announcement Topic" motherMenu="Announcement" />
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>{id ? "Edit Annoucement Topic" : "New Announcement Topic"}</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingAnnouncementTopicFn && id}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Topic<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="topic"
                                                    value={announcementTopic?.topic}
                                                    onChange={handleInputChange}
                                                    required />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">Description</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="description"
                                                    value={announcementTopic?.description}
                                                    onChange={handleInputChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Row>
                                            {/* <Col className='col-md-8  mb-2'>
                                            <div className="form-check custom-checkbox mb-3">
                                                <input
                                                    type="checkbox"
                                                    name="is_to_every_one"
                                                    className="form-check-input"
                                                    id="is_to_every_one"
                                                    checked={isChecked}
                                                    onChange={handleInputChange}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="is_to_every_one"
                                                >
                                                    <b>Show Everyone</b>
                                                </label>
                                            </div> 
                                             <Form.Check // prettier-ignore
                                                type='switch'
                                                onChange={handleInputChange}
                                                name='is_to_every_one'
                                                checked={isChecked}
                                                label={"Show Everyone"}
                                            />
                                        </Col> */}
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mt-2'>
                                        <Row>
                                            <Col className="col-md-12">
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            navigate("/list-announcementsTopic/");
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="primary btn-sm"
                                                        onClick={handleSubmit}
                                                        disabled={loadingAnnouncementTopicFn && id || loadingpostAnnouncementTopicFn || loadingpatchAnnouncementTopicFn}
                                                    >{(loadingpostAnnouncementTopicFn || loadingpatchAnnouncementTopicFn) ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" className="me-2" />
                                                            Loading...
                                                        </>
                                                    ) : (
                                                        'Submit'
                                                    )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default AddAnnouncementsTopic