import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// API GET Method 
const getExpirydataFn = async (query) => {
    
    let [, days] = query.queryKey;

    let config = {
        method: "get",
        url: `/employee/document_expiry_report/${days}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetExpirydata = (days) => {
    return useQuery(["get-expiry-by-days", days], getExpirydataFn);
}; 