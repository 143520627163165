import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { isValidPermission } from "../../../../services/AuthService";
import { ServiceContext } from "../../../../context/ServiceContext";

function EmergencyContactList(props) {
    const { canEditEmployee } = useContext(ServiceContext);

    return (
        <div className="card" id="emergency-contact">
            <div className="card-header d-flex justify-content-between">
                <h4 className="card-title">
                    <i className="fa fa-phone font-18 align-middle me-2 text-primary"></i>
                    &nbsp; Emergency Contact
                </h4>

                {canEditEmployee && isValidPermission('employee.change_emergencycontactinfo', props.getPermissionList) &&
                    <Button className="me-2 btn-sm"
                        variant="primary"
                        onClick={() => {
                            props.setCardView({ emergencyContactEdit: true });
                        }}
                    >
                        <i className="fas fa-pencil-alt"></i>&nbsp;&nbsp;Edit
                    </Button>
                }
            </div>
            <div className="card-body">
                <div className="form-validation">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="form-group mb-3 row">
                                <label className="col-lg-4 col-form-label">
                                    FullName:
                                </label>
                                <label className="col-lg-8 col-form-label">
                                    {props.emergencyContactData.full_name}
                                </label>
                                <label className="col-lg-4 col-form-label">
                                    Primary Contact:
                                </label>
                                <label className="col-lg-8 col-form-label">
                                    {props.emergencyContactData.home_phone ?
                                        props.emergencyContactData.home_phone.startsWith('+44') ?
                                            props.emergencyContactData.home_phone.substring(3) :
                                            '+44 ' + props.emergencyContactData.home_phone
                                        :
                                        null}
                                </label>
                                <label className="col-lg-4 col-form-label">
                                    Secondary Contact:
                                </label>
                                <label className="col-lg-8 col-form-label">
                                    {props.emergencyContactData.work_phone ?
                                        props.emergencyContactData.work_phone.startsWith('+44') ?
                                            props.emergencyContactData.work_phone.substring(3) :
                                            '+44 ' + props.emergencyContactData.work_phone
                                        :
                                        null}
                                </label>
                                <label className="col-lg-4 col-form-label">
                                    Email:
                                </label>
                                <label className="col-lg-8 col-form-label">
                                    {props.emergencyContactData.email}
                                </label>
                                <label className="col-lg-4 col-form-label">
                                    Relationship:
                                </label>
                                <label className="col-lg-8 col-form-label">
                                    {props.emergencyContactData.relationship}
                                </label>
                                <label className="col-lg-4 col-form-label">
                                    Address1:
                                </label>
                                <label className="col-lg-8 col-form-label">
                                    {props.emergencyContactData.address1}
                                </label>
                                <label className="col-lg-4 col-form-label">
                                    City:
                                </label>
                                <label className="col-lg-8 col-form-label">
                                    {props.emergencyContactData.city}
                                </label>
                                <label className="col-lg-4 col-form-label">
                                    County:
                                </label>
                                <label className="col-lg-8 col-form-label">
                                    {props.emergencyContactData.state}
                                </label>
                                <label className="col-lg-4 col-form-label">
                                    PostCode:
                                </label>
                                <label className="col-lg-8 col-form-label">
                                    {props.emergencyContactData.zip_code}
                                </label>
                                <label className="col-lg-4 col-form-label">
                                    Country:
                                </label>
                                <label className="col-lg-8 col-form-label">
                                    {props.emergencyContactData.country_name}
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmergencyContactList;