import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useGetCountryList } from "../../actions/country-action";
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../components/table/FilteringTable/filtering.css';
import DataTable from '../pages/commonPages/DataTable';
import { useCountryDeleteById } from '../../actions/country-action';
import { deleteConfirmation } from '../../utils/ComponentUtilse';
import CustomTooltip from '../../utils/CommonUtils';


export const CountryListView = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([])

    const { mutate: deleteCountryInfo, isLoading: loadingdeleteCountryFn } = useCountryDeleteById();

    const { data: getAllCountry, isLoading: loadingCountryFn, refetch: refetchCountry } = useGetCountryList()

    useEffect(() => {
        if (getAllCountry) {
            setData(getAllCountry);
        }
    }, [getAllCountry]);

    const columns = useMemo(
        () => [
            {
                Header: " Country Of Issue",
                Footer: " Country Of Issue ",
                accessor: "country_of_issue",
                Filter: ColumnFilter,
                className: "minWidth200",
            },

            {
                Header: "Description",
                Footer: "Description",
                accessor: "description",
                Filter: ColumnFilter,
                className: "minWidth200",
            },

            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                Filter: ColumnFilter,
                className: "minWidth100",
                disableFilters: true,
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip id={`delete-${row.index}`} message="Delete">
                            <Button
                                className="shadow btn-xs sharp me-1"
                                variant="danger light"
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteConfirmation(row.original.id, `Country - ${row.original.country_of_issue}`, deleteCountryInfo, refetchCountry)}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </CustomTooltip>&nbsp;

                        <CustomTooltip id={`edit-${row.index}`} message="Edit">
                            <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(row.original.id)}>
                                <i className="fas fa-pencil-alt"></i>
                            </Button>
                        </CustomTooltip>
                    </>
                ),

            },
        ],
        []
    );


    const handleEdit = (id) => {
        navigate('/add-country/', { state: { rowId: id } })
    }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    const { pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Country" motherMenu="Settings" />
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title"><i class="fa fa-users" aria-hidden="true"></i>Country</h4>
                    <div className="d-inline-flex">
                        <Link to={`/add-country/`}>
                            <Button className='me-2' variant='primary btn-sm'>
                                <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; Country
                            </Button>
                        </Link>

                    </div>
                </div>
                <div className="card-body">
                    <DataTable
                        isLoading={loadingCountryFn}
                        columns={columns}
                        tableInstance={tableInstance}
                    />

                </div>
            </div>
        </>
    )

}
export default CountryListView;