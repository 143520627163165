import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";



const getAllClient = async () => {
  let config = {
    method: "get",
    url: "organization/api/v1/clients/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetClientList = () => {
  return useQuery(["get-all-client"], getAllClient);
};



const createNewClient = async (clientData) => {
  let config = {
    method: "post",
    url: "organization/api/v1/clients/",
    headers: {
      "Content-Type": "application/json",
    },
    data: clientData
  };

  return await axios.create().request(config)
};
export const useCreateClient = () => {
  return useMutation(["client-register"], createNewClient);
};


const getClientById = async (query) => {
  let [, id] = query.queryKey;
  let config = {
    method: "get",
    url: `organization/api/v1/clients/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetClientById = (id) => {
  return useQuery(["get-client-by-id", id], getClientById, { enabled: id ? true : false });
};


const clientUpdateById = async (data) => {
  let { id, clientData } = data;
  let config = {
    method: "patch",
    url: `organization/api/v1/clients/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: clientData,
  };
  return await axios.request(config);
};
export const useClientUpdateById = () => {
  return useMutation(["client-update"], clientUpdateById);
};

//API DELETE METHOD

const clientDeleteById = async (id) => {
  try {
    const response = await axios.delete(`/organization/api/v1/clients/${id}/`);
    return response.data;
  } catch (error) {
    // Handle error or throw it again
    throw error;
  }
};

export const useClientDeleteById = () => {
  return useMutation(["client-delete"], clientDeleteById);
};