import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { GlobalFilter } from '../components/table/FilteringTable/GlobalFilter';
import '../components/table/FilteringTable/filtering.css';
import { useGetTeamList } from '../../actions/team-action';
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button, Alert, Table, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IsValidPermissions } from '../../services/AuthService';
import DataTable from '../pages/commonPages/DataTable';
import {
    useGetLeaveBalanceByUUID,
    useGetAllLeaveBalanceByUUID
} from "../../actions/leavebalance-actions";
import { useParams } from 'react-router-dom';
import { deleteConfirmation } from '../../utils/ComponentUtilse';
import { useLeaveBalanceDeleteById } from '../../actions/leavebalance-actions';
import CustomTooltip from '../../utils/CommonUtils';

export const LeaveBalanceListView = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const employee_uuid = useParams();

    const { data: getLeaveBalance, isLoading: loadingLeaveBalance, refetch: refetchLeaveBalance } = useGetAllLeaveBalanceByUUID();

    const { mutate: deleteLeaveBalanceInfo, isLoading: loadingdeleteLeaveBalanceFn } = useLeaveBalanceDeleteById();

    useEffect(() => {
        if (getLeaveBalance) {
            setData(getLeaveBalance);
        }
    }, [getLeaveBalance]);

    const columns = useMemo(
        () => [
            {
                Header: "Employee",
                Footer: "Employee",
                accessor: "employee_fullname",
                Filter: ColumnFilter,
                Cell: ({ cell, row }) => {
                    // Initialize initials to an empty string
                    let initials = "";

                    if (row?.original?.employee_first_name) {
                        // Extract the first letter and capitalize it
                        const firstNameInitial = row?.original?.employee_first_name.charAt(0).toUpperCase();
                        initials += firstNameInitial;
                    }

                    if (row?.original?.employee_last_name) {
                        // Extract the first letter and capitalize it
                        const lastNameInitial = row?.original?.employee_last_name.charAt(0).toUpperCase();
                        initials += lastNameInitial;
                    }
                    return (
                        <div>
                            {row?.original?.employee_profile_image?.profile_image_url ? (
                                <div className="media">
                                    <img
                                        src={row?.original?.employee_profile_image?.profile_image_url}
                                        className="rounded-lg me-2 user-avator-img"
                                        alt=""
                                    />
                                    {" "}
                                    <div className="media-body text-nowrap">
                                        <Link className='table-icon-row' to={`/employee-profile-view/${row?.original?.employee_employee_uuid}`}>
                                            <h6 className="text-black  fs-16 mb-0">
                                                {row?.original?.employee_first_name}
                                            </h6>
                                            <span className="text-black fs-14 mb-0">
                                                {row?.original?.employee_last_name}
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <div className="media">
                                    <div className='rounded-lg me-2 justify-content-center user-avator-letter'>{initials}</div>
                                    {" "}
                                    <div className="media-body text-nowrap">
                                        <Link className='table-icon-row' to={`/employee-profile-view/${row?.original?.employee_employee_uuid}`}>
                                            <h6 className="text-black  fs-16 mb-0">
                                                {row?.original?.employee_first_name}
                                            </h6>
                                            <span className="text-black fs-14 mb-0">
                                                {row?.original?.employee_last_name}
                                            </span>
                                        </Link>
                                    </div>
                                    <span className="w-space-no"></span>
                                </div>
                            )}
                        </div>
                    )
                },
            },
            {
                Header: " Leave type",
                Footer: " Leave type",
                accessor: "leave_type_name",
                Filter: ColumnFilter,
                className: "minWidth100"
            },
            {
                Header: "Year",
                Footer: "Year",
                accessor: "year",
                Filter: ColumnFilter,
                className: "minWidth50"
            },
            {
                Header: "Custom Leave",
                Footer: "Custom Leave",
                accessor: "custom_leave_days",
                Filter: ColumnFilter,
                className: "minWidth50"
            },
            {
                Header: "Annual Leave",
                Footer: "Annual Leave",
                accessor: "total_annual_days",
                Filter: ColumnFilter,
                className: "minWidth50"
            },
            {
                Header: "Balance carry over",
                Footer: "Balance carry over",
                accessor: "balance_carry_over",
                Filter: ColumnFilter,
                className: "minWidth50"
            },
            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                disableFilters: true,
                className: "minWidth100",
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip id={`delete-${row.index}`} message="Delete">
                            <Button
                                className="shadow btn-xs sharp me-1"
                                variant="danger light"
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteConfirmation(row.original.id, `Leave Balance - ${row.original.leave_type_name}`, deleteLeaveBalanceInfo, refetchLeaveBalance)}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </CustomTooltip>
                        &nbsp;&nbsp;
                        <CustomTooltip id={`edit-${row.index}`} message="Edit">
                            <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(row.original.id)}>
                                <i className="fa fa-pencil-alt"></i>
                            </Button>
                        </CustomTooltip>

                    </>
                )
            },
        ],
        []
    );


    const handleEdit = (id) => {
        navigate('/add-leave-balance/', { state: { rowId: id } })
    }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const { globalFilter, pageIndex } = state

    const groupedData = data?.reduce((acc, current) => {
        const fullName = current.employee_fullname;
        if (!acc[fullName]) {
            acc[fullName] = [];
        }
        acc[fullName].push(current);
        return acc;
    }, {});

    function getInitials(firstName, lastName) {
        let initials = "";

        if (firstName) {
            initials += firstName.charAt(0).toUpperCase();
        }

        if (lastName) {
            initials += lastName.charAt(0).toUpperCase();
        }

        return initials;
    }

    return (
        <>
            <PageTitle activeMenu="Leaves" motherMenu="Leaves" />
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Leave Balance</h4>
                    <div className="d-inline-flex">
                        <Link to={`/add-leave-balance/`}>
                            <Button className='me-2' variant='primary btn-sm'>
                                <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; Leave Balance
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="card-body">
                    {/* <DataTable
                        isLoading={loadingLeaveBalance}
                        columns={columns}
                        tableInstance={tableInstance}
                    /> */}

                    <Table responsive className="pt-4">
                        <thead Style={"background-color:#F1F5F8;"}>
                            <tr>
                                <th>Employee</th>
                                <th>Leave type</th>
                                <th>Duration</th>
                                <th>Custom Leave</th>
                                <th>Annual Leave</th>
                                <th>Balance carry over</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loadingLeaveBalance ? (
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        <Spinner animation="border" size="lg" className="me-2" />
                                    </td>
                                </tr>
                            ) : groupedData && Object.keys(groupedData).length === 0 ? (
                                <tr>
                                    <td colSpan="7" className="text-center">No data found</td>
                                </tr>
                            ) : (
                                Object.entries(groupedData).map(([empName, leaveData]) => (
                                    <React.Fragment key={empName}>
                                        {leaveData.map((data, index) => (
                                            <tr key={`${empName}-${index}`}>
                                                {index === 0 &&
                                                    <td rowSpan={leaveData.length}>
                                                        {data.employee_profile_image ? (
                                                            <div className="media">
                                                                <img
                                                                    src={data.employee_profile_image.profile_image_url}
                                                                    className="rounded-lg me-2 user-avator-img"
                                                                    alt=""
                                                                />
                                                                {" "}
                                                                <div className="media-body text-nowrap">
                                                                    <Link className='table-icon-row' to={`/employee-profile-view/${data?.employee_employee_uuid}`}>
                                                                        <h6 className="text-black  fs-16 mb-0">
                                                                            {data?.employee_first_name}
                                                                        </h6>
                                                                        <span className="text-black fs-14 mb-0">
                                                                            {data?.employee_last_name}
                                                                        </span>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="media">
                                                                <div className='rounded-lg me-2 justify-content-center user-avator-letter'>
                                                                    {getInitials(data.employee_first_name, data.employee_last_name)}
                                                                </div>
                                                                {" "}
                                                                <div className="media-body text-nowrap">
                                                                    <Link className='table-icon-row' to={`/employee-profile-view/${data?.employee_employee_uuid}`}>
                                                                        <h6 className="text-black  fs-16 mb-0">
                                                                            {data?.employee_first_name}
                                                                        </h6>
                                                                        <span className="text-black fs-14 mb-0">
                                                                            {data?.employee_last_name}
                                                                        </span>
                                                                    </Link>
                                                                </div>
                                                                <span className="w-space-no"></span>
                                                            </div>
                                                        )}

                                                    </td>
                                                }
                                                <td>{data.leave_type_name}</td>
                                                <td><i class="fa fa-calendar" aria-hidden="true"></i> {data.month_start_to_end}</td>
                                                <td>{data.custom_leave_days}</td>
                                                <td>{data.total_annual_days}</td>
                                                <td>{data.balance_carry_over}</td>
                                                <td>
                                                    <CustomTooltip id={`delete-${data.index}`} message="Delete">
                                                        <Button
                                                            className="shadow btn-xs sharp me-1"
                                                            variant="danger light"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => deleteConfirmation(data.id, `Leave Balance - ${data.leave_type_name}`, deleteLeaveBalanceInfo, refetchLeaveBalance)}
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </Button>
                                                    </CustomTooltip>
                                                    &nbsp;&nbsp;
                                                    <CustomTooltip id={`edit-${data.index}`} message="Edit">
                                                        <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(data.id)}>
                                                            <i className="fa fa-pencil-alt"></i>
                                                        </Button>
                                                    </CustomTooltip>
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))
                            )}
                        </tbody>
                    </Table>


                </div>
            </div>


            {/* {(!loadingGetTeamFn && !IsValidPermissions('employee.view_employeeteams')) &&
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <Alert variant="warning" show={true}>

                                <svg
                                    viewBox='0 0 24 24'
                                    width='24'
                                    height='24'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='me-2'
                                >
                                    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                    <line x1='12' y1='9' x2='12' y2='13'></line>
                                    <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                </svg>

                                <strong>Info!  </strong> You don't have permission to view Teams.

                            </Alert>
                        </div>
                    </div>
                </div>
            } */}

        </>
    )

}
export default LeaveBalanceListView;