import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useGetVisaTypeList } from "../../actions/visatype-action";
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../components/table/FilteringTable/filtering.css';
import DataTable from '../pages/commonPages/DataTable';
import { useVisaTypeDeleteById } from '../../actions/visatype-action';
import { deleteConfirmation } from '../../utils/ComponentUtilse';
import CustomTooltip from '../../utils/CommonUtils';


export const VisaTypeListView = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);

    const { data: getAllVisaType, isLoading: loadingVisaTypeFn, refetch: refetchVisaType } = useGetVisaTypeList()

    const { mutate: deleteVisaTypeInfo, isLoading: loadingdeleteVisaTypeFn } = useVisaTypeDeleteById();


    useEffect(() => {
        if (getAllVisaType) {
            setData(getAllVisaType);
        }
    }, [getAllVisaType]);

    const columns = useMemo(
        () => [
            {
                Header: " Visa Type",
                Footer: " Visa Type ",
                accessor: "visa_type",
                Filter: ColumnFilter,
                className: "minWidth200",
            },

            {
                Header: " Visa Code",
                Footer: " Visa Code ",
                accessor: "code",
                Filter: ColumnFilter,
                className: "minWidth200",
            },

            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                Filter: ColumnFilter,
                disableFilters: true,
                className: "minWidth100",
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip id={`delete-${row.index}`} message="Delete">
                            <Button
                                className="shadow btn-xs sharp me-1"
                                variant="danger light"
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteConfirmation(row.original.id, `Visa Type - ${row.original.visa_type}`, deleteVisaTypeInfo, refetchVisaType)}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </CustomTooltip>&nbsp;

                        <CustomTooltip id={`edit-${row.index}`} message="Edit">
                            <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleEdit(row.original.id)}><i className="fas fa-pencil-alt"></i></Button>
                        </CustomTooltip>
                    </>
                )
            },
        ],
        []
    );


    const handleEdit = (id) => {
        navigate('/add-visatype/', { state: { rowId: id } })
    }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
    } = tableInstance

    const { pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Visa Type" motherMenu="Settings" />
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title"><i class="fa fa-users" aria-hidden="true"></i>Visa Type</h4>
                    <div className="d-inline-flex">
                        <Link to={`/add-visatype/`}>
                            <Button className='me-2' variant='primary btn-sm'>
                                <i class="fa fa-plus" aria-hidden="true"></i> &nbsp; Visa Type
                            </Button>
                        </Link>

                    </div>
                </div>
                <div className="card-body">
                    <DataTable
                        isLoading={loadingVisaTypeFn}
                        columns={columns}
                        tableInstance={tableInstance}
                    />

                </div>
            </div>
        </>
    )

}
export default VisaTypeListView;