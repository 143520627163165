import Spinner from "react-bootstrap/Spinner";
import { ProgressBar } from "react-bootstrap";

function ProfileImageView(props) {
    return (
      <div className="d-flex flex-wrap search-job bg-white rounded py-3 px-md-3 px-0 mb-4 align-items-center">
                <div className="col-xl-3 col-xxl-4 col-md-4 border-right">
                  <div class="text-center">
                    <div className="profile-bx" style={{ border: "none" }}>
                      <div className="profile-image mb-4 mt-4">
                        {!props.LoadingEmployeeDetailByUUID &&
                        props.employeeProfileData?.employee_profile_image_url ? (
                          <img
                            src={props.employeeProfileData?.employee_profile_image_url?.profile_image_url}
                            className="rounded-circle"
                            alt=""
                          />
                        ) : (
                          <>
                            {props.LoadingEmployeeDetailByUUID ? (
                              <div className="pt-5">
                                <Spinner />
                              </div>
                            ) : (
                              <div className="rounded-lg justify-content-center user-avator-profile">
                                {props.initialData}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-9 col-xxl-8 col-md-8 d-flex justify-content-sm-around">
                  <div class="text-center">
                    <span class="fs-14 mb-5 text-mute">Employee</span>
                    <h4 className="fs-18 text-black mb-1 mt-2">
                      {props.employeeProfileData?.first_name}{" "}
                      {props.employeeProfileData?.last_name}
                    </h4>
                    <p className="d-block mb-1">
                      {" "}
                      {props.employeeProfileData?.position_name}
                    </p>
                    <p className="text-primary">
                      {" "}
                      {props.employeeProfileData?.employee_number}{" "}
                    </p>
                  </div>
                  <div>
                    <div
                      className="card-body  text-center  profile-bx"
                      style={{ background: "rgb(234 233 235)", "border-radius": "5%" }}
                    >
                      <div>
                        <div class="d-flex justify-content-between width190">
                          <h6>Profile Strength</h6>
                          <span className="text-danger">
                            {props.pendingActionData?.average_percentage}%
                          </span>
                        </div>
                        <ProgressBar
                          now={props.pendingActionData?.average_percentage}
                          variant={"info"}
                          className="mt-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    );
  }
export default ProfileImageView;