import React, {
    useState,
    useEffect,
    useMemo,
    Fragment,
} from "react";
import { Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { IsValidPermissions } from "../../services/AuthService";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import "rc-time-picker/assets/index.css";
import { Spinner } from "react-bootstrap";
import { useGetEmployeeTimemsheetSummary } from "../../actions/timesheet-actions";
import { dateHandler } from "../../utils/ComponentUtilse";

function TimeSheetSummary() {

    const [data, setData] = useState([]);

    const [projectGroupedData, setProjectGroupedData] = useState([]);

    const [csvData, setCsvData] = useState([]);
    const [csvOverAllData, setCsvOverAllData] = useState([]);

    const [selectedDate, setSelectedDate] = useState(null);

    const [parserDate, setParserDate] = useState(null);


    const {
        data: getEmployeeTimesheetSummary,
        isLoading: loadingTimeSheetSummary,
        refetch: refetchEmployeeTimeSheetSummary,
    } = useGetEmployeeTimemsheetSummary(parserDate);

    const handleDateChange = (date) => {
        const month = date.getMonth() + 1;
        const formattedMonth = month < 10 ? `0${month}` : `${month}`;
        const year = date.getFullYear();
        setSelectedDate(date);
        setParserDate(`${formattedMonth}/${year}`);
    };

    useEffect(() => {
        // Get the current date
        const currentDate = new Date();
        const month = currentDate.getMonth() + 1;
        const formattedMonth = month < 10 ? `0${month}` : `${month}`;
        const year = currentDate.getFullYear();
        // Set the selected date to the first day of the current month and year
        const firstDayOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        );
        setSelectedDate(firstDayOfMonth);
        setParserDate(`${formattedMonth}/${year}`);
    }, []);

    useEffect(() => {
        if (getEmployeeTimesheetSummary) {
            setData(getEmployeeTimesheetSummary);
            setProjectGroupedData(getEmployeeTimesheetSummary)
            //   sortGroupTimesheetData(getEmployeeTimesheetByUUID);
            generateCSVData();
        }
    }, [getEmployeeTimesheetSummary]);


    const groupedData = data?.reduce((acc, current) => {
        const fullName = current.employee_fullname;
        if (!acc[fullName]) {
            acc[fullName] = [];
        }
        acc[fullName].push(current);
        return acc;
    }, {});

    const generateCSVData = () => {
        // Construct CSV data array
        if (Array.isArray(getEmployeeTimesheetSummary) && getEmployeeTimesheetSummary.length > 0) {

            let projectData = getEmployeeTimesheetSummary.reduce((projectAcc, projItem) => {
                const updatedItem = {};
                updatedItem['Employee Fullname'] = projItem.employee_fullname;
                updatedItem['Project Name'] = projItem.project_name;
                updatedItem['Start Date'] = projItem.start_date;
                updatedItem['End Date'] = projItem.end_date;
                updatedItem['Total Hours'] = projItem.total_hours_sum;
                updatedItem['Total Days'] = projItem.total_days;
                projectAcc.push(updatedItem);
                return projectAcc;
            }, []);

            // Calculate the total hours
            const totalHours = projectData.reduce((total, item) => total + item["Total Hours"], 0);

            // Calculate the total days
            const totalDays = projectData.reduce((total, item) => total + item["Total Days"], 0);

            projectData.push({
                "Employee Fullname": "",
                "Project Name": "",
                "Start Date": "",
                "End Date": "",
                "Total Hours": totalHours,
                "Total Days": totalDays
            });

            setCsvData(projectData);

            const groupedData = getEmployeeTimesheetSummary.reduce((acc, curr) => {
                const key = curr.employee_fullname;
                if (!acc[key]) {
                    acc[key] = {
                        "Employee Fullname": key,
                        "Total Hours": 0,
                        "Total Days": 0
                    };
                }
                acc[key]["Total Hours"] += curr.total_hours_sum;
                acc[key]["Total Days"] += curr.total_days;
                return acc;
            }, {});
        
            const finalData = Object.values(groupedData);
            // Calculate the total hours
            const totalHoursAll = finalData.reduce((total, item) => total + item["Total Hours"], 0);

            // Calculate the total days
            const totalDaysAll = finalData.reduce((total, item) => total + item["Total Days"], 0);

            finalData.push({
                "Employee Fullname": "",
                "Total Hours": totalHoursAll,
                "Total Days": totalDaysAll
            });

            setCsvOverAllData(finalData);
        
        }
      };


    const totalHoursSum = useMemo(() => {
        return data.reduce((total, item) => total + item.total_hours_sum, 0);
    }, [data]);

    const totalDaysSum = useMemo(() => {
        return data.reduce((total, item) => total + item.total_days, 0);
    }, [data]);



    return (
        <Fragment>
            {(IsValidPermissions("timesheet.add_timesheet")) && (
                <>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">TimeSheet Summary (Project)</h4>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div></div>

                                    <div className="d-inline-flex mb-2">
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            showMonthYearPicker
                                            dateFormat="MM/yyyy"
                                            className="form-control mt-0 date-month-picker"
                                        />
                                        &nbsp;&nbsp;
                                        {(csvData?.length > 0 && !loadingTimeSheetSummary) ? (
                                            <CSVLink
                                                style={{ borderRadius: "5px" }}
                                                className="btn-primary btn-md"
                                                data={csvData}
                                                filename={`monthly-summary-report-${parserDate || " "}.csv`}                                            >
                                                Export
                                            </CSVLink>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>

                                <Table responsive className="pt-4">
                                    <thead Style={"background-color:#F1F5F8;"}>
                                        <tr>
                                            <th>Employee</th>
                                            <th>Project</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Total Hours</th>
                                            <th>Total Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loadingTimeSheetSummary ? (
                                            <tr>
                                                <td colSpan="7" className="text-center">
                                                    <Spinner animation="border" size="lg" className="me-2" />
                                                </td>
                                            </tr>
                                        ) : groupedData && Object.keys(groupedData).length === 0 ? (
                                            <tr>
                                                <td colSpan="7" className="text-center">No data found</td>
                                            </tr>
                                        ) : (
                                            Object.entries(groupedData).map(([empName, leaveData]) => (
                                                <React.Fragment key={empName}>
                                                    {leaveData.map((data, index) => (
                                                        <tr key={`${empName}-${index}`}>
                                                            {index === 0 &&
                                                                <td rowSpan={leaveData.length}>
                                                                    {data.employee_fullname && (
                                                                        <td>{data.employee_fullname}</td>
                                                                    )}
                                                                </td>
                                                            }
                                                            <td>{data.project_name}</td>
                                                            <td>{dateHandler(data.start_date)}</td>
                                                            <td>{dateHandler(data.end_date)}</td>
                                                            <td>{data.total_hours_sum}</td>
                                                            <td>{data.total_days}</td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            ))
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="4" style={{ textAlign: "right" }}>Total:</td>
                                            <td>{totalHoursSum}</td>
                                            <td>{totalDaysSum}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Overall Summary</h4>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div></div>

                                    <div className="d-inline-flex mb-2">
                                        {/* <DatePicker
                                            selected={selectedDate}
                                            onChange={handleDateChange}
                                            showMonthYearPicker
                                            dateFormat="MM/yyyy"
                                            className="form-control mt-0 date-month-picker"
                                        />
                                        &nbsp;&nbsp; */}
                                        {(csvOverAllData?.length > 0 && !loadingTimeSheetSummary) ? (
                                            <CSVLink
                                                style={{ borderRadius: "5px" }}
                                                className="btn-primary btn-md"
                                                data={csvOverAllData}
                                                filename={`monthly-overall-summary-report-${parserDate || " "}.csv`}                                            >
                                                Export
                                            </CSVLink>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>

                                <Table responsive className="pt-4">
                                    <thead Style={"background-color:#F1F5F8;"}>
                                        <tr>
                                            <th>Employee</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Total Hours</th>
                                            <th>Total Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loadingTimeSheetSummary ? (
                                            <tr>
                                                <td colSpan="7" className="text-center">
                                                    <Spinner animation="border" size="lg" className="me-2" />
                                                </td>
                                            </tr>
                                        ) : groupedData && Object.keys(groupedData).length === 0 ? (
                                            <tr>
                                                <td colSpan="7" className="text-center">No data found</td>
                                            </tr>
                                        ) : (
                                            Object.entries(groupedData).map(([empName, leaveData]) => (
                                                <React.Fragment key={empName}>
                                                    {leaveData.map((data, index) => (
                                                        <tr key={`${empName}-${index}`}>
                                                            {index === 0 &&
                                                                <td rowSpan={leaveData.length}>
                                                                    {data.employee_fullname && (
                                                                        <td>{data.employee_fullname}</td>
                                                                    )}
                                                                </td>
                                                            }
                
                                                            {index === 0 && (
                                                                <td rowSpan={leaveData.length}>
                                                                    {dateHandler(data.start_date)}
                                                                </td>
                                                            )}

                                                            {index === 0 && (
                                                                <td rowSpan={leaveData.length}>
                                                                    {dateHandler(data.end_date)}
                                                                </td>
                                                            )}

                                                            {index === 0 && (
                                                                <td rowSpan={leaveData.length}>
                                                                    {/* Calculate total days for the employee */}
                                                                    {leaveData.reduce((total, item) => total + item.total_hours_sum, 0)}
                                                                </td>
                                                            )}
                                                            {index === 0 && (
                                                                <td rowSpan={leaveData.length}>
                                                                    {/* Calculate total days for the employee */}
                                                                    {leaveData.reduce((total, item) => total + item.total_days, 0)}
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            ))
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="3" style={{ textAlign: "right" }}>Total:</td>
                                            <td>{totalHoursSum}</td>
                                            <td>{totalDaysSum}</td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Fragment>
    )

}
export default TimeSheetSummary;