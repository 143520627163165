import React, {
  useState,
  useEffect,
  useMemo,
  Fragment,
  useContext,
  useRef,
} from "react";
import { Form, Button, Alert, Modal, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { useGetEmployeeTeamsheetByUUID } from "../../actions/timesheet-actions";
import { useGetProjectByUUId } from "../../actions/project-action";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { ColumnFilter } from "../components/table/FilteringTable/ColumnFilter";
import { useEmployeeDropdown } from "../../actions/employee-actions";
import { IsValidPermissions } from "../../services/AuthService";
import { ServiceContext } from "../../context/ServiceContext";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { dateHandler } from "../../utils/ComponentUtilse";
import { useTimeSheetDeleteById } from "../../actions/timesheet-actions";
import { deleteConfirmation } from "../../utils/ComponentUtilse";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";
import { useEmployeeWorkLocation } from "../../actions/employee-actions";
import { useTimeSheetUpdateFn } from "../../actions/timesheet-actions";
import { Spinner } from "react-bootstrap";
import CustomTooltip from "../../utils/CommonUtils";

function EmployeeTimeSheetView() {
  const { isAdminUser, getGlobleEmployeeUUID, getEmployeeUUID } = useContext(ServiceContext);

  const [getEmployeeUUid, setEmployeeUUid] = useState("");

  const [employeeProfileData, setEmployeeProfileData] = useState({});

  const [selectedDate, setSelectedDate] = useState(null);

  const [parserDate, setParserDate] = useState(null);

  const [getProjectName, setProjectName] = useState("");

  const [getProjectId, setProjectId] = useState(null);

  const [csvData, setCsvData] = useState([]);

  const [fileName, setFileName] = useState('');

  const [modalData, setModalData] = useState({
    date: "",
    start_time: moment("09:00:00", "HH:mm:ss"),
    end_time: "",
    total_hours: 0,
    projects: "",
    resource: "",
    reporter: "",
  });

  const [data, setData] = useState([]);

  const [rowId, setRowId] = useState("");

  const [showModal, setShowModal] = useState(false);

  const { data: getEmployeeInfo, isLoading: loadingEmployeeInfo } =
    useEmployeeDropdown();

  const {
    data: getEmployeeTimesheetByUUID,
    isFetching: loadingTimesheet,
    refetch: refetchEmployeeTimesheet,
  } = useGetEmployeeTeamsheetByUUID(getEmployeeUUid, "", parserDate);

  const {
    data: getEmployeeProjectsByUUID,
    isFetching: loadingEmployeeProjectsByUUID,
  } = useGetProjectByUUId(getEmployeeUUid !== 'All' ? getEmployeeUUid : null);

  const { mutate: deleteTimesheetInfo, isLoading: loadingdeleteTimesheetFn } =
    useTimeSheetDeleteById();

  const { data: getEmployeeWorkLocation } = useEmployeeWorkLocation();

  const handleEdit = (timesheetObj) => {
    setShowModal(true);

    // Find the timesheet data with the matching id
    // const timesheetToEdit = getEmployeeTimesheetByUUID?.find((timesheet) => timesheet.id === id);

    // Update modalData with the found timesheet data
    if (timesheetObj) {
      setModalData({
        id: timesheetObj.id,
        date: timesheetObj.date,
        start_time: moment(`${timesheetObj.start_time}`, "HH:mm:ss"),
        end_time: moment(`${timesheetObj.end_time}`, "HH:mm:ss"),
        total_hours: timesheetObj.total_hours,
        project: timesheetObj.projects,
        resource: timesheetObj.resource, // Assuming you have a 'resource' property in your timesheet data
        reporter: timesheetObj.reporter, // Assuming you have a 'reporter' property in your timesheet data
        location: timesheetObj.location,
      });
    }
  };

  const { mutate: patchTimesheetFn, isLoading: patchTimesheetLoading } =
    useTimeSheetUpdateFn();

  const formatTime = (timeString) => {
    if (timeString) {
      const selectedTime = new Date(timeString);
      const hours = selectedTime.getHours();
      const minutes = selectedTime.getMinutes();
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}`;
    }
    return null;
  };

  let handelModalButtonClick = () => {
    const formattedStartTime = formatTime(modalData.start_time);
    const formattedEndTime = formatTime(modalData.end_time);
    const rowId = modalData?.id;
    const patchData = {
      date: modalData.date,
      total_hours: modalData.total_hours,
      projects: modalData.project,
      resource: modalData.resource,
      location: modalData.location,
      start_time: formattedStartTime,
      end_time: formattedEndTime,
    };

    if (
      rowId &&
      modalData.total_hours > 0 &&
      formattedStartTime &&
      formattedEndTime &&
      modalData.date &&
      modalData.project &&
      // modalData.reporter &&
      modalData.resource &&
      modalData.location
    ) {
      patchTimesheetFn(
        {
          rowId,
          patchData,
        },
        {
          onSuccess: (e) => {
            toast.success("TimeSheet Updated!!", TostifyConfig);
            setShowModal(false);
            refetchEmployeeTimesheet();
          },
        }
      );
    }
  };

  let totalHoursSum = data.reduce((total, row) => {
    const hours = parseFloat(row.total_hours);
    return total + (isNaN(hours) ? 0 : hours); // If NaN, add 0
  }, 0);

  const columns = useMemo(
    () => [
      {
        Header: "Employee Name",
        // Footer: "Project",
        accessor: "employee_fullname",
        Filter: ColumnFilter,
      },
      {
        Header: "Date",
        // Footer: "Date",
        accessor: "date",
        Filter: ColumnFilter,
        className: "width160",
        Cell: ({ row }) => {
          const { original } = row;
          return <span>{dateHandler(original.date)}</span>;
        },
      },
      {
        Header: "Project",
        // Footer: "Project",
        accessor: "project_name",
        Filter: ColumnFilter,
      },
      {
        Header: "Location",
        // Footer: "Total Hours",
        accessor: "location_name",
        Filter: ColumnFilter,
      },
      {
        Header: "Start Time",
        // Footer: "Start Time",
        accessor: "start_time",
        Filter: ColumnFilter,
      },
      {
        Header: "End Time",
        // Footer: "End Time",
        accessor: "end_time",
        Filter: ColumnFilter,
      },
      {
        Header: "Total Hours",
        // Footer: data.reduce((acc, item) => acc + item, 0),
        // Footer: "Total : " + totalHoursSum.toFixed(2),
        accessor: "total_hours",
        Filter: ColumnFilter,
      },
      {
        Header: "Action",
        // Footer: "Action",
        accessor: "action",
        disableFilters: true,
        className: "minWidth100",

        Cell: ({ row }) => (
          <>
            {IsValidPermissions(
              "timesheet.change_timesheet" ||
                IsValidPermissions("timesheet.timesheet.delete_timesheet")
            ) && (
              <>
                <CustomTooltip id={`delete-${row.index}`} message="Delete">
                  <Button
                    className="shadow btn-xs sharp me-1"
                    variant="danger light"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      deleteConfirmation(
                        row.original.id,
                        `TimeSheet - ${row.original.date}`,
                        deleteTimesheetInfo,
                        refetchEmployeeTimesheet
                      )
                    }
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </CustomTooltip>
                &nbsp;
                <CustomTooltip id={`edit-${row.index}`} message="Edit">
                  <Button
                    className="shadow btn-xs sharp me-1"
                    variant="primary light"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEdit(row.original)}
                  >
                    <i className="fa fa-pencil-alt"></i>
                  </Button>
                </CustomTooltip>
              </>
            )}
          </>
        ),
      },
    ],
    []
  );

  const sortGroupTimesheetData = (data) => {
    const sortedData = data.sort((a, b) => {
        // Compare project names
        if (a.project_name < b.project_name) return -1;
        if (a.project_name > b.project_name) return 1;
    
        // If project names are equal, compare dates
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
    
        return 0; // If both project names and dates are equal
    });
  }

  const generateCSVData = () => {
    // Construct CSV data array
    const tableData = data.map(row => {
      const rowData = {};
      columns.forEach(column => {
        if (column.Header !== "Action")
            rowData[column.Header] = row[column.accessor];
      });
      return rowData;
    });

    let fileName = '';
    if (getEmployeeUUid === 'All'){
      fileName = 'all_employees_'+data[0]['date'].slice(0,7).replace('-','_')+'_timesheet.csv';
    }else{
      fileName = data[0]['employee_fullname'].replace(' ', '_')+'_'+data[0]['date'].slice(0,7).replace('-','_')+'_timesheet.csv';
    }
    setFileName(fileName);

    // Construct footer data
    const footerData = columns.map(column => {
        if (column.Header !== "Action"){

                if (column.Header === "Total Hours") {
                    return { [column.Header]: "Total : " + totalHoursSum.toFixed(2) };
                }
                if (column.Header === "End Time") {
                  return { [column.Header]: "Days : " + data.length };
                }
                return { [column.Header]: column.Footer };
        }
    });

    const combinedObject = footerData.reduce((acc, obj) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                acc[key] = obj[key];
            }
        }
        return acc;
    }, {});

    // Combine table data and footer data
    const combinedData = [...tableData, ...[combinedObject]];

    // Set CSV data state
    setCsvData(combinedData);
  };

  let projectSelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    // const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;
    const selectedLabel = e.target.options[selectedIndex].label;

    setProjectName(selectedLabel);
  };

  const employeeSelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;
    setEmployeeProfileData({
      ...employeeProfileData,
      [selectName]: selectValue,
    });
    setEmployeeUUid(selectValue);
    refetchEmployeeTimesheet();
  };

  const handleDateChange = (date) => {
    const month = date.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const year = date.getFullYear();
    setSelectedDate(date);
    setParserDate(`${formattedMonth}/${year}`);
  };

  useEffect(() => {
    // Get the current date
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const year = currentDate.getFullYear();
    // Set the selected date to the first day of the current month and year
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    setSelectedDate(firstDayOfMonth);
    setParserDate(`${formattedMonth}/${year}`);
  }, []);

  useEffect(() => {
    // Get the current date
    if (!loadingEmployeeProjectsByUUID && getEmployeeProjectsByUUID) {
      setProjectId(getEmployeeProjectsByUUID[0]?.project);
      setProjectName(getEmployeeProjectsByUUID[0]?.project_name);
    }
  }, [
    loadingEmployeeProjectsByUUID,
    getEmployeeProjectsByUUID,
    refetchEmployeeTimesheet,
  ]);

  useEffect(() => {
    if (getEmployeeTimesheetByUUID) {
      setData(getEmployeeTimesheetByUUID);
      sortGroupTimesheetData(getEmployeeTimesheetByUUID);
    }
  }, [getEmployeeTimesheetByUUID]);

  useEffect(() => {
    if (getProjectName) {
      refetchEmployeeTimesheet();
    }
  }, [getProjectName, refetchEmployeeTimesheet]);

  useEffect(() => {
    if (getEmployeeUUid && parserDate) {
      refetchEmployeeTimesheet();
    }else{
        setData([]);
        setCsvData([]);
    }
  }, [getEmployeeUUid, parserDate, refetchEmployeeTimesheet]);

  useEffect(() => {
    setEmployeeUUid(employeeProfileData?.employee);
  }, [employeeProfileData?.employee]);

  useEffect(() => {
    if (isAdminUser === false) {
      setEmployeeUUid(getEmployeeUUID);
    }
  }, [getEmployeeUUID, isAdminUser]);

  useEffect(() => {
    if (data.length > 0) {
      generateCSVData();
    }
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    footerGroups,
  } = useTable({
    columns,
    data,
  });

  return (
    <Fragment>
      {(IsValidPermissions("timesheet.add_timesheet") ||
        IsValidPermissions("timesheet.can_add_employee_timesheet")) && (
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Monthly TimeSheet Report</h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="d-flex align-items-center justify-content-between">
                <div></div>

                <div className="d-inline-flex mb-2">
                  {IsValidPermissions("timesheet.add_timesheet") && (
                    <>
                      <Form.Select
                        name="employee"
                        className="form-control"
                        value={employeeProfileData.employee}
                        onChange={employeeSelectChangeHandler}
                        disabled={loadingEmployeeInfo}
                        required
                      >
                        <option value="">Select Employee</option>
                        <option key='All' value='All'>All -- Employees</option>
                        {getEmployeeInfo?.map((option) => (
                          <option key={option.id} value={option.employee_uuid}>
                            {option.first_name} {option.last_name}{" "}
                            {option.employee_number
                              ? ` (${option.employee_number})`
                              : ""}
                          </option>
                        ))}
                      </Form.Select>
                      &nbsp;&nbsp;
                    </>
                  )}

                  &nbsp;&nbsp;
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    className="form-control mt-0 date-month-picker"
                  />
                  &nbsp;&nbsp;
                  {(csvData.length > 0 && !loadingTimesheet) ? (
                    <CSVLink
                      style={{ borderRadius: "5px" }}
                      className="btn-primary btn-md"
                      data={csvData}
                      filename={fileName}
                    >
                      Export
                    </CSVLink>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <table
                {...getTableProps()}
                className="table filtering-table table-responsive-lg"
              >
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          className={column.className}
                        >
                          {column.render("Header")}
                          {column.canFilter ? column.render("Filter") : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="">
                {loadingTimesheet ?
						<tr>
							<td style={{ textAlign: 'center' }} colSpan={columns.length}>
								<Spinner animation="border" size="lg" className="me-2" />
							</td>
						</tr>
						: rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          const { column } = cell;
                          return (
                            <td {...cell.getCellProps()}>
                              {(column.id === 'action' && getEmployeeUUid === 'All') ? '' : cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}


                </tbody>
                <tfoot>
                  {footerGroups.map((footerGroup) => (
                    <tr {...footerGroup.getFooterGroupProps()}>
                      {footerGroup.headers.map((column) => (
                        <td {...column.getFooterProps()}>
                          {!loadingTimesheet && column.Header === "End Time" && ("Days : " + data.length)}
                          {!loadingTimesheet && column.Header === "Total Hours" && ("Total : " + totalHoursSum.toFixed(2))}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      )}

      <ModalComponent
        setShowModal={setShowModal}
        showModal={showModal}
        modalData={modalData}
        setModalData={setModalData}
        getEmployeeWorkLocation={getEmployeeWorkLocation}
        handelModalButtonClick={handelModalButtonClick}
        patchTimesheetLoading={patchTimesheetLoading}
        getEmployeeProjectsByUUID={getEmployeeProjectsByUUID}
        loadingEmployeeProjectsByUUID={loadingEmployeeProjectsByUUID}
      />
    </Fragment>
  );
}
function ModalComponent(props) {
  const ModalFormRef = useRef(null);

  const totalHoursInputRef = useRef(null);

  const [modalValidation, setModalValidation] = useState("");

  const startTimeDefault = moment("09:00:00", "HH:mm:ss");

  const calculateTotalHours = (start, end, changedKey, changedValue) => {
    if (changedKey === "start_time" || changedKey === "end_time") {
      const startTime = changedKey === "start_time" ? changedValue : start;
      const endTime = changedKey === "end_time" ? changedValue : end;

      if (startTime && endTime) {
        const diffMilliseconds = endTime.valueOf() - startTime.valueOf();
        const hours = diffMilliseconds / (1000 * 60 * 60);

        if (hours <= 0) {
          setModalValidation("Invalid Time Slot");
          return "";
        } else {
          setModalValidation("");

          // Convert fractional part to minutes
          const minutes = Math.round((hours % 1) * 60);

          // Format the result as 'hours.minutes'
          return `${Math.floor(hours)}.${String(minutes).padStart(2, "0")}`;
        }
      }
    }

    return "";
  };

  const employeeLocationSelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;
    props.setModalData({
      ...props.modalData,
      [selectName]: selectValue,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "total_hours" && parseFloat(value) <= 0) {
      setModalValidation("Invalid Total Hours");
    } else {
      setModalValidation("");
    }
    props.setModalData({ ...props.modalData, [name]: value });
  };

  const handleTimeChange = (key, value) => {
    props.setModalData({
      ...props.modalData,
      [key]: value,
      total_hours: calculateTotalHours(
        props.modalData.start_time,
        props.modalData.end_time,
        key,
        value
      ),
    });
  };

  const handleButtonClick = (e) => {
    let FormValidity = ModalFormRef.current.reportValidity();
    if (FormValidity === true) {
      props.handelModalButtonClick();
    }
  };

  let closeModal = () => {
    props.setShowModal(false);
  };

  return (
    <>
      <Modal
        className="fade"
        show={props.showModal}
        size="lg"
        backdrop="static"
        onHide={() => closeModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit TimeSheet</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form ref={ModalFormRef}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Col className="col-md-14">
                    <label className="col-form-label">Date</label>
                    <Form.Control
                      type="date"
                      className="form-control mb-3"
                      name="date"
                      onChange={handleInputChange}
                      value={props.modalData.date}
                      readOnly
                    />
                  </Col>
                </Form.Group>

                <Form.Group>
                  <label>Start Time</label>
                  <TimePicker
                    showSecond={false}
                    minuteStep={5}
                    defaultValue={startTimeDefault}
                    value={props.modalData.start_time}
                    className="form-control mb-4"
                    onChange={(value) => handleTimeChange("start_time", value)}
                  />
                </Form.Group>

                <Form.Group>
                  <label>Work Location</label>
                  <Form.Select
                    name="location"
                    value={props.modalData.location}
                    onChange={employeeLocationSelectChangeHandler}
                    className="form-control mb-4"
                    required
                  >
                    <option value="">Select Location</option>
                    {props.getEmployeeWorkLocation?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.location_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group>
                  <label>Project</label>
                  <Form.Select
                    name="project"
                    value={props.modalData.project}
                    onChange={employeeLocationSelectChangeHandler}
                    className="form-control mb-4"
                    required
                  >
                    <option value="">Select Project</option>
                    {props.getEmployeeProjectsByUUID?.map((option) => (
                      <option key={option.project} value={option.project}>
                        {option.project_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <label>End Time</label>
                  <TimePicker
                    showSecond={false}
                    minuteStep={5}
                    value={props.modalData.end_time}
                    className="form-control mb-4"
                    onChange={(value) => handleTimeChange("end_time", value)}
                  />
                </Form.Group>

                <Form.Group>
                  <label>Total Hours</label>
                  <Form.Control
                    type="text" // Change the type to "text"
                    onChange={handleInputChange}
                    name="total_hours"
                    value={props.modalData.total_hours}
                    ref={totalHoursInputRef}
                    onClick={() => {
                      if (totalHoursInputRef.current) {
                        totalHoursInputRef.current.focus();
                        totalHoursInputRef.current.select();
                      }
                    }}
                    className="mb-4"
                    required
                  />

                  <div className="text-danger">{modalValidation}</div>
                </Form.Group>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary btn-sm"
            onClick={handleButtonClick}
            disabled={props.patchTimesheetLoading}
          >
            {props.patchTimesheetLoading ? (
              <>
                <Spinner animation="border" size="sm" className="me-2" />
                Loading...
              </>
            ) : (
              "Save"
            )}
          </Button>
          <Button variant="danger light btn-sm" onClick={() => closeModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default EmployeeTimeSheetView;
