import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";


const getEmployeeProfileImageByUUIDFn = async (query) => {
  let [, employee_uuid] = query.queryKey;
  let config = {
    method: "get",
    url: `/employee/api/v1/employee_profile_image/${employee_uuid}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetEmployeeProfileImageByUUID = (employee_uuid) => {
  return useQuery(["get-employee-profile-image-by-uuid", employee_uuid], getEmployeeProfileImageByUUIDFn);
};

const employeeProfileImageUpdateFn = async (employeeData) => {
  let { employee_uuid, userData } = employeeData;
  let config = {
    method: "patch",
    url: `/employee/api/v1/employee_profile_image/${employee_uuid}/`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: userData,
  };
  return await axios.request(config);
};
export const useEmployeeProfileImageUpdateFn = () => {
  return useMutation(["employee-profile-image-update"], employeeProfileImageUpdateFn);
};

const getProfileInfo = async () => {
  let config = {
    method: "get",
    url: "/user_management/profile_info/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetProfileInfo = () => {
  return useQuery(["get-profile-info"], getProfileInfo);
};
