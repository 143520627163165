import { useContext, useEffect, useRef, useState } from "react"
import { PASSWORD_MESSAGES, PROFILE_MESSAGES } from "../../../../utils/ConstantMessages"
import ChangePassword from "./ChangePassword"
import { Button, Spinner, Modal, Badge } from "react-bootstrap"
import { useEmployeeProfileImageUpdateFn, useGetEmployeeProfileImageByUUID, useGetProfileInfo } from "../../../../actions/employee-profile-image"
import { TostifyConfig } from "../../../../utils/Constance"
import { toast } from "react-toastify"
import profileImg from "../../../../images/avatar/user2.png";
import { useQueryClient } from "@tanstack/react-query"
import { ServiceContext } from "../../../../context/ServiceContext"
import { usePasswordUpdateFn } from "../../../../actions/employee-reset-password"
import LogoutPage from "../../../layouts/nav/Logout"
import { useNavigate } from "react-router-dom"
import { sessionLogout } from "../../../../services/AuthService"
import { useGetEmployeeInfoDetailByUUID } from "../../../../actions/employee-actions"
import { dateTimeHandler } from "../../../../utils/ComponentUtilse"



const AdminProfileView = (props) => {
	const profileContext = useContext(ServiceContext)
	const client = useQueryClient()
	const navigate = useNavigate();


	const [profileData, setProfileData] = useState({})
	const [current_password, setCurrentPassword] = useState('');
	const [new_password, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [passwordData, setPasswordData] = useState({})
	const [passwordMissMatch, setPasswordMissMatch] = useState(false)
	const [employeeImageData, setEmployeeImageData] = useState({})
	const [file, setFile] = useState()

	const [tooltipContent, setTooltipContent] = useState("");
	const [passwordError, setPasswordError] = useState('');
	const [currentPasswordError, setCurrentPasswordError] = useState('');
	const [passwordMessage, setPasswordMessage] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [basicModal, setBasicModal] = useState(false);

	const [employeeProfileData, setEmployeeProfileData] = useState({});
	const [employeePersonalInfoData, setEmployeePersonalInfoData] = useState({});



	useEffect(() => {
		passwordContentFn(new_password);
	}, []);

	const handleFocus = () => {
		setIsOpen(true);
	};

	const handleBlur = () => {
		setIsOpen(false);
	};

	const passwordContentFn = (passwordValue) => {
		let tooltip = "";
		if (passwordValue.length >= 8) {
			tooltip +=
				'<span class="text-success">&#10004; MUST contain at least 8 characters</span></br></br>';
		} else {
			tooltip +=
				'<span class="text-danger">&#10006; MUST contain at least 8 characters</span></br></br>';
		}

		if (/[A-Z]+/.test(passwordValue)) {
			tooltip +=
				'<span class="text-success">&#10004; MUST contain at least one uppercase letter</span></br></br>';
		} else {
			tooltip +=
				'<span class="text-danger">&#10006; MUST contain at least one uppercase letter</span></br></br>';
		}

		if (/[a-z]+/.test(passwordValue)) {
			tooltip +=
				'<span class="text-success">&#10004; MUST contain at least one lowercase letter</span></br></br>';
		} else {
			tooltip +=
				'<span class="text-danger">&#10006; MUST contain at least one lowercase letter</span></br></br>';
		}

		if (/[0-9]+/.test(passwordValue)) {
			tooltip +=
				'<span class="text-success">&#10004; MUST contain at least one number</span></br></br>';
		} else {
			tooltip +=
				'<span class="text-danger">&#10006; MUST contain at least one number</span></br></br>';
		}

		if (/[^A-Za-z0-9]+/.test(passwordValue)) {
			tooltip +=
				'<span class="text-success">&#10004; MUST contain at least one special character</span></br></br>';
		} else {
			tooltip +=
				'<span class="text-danger">&#10006; MUST contain at least one special character</span></br></br>';
		}
		setTooltipContent(tooltip);
	};

	const handlePassword = (passwordValue) => {
		const strengthChecks = {
			length: 0,
			hasUpperCase: false,
			hasLowerCase: false,
			hasDigit: false,
			hasSpecialChar: false,
		};

		strengthChecks.length = passwordValue.length >= 8 ? true : false;
		strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
		strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
		strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
		strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

		let verifiedList = Object.values(strengthChecks).filter((value) => value);

		let strength =
			verifiedList.length === 5
				? "Strong"
				: verifiedList.length >= 2
					? "Medium"
					: "Weak";

		passwordContentFn(passwordValue);

		setNewPassword(passwordValue);
		setPasswordMessage(strength);

		if (strength !== "Strong") {
			setPasswordError("Invalid password");
		} else {
			setPasswordError("");
			setPasswordMessage(strength);
		}

		console.log("verifiedList: ", `${(verifiedList?.length / 5) * 100}%`);
	};



	const employee_uuid = profileContext?.getEmployeeUUID

	const FormRef = useRef(null)
	const fileInputRef = useRef(null)

	const {
		data: getPersonalInfo,
		refetch: refetchPersonalInfo,
	} = useGetProfileInfo();

	const {
		data: getEmployeeDetailByUUID,
		isLoading: LoadingEmployeeDetailByUUID,
	} = useGetEmployeeInfoDetailByUUID(employee_uuid);

	const {
		data: getEmployeeImageUUID,
		isLoading: loadingGetEmployeeImageUUIDFn,
		refetch: refetchEmployeeImage,
	} = useGetEmployeeProfileImageByUUID(employee_uuid)

	const { mutate: patchEmployeeImageFn, isLoading: loadingEmployeeImageUploadFn } = useEmployeeProfileImageUpdateFn()
	const { mutate: patchPasswordFn, isLoading: loadingPasswordFn } = usePasswordUpdateFn()

	useEffect(() => {
		if (getPersonalInfo) {
			setEmployeePersonalInfoData(getPersonalInfo);
		}
	}, [getPersonalInfo]);

	useEffect(() => {
		if (getEmployeeImageUUID) {
			setFile(getEmployeeImageUUID?.profile_image_url)
		}
	}, [getEmployeeImageUUID])

	useEffect(() => {
		if (getEmployeeDetailByUUID) {
			setEmployeeProfileData(getEmployeeDetailByUUID);
		}
	}, [getEmployeeDetailByUUID]);

	const handleProfileOnChange = (event) => {
		const { name, value } = event.target
		if (event.target.type === "file") {
			setEmployeeImageData({
				...employeeImageData,
				[name]: event.target.files[0], // Capture the selected file object
			});
			setFile(URL.createObjectURL(event.target.files[0]));
		} else {
			setProfileData({
				...profileData,
				[name]: value
			})
		}
	}

	useEffect(() => {
		if (employeeImageData?.profile_image) {
			submitForm()
		}
	}, [employeeImageData])

	const submitForm = () => {
		let FormValidity = FormRef.current.reportValidity()

		if (FormValidity === true) {
			updateEmployeeBankHandeler()
		}
	}

	const handleImageClick = () => {
		// Trigger a click event on the hidden file input
		fileInputRef.current.click()
	}

	const handleProfileOnSubmit = (event) => {
		event.preventDefault()
	}

	const handlePasswordOnChange = (event) => {
		const { name, value } = event.target
		setPasswordData({
			...passwordData,
			[name]: value
		})
	}

	const handlePasswordSubmit = (event) => {
		event.preventDefault()
		if (!new_password && !confirmPassword) {
			setPasswordError("Invalid Passwords")
		}

		if (new_password !== confirmPassword) {
			setPasswordError("Passwords do not match.")
			return
		}
		if ((new_password && confirmPassword) && (new_password === confirmPassword) && (passwordMessage === "Strong")) {
			setPasswordError('');

			patchPasswordFn({ 'new_password': new_password, 'current_password': current_password }, {
				onSuccess: async (response) => {
					setBasicModal(true)
					localStorage.clear();
					// setPasswordSuccesss(response?.data?.detail);
					// setResetSuccesss(true);
				}, onError: (response) => {
					setCurrentPasswordError(response?.detail);
				}
			});
		}
		else {
			setPasswordError("Invalid Passwords")
		}

	}

	const updateEmployeeBankHandeler = () => {
		let userData = {
			profile_image: employeeImageData.profile_image
		}

		patchEmployeeImageFn(
			{ employee_uuid, userData },
			{
				onSuccess: (response) => {
					toast.success('Employee Image Updated', TostifyConfig)
					setEmployeeImageData({});
					client.invalidateQueries(['get-employee-profile-image-by-uuid']);
					client.invalidateQueries(['get-pending-action-by-uuid']);
					client.invalidateQueries(['get-employee-info-detail-by-uuid']);
					props.setCardView({ image: true });
				},
			}
		);
	}
	const handleLogout = () => {
		const { setIsAuthenticated } = profileContext;
		sessionLogout(navigate, setIsAuthenticated);
		// window.location.reload();
	}

	return (
		<div className="row">
			<div className="col-xl-4">
				<div className="card mb-4 mb-xl-0">
					<div className="card-header">{PROFILE_MESSAGES.profilePicture}</div>
					<div className="card-body text-center">
						<form ref={FormRef}>
							{file ?
								<img width={200} height={200} className="rounded-circle mb-2" src={file} alt="profile-image" />
								: <img width={200} height={200} className="rounded-circle mb-2" src={profileImg} alt="profile-image" />}

							<div className="small font-italic text-muted mb-4">JPG or PNG or JPEG</div>
							{/* <div style={{ position: 'relative' }} onClick={handleImageClick}>
							<button className="btn btn-primary" type="button">{PROFILE_MESSAGES.uploadButton}</button>
							<input type="file"
								placeholder="Type Text"
								name="profile_image"
								onChange={handleProfileOnChange}
								accept=".png, .jpg, .jpeg"
								ref={fileInputRef}
								style={{ opacity: 0, position: 'absolute', top: 0, left: 0 }}
							/>
						</div> */}
							{/* <div style={{ position: 'relative' }}> */}
							<Button
								//disabled={!employeeImageData?.profile_image}
								onClick={handleImageClick}
								variant="primary"
							>
								{(loadingEmployeeImageUploadFn) ? (
									<>
										<Spinner animation="border" size="sm" className="me-2" />
										Loading...
									</>
								) : (
									<>
										<span className='btn-icon-left'>
											<i className='fa fa-upload' />
										</span>&nbsp;&nbsp;
										Upload
									</>
								)}
							</Button>
							<input
								type="file"
								placeholder="Type Text"
								name="profile_image"
								onChange={handleProfileOnChange}
								accept=".png, .jpg, .jpeg"
								ref={fileInputRef}
								required
								style={{ opacity: 0, position: 'absolute', top: 0, left: 0 }}
							/>
							{/* </div> */}
						</form>
						<hr />
						<div className="mail-list row mt-5">
							<div className="list-group-item profile-list">
								<label className="col-lg-6 col-form-label">
									<i className="fa fa-user font-18 align-middle text-primary"></i>
									Role:
								</label>
								<label className="col-lg-6 col-form-label">
									{employeePersonalInfoData.is_admin_account ?
										<Badge pill bg="success" className='badge-sm text-capitalize'>ADMIN</Badge> :
										<Badge pill bg="success" className='badge-sm text-capitalize'>EMPLOYEE</Badge>
									}
								</label>
							</div>
							<div className="list-group-item profile-list ">
								<label className="col-lg-6 col-form-label">
									<i className="fa fa-clock font-18 align-middle text-primary"></i>
									Login:
								</label>
								<label className="col-lg-6" Style="font-size:13px;">
									{dateTimeHandler(employeePersonalInfoData?.last_login)
									}
								</label>
							</div>
							<div className="list-group-item profile-list" onClick={handleLogout}>
								<svg
									id="icon-logout" xmlns="http://www.w3.org/2000/svg"
									className="text-danger" width={18} height={18} viewBox="0 0 24 24"
									fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
								>
									<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
									<polyline points="16 17 21 12 16 7" />
									<line x1={21} y1={12} x2={9} y2={12} />
								</svg>&nbsp;
								Logout

							</div>
						</div>
						{/* <div className="row">
							<div className="col-xl-12 mail-list">
								<div className="form-group row list-group-item ">
									<label className="col-lg-6 col-form-label">
										Account Type:
									</label>
									<label className="col-lg-6 col-form-label">
										{employeePersonalInfoData.is_admin_account ?
											<Badge pill bg="success" className='badge-sm text-capitalize'>ADMIN</Badge> :
											<Badge pill bg="success" className='badge-sm text-capitalize'>EMPLOYEE</Badge>
										}
									</label>
								</div>
								<div className="form-group mb-3 row list-group-item profile-list">
									<label className="col-lg-5 col-form-label">
										Last Login:
									</label>
									<label className="col-lg-6 col-form-label">
										{employeePersonalInfoData?.last_login}
										{dateTimeHandler(employeePersonalInfoData?.last_login)}
									</label>
								</div>
							</div>
						</div> */}

					</div>
				</div>
			</div>
			<div className="col-xl-8">
				<div className="card mb-4 h-auto">
					<div className="card-header">{PROFILE_MESSAGES.profileDetails}</div>
					<div className="card-body">
						<div className="row">
							<div className="col-xl-12">
								<div className="form-group mb-3 row">
									<label className="col-lg-4 col-form-label">
										Employee Name:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData?.first_name}{" "}
										{employeeProfileData?.last_name}
									</label>
									<label className="col-lg-4 col-form-label">
										Employee Number:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.employee_number}
									</label>
									<label className="col-lg-4 col-form-label">
										Work Email:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.work_email}
									</label>
									<label className="col-lg-4 col-form-label">
										Position:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData?.position_name}
									</label>
									<label className="col-lg-4 col-form-label">
										Contact Number:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.work_phone}
									</label>
									<label className="col-lg-4 col-form-label">
										Work Location:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.work_location_name}
									</label>
									<label className="col-lg-4 col-form-label">
										Start Date:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.employee_start_date}
									</label>
									{/* <label className="col-lg-4 col-form-label">
										Reporting Manager:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.direct_manager_name}
									</label> */}
									{/* <label className="col-lg-4 col-form-label">
										Client:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.client_name}
									</label> */}
									<label className="col-lg-4 col-form-label">
										Project/Work/Branch:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.project_name}
									</label>
									<label className="col-lg-4 col-form-label">
										National Insurance Number:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.ni_number}
									</label>
									<label className="col-lg-4 col-form-label">
										SOC Number:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.soc}
									</label>
									{/* <label className="col-lg-6 col-form-label">
										Job Contract:
									</label>
									<label className="col-lg-6 col-form-label">
										{employeeProfileData?.job_contract ? <Button className="shadow btn-xs sharp me-1" variant='primary light' style={{ cursor: "pointer" }} onClick={() => handleFileDownload(props?.employeeProfileData?.job_contract)}><i className="fa fa-download"></i></Button> : null}
									</label> */}
									<label className="col-lg-4 col-form-label">
										Sponsored Employee:
									</label>
									<label className="col-lg-8 col-form-label">
										{employeeProfileData.is_sponsored_employee ?
											<Badge pill bg="success" className='badge-sm text-capitalize'>Yes</Badge> :
											<Badge pill bg="danger" className='badge-sm text-capitalize'>No</Badge>
										}
									</label>
								</div>
							</div>
						</div>
						{/* <form onSubmit={handleProfileOnSubmit}>
							<div className="mb-3">
								<label className="small mb-1" for="emailAddress">{PROFILE_MESSAGES.email}</label>
								<input className="form-control" id="emailAddress" disabled type="email" value="name@example.com" />
							</div>
							<div className="row gx-3 mb-3">
								<div className="col-md-6">
									<label className="small mb-1" for="firstName">{PROFILE_MESSAGES.firstName}<span className="required">*</span></label>
									<input className="form-control" required name="firstName" id="firstName" type="text" onChange={handleProfileOnChange} placeholder="Enter your first name" value={profileData?.firstName} />
								</div>
								<div className="col-md-6">
									<label className="small mb-1" for="lastName">{PROFILE_MESSAGES.lastName}<span className="required">*</span></label>
									<input className="form-control" required name="lastName" id="lastName" type="text" onChange={handleProfileOnChange} placeholder="Enter your last name" value={profileData?.lastName} />
								</div>
							</div>
							<button className="btn btn-primary" type="submit">{PROFILE_MESSAGES.saveButton}</button>
						</form> */}
					</div>
				</div>

				<ChangePassword
					new_password={new_password}
					setNewPassword={setNewPassword}
					current_password={current_password}
					setCurrentPassword={setCurrentPassword}
					confirmPassword={confirmPassword}
					setConfirmPassword={setConfirmPassword}
					passwordMissMatch={passwordMissMatch}
					handleOnChange={handlePasswordOnChange}
					handleSubmit={handlePasswordSubmit}
					isOpen={isOpen}
					handleFocus={handleFocus}
					tooltipContent={tooltipContent}
					handlePassword={handlePassword}
					handleBlur={handleBlur}
					passwordError={passwordError}
					currentPasswordError={currentPasswordError}
					loadingPasswordFn={loadingPasswordFn}
				/>
				<Modal centered className="fade" show={basicModal} >
					<Modal.Header>
						<Modal.Title>Password Reset</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className="card-body text-center">
							<h4>Password reset successful.</h4><br />
							<Button
								className=" btn-xs logout-btn" variant=' light'
								onClick={handleLogout}
							>
								<svg
									id="icon-logout" xmlns="http://www.w3.org/2000/svg"
									className="text-danger" width={18} height={18} viewBox="0 0 24 24"
									fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"
								>
									<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
									<polyline points="16 17 21 12 16 7" />
									<line x1={21} y1={12} x2={9} y2={12} />
								</svg>&nbsp;
								Logout{/* <LogoutPage /> */}
							</Button>
						</div>
					</Modal.Body>

				</Modal>
			</div>
		</div>
	)
}

export default AdminProfileView
