/// Menu
import React, {
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";
import { Collapse } from "react-bootstrap";
/// Link
import { Link } from "react-router-dom";
import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { ServiceContext } from "../../../context/ServiceContext";
import { IsValidPermissions } from "../../../services/AuthService";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const SideBar = () => {
  /// Open menu
  let d = new Date();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const { isAdminUser, getEmployeeUUID, getCompanyList, isRecruitmentEnabled } =
    useContext(ServiceContext);

  const initialState = {
    active: "",
    activeSubmenu: "",
  };

  const [state, setState] = useReducer(reducer, initialState);

  let handleheartBlast = document.querySelector(".heart");
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
  };

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  useEffect(() => {
    if (state.active === "") {
      if (isAdminUser === true) {
        setState({ active: "Dashboard" });
      }
      if (isAdminUser === false) {
        setState({ active: "Employee Profile" });
      }
    }
  }, [isAdminUser]);

  return (
    <>
      {" "}
      <div className="deznav">
        <div className="deznav-scroll ">
          <ul className="metismenu" id="menu">
            {MenuList(isAdminUser, getEmployeeUUID, isRecruitmentEnabled).map((data, index) => {

              if (!data) {
                return null; // Skip rendering if data is null or undefined
              }
                                          
              let menuClass = data.classsChange;
              if (menuClass === "menu-title") {
                return (
                  <li className={menuClass} key={index}>
                    {data.title}
                  </li>
                );
              } else {
                return (
                  <li
                    className={`
                       ${data.content &&
                        data.content.length > 0 &&
                        IsValidPermissions(data?.perms)
                        ? "has-menu"
                        : ""
                      }
                       ${state.active === data.title ? "mm-active" : ""}`}
                    key={index}
                  >
                    {data.content && data.content.length > 0
                      ? IsValidPermissions(data?.perms) && (
                        <Link
                          to={"#"}
                          className="has-arrow ai-icon"
                          onClick={() => {
                            handleMenuActive(data.title);
                          }}
                        >
                          {data.iconStyle}{" "}
                          <span className="nav-text">
                            {data.title} {IsValidPermissions(data?.perms)}{" "}
                          </span>
                        </Link>
                      )
                      : IsValidPermissions(data?.perms) && (
                        <Link
                          to={data.to}
                          onClick={() => {
                            handleMenuActive(data.title);
                          }}
                        >
                          {data.iconStyle}{" "}
                          <span className="nav-text">{data.title}</span>
                        </Link>
                      )}

                    {data.content && data.content.length > 0 && (
                      <Collapse in={state.active === data.title ? true : false}>
                        <ul
                          className={`${menuClass === "mm-collapse" ? "mm-show 12" : ""
                            }`}
                        >
                          {data.content &&
                            data.content.map((data, index) => {
                              return (
                                <li
                                  key={index}
                                  className={`${state.activeSubmenu === data.title
                                    ? "mm-active"
                                    : ""
                                    }`}
                                >
                                  {data.content && data.content.length > 0
                                    ? IsValidPermissions(data?.perms) && (
                                      <>
                                        <Link
                                          to={data.to}
                                          className={
                                            data.hasMenu ? "has-arrow" : ""
                                          }
                                          onClick={() => {
                                            handleSubmenuActive(data.title);
                                          }}
                                        >
                                          {data.title}
                                        </Link>
                                        <Collapse
                                          in={
                                            state.activeSubmenu === data.title
                                              ? true
                                              : false
                                          }
                                        >
                                          <ul
                                            className={`${menuClass === "mm-collapse"
                                              ? "mm-show 34"
                                              : ""
                                              }`}
                                          >
                                            {data.content &&
                                              data.content.map(
                                                (data, index) => {
                                                  return (
                                                    <li key={index}>
                                                      <Link
                                                        className={`${path === data.to
                                                          ? "mm-active"
                                                          : ""
                                                          }`}
                                                        to={data.to}
                                                      >
                                                        {data.title}
                                                      </Link>
                                                    </li>
                                                  );
                                                }
                                              )}
                                          </ul>
                                        </Collapse>
                                      </>
                                    )
                                    : IsValidPermissions(data?.perms) && (
                                      <Link
                                        to={data.to}
                                        className={`${path === data.to ? "mm-active" : ""
                                          }`}
                                        onClick={() => {
                                          handleMenuActive(data.parentTitle);
                                        }}
                                      >
                                        {data.title}
                                      </Link>
                                    )}
                                </li>
                              );
                            })}
                        </ul>
                      </Collapse>
                    )}
                  </li>
                );
              }
            })}
          </ul>

          {isAdminUser !== null && (
            <div
              className="copyright"
              style={{
                textAlign: "center",
                position: "relative",
                marginTop: "25%",
                marginBottom: 0,
              }}
            >
              <p>
                <strong>UKVICAS.COM</strong>
                <span>© {d.getFullYear()} All Rights Reserved</span>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SideBar;
