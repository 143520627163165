import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Card, Table, Col, Button, Spinner, Badge, ProgressBar, Row, Dropdown } from "react-bootstrap";
import { ColumnFilter } from "../components/table/FilteringTable/ColumnFilter";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DataTable from "../pages/commonPages/DataTable";
import { useEmployeeInfo } from "../../actions/employee-actions";
import { useTaskList } from "../../actions/task-action";
import { useGetAnnouncementList } from "../../actions/announcement-action";
import { useGetAllLeaves } from "../../actions/leave-actions";
import './icon.css'
import {
    useTable,
    useGlobalFilter,
    useFilters,
    usePagination,
} from "react-table";

import {
    useDashBoardSummary,
    useSponseredEmployees,
    useCOSExpiry,
    usePassportExpiry,
    useRTWExpiry,
    useVisaExpiry
} from "../../actions/dashboard-actions";
import { dateHandler } from "../../utils/ComponentUtilse";
import moment from 'moment';

function AdminDashBoard() {

    const [dashBoardData, setDashBoardData] = useState([]);

    const [sponseredEmpoyeeData, setSponseredEmpoyeeData] = useState([]);

    const [data, setData] = useState([]);
    const [announcement, setAnnouncement] = useState([])
    const [leaves, setLeaves] = useState([]);
    const [task, setTask] = useState([]);


    const navigate = useNavigate();

    const {
        data: getEmployeeListViewFn,
        isLoading: loadingGetEmployeeListFn,
        isFetching: loadingGetEmployeeFetchFn,
        refetch: refetchEmployee,
    } = useEmployeeInfo();

    const {
        data: getSponseredEmployeeData,
        isLoading: LoadingSponseredEmployee,
    } = useDashBoardSummary();

    const {
        data: getDashBoardData,
        isLoading: LoadingDashBoardData,
    } = useSponseredEmployees();

    const { data: getAllAnnouncement,
        isLoading: loadingGetAnnouncementFn
    } = useGetAnnouncementList();

    const { data: getAllLeaves,
        isLoading: loadingGetLeavesFn,
        refetch: refetchLeaves
    } = useGetAllLeaves();

    const {
        data: getAllTask,
        isLoading: loadingGetTaskFn,
        refetch: refetchTask
    } = useTaskList()

    useEffect(() => {
        if (getAllTask) {
            setTask(getAllTask);
        }
    }, [getAllTask]);

    useEffect(() => {
        if (getAllLeaves) {
            setLeaves(getAllLeaves);
        }
    }, [getAllLeaves]);

    useEffect(() => {
        if (getAllAnnouncement) {
            setAnnouncement(getAllAnnouncement);
        }
    }, [getAllAnnouncement]);


    useEffect(() => {
        if (getEmployeeListViewFn) {
            setData(getEmployeeListViewFn.filter((n) => n.active === true));
        }
    }, [getEmployeeListViewFn]);

    useEffect(() => {
        if (getSponseredEmployeeData) {
            setDashBoardData(getSponseredEmployeeData);
        }
    }, [getSponseredEmployeeData]);

    useEffect(() => {
        if (getDashBoardData) {
            setSponseredEmpoyeeData(getDashBoardData);
        }
    }, [getDashBoardData]);

    const calculateDaysAgo = (publishDate) => {
        const currentDate = new Date();
        const formattedPublishDate = new Date(publishDate);

        const timeDifference = currentDate - formattedPublishDate;
        const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        if (daysAgo === 0) {
            return 'Today';
        } else if (daysAgo === 1) {
            return 'Yesterday';
        } else {
            return `${daysAgo} days ago`;
        }
    };


    const TagList = ({ tags }) => {
        const tagArray = tags?.split(',');

        return (
            <div>
                {tagArray?.map((tag, index) => (
                    <Badge key={index} className='badge-sm text-capitalize' variant="primary">
                        {tag.trim()}
                    </Badge>
                ))}
            </div>
        );
    };



    const columns = useMemo(
        () => [
            {
                Header: "Employee",
                Footer: "",
                accessor: "employee_fullname",
                Filter: ColumnFilter,
                className: "minWidth220",
                Cell: ({ cell, row }) => {
                    // Initialize initials to an empty string
                    let initials = "";

                    if (row?.original?.first_name) {
                        // Extract the first letter and capitalize it
                        const firstNameInitial = row?.original?.first_name
                            .charAt(0)
                            .toUpperCase();
                        initials += firstNameInitial;
                    }

                    if (row?.original?.last_name) {
                        // Extract the first letter and capitalize it
                        const lastNameInitial = row?.original?.last_name
                            .charAt(0)
                            .toUpperCase();
                        initials += lastNameInitial;
                    }
                    return (
                        <div>
                            {row?.original?.employee_profile_image ? (
                                <div className="media">
                                    <img
                                        src={
                                            row?.original?.employee_profile_image_url
                                                ?.profile_image_url
                                        }
                                        className="rounded-lg me-2 user-avator-img"
                                        alt=""
                                    />{" "}
                                    <div className="media-body text-nowrap">
                                        {row.original.active ? (
                                            <Link
                                                className="table-icon-row"
                                                to={`/employee-profile-view/${row?.original?.employee_uuid}`}
                                            >
                                                <h6 className="text-black  fs-16 mb-0">
                                                    {row?.original?.first_name}
                                                </h6>
                                                <span className="text-black fs-14 mb-0">
                                                    {row?.original?.last_name}
                                                </span>
                                            </Link>
                                        ) : (
                                            <>
                                                <h6 className="text-black  fs-16 mb-0">
                                                    {row?.original?.first_name}
                                                </h6>
                                                <span className="text-black fs-14 mb-0">
                                                    {row?.original?.last_name}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="media">
                                    <div className="rounded-lg me-2 justify-content-center user-avator-letter">
                                        {initials}
                                    </div>{" "}
                                    <div className="media-body text-nowrap">
                                        {row.original.active ? (
                                            <Link
                                                className="table-icon-row"
                                                to={`/employee-profile-view/${row?.original?.employee_uuid}`}
                                            >
                                                <h6 className="text-black  fs-16 mb-0">
                                                    {row?.original?.first_name}
                                                </h6>
                                                <span className="text-black fs-14 mb-0">
                                                    {row?.original?.last_name}
                                                </span>
                                            </Link>
                                        ) : (
                                            <>
                                                <h6 className="text-black  fs-16 mb-0">
                                                    {row?.original?.first_name}
                                                </h6>
                                                <span className="text-black fs-14 mb-0">
                                                    {row?.original?.last_name}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <span className="w-space-no"></span>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: "Profile Progress",
                Footer: "Profile Progress",
                accessor: "pending_action",
                disableFilters: true,
                className: "minWidth240",
                Cell: ({ cell, row }) => {
                    return (
                        <>
                            <ProgressBar
                                style={{ height: "20px" }}
                                variant={row?.original?.pending_action_average_percentage === 100 ? "success" : 'warning'}
                                animated
                                now={row?.original?.pending_action_average_percentage}
                                label={`${row?.original?.pending_action_average_percentage}%`}
                            />
                            {row?.original?.pending_action_average_percentage === 0 && (
                                <div
                                    style={{
                                        textAlign: "center",
                                        marginTop: "-19px",
                                        fontSize: "14px",
                                        color: "#f06300",
                                    }}
                                >
                                    0%
                                </div>
                            )}
                        </>
                    );
                },
                className: "minWidth150",
            },

            {
                Header: "Is Sponsored Employee",
                Footer: "Is Sponsored Employee",
                accessor: "is_sponsored_employee",
                Cell: ({ cell, row }) => {
                    return row?.original?.is_sponsored_employee ? (
                        <Badge pill bg="success" className="badge-sm text-capitalize">
                            Yes
                        </Badge>
                    ) : (
                        <Badge pill bg="danger" className="badge-sm text-capitalize">
                            No
                        </Badge>
                    );
                },
                Filter: ColumnFilter,
                className: "minWidth120",
            },
            // {
            //     Header: "Position",
            //     Footer: "Position",
            //     accessor: "position_name",
            //     Filter: ColumnFilter,
            //     className: "minWidth220",
            // },

            {
                Header: "Passport Expiry In",
                Footer: "Passport Expiry In",
                accessor: "passport_expiry_day_count",
                Filter: ColumnFilter,
                className: "minWidth180",
            },
            {
                Header: "Visa Expiry In",
                Footer: "Visa Expiry In",
                accessor: "visa_expiry_day_count",
                Filter: ColumnFilter,
                className: "minWidth120",
            },
            // {
            //     Header: "COS Expiry In",
            //     Footer: "COS Expiry In",
            //     accessor: "cos_expiry_day_count",
            //     Filter: ColumnFilter,
            //     className: "minWidth120",
            // },
            {
                Header: "RTW Expiry In",
                Footer: "RTW Expiry In",
                accessor: "rtw_expiry_day_count",
                Filter: ColumnFilter,
                className: "minWidth120",
            },
            {
                Header: "Leave Remaining",
                Footer: "Leave Remaining",
                accessor: "leave_remaining",
                Filter: ColumnFilter,
                className: "minWidth180",
                Cell: ({ cell, row }) => {
                    const leaveRemaining = row?.original?.leave_remaining;

                    return (
                        <div>
                            <TagList tags={leaveRemaining} />
                        </div>
                    )
                }
            },
            {
                Header: "Start Date",
                Footer: "Start Date",
                accessor: "employee_start_date",
                Cell: ({ row }) => {
                    const { original } = row;
                    return <span>{dateHandler(original.employee_start_date)}</span>;
                },
                Filter: ColumnFilter,
                className: "minWidth150",
            },

        ],
        []
    );

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );



    return (
        <Fragment>
            <div className="row">

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h6 class="text-uppercase text-muted mb-0">Employee's</h6>
                                    <Link to="/list-employee/" className="hover-underline">
                                        <span class="h2 font-weight-bold mb-0">{dashBoardData?.employee_count}</span>
                                    </Link>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                                        <i class="fa fa-users"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-success mr-2 fs-14">Total Active Employee's</span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h6 class="text-uppercase text-muted mb-0">Sponsored Employee's</h6>
                                    <Link to="/list-employee/" className="hover-underline">
                                        <span class="h2 font-weight-bold mb-0">{dashBoardData?.sponsered_employee_count}</span>
                                    </Link>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                                        <i class="fa fa-users"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-success mr-2 fs-14">Total Sponsored Employee's</span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h6 class="text-uppercase text-muted mb-0">Passport Expiry</h6>
                                    <Link to="/expiry-dashboard/" className="hover-underline">
                                        <span class="h2 font-weight-bold mb-0"> {dashBoardData?.passport_expiration_count}</span>
                                    </Link>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                                        <i class="fas fa-address-card"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-success mr-2 fs-14">1 year & below</span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h6 class="text-uppercase text-muted mb-0">Visa Expiry</h6>
                                    <Link to="/expiry-dashboard/" className="hover-underline">
                                        <span class="h2 font-weight-bold mb-0">{dashBoardData?.visa_expiration_count}</span>
                                    </Link>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                                        <i class="fa fa-newspaper"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-success mr-2 fs-14">1 year & below</span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h6 class="text-uppercase text-muted mb-0">COS Expiry</h6>
                                    <Link to="/expiry-dashboard/" className="hover-underline">
                                        <span class="h2 font-weight-bold mb-0">{dashBoardData?.cos_expiration_count}</span>
                                    </Link>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                                        <i class="fa fa-newspaper"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-success mr-2 fs-14">1 year & below</span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-xl-4 col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <h6 class="text-uppercase text-muted mb-0">RTW Expiry</h6>
                                    <Link to="/expiry-dashboard/" className="hover-underline">
                                        <span class="h2 font-weight-bold mb-0">{dashBoardData?.rtw_expiration_count}</span>
                                    </Link>
                                </div>
                                <div class="col-auto">
                                    <div class="icon icon-shape bg-danger text-white rounded-circle shadow">
                                        <i class="fa fa-newspaper"></i>
                                    </div>
                                </div>
                            </div>
                            <p class="mt-3 mb-0 text-muted text-sm">
                                <span class="text-success mr-2 fs-14">1 year & below</span>
                                <span class="text-nowrap"></span>
                            </p>
                        </div>
                    </div>
                </div>

                {/* Announcement, Task, Leave Start Here */}


                <div className='col-xl-4 col-lg-6'>
                    <div className='card card-stats border-0 pb-0 mb-4 mb-xl-0'>
                        <div className='card-header  border-0 pb-0 '>
                            <h4 className='card-title'>Announcements</h4>
                        </div>
                        <div className='card-body'>
                            <div
                                style={{ height: '370px' }}
                                id='DZ_W_Todo3'
                                className='widget-media dz-scroll height370'
                            >
                                <ul className='timeline'>
                                    {loadingGetAnnouncementFn ?
                                        <li>
                                            <div className="timeline-panel">
                                                <Spinner animation="border" size="lg" className="me-2" />
                                            </div>
                                        </li>
                                        :

                                        announcement?.length > 0
                                            ? announcement.map((item, i) => {
                                                return (
                                                    <li>
                                                        <Link to="/list-announcements/">
                                                            <div className='timeline-panel'>
                                                                <div className='media me-2 media-success'> <i className="fa fa-bullhorn"></i></div>
                                                                <div className='media-body'>
                                                                    <h5 className='mb-1'>
                                                                        {item?.title} {' '}
                                                                        <small className='text-muted'>{calculateDaysAgo(item?.publish_date)}</small>
                                                                    </h5>
                                                                    <p className='mb-1'>
                                                                        {item?.message}
                                                                    </p>
                                                                    {/* <Link
                                                        to='/widget-basic'
                                                        className='btn btn-primary btn-xxs shadow'
                                                    >
                                                        Reply
                                                    </Link>
                                                    <Link
                                                        to='/widget-basic'
                                                        className='btn btn-outline-danger btn-xxs ms-1'
                                                    >
                                                        Delete
                                                    </Link> */}
                                                                </div>

                                                            </div>
                                                        </Link>
                                                    </li>

                                                )

                                            })

                                            :

                                            <li>
                                                <div className="timeline-panel">
                                                    <div className="media-body text-center">
                                                        <h6 className="mb-1">
                                                            No Announcements Found
                                                        </h6>
                                                    </div>

                                                </div>
                                            </li>

                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-xl-4 col-xxl-4 col-lg-4'>
                    <div className='card border-0 pb-0'>
                        <div className='card-header border-0 pb-0'>
                            <h4 className='card-title'>Tasks</h4>
                        </div>
                        <div className='card-body'>
                            <div
                                style={{ height: '370px' }}
                                id='DZ_W_Todo3'
                                className='widget-media dz-scroll height370'
                            >
                                <ul className='timeline'>
                                    {loadingGetTaskFn ?
                                        <li>
                                            <div className="timeline-panel">
                                                <Spinner animation="border" size="lg" className="me-2" />
                                            </div>
                                        </li>
                                        :

                                        task?.length > 0
                                            ? task.map((item, i) => {
                                                return (
                                                    <li>
                                                        <Link to="/list-task/">
                                                            <div className='timeline-panel'>
                                                                <div className='media me-2 media-warning'> <i className="fa fa-tasks"></i></div>
                                                                <div className='media-body'>
                                                                    <h5 className='mb-1'>
                                                                        {item?.title} {' '}
                                                                        <small className='text-muted'>{calculateDaysAgo(item?.start_date)}</small>
                                                                    </h5>
                                                                    <p className='mb-1'>
                                                                        {item?.description}
                                                                    </p>
                                                                    {/* <Link
                                                        to='/widget-basic'
                                                        className='btn btn-primary btn-xxs shadow'
                                                    >
                                                        Reply
                                                    </Link>
                                                    <Link
                                                        to='/widget-basic'
                                                        className='btn btn-outline-danger btn-xxs ms-1'
                                                    >
                                                        Delete
                                                    </Link> */}
                                                                </div>

                                                            </div>
                                                        </Link>
                                                    </li>

                                                )

                                            })

                                            :

                                            <li>
                                                <div className="timeline-panel">
                                                    <div className="media-body text-center">
                                                        <h6 className="mb-1">
                                                            No Tasks Found
                                                        </h6>
                                                    </div>

                                                </div>
                                            </li>

                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-xl-4 col-xxl-4 col-lg-4'>
                    <div className='card border-0 pb-0'>
                        <div className='card-header border-0 pb-0'>
                            <h4 className='card-title'>Leaves</h4>
                        </div>
                        <div className='card-body'>
                            <div
                                style={{ height: '370px' }}
                                id='DZ_W_Todo3'
                                className='widget-media dz-scroll height370'
                            >
                                <ul className='timeline'>
                                    {loadingGetLeavesFn ?
                                        <li>
                                            <div className="timeline-panel">
                                                <Spinner animation="border" size="lg" className="me-2" />
                                            </div>
                                        </li>
                                        :

                                        leaves?.length > 0
                                            ? leaves.map((item, i) => {
                                                return (
                                                    <li>
                                                        <Link to="/admin-leave-list/">
                                                            <div className='timeline-panel'>
                                                                <div className='media me-2 media-primary'> <i className="fa fa-envelope"></i></div>
                                                                <div className='media-body'>
                                                                    <h5 className='mb-1'>
                                                                        {item?.employee_fullname} {' '}
                                                                        <small className='text-muted'>{calculateDaysAgo(item?.start_date)}</small>
                                                                    </h5>
                                                                    <p className='mb-1'>
                                                                        {item?.policy_name_name} : {item?.reason}
                                                                    </p>
                                                                    {/* <Link
                                                        to='/widget-basic'
                                                        className='btn btn-primary btn-xxs shadow'
                                                    >
                                                        Reply
                                                    </Link>
                                                    <Link
                                                        to='/widget-basic'
                                                        className='btn btn-outline-danger btn-xxs ms-1'
                                                    >
                                                        Delete
                                                    </Link> */}
                                                                </div>

                                                            </div>
                                                        </Link>
                                                    </li>

                                                )

                                            })

                                            :

                                            <li>
                                                <div className="timeline-panel">
                                                    <div className="media-body text-center">
                                                        <h6 className="mb-1">
                                                            No Leaves Found
                                                        </h6>
                                                    </div>

                                                </div>
                                            </li>

                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>



                <Col lg={12}>
                    <div class="card">
                        <div class="card-body">
                            <DataTable
                                isLoading={loadingGetEmployeeListFn}
                                columns={columns}
                                tableInstance={tableInstance}
                            />
                        </div>
                    </div>
                </Col>
            </div>
        </Fragment>
    );
};

export default AdminDashBoard;