import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

// API POST Method

const employeePassPortPostFn = async (employeeData) => {
    let config = {
      method: "post",
      url: `/employee/api/v1/employee_passport/ `,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: employeeData,
    };
    
    return await axios.request(config);
  };
  export const useEmployeePassPortPostFn = () => {
    return useMutation(["employee-passport-post"], employeePassPortPostFn);
  };


  // API PATCH Method
  const employeePassPortUpdateFn = async (employeeData) => {
  
    let {id, userData} = employeeData;
    let config = {
      method: "patch",
      url: `/employee/api/v1/employee_passport/${id}/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: userData,
    };
    
    return await axios.request(config);
  };
  export const useEmployeePassPortUpdateFn = () => {
    return useMutation(["employee-passport-update"], employeePassPortUpdateFn);
  };

 // API GET Method Using UUID

  const getEmployeePassPortByUUIDFn = async (query) => {
    let [, employee_uuid] = query.queryKey;
    let config = {
      method: "get",
      url: `/employee/api/v1/employee_passport/?employee__employee_uuid=${employee_uuid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetEmployeePassPortByUUID = (employee_uuid) => {
    return useQuery(["get-employee-passport-by-uuid", employee_uuid], getEmployeePassPortByUUIDFn, {enabled: !!employee_uuid});
  };

  // API GET Method 
  
  const getEmployeePassPortByIDFn = async (query) => {
    let [, employee_id] = query.queryKey;
    let config = {
      method: "get",
      url: `/employee/api/v1/employee_passport/${employee_id}/`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useGetEmployeePassPortByID = (employee_id) => {
    return useQuery(["get-employee-passport-by-id", employee_id], getEmployeePassPortByIDFn, {enabled: false});
  }; 


  const getCountryofIssueFn = async () => {
    let config = {
      method: "get",
      url: "/employee/api/v1/employee_country_of_issue/",
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useCountryofIssueFn = () => {
    return useQuery(["get-country-of-issue"], getCountryofIssueFn);
  };

  const getNationalityFn = async () => {
    let config = {
      method: "get",
      url: "/employee/api/v1/employee_nationality/",
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const response = await axios.request(config);
    return response?.data;
  };
  export const useNationalityFn = () => {
    return useQuery(["get-nationality"], getNationalityFn);
  };