import React, { Fragment, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import {
    useEmployeeEducationUpdateFn,
    useGetEmployeeEducationByUUID,
    useGetEmployeeEducationByID,
    useEmployeeEducationPostFn
} from "../../../actions/employee-education-action";
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { extractFileName } from '../../../utils/ComponentUtilse';
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import DatePicker from 'react-datepicker';
import LoadingOverlay from 'react-loading-overlay';



function EmployeeEducation(props) {

    const client = useQueryClient();

    const location = useLocation()

    let id = props?.educationId;

    const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const [employeeEducationData, setEmployeeEducationData] = useState({});

    const [currentAttachmentData, setCurrentAttachmentData] = useState(null);

    const [selectedDate, setSelectedDate] = useState(null);

    const [parserDate, setParserDate] = useState(null);

    const {
        data: getEmployeeEducationID,
        isLoading: loadingGetEmployeeEducationIDFn,
        refetch: refetchEmployeeEducation,
    } = useGetEmployeeEducationByID(id);

    const {
        mutate: postEmployeeEducationFn,
        isLoading: loadingPostEmployeeEducationFn,
    } = useEmployeeEducationPostFn();

    const {
        mutate: patchEmployeeEducationFn,
        isLoading: loadingEmployeeEducationFn,
    } = useEmployeeEducationUpdateFn();


    const submitForm = () => {
        let FormValidity = FormRef.current.reportValidity();

        if (FormValidity === true) {
            if (id) {
                updateEmployeeEducationHandeler();
            }
            else {
                postEmployeeEducationHandeler();
            }
        }
    }

    const postEmployeeEducationHandeler = () => {

        let userData = {
            attachment: employeeEducationData.attachment,
            start_date: employeeEducationData.start_date || null,
            grade: employeeEducationData.grade || null,
            major: employeeEducationData.major,
            institution: employeeEducationData.institution,
            degree: employeeEducationData.degree,
            description: employeeEducationData.description,
            end_date: employeeEducationData.end_date || null,
            year: employeeEducationData.year,
            employee_employee_uuid: employee_uuid
        }

        postEmployeeEducationFn(
            userData,
            {
                onSuccess: (response) => {
                    //navigate('list-education')
                    toast.success('Employee Education Added', TostifyConfig);
                    client.invalidateQueries(['get-employee-education-by-uuid']);
                    client.invalidateQueries(['get-pending-action-by-uuid']);
                    props.setCardView({ education: true });
                },
            }
        );
    }

    const updateEmployeeEducationHandeler = () => {

        let userData = {
            // attachment: employeeEducationData.attachment,
            start_date: employeeEducationData.start_date,
            grade: employeeEducationData.grade,
            major: employeeEducationData.major,
            institution: employeeEducationData.institution,
            degree: employeeEducationData.degree,
            description: employeeEducationData.description,
            end_date: employeeEducationData.end_date,
            year: employeeEducationData.year,
            employee_employee_uuid: employee_uuid
        }

        if (employeeEducationData?.attachment?.name) {
            userData['attachment'] = employeeEducationData.attachment
        }

        patchEmployeeEducationFn(
            { id, userData },
            {
                onSuccess: (response) => {
                    //navigate('list-education')
                    toast.success('Employee Education Updated', TostifyConfig);
                    // history.push(`/employee/employee_education_list/${employee_uuid}`);
                    client.invalidateQueries(['get-employee-education-by-uuid']);
                    props.setCardView({ education: true });
                },
            }
        );
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (event.target.type === "file") {
            setEmployeeEducationData({
                ...employeeEducationData,
                [name]: event.target.files[0], // Capture the selected file object
            });
            setCurrentAttachmentData(event.target.files[0].name);
        } else {
            setEmployeeEducationData({
                ...employeeEducationData,
                [name]: value,
            });
        }
    };

    const handleDateChange = (date) => {
        const year = date.getFullYear();
        setSelectedDate(date);
        setParserDate(`${year}`);
        setEmployeeEducationData({
            ...employeeEducationData,
            'year': `${year}`,
        });
    };

    // useEffect(() => {
    //     const currentDate = new Date();
    //     const year = currentDate.getFullYear();
    //     // Set the selected date to the first day of the current month and year
    //     const firstDayOfMonth = new Date(currentDate.getFullYear(), 1);
    //     setSelectedDate(firstDayOfMonth);
    //     setParserDate(`${year}`);
    // }, []);

    useEffect(() => {
        if (employeeEducationData?.year) {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            // Set the selected date to the first day of the current month and year
            const firstDayOfMonth = new Date(parseInt(employeeEducationData?.year), 1);
            setSelectedDate(firstDayOfMonth);
            setParserDate(`${year}`);
        }
    }, [employeeEducationData?.year]);

    useEffect(() => {
        if (id) {
            refetchEmployeeEducation();
        }

        if (getEmployeeEducationID) {
            setEmployeeEducationData(getEmployeeEducationID);
            setCurrentAttachmentData(extractFileName(getEmployeeEducationID.attachment));
        }
    }, [getEmployeeEducationID, refetchEmployeeEducation, id])


    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>Employee Education</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingGetEmployeeEducationIDFn && id}
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Degree<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="degree"
                                                    onChange={handleInputChange}
                                                    value={employeeEducationData.degree}
                                                    required />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label className="text-label">Institution<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="institution"
                                                    onChange={handleInputChange}
                                                    value={employeeEducationData.institution}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Major</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="major"
                                                    onChange={handleInputChange}
                                                    value={employeeEducationData.major}
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Year of completion</label><br />
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                    className="form-control mt-0"
                                                />
                                            </Col>
                                            {/* <Col className='col-md-6 mb-2'>
                                            <label>Start Date<span className="required">*</span></label>
                                            <Form.Control
                                                type="date"
                                                placeholder="Type Text"
                                                name="start_date"
                                                onChange={handleInputChange}
                                                value={employeeEducationData.start_date}
                                                required />
                                        </Col> */}
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            {/* <Col className='col-md-6 mb-2'>
                                            <label>End Date<span className="required">*</span></label>
                                            <Form.Control
                                                type="date"
                                                placeholder="Type Text"
                                                name="end_date"
                                                onChange={handleInputChange}
                                                value={employeeEducationData.end_date}
                                                required />
                                        </Col> */}
                                            {/* <Col className='col-md-6 mb-2'>
                                            <label>Grade<span className="required">*</span></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Type Text"
                                                name="grade"
                                                onChange={handleInputChange}
                                                value={employeeEducationData.grade}
                                                required />
                                        </Col> */}
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Description</label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="description"
                                                    onChange={handleInputChange}
                                                    value={employeeEducationData.description}
                                                />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Attachment</label>
                                                <Form.Control
                                                    type="file"
                                                    placeholder="Type Text"
                                                    name="attachment"
                                                    onChange={handleInputChange}
                                                />

                                                <Row className='col-md-6 mt-3'>
                                                    <label>Selected File</label>
                                                    <label>{currentAttachmentData}</label>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>

                                            </Col>

                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mt-2'>
                                        <Row>
                                            <Col>
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            props.setCardView({ education: true });
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="primary btn-sm"
                                                        onClick={submitForm}
                                                        disabled={loadingGetEmployeeEducationIDFn && id  || loadingPostEmployeeEducationFn || loadingEmployeeEducationFn}
                                                    >
                                                        {(loadingPostEmployeeEducationFn || loadingEmployeeEducationFn) ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    className="me-2"
                                                                />
                                                                Loading...
                                                            </>
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default EmployeeEducation