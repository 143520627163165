import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";


const employeeDeleteById = async (formData) => {

  const { employee_uuid, end_contract_status, end_contract_date, end_contract_notes, eligible_to_rehire } = formData;

  // Convert boolean to string "True" or "False"
  const eligibleToRehireString = eligible_to_rehire ? "True" : "False";

  try {
    // Construct the URL with query parameters
    const url = `/employee/api/v1/employee/${employee_uuid}/?end_contract_notes=${end_contract_notes}&end_contract_status=${end_contract_status}&end_contract_date=${end_contract_date}&eligible_to_rehire=${eligibleToRehireString}`;

    // Send the DELETE request
    const response = await axios.delete(url);

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle error or throw it again
    throw error;
  }
};

export const useEmployeeDeleteById = () => {
  // Use useMutation hook to handle the delete action
  return useMutation(["employee-delete"], employeeDeleteById);
};



// Employee Info Request
const employeeUpdateFn = async (employeeData) => {
  let { employee_uuid, userData } = employeeData;
  let config = {
    method: "patch",
    url: `/employee/api/v1/employee/${employee_uuid}/`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: userData,
  };
  return await axios.request(config);
};
export const useEmployeeUpdateFn = () => {
  return useMutation(["employee-profile-update"], employeeUpdateFn);
};

const employeeOnboardFn = async (userData) => {
  let config = {
    method: "post",
    url: `/employee/api/v1/employee_onboarding/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: userData,
  };

  return await axios.create().request(config);
};
export const useEmployeeOnboardFn = () => {
  return useMutation(["employee-profile-register"], employeeOnboardFn);
};

const getEmployeePositionFn = async () => {
  let config = {
    method: "get",
    url: "/employee/api/v1/employee_role_policy/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useEmployeePosition = () => {
  return useQuery(["get-employee-position"], getEmployeePositionFn);
};

const getEmployeeLocationFn = async () => {
  let config = {
    method: "get",
    url: "/employee/api/v1/employee_work_location/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useEmployeeWorkLocation = () => {
  return useQuery(["get-employee-work"], getEmployeeLocationFn);
};


const getEmployeeByUUIDFn = async (query) => {
  let [, employee_uuid] = query.queryKey;
  let config = {
    method: "get",
    url: `/employee/api/v1/employee/${employee_uuid}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetEmployeeByUUID = (employee_uuid) => {
  return useQuery(["get-employee-by-uuid", employee_uuid], getEmployeeByUUIDFn);
};


const getEmployeeClientFn = async () => {
  let config = {
    method: "get",
    url: "/organization/api/v1/clients/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useEmployeeClient = () => {
  return useQuery(["get-employee-client"], getEmployeeClientFn);
};

const getEmployeeProjectFn = async () => {
  let config = {
    method: "get",
    url: "/organization/api/v1/projects/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useEmployeeProject = () => {
  return useQuery(["get-employee-project"], getEmployeeProjectFn);
};

const getEmployeeTeamsFn = async () => {
  let config = {
    method: "get",
    url: "/organization/api/v1/teams/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useEmployeeTeam = () => {
  return useQuery(["get-employee-team"], getEmployeeTeamsFn);
};

// Employee Personal Info Request

const getEmployeeInfoFn = async () => {

  let config = {
    method: "get",
    url: `/employee/api/v1/employee_info/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useEmployeeInfo = () => {
  return useQuery(["get-employee-info"], getEmployeeInfoFn, { cacheTime: 0 });
};

// Employee Dropdown Info Request

const getEmployeeDropdownFn = async () => {

  let config = {
    method: "get",
    url: `/employee/api/v1/employee_drop_down/`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useEmployeeDropdown = () => {
  return useQuery(["get-employee-dropdown"], getEmployeeDropdownFn, { cacheTime: 0 });
};

const getEmployeeInfoByUUIDFn = async (query) => {
  let [, employee_uuid] = query.queryKey;
  let config = {
    method: "get",
    url: `/employee/api/v1/employee_personal_info/${employee_uuid}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetEmployeeInfoByUUID = (employee_uuid) => {
  return useQuery(["get-employee-personal-info-by-uuid", employee_uuid], getEmployeeInfoByUUIDFn, { enabled: !!employee_uuid });
};

const employeeInfoUpdateFn = async (employeeData) => {
  let { employee_uuid, userData } = employeeData;
  let config = {
    method: "patch",
    url: `/employee/api/v1/employee_personal_info/${employee_uuid}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: userData,
  };
  return await axios.request(config);
};
export const useEmployeeInfoUpdateFn = () => {
  return useMutation(["employee-profile-info-update"], employeeInfoUpdateFn);
};

// Emergency Contact Request

const getEmergencyContactByUUIDFn = async (query) => {
  let [, employee_uuid] = query.queryKey;
  let config = {
    method: "get",
    url: `/employee/api/v1/emergency_contact_info/${employee_uuid}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetEmergencyContactByUUID = (employee_uuid) => {
  return useQuery(["get-emergency-contact-by-uuid", employee_uuid], getEmergencyContactByUUIDFn, { enabled: !!employee_uuid });
};

const emergencyContactUpdateFn = async (employeeData) => {

  let { employee_uuid, userData } = employeeData;
  let config = {
    method: "patch",
    url: `/employee/api/v1/emergency_contact_info/${employee_uuid}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: userData,
  };
  return await axios.request(config);
};
export const useEmergencyContactUpdateFn = () => {
  return useMutation(["employee-profile-info-update"], emergencyContactUpdateFn);
};


const getEmployeeInfoDetailByUUIDFn = async (query) => {
  let [, employee_uuid] = query.queryKey;
  let config = {
    method: "get",
    url: `/employee/api/v1/employee_info/${employee_uuid}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetEmployeeInfoDetailByUUID = (employee_uuid) => {
  return useQuery(["get-employee-info-detail-by-uuid", employee_uuid], getEmployeeInfoDetailByUUIDFn, { enabled: !!employee_uuid });
};


const getAllEmployee = async () => {
  let config = {
    method: "get",
    url: "employee/api/v1/employee/",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useGetEmployeeList = () => {
  return useQuery(["get-all-employee"], getAllEmployee);
};

const getVerifyEmployeeEmailFn = async (query) => {
  let [, user_email] = query.queryKey;
  let config = {
    method: "get",
    url: `/employee/api/v1/employee_onboard_verify_user/?work_email__iexact=${user_email}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useVerifyEmployeeEmail = (user_email) => {
  return useQuery(["get-verify-employee-email", user_email], getVerifyEmployeeEmailFn, { enabled: !!user_email });
};


const getVerifyEmployeeNumberFn = async (query) => {
  let [, user_number] = query.queryKey;
  let config = {
    method: "get",
    url: `/employee/api/v1/employee_onboard_verify_user/?employee_number__iexact=${user_number}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await axios.request(config);
  return response?.data;
};
export const useVerifyEmployeeNumber = (user_number) => {
  return useQuery(["get-verify-employee-Number", user_number], getVerifyEmployeeNumberFn, { enabled: !!user_number });
};