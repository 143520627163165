export const GENERIC_STATUS_CHOICES = [
  {
    label: "Active",
    value: 1
  },
  {
    label: "Inactive",
    value: 2
  },
  {
    label: "Disabled",
    value: 3
  },
]


export const LEAVE_APPROVED_STATUS_COLOR = [
  { status: 'Approved', color: 'success' },
  { status: 'Rejected', color: 'red' },
  { status: 'OnHold', color: 'info' },
  { status: 'Pending', color: 'warning' },
]

export const TASK_STATUS = [
  { id: 1, name: 'ToDo' },
  { id: 2, name: 'Assigned' },
  { id: 3, name: 'In Progress' },
  { id: 4, name: 'On Hold' },
  { id: 5, name: 'Completed' },
  { id: 6, name: 'Cancelled' },
  { id: 7, name: 'Pending Review' },
  { id: 8, name: 'Blocked' },
  { id: 9, name: 'Resolved' },
];



export const COS_STATUS_CHOICES = [
  { id: 1, name: 'Approved' },
  { id: 2, name: 'Rejected' },
  { id: 4, name: 'Pending' },
  { id: 5, name: 'Canceled' },
  { id: 6, name: 'Assigned' },
  { id: 7, name: 'Not Valid' },
  { id: 8, name: 'Valid' },
  { id: 9, name: 'In Progress' },
];



export const RTW_STATUS_CHOICES = [
  { id: 1, name: 'Approved' },
  { id: 2, name: 'Rejected' },
  { id: 3, name: 'OnHold' },
  { id: 4, name: 'Pending' },
  { id: 5, name: 'Canceled' },
];

export const Title = [
  { id: 1, name: 'Mr' },
  { id: 2, name: 'Mrs' },
  { id: 3, name: 'Ms' },
  { id: 4, name: 'Miss' },
  { id: 5, name: 'Dr' }
];


export const Gender = [
  { id: 1, name: 'Male' },
  { id: 2, name: 'Female' },
  { id: 3, name: 'Others' }
];

export default Gender;

export const MaritialStatus = [
  { id: 1, name: "Single" },
  { id: 2, name: "Married" },
  { id: 3, name: "Divorced" },
  { id: 4, name: "Widowed" },
  { id: 5, name: "Separated" },
  { id: 6, name: "Domestic Partnership" },
  { id: 7, name: "Civil Union" },
]

export const TostifyConfig = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: "light",
}


export const JOB_TYPE = [
  { id: 1, name: 'Full-Time' },
  { id: 2, name: 'Part-Time' },
  { id: 3, name: 'Remote Job' },
];


export const EMPLOYMENT_TYPE = [
  { id: 1, name: 'Permanent' },
  { id: 2, name: 'Contract' },
  { id: 3, name: 'Internship' },
  { id: 4, name: 'Apprenticeship' },
  { id: 5, name: 'Trainee' },

];

export const JOB_STATUS = [
  { id: 1, name: 'Application Open' },
  { id: 2, name: 'Application Closed' },
  { id: 3, name: 'Application On Hold' },
];

export const INTERVIEW_STATUS = [
  { id: 1, name: 'Application Received' },
  { id: 2, name: 'Resume Screening' },
  { id: 3, name: 'Phone Screening' },
  { id: 4, name: 'First Round Interview' },
  { id: 5, name: 'Second Round Interview' },
  { id: 6, name: 'Salary Discussion' },
  { id: 7, name: 'Offer Accepted' },
  { id: 8, name: 'Reference Check' },
  { id: 9, name: 'Background Check' },
  { id: 10, name: 'Candidate Selected' },
  { id: 11, name: 'Candidate Rejected' },
]


export const getBadgeColor = (interviewStatusName) => {
  switch (interviewStatusName) {
    case 'Application Received':
    case 'Resume Screening':
    case 'Phone Screening':
    case 'First Round Interview':
    case 'Second Round Interview':
    case 'Salary Discussion':
    case 'Reference Check':
    case 'Background Check':
      return 'info'; // You can adjust the class name as needed
    case 'Candidate Selected':
      return 'success';
    case 'Candidate Rejected':
      return 'red';
    default:
      return 'info'; // Default color if the status doesn't match any case
  }
};


export const LEAVE_MONTH_STATUS = [
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
]


export const EMPLOYEMENT_END_STATUS = [
  { id: 1, name: 'Resigned' },
  { id: 2, name: 'Suspend' },
  { id: 3, name: 'Terminated' },
  { id: 4, name: 'Rejoin' },
  { id: 5, name: 'Others' },
]
