import React, { Fragment, useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useRef } from "react";
import { useLocation } from "react-router-dom/dist";
import {
  useEmployeePostRtwFn,
  useEmployeeRtwUpdateFn,
  useGetEmployeeRtwByID,
} from "../../../actions/cos_rtw-actions";
import { useState } from "react";
import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import { RTW_STATUS_CHOICES } from "../../../utils/Constance";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from "../../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import { extractFileName } from "../../../utils/ComponentUtilse";
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import LoadingOverlay from "react-loading-overlay";

function EmployeeRtw(props) {
  const client = useQueryClient();

  const location = useLocation();

  let id = props?.rtwId;

  const employee_uuid = props?.employee_uuid;

  const FormRef = useRef(null);

  const [employeeRtwData, setEmployeeRtwData] = useState({});

  const [isChecked, setIsChecked] = useState(false);

  const [isActive, setIsActive] = useState(false);

  const [currentAttachmentData, setCurrentAttachmentData] = useState(null);

  const [currentProfileData, setCurrentProfileData] = useState(null);

  const {
    data: getEmployeeRtwByID,
    isLoading: loadingGetEmployeeRtwByID,
    refetch: refetchEmployeeRtwByID,
  } = useGetEmployeeRtwByID(id);

  const { mutate: postEmployeePostRtwFn, isLoading: loadingEmployeePostRtwFn } =
    useEmployeePostRtwFn();

  const {
    mutate: patchEmployeeRtwUpdateFn,
    isLoading: loadingEmployeeRtwUpdateFn,
  } = useEmployeeRtwUpdateFn();

  const submitForm = () => {
    let FormValidity = FormRef.current.reportValidity();

    if (FormValidity === true) {
      if (id) {
        updateEmployeeRtwHandeler();
      } else {
        postEmployeeRtwHandeler();
      }
    }
  };

  const postEmployeeRtwHandeler = () => {
    let userData = {
      emp_share_code: employeeRtwData.emp_share_code,
      emp_share_code_expiry: employeeRtwData.emp_share_code_expiry,
      rtw_reference: employeeRtwData.rtw_reference,
      rtw_submit_date: employeeRtwData.rtw_submit_date,
      rtw_expiry_date: employeeRtwData.rtw_expiry_date,
      is_verified: employeeRtwData.is_verified,
      rtw_status: employeeRtwData.rtw_status,
      attachment: employeeRtwData.attachment,
      profile_image: employeeRtwData.profile_image,
      current_active: employeeRtwData.current_active,
      employee_employee_uuid: employee_uuid,
    };

    postEmployeePostRtwFn(userData, {
      onSuccess: (response) => {
        //navigate('list-education')
        toast.success("Employee RTW-Data Added", TostifyConfig);
        client.invalidateQueries(["get-employee-rtw-by-uuid"]);
        client.invalidateQueries(["get-pending-action-by-uuid"]);
        props.setCardView({ rtw: true });
      },
    });
  };

  const updateEmployeeRtwHandeler = () => {
    let userData = {
      emp_share_code: employeeRtwData.emp_share_code,
      emp_share_code_expiry: employeeRtwData.emp_share_code_expiry,
      rtw_reference: employeeRtwData.rtw_reference,
      rtw_submit_date: employeeRtwData.rtw_submit_date,
      rtw_expiry_date: employeeRtwData.rtw_expiry_date,
      is_verified: employeeRtwData.is_verified,
      rtw_status: employeeRtwData.rtw_status,
      current_active: employeeRtwData.current_active,
      employee_employee_uuid: employee_uuid,
    };

    if (employeeRtwData?.attachment?.name) {
      userData["attachment"] = employeeRtwData.attachment;
    }

    if (employeeRtwData?.profile_image?.name) {
      userData["profile_image"] = employeeRtwData.profile_image;
    }

    patchEmployeeRtwUpdateFn(
      { id, userData },
      {
        onSuccess: (response) => {
          //navigate('list-education')
          toast.success("Employee COS-Data Updated", TostifyConfig);
          // history.push(`/employee/employee_education_list/${employee_uuid}`);
          client.invalidateQueries(["get-employee-rtw-by-uuid"]);
          props.setCardView({ rtw: true });
        },
      }
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (event.target.type === "file") {
      setEmployeeRtwData({
        ...employeeRtwData,
        [name]: event.target.files[0], // Capture the selected file object
      });
      if (name === "attachment")
        setCurrentAttachmentData(event.target.files[0].name);
      if (name === "profile_image")
        setCurrentProfileData(event.target.files[0].name);
    } else if (event.target.type === "checkbox") {
      setEmployeeRtwData({
        ...employeeRtwData,
        [name]: event.target.checked,
      });
      setIsActive(event.target.checked);
      setIsChecked(event.target.checked);
    } else {
      setEmployeeRtwData({
        ...employeeRtwData,
        [name]: value,
      });
    }
  };
  const employeeSelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;
    //const selectedOptionLabel = e.target.options[selectedIndex].label;
    setEmployeeRtwData({
      ...employeeRtwData,
      [selectName]: selectValue,
    });
  };

  useEffect(() => {
    if (id) {
      refetchEmployeeRtwByID();
    }
  }, [refetchEmployeeRtwByID, id]);

  useEffect(() => {
    if (getEmployeeRtwByID) {
      setEmployeeRtwData(getEmployeeRtwByID);
      setIsChecked(getEmployeeRtwByID.is_verified);
      setIsActive(getEmployeeRtwByID.current_active);
      setCurrentAttachmentData(extractFileName(getEmployeeRtwByID.attachment));
      setCurrentProfileData(extractFileName(getEmployeeRtwByID.profile_image));
    }
  }, [getEmployeeRtwByID]);

  return (
    <Fragment>
      <div>
        <div className="col-xl-12 col-xxl-12">
          <Card className="az-card">
            <Card.Header>
              <h3>Employee RTW</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingGetEmployeeRtwByID && id}
              spinner
              text="Fetching..."
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-lg-6 mb-2">
                        <label className="text-label">Share Code</label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="emp_share_code"
                          onChange={handleInputChange}
                          value={employeeRtwData?.emp_share_code}
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label className="text-label">Share Code Expiry</label>
                        <Form.Control
                          type="date"
                          placeholder="Type Text"
                          name="emp_share_code_expiry"
                          onChange={handleInputChange}
                          value={employeeRtwData?.emp_share_code_expiry}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>
                          RTW Reference<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="rtw_reference"
                          onChange={handleInputChange}
                          value={employeeRtwData.rtw_reference}
                          required
                        />
                      </Col>
                      {/* <Col className='col-md-6 mb-2'>
                                            <label>Year of completion</label>
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={handleDateChange}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                className="form-control mt-0"
                                            />
                                        </Col> */}
                      <Col className="col-md-6 mb-2">
                        <label>
                          RTW Submit Date<span className="required">*</span>
                        </label>
                        <Form.Control
                          type="date"
                          placeholder="Type Text"
                          name="rtw_submit_date"
                          onChange={handleInputChange}
                          value={employeeRtwData.rtw_submit_date}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>RTW Expiry Date</label>
                        <Form.Control
                          type="date"
                          placeholder="Type Text"
                          name="rtw_expiry_date"
                          onChange={handleInputChange}
                          value={employeeRtwData.rtw_expiry_date}
                        />
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <label>
                          RTW Status<span className="required">*</span>
                        </label>
                        <Form.Select
                          name="rtw_status"
                          className="form-control"
                          value={employeeRtwData.rtw_status}
                          onChange={employeeSelectChangeHandler}
                          required
                        >
                          <option value="">Status</option>
                          {RTW_STATUS_CHOICES?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label>Screenshot</label>
                        <Form.Control
                          type="file"
                          accept="image/*"
                          placeholder="Type Text"
                          name="profile_image"
                          onChange={handleInputChange}
                        />
                        <Row>
                          <label className="mt-3">Selected File</label>
                          <label>{currentProfileData}</label>
                        </Row>
                      </Col>

                      <Col className="col-md-6 mb-2">
                        <label>Attachment</label>
                        <Form.Control
                          type="file"
                          placeholder="Type Text"
                          name="attachment"
                          onChange={handleInputChange}
                        />
                        <Row>
                          <label className="mt-3">Selected File</label>
                          <label>{currentAttachmentData}</label>
                        </Row>
                      </Col>
                      <Col className="col-md-6 mb-2">
                        <div className="form-check custom-checkbox mb-3">
                          <input
                            type="checkbox"
                            name="current_active"
                            className="form-check-input"
                            id="current_active"
                            checked={isActive}
                            onChange={handleInputChange}
                            value={employeeRtwData?.current_active}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="current_active"
                          >
                            <b>Current Active</b>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mt-2">
                    <Row>
                      <Col>
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              props.setCardView({ rtw: true });
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="primary btn-sm"
                            onClick={submitForm}
                            disabled={
                              (loadingGetEmployeeRtwByID && id) ||
                              loadingEmployeePostRtwFn ||
                              loadingEmployeeRtwUpdateFn
                            }
                          >
                            {loadingEmployeePostRtwFn ||
                            loadingEmployeeRtwUpdateFn ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                />
                                Loading...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  );
}

export default EmployeeRtw;
