import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";


// API POST Method

const interViewStatusPostFn = async (applicationData) => {
    let config = {
        method: "post",
        url: `/recruitment/api/v1/interview_status/`,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data: applicationData,
    };

    return await axios.request(config);
};
export const useInterViewStatusPostFn = () => {
    return useMutation(["interview-post"], interViewStatusPostFn);
};


const getInterViewStatusById = async (query) => {
    let [, applicationid] = query.queryKey;
    let config = {
        method: "get",
        url: `/recruitment/api/v1/interview_status/?job_applicantion__id=${applicationid}`,
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.request(config);
    return response?.data;
};
export const useGetInterViewStatusById = (applicationid) => {
    return useQuery(["get-interviewstatus-by-uuid", applicationid], getInterViewStatusById);
};
