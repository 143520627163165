import React, { Fragment, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Gender, { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import LoadingOverlay from 'react-loading-overlay';
import { useApplicationPostFn, useApplicationUpdateFn, useGetApplicationByID } from '../../../actions/recruitment-application-action';
import { useGetInterviewStatus } from '../../../actions/recruitment-actions'
import { INTERVIEW_STATUS } from '../../../utils/Constance'
import { extractFileName } from '../../../utils/ComponentUtilse'
import { useInterViewStatusPostFn } from "../../../actions/interviewstatus-actions";
import { dateTimeHandler } from '../../../utils/ComponentUtilse'
import { useVerifyEmployeeEmail } from '../../../actions/employee-actions'

const JobApplicationDetail = (props) => {

  const client = useQueryClient();

  let id = props?.applicationId

  const jobId = props?.jobId;

  const FormRef = useRef(null);

  const [applicationData, setApplicationData] = useState({});

  const [emailValidationError, setEmailValidationError] = useState("");

  const [isChecked, setIsChecked] = useState(false);

  const [currentAttachmentData, setCurrentAttachmentData] = useState(null);

  const [phoneError, setPhoneError] = useState("");

  const { data: getVerifyEmployeeEmail } = useVerifyEmployeeEmail(
    applicationData?.email_address || ""
  );

  const {
    data: getApplicationID,
    isLoading: loadingGetApplicationIDFn,
    refetch: refetchApplication,
  } = useGetApplicationByID(id);


  const {
    data: getInterviewStatusData,
    isLoading: loadingGetInterviewStatusFn,
    refetch: refetchInterviewStatus,
  } = useGetInterviewStatus();

  const {
    mutate: postApplicationFn,
    isLoading: loadingPostApplicationFn,
  } = useApplicationPostFn();

  const {
    mutate: patchApplicationFn,
    isLoading: loadingApplicationFn,
  } = useApplicationUpdateFn();

  const {
    mutate: postInterViewStatusFn,
    isLoading: loadingPostInterViewStatusFn,
  } = useInterViewStatusPostFn();


  const submitForm = () => {
    let FormValidity = FormRef.current.reportValidity();

    // Check if phone number is valid
    const startsWithZero = /^0/;
    const tenDigits = /^0\d{10}$/;
    if (!startsWithZero.test(applicationData.mobile_number) || !tenDigits.test(applicationData.mobile_number)) {
      setPhoneError("Mobile Number Format is: 07800123456");
      return;
    }

    if (FormValidity === true) {
      if (id) {
        updateApplicationHandeler();
      }
      else {
        postApplicationHandeler();
      }
    }
  }
  const postInterViewStatusHandeler = (id) => {
    let userData = {
      job_applicantion: id,
      interview_status_id: 1,
      interview_status_name: 'Application Received',
      interviewer_name: props.interviewerName,
      interviewer_date: new Date().toISOString().split('T')[0],
    }

    postInterViewStatusFn(
      userData,
      {
        onSuccess: (response) => {
          // client.invalidateQueries(['get-application-details-by-jobid']);
        },
      }
    );
  }

  const postApplicationHandeler = () => {

    let userData = {
      //attachment: employeeVisaData.attachment,
      resume: applicationData.resume,
      address: applicationData.address,
      date_of_birth: applicationData.date_of_birth,
      candidate_first_name: applicationData.candidate_first_name,
      candidate_last_name: applicationData.candidate_last_name,
      job_id: jobId,
      gender: applicationData.gender,
      mobile_number: applicationData.mobile_number,
      email_address: applicationData.email_address,
      sponsorship_required: applicationData.sponsorship_required || false,
      notes: applicationData.notes,
      interview_status: 1,
      status_notes: applicationData.status_notes
    }

    postApplicationFn(
      userData,
      {
        onSuccess: (response) => {
          toast.success('Application Added', TostifyConfig);
          client.invalidateQueries(['get-application-details-by-jobid']);
          props.setCardView({ application: true });
          postInterViewStatusHandeler(response?.data?.id);
        },
      }
    );
  }


  const updateApplicationHandeler = () => {

    let userData = {
      resume: applicationData.resume,
      address: applicationData.address,
      date_of_birth: applicationData.date_of_birth,
      candidate_first_name: applicationData.candidate_first_name,
      candidate_last_name: applicationData.candidate_last_name,
      job_id: jobId,
      gender: applicationData.gender,
      mobile_number: applicationData.mobile_number,
      email_address: applicationData.email_address,
      sponsorship_required: applicationData.sponsorship_required || false,
      notes: applicationData.notes,
      status_notes: applicationData.status_notes
    }

    if (applicationData?.resume?.name) {
      userData['resume'] = applicationData.resume
    }

    patchApplicationFn(
      { id, userData },
      {
        onSuccess: (response) => {
          toast.success('Application Details Updated', TostifyConfig);
          client.invalidateQueries(['get-application-details-by-jobid']);
          props.setCardView({ application: true });
        },
      }
    );
  }

  const handlePhoneInputChange = (e) => {
    const { name, value } = e.target;

    // Remove leading +44 from the existing value
    let newValue = value.replace(/^\+44/, '');

    // Allow only numbers and the backspace key
    const onlyNumbers = /^[0-9\b]+$/;
    if (newValue !== "" && !onlyNumbers.test(newValue)) {
      // Display an error message or handle invalid input
      setPhoneError("Phone number must contain only numbers");
      return;
    }

    // Check if the number starts with zero and has exactly 10 digits after it
    const startsWithZero = /^0/;
    const tenDigits = /^0\d{0,9}$/;

    // Allow backspace and exactly 10 digits after zero
    if (newValue !== "" && !onlyNumbers.test(newValue) && (!startsWithZero.test(newValue) || !tenDigits.test(newValue))) {
      // Display an error message or handle invalid input

      setPhoneError("Mobile Number Format is: 07800123456");
      return;
    }

    setPhoneError("");
    setApplicationData({ ...applicationData, [name]: newValue });
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (event.target.type === "checkbox") {
      setApplicationData({
        ...applicationData,
        [name]: event.target.checked,
      });
      setIsChecked(event.target.checked);
    }
    else if (event.target.type === "file") {
      setApplicationData({
        ...applicationData,
        [name]: event.target.files[0], // Capture the selected file object
      });
      setCurrentAttachmentData(event.target.files[0].name);

    }
    else {
      setApplicationData({
        ...applicationData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    if (id) {
      refetchApplication();
    }

    if (getApplicationID) {
      setApplicationData(getApplicationID);
      setIsChecked(getApplicationID.sponsorship_required)
      setCurrentAttachmentData(extractFileName(getApplicationID?.resume));
      //setCurrentAttachmentData(extractFileName(getApplicationID.attachment));
    }
  }, [getApplicationID])


  useEffect(() => {
    if (getVerifyEmployeeEmail?.[0]?.work_email) {
      setEmailValidationError("Email already exists");
    } else {
      setEmailValidationError("");
    }
  }, [getVerifyEmployeeEmail]);

  const SelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;

    setApplicationData({
      ...applicationData,
      [selectName]: selectValue,
    });
  };

  return (
    <Fragment>
      <div>
        <div className='col-xl-12 col-xxl-12'>
          <Card className="az-card">
            <Card.Header>
              <h3>Candidates</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingGetApplicationIDFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-lg-6 mb-2'>
                        <label className="text-label">First name<span className="required">*</span></label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="candidate_first_name"
                          onChange={handleInputChange}
                          value={applicationData.candidate_first_name}
                          required />
                      </Col>
                      <Col className='col-lg-6 mb-2'>
                        <label className="text-label">Last name<span className="required">*</span></label>
                        <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="candidate_last_name"
                          onChange={handleInputChange}
                          value={applicationData.candidate_last_name}
                          required />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6 mb-2'>
                        <label>Gender<span className="required">*</span></label>
                        <Form.Select
                          name="gender"
                          className='form-control'
                          onChange={SelectChangeHandler}
                          value={applicationData.gender}
                          required
                        >
                          <option value="">Select Gender</option>
                          {Gender?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col className='col-md-6 mb-2'>
                        <label>Email<span className="required">*</span></label>
                        <Form.Control
                          type="email"
                          placeholder="Type Text"
                          name="email_address"
                          onChange={handleInputChange}
                          value={applicationData.email_address}
                          pattern="^\S+@\S+\.[a-zA-Z]{2,}$"
                          required
                        />
                        {emailValidationError && (
                          <div className="text-danger">{emailValidationError}</div>
                        )}
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid email address.
                        </Form.Control.Feedback>

                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6 mb-2'>
                        <label>Date of Birth</label>
                        <Form.Control
                          type="date"
                          placeholder="Type Text"
                          name="date_of_birth"
                          onChange={handleInputChange}
                          value={applicationData.date_of_birth}
                        />
                      </Col>
                      <Col className='col-md-6 mb-2'>
                        <div
                          className={`form-group mb-3`}
                        >
                          <label className="text-label">Mobile Number<span className="required">*</span></label>
                          <div className="input-group">
                            <span className="input-group-text ">
                              <img src="https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_United_Kingdom.png" Style="width: 30px; margin-right: 8px;" />
                              +44
                              {/* <i className="fa fa-user" />{" "} */}
                            </span>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="07800123456"
                              name="mobile_number"
                              onChange={handlePhoneInputChange}
                              value={applicationData.mobile_number}
                              required
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {phoneError && phoneError}
                            </div>

                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            />
                          </div>
                        </div>
                        {/* <Form.Control
                          type="text"
                          placeholder="Type Text"
                          name="mobile_number"
                          onChange={handleInputChange}
                          value={applicationData.mobile_number}
                          required
                        /> */}
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6 mb-2'>
                        <label>Address<span className="required">*</span></label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="mb-3"
                          name="address"
                          onChange={handleInputChange}
                          value={applicationData.address}
                          required
                        />
                      </Col>
                      <Col className='col-md-6 mb-2'>
                        <label> Notes</label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="mb-3"
                          name="notes"
                          onChange={handleInputChange}
                          value={applicationData.notes}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      {/* <Col className='col-md-6 mb-2' Style="display:none;">
                        <label>Interview Status<span className="required">*</span></label>
                        <Form.Select
                          name="interview_status"
                          className="mb-4 form-control"
                          value={applicationData.interview_status}
                          onChange={SelectChangeHandler}
                          defaultValue={1}
                        >
                          <option value="">Select Status</option>
                          {INTERVIEW_STATUS?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col>
                        <label>Status notes</label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="mb-3"
                          name="status_notes"
                          onChange={handleInputChange}
                          value={applicationData.status_notes}
                        />
                      </Col> */}
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className="col-md-6 mb-2">
                        <label
                          className="form-check-label me-3"
                          htmlFor="sponsorship_required"
                        >
                          Is Sponsored
                        </label>
                        {/* <div className="form-check custom-checkbox mt-5"> */}
                        <input
                          type="checkbox"
                          name="sponsorship_required"
                          className="form-check-input"
                          id="sponsorship_required"
                          checked={isChecked}
                          onChange={handleInputChange}
                          value={applicationData.sponsorship_required}
                        />
                        {/* </div> */}
                      </Col>
                      <Col>
                        <label>Resume</label>
                        <Form.Control
                          type="file"
                          placeholder="Type Text"
                          name="resume"
                          onChange={handleInputChange}
                        />
                        <Row className='col-md-6 mt-3'>
                          <label>Selected File</label>
                          <label>{currentAttachmentData}</label>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mt-2'>
                    <Row>
                      <Col>
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              props.setCardView({ application: true });
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="primary btn-sm"
                            onClick={submitForm}
                            disabled={(loadingGetApplicationIDFn && id) ||
                              loadingPostApplicationFn ||
                              loadingApplicationFn ||
                              getVerifyEmployeeEmail?.[0]?.work_email}
                          >
                            {(loadingPostApplicationFn || loadingApplicationFn) ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="me-2"
                                />
                                Loading...
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>
        </div>
      </div>
    </Fragment>
  )
}

export default JobApplicationDetail;