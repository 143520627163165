import React, { Fragment, } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import {
    useEmployeePassPortUpdateFn,
    useEmployeePassPortPostFn,
    useGetEmployeePassPortByID,
    useCountryofIssueFn,
    useNationalityFn
} from '../../../actions/employee-passport-actions';
import { useState } from 'react'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { extractFileName } from '../../../utils/ComponentUtilse';
import { TostifyConfig } from "../../../utils/Constance";
import { toast } from "react-toastify";
import { useQueryClient } from '@tanstack/react-query';
import LoadingOverlay from 'react-loading-overlay';


function EmployeePassport(props) {

    const client = useQueryClient();

    let id = props?.passportId;

    const employee_uuid = props?.employee_uuid;

    const FormRef = useRef(null);

    const [employeePassPortData, setEmployeePassPortData] = useState({});

    const [isChecked, setIsChecked] = useState(false);

    const [currentAttachmentData, setCurrentAttachmentData] = useState(null);

    const { data: getCountryofIssue, isLoading: loadingCountryofIssue } = useCountryofIssueFn();

    const { data: getNationality, isLoading: loadingNationality } = useNationalityFn();

    const employeeSelectChangeHandler = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectName = e.target.name;
        const selectValue = e.target.options[selectedIndex].value;
        const selectedOptionLabel = e.target.options[selectedIndex].label;
        setEmployeePassPortData({
            ...employeePassPortData,
            [selectName]: selectValue,
        });
    };


    const {
        data: getEmployeePassPortID,
        isLoading: loadingGetEmployeePassPortIDFn,
        refetch: refetchEmployeePassPort,
    } = useGetEmployeePassPortByID(id);

    const {
        mutate: postEmployeePassPortFn,
        isLoading: loadingPostEmployeePassPortFn,
    } = useEmployeePassPortPostFn();

    const {
        mutate: patchEmployeePassPortFn,
        isLoading: loadingEmployeePassPortFn,
    } = useEmployeePassPortUpdateFn();


    const submitForm = () => {
        let FormValidity = FormRef.current.reportValidity();

        if (FormValidity === true) {
            if (id) {
                updateEmployeePassPortHandeler();
            }
            else {
                postEmployeePassPortHandeler();
            }
        }
    }

    const postEmployeePassPortHandeler = () => {

        let userData = {
            attachment: employeePassPortData.attachment,
            issue_date: employeePassPortData.issue_date,
            expiration_date: employeePassPortData.expiration_date,
            nationality: employeePassPortData.nationality,
            country_of_issue: employeePassPortData.country_of_issue,
            place_of_birth: employeePassPortData.place_of_birth,
            passport_number: employeePassPortData.passport_number,
            issuing_authority: employeePassPortData.issuing_authority,
            current_active: employeePassPortData.current_active,
            employee_employee_uuid: employee_uuid
        }

        postEmployeePassPortFn(
            userData,
            {
                onSuccess: (response) => {
                    toast.success('Employee Passport Added', TostifyConfig);
                    client.invalidateQueries(['get-employee-passport-by-uuid']);
                    client.invalidateQueries(['get-pending-action-by-uuid']);
                    props.setCardView({ passport: true });
                },
            }
        );
    }


    const updateEmployeePassPortHandeler = () => {

        let userData = {
            //attachment: employeePassPortData.attachment,
            issue_date: employeePassPortData.issue_date,
            expiration_date: employeePassPortData.expiration_date,
            nationality: employeePassPortData.nationality,
            country_of_issue: employeePassPortData.country_of_issue,
            place_of_birth: employeePassPortData.place_of_birth,
            passport_number: employeePassPortData.passport_number,
            issuing_authority: employeePassPortData.issuing_authority,
            current_active: employeePassPortData.current_active,
            employee_employee_uuid: employee_uuid
        }

        if (employeePassPortData?.attachment?.name) {
            userData['attachment'] = employeePassPortData.attachment
        }

        patchEmployeePassPortFn(
            { id, userData },
            {
                onSuccess: (response) => {
                    toast.success('Employee Passport Updated', TostifyConfig);
                    client.invalidateQueries(['get-employee-passport-by-uuid']);
                    props.setCardView({ passport: true });
                },
            }
        );
    }

    // const handleCheckboxChange = (event) => {
    //     setIsChecked(event.target.checked);
    // }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (event.target.type === "file") {
            setEmployeePassPortData({
                ...employeePassPortData,
                [name]: event.target.files[0], // Capture the selected file object
            });
            setCurrentAttachmentData(event.target.files[0].name);
        }
        else if (event.target.type === "checkbox") {
            setEmployeePassPortData({
                ...employeePassPortData,
                [name]: event.target.checked,
            });
            setIsChecked(event.target.checked);
        }
        else {
            setEmployeePassPortData({
                ...employeePassPortData,
                [name]: value,
            });
        }
    };

    useEffect(() => {
        if (id) {
            refetchEmployeePassPort();
        }

        if (getEmployeePassPortID) {
            setEmployeePassPortData(getEmployeePassPortID);
            setCurrentAttachmentData(extractFileName(getEmployeePassPortID.attachment));
            setIsChecked(getEmployeePassPortID.current_active)
        }
    }, [getEmployeePassPortID, refetchEmployeePassPort, id])

    return (
        <Fragment>
            <div>
                <div className='col-xl-12 col-xxl-12'>
                    <Card className="az-card">
                        <Card.Header>
                            <h3>Employee Passport</h3>
                        </Card.Header>
                        <LoadingOverlay
                            active={loadingGetEmployeePassPortIDFn && id }
                            spinner
                            text='Fetching...'
                            className="custom-overlay"
                        >
                            <Card.Body>
                                <form ref={FormRef}>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-lg-6 mb-2'>
                                                <label className="text-label">Passport Number<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="passport_number"
                                                    onChange={handleInputChange}
                                                    value={employeePassPortData.passport_number}
                                                    required />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Country Of Issue<span className="required">*</span></label>
                                                <Form.Select
                                                    name="country_of_issue"
                                                    value={employeePassPortData.country_of_issue}
                                                    onChange={employeeSelectChangeHandler}
                                                    required
                                                    className='form-control'
                                                //disabled={loadingWorkLocation}
                                                >
                                                    <option value="">Select Country Of Issue</option>
                                                    {getCountryofIssue?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.country_of_issue}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            {/* <Col className='col-md-6 mb-2'>
                                            <label className="text-label">Country Of Issue<span className="required">*</span></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Type Text"
                                                name="country_of_issue"
                                                onChange={handleInputChange}
                                                value={employeePassPortData.country_of_issue}
                                                required />
                                        </Col> */}
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Issued Date<span className="required">*</span></label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Type Text"
                                                    name="issue_date"
                                                    onChange={handleInputChange}
                                                    value={employeePassPortData.issue_date}
                                                    required />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Expiration Date<span className="required">*</span></label>
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Type Text"
                                                    name="expiration_date"
                                                    onChange={handleInputChange}
                                                    value={employeePassPortData.expiration_date}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Issuing Authority<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="issuing_authority"
                                                    onChange={handleInputChange}
                                                    value={employeePassPortData.issuing_authority}
                                                    required />
                                            </Col>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Place Of Birth<span className="required">*</span></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Type Text"
                                                    name="place_of_birth"
                                                    onChange={handleInputChange}
                                                    value={employeePassPortData.place_of_birth}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Nationality<span className="required">*</span></label>
                                                <Form.Select
                                                    name="nationality"
                                                    value={employeePassPortData.nationality}
                                                    onChange={employeeSelectChangeHandler}
                                                    required
                                                    className='form-control'
                                                //disabled={loadingWorkLocation}
                                                >
                                                    <option value="">Select Nationality</option>
                                                    {getNationality?.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.nationality}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            {/* <Col className='col-md-6 mb-2'>
                                            <label>Nationality<span className="required">*</span></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Type Text"
                                                name="nationality"
                                                onChange={handleInputChange}
                                                value={employeePassPortData.nationality}
                                                required />
                                        </Col> */}

                                            <Col className='col-md-6 mb-2'>
                                                <div className="form-check custom-checkbox mt-5">
                                                    <input
                                                        type="checkbox"
                                                        name="current_active"
                                                        className="form-check-input"
                                                        id="current_active"
                                                        checked={isChecked}
                                                        onChange={handleInputChange}
                                                        value={employeePassPortData.current_active}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="current_active"
                                                    >
                                                        <b>Current Active</b>
                                                    </label>
                                                </div>

                                                {/* <input
                                                type='checkbox'
                                                name="current_active"
                                                checked={isChecked}
                                                onChange={handleInputChange}
                                            />
                                            <label>Current Active</label> */}
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Row>
                                            <Col className='col-md-6 mb-2'>
                                                <label>Attachment</label>
                                                <Form.Control
                                                    type="file"
                                                    placeholder="Type Text"
                                                    name="attachment"
                                                    onChange={handleInputChange}
                                                />
                                                <Row>
                                                    <label className='mt-3'>Selected File</label>
                                                    <label>{currentAttachmentData}</label>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group className='mt-2'>
                                        <Row>
                                            <Col>
                                                <hr />
                                                <div
                                                    style={{ display: "flex", justifyContent: "center" }}
                                                >
                                                    <Button
                                                        variant="danger light btn-sm"
                                                        onClick={() => {
                                                            props.setCardView({ passport: true });
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button
                                                        variant="primary btn-sm"
                                                        onClick={submitForm}
                                                        disabled={(loadingGetEmployeePassPortIDFn && id) || loadingPostEmployeePassPortFn || loadingEmployeePassPortFn}
                                                    >
                                                        {(loadingPostEmployeePassPortFn || loadingEmployeePassPortFn) ? (
                                                            <>
                                                                <Spinner
                                                                    animation="border"
                                                                    size="sm"
                                                                    className="me-2"
                                                                />
                                                                Loading...
                                                            </>
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </form>
                            </Card.Body>
                        </LoadingOverlay>
                    </Card>

                </div>
            </div>
        </Fragment>
    )
}

export default EmployeePassport