
import { NAVTOGGLE } from '../actions/AuthActions';

let initial = false;  

if ( window.innerWidth >= 768 && window.innerWidth < 1300 ){
    initial = true;
}


function toggleMenu(state = initial, action) {
    if (action.type === NAVTOGGLE) {          
        return action.payload;
    }
    return state;
}

export {toggleMenu};