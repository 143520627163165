import React, { Fragment, } from 'react'
import DatePicker from 'react-datepicker'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'
import { useRef } from 'react'
import { useLocation, } from 'react-router-dom/dist'
import { useGetEmployeeList } from '../../actions/employee-actions';
import { useState } from 'react'
import { useCreateTask, useGetTaskDetails, useTaskPriorityList, useTaskUpdate } from '../../actions/task-action'
import { useEffect } from 'react'
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import PageTitle from '../layouts/PageTitle'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";
import { TASK_STATUS } from '../../utils/Constance'
import LoadingOverlay from 'react-loading-overlay';


function AddTask() {

  const navigate = useNavigate();

  const FormRef = useRef(null);

  const location = useLocation()

  let id = location?.state?.rowId

  const { data: getAllEmployee } = useGetEmployeeList()
  const { data: getAllPriority } = useTaskPriorityList()

  const [employeeList, setEmployeeList] = useState([])
  const [priorityList, setPriorityList] = useState([])
  const { data: taskDetails, isLoading: loadingNewTaskFn, refetch: taskDetailsRefetch } = useGetTaskDetails(location?.state?.rowId)

  const { mutate: postNewTask, isLoading: loadingpostNewTaskFn, } = useCreateTask()
  const { mutate: patchTask, isLoading: loadingpatchTaskInfoFn } = useTaskUpdate()

  const priorityOption = [
    {
      name: "low",
      id: 3
    },
    {
      name: "Medium",
      id: 2
    },
    {
      name: "High",
      id: 1
    },
  ]

  const [taskData, setTaskData] = useState({})

  const [taskPriorityList, setTaskPriorityList] = useState([])

  useEffect(() => {
    if (getAllPriority) {
      setTaskPriorityList(getAllPriority);
    }
  }, [getAllPriority]);

  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();

    if (FormValidity === true) {
      if (id) {
        updateTaskHandeler();
      }
      else {
        postTaskHandeler();
      }
    }
  }

  const postTaskHandeler = () => {
    try {
      let taskdata = {
        reporter: taskData.reporter,
        assignee: taskData.assignee,
        start_date: taskData.start_date,
        end_date: taskData.end_date,
        title: taskData.title,
        description: taskData.description,
        original_estimate: taskData.original_estimate,
        task_status: taskData.task_status,
        priority: parseInt(taskData.priority)

      }
      postNewTask(taskdata, {
        onSuccess: (response) => {
          toast.success("Task Added", TostifyConfig);
          navigate("/list-task/")
        }
      })
    } catch (error) {
      console.error(error);
    }

  }

  const updateTaskHandeler = () => {
    let taskdata = {
      reporter: taskData.reporter,
      assignee: taskData.assignee,
      start_date: taskData.start_date,
      end_date: taskData.end_date,
      title: taskData.title,
      description: taskData.description,
      original_estimate: taskData.original_estimate,
      task_status: taskData.task_status,
      priority: parseInt(taskData.priority)
    }
    patchTask({ id, taskdata }, {
      onSuccess: (response) => {
        toast.success("Task  Updated", TostifyConfig);
        navigate("/list-task/");
      }
    })
  }

  const SelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;
    const selectedOptionLabel = e.target.options[selectedIndex].label;
    setTaskData({
      ...taskData,
      [selectName]: selectValue,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTaskData({
      ...taskData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (id) {
      taskDetailsRefetch()
    }

    if (taskDetails) {
      setTaskData(taskDetails)
    }
  }, [taskDetails, taskDetailsRefetch, id])

  return (
    <Fragment>
      <PageTitle activeMenu="Settings" motherMenu="Home" />
      <div>
        <div className='col-xl-12 col-xxl-12'>
          <Card className="az-card">
            <Card.Header>
              <h3>{id ? "Edit Task" : "New Task"}</h3>
            </Card.Header>
            <LoadingOverlay
              active={loadingNewTaskFn && id}
              spinner
              text='Fetching...'
              className="custom-overlay"
            >
              <Card.Body>
                <form ref={FormRef}>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6 my-2'>
                        <label>Task Name <span className="required">*</span></label>
                        <Form.Control
                          type="text"
                          name='title'
                          placeholder="Task Name"
                          value={taskData?.title}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                      <Col className='col-md-6'>
                        <label>Priority <span className="required">*</span></label>
                        <Form.Select
                          name='priority'
                          className="form-control"
                          value={taskData?.priority}
                          onChange={SelectChangeHandler}
                          required
                        >
                          <option value="">Select Priority</option>
                          {priorityOption?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group >
                  <Form.Group className='mb-3' >
                    <Row>
                      <Col className='col-md-6'>
                        <label>Reporter <span className="required">*</span></label>
                        <Form.Select
                          name='reporter'
                          className="form-control"
                          value={taskData?.reporter}
                          onChange={SelectChangeHandler}
                          required
                        >
                          <option value="">Select Reporter</option>
                          {getAllEmployee?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.first_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      <Col className='col-md-6'>
                        <label>Assingnee<span className="required">*</span></label>
                        <Form.Select
                          name='assignee'
                          className="form-control"
                          value={taskData?.assignee}
                          onChange={SelectChangeHandler}
                          required
                        >
                          <option value="">Select Assingnee</option>
                          {getAllEmployee?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.first_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-lg-6 mb-2'>
                        <label>Start Date <span className="required">*</span></label>
                        <Form.Control
                          type="date"
                          placeholder="Type Text"
                          name="start_date"
                          value={taskData?.start_date}
                          onChange={handleInputChange}
                          required
                        />
                        <div>
                        </div>
                      </Col>
                      <Col className='col-lg-6 mb-2'>
                        <label>End Date <span className="required">*</span></label>
                        <Form.Control
                          type="date"
                          name="end_date"
                          value={taskData?.end_date}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-6'>
                        <label>Original Estimate</label>
                        <Form.Control
                          type="number"
                          name='original_estimate'
                          placeholder="Original Estimate"
                          value={taskData?.original_estimate}
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col className='col-md-6'>
                        <label>Task Status <span className="required">*</span></label>
                        <Form.Select
                          name='task_status'
                          className="form-control"
                          value={taskData?.task_status}
                          onChange={SelectChangeHandler}
                          required
                        >
                          <option value="">Select Status</option>
                          {TASK_STATUS?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className='mb-3'>
                    <Row>
                      <Col className='col-md-12'>
                        <label>Task Information <span className="required">*</span></label>
                        <Form.Control
                          Style="height:80px"
                          as="textarea"
                          rows={5}
                          name='description'
                          placeholder="Task Notes"
                          value={taskData?.description}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className='mt-2'>
                    <Row>
                      <Col className="col-md-12">
                        <hr />
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="danger light btn-sm"
                            onClick={() => {
                              navigate("/list-task/");
                            }}
                          >
                            Cancel
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="primary btn-sm"
                            onClick={handleSubmit}
                            disabled={loadingNewTaskFn && id || loadingpostNewTaskFn || loadingpatchTaskInfoFn}
                          >{(loadingpostNewTaskFn || loadingpatchTaskInfoFn) ? (
                            <>
                              <Spinner animation="border" size="sm" className="me-2" />
                              Loading...
                            </>
                          ) : (
                            'Submit'
                          )}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </form>
              </Card.Body>
            </LoadingOverlay>
          </Card>

        </div>
      </div>
    </Fragment>
  )
}

export default AddTask