import { useGetLeaveSummary } from "../../actions/leave-actions";

const LeaveSummary = (props) => {

  const { data: getLeaveSummary, isLoading: loadingLeaveSummary } = useGetLeaveSummary(props.employeeUUID);

  return (
    <>
    <div className="row">
      {getLeaveSummary?.map((item, index) => (
        <div className="col-lg-3" key={index}>
          <div className="card">
            <div className="card-body" style={{ padding: '15px' }}>
              <div className="profile-statistics" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div className="text-center" style={{display: 'contents'}}>
                  <table>
                  <tr>
                      <td className="px-4" style={{color: "#3f7be7"}}><strong>Total</strong></td>
                      <td className="px-4" style={{color: "#d18e00"}}><strong>Taken</strong></td>
                    </tr>
                    <tr>
                      <td className="px-4" style={{color: "#3f7be7"}}><strong>{item.total_days}</strong></td>
                      <td className="px-4" style={{color: "#d18e00"}}><strong>{item.total_taken}</strong></td>
                    </tr>
                   
                  </table>
                  <hr style={{width: "100%"}}/>
                  <span><strong>{item.leave_type}</strong></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
  )

}

export default LeaveSummary