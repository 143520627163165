import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

const createNewNationality = async (data) => {
    let config = {
      method: "post",
      url: "employee/api/v1/employee_nationality/",
      headers: {
        "Content-Type": "application/json",
      },
      data:data
    };
    
    return await axios.create().request(config)
  };
  export const useCreateNationality = () => {
    return useMutation(["announcement-register"], createNewNationality);
  };

  
const getAllNationality = async () => {
  let config = {
    method: "get",
    url: "employee/api/v1/employee_nationality/",
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const response = await axios.request(config);
  return response?.data;
};
export const useGetNationalityList = () => {
  return useQuery(["get-all-nationality"], getAllNationality);
};


const getNationalityDetail = async (query) => {
  let [, id] = query.queryKey;
  let config = {
    method: "get",
    url: `employee/api/v1/employee_nationality/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  
  const response = await axios.request(config);
  return response?.data;
};
export const useGetNationalityDetail = (id) => {
  return useQuery(["get-nationality-by-id", id], getNationalityDetail,{enabled: id?true:false});
};

const nationalityUpdate = async (data) => {
  let {id, nationality} = data;
  let config = {
    method: "patch",
    url: `employee/api/v1/employee_nationality/${id}/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: nationality,
  };
  return await axios.request(config);
};
export const useNationalityUpdate = () => {
  return useMutation(["nationality-update"], nationalityUpdate);
};


//API DELETE METHOD

const nationalityDeleteById = async (id) => {
  try {
    const response = await axios.delete(`/employee/api/v1/employee_nationality/${id}/`);
    return response.data;
  } catch (error) {
    // Handle error or throw it again
    throw error;
  }
};

export const useNationalityDeleteById = () => {
  return useMutation(["nationality-delete"], nationalityDeleteById);
};

