import React, { useMemo, useEffect, useState } from 'react';
import PageTitle from '../layouts/PageTitle';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { GlobalFilter } from '../components/table/FilteringTable/GlobalFilter';
import '../components/table/FilteringTable/filtering.css';
import { useGetTeamList } from '../../actions/team-action';
import { ColumnFilter } from '../components/table/FilteringTable/ColumnFilter';
import { Link } from 'react-router-dom';
import { Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IsValidPermissions } from '../../services/AuthService';
import DataTable from '../pages/commonPages/DataTable';
import { deleteConfirmation } from '../../utils/ComponentUtilse';
import { useTeamsDeleteById } from '../../actions/team-action';
import CustomTooltip from '../../utils/CommonUtils';

export const TeamListView = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([])

    const { data: getAllTeam, isLoading: loadingGetTeamFn, refetch: refetchTeams } = useGetTeamList()

    const { mutate: deleteTeamInfo, isLoading: loadingdeleteTeamFn } = useTeamsDeleteById();

    useEffect(() => {
        if (getAllTeam) {

            setData(getAllTeam);
        }
    }, [getAllTeam]);

    const columns = useMemo(
        () => [
            {
                Header: " Team Name",
                Footer: " Team Name",
                accessor: "team_name",
                Filter: ColumnFilter,
            },
            {
                Header: "Team short Name",
                Footer: "Team short Name",
                accessor: "team_short_name",
                Filter: ColumnFilter,
            },
            {
                Header: "Team Manager",
                Footer: "Team Manager",
                accessor: "team_manager",
                Filter: ColumnFilter,
            },
            {
                Header: "Company ",
                Footer: "Company ",
                accessor: "company",
                Filter: ColumnFilter,
            },
            {
                Header: "Project",
                Footer: "Project",
                accessor: "project",
                Filter: ColumnFilter,
            },
            {
                Header: "Client",
                Footer: "Client",
                accessor: "client",
                Filter: ColumnFilter,
            },
            {
                Header: "Location",
                Footer: "Location",
                accessor: "location",
                Filter: ColumnFilter,
            },
            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => (
                    <>
                        <CustomTooltip id={`delete-${row.index}`} message="Delete">
                            <Button
                                className="shadow btn-xs sharp me-1"
                                variant="danger light"
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteConfirmation(row.original.id, `Team - ${row.original.team_name}`, deleteTeamInfo, refetchTeams)}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </CustomTooltip>&nbsp;

                        {IsValidPermissions('employee.change_employeeteams') ?
                            <CustomTooltip id={`edit-${row.index}`} message="Edit">
                                <Button
                                    className="shadow btn-xs sharp me-1"
                                    variant='primary light'
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleEdit(row.original.id)}
                                >
                                    <i className="fa fa-pencil-alt"></i>
                                </Button>
                            </CustomTooltip>
                            : null

                        }
                    </>
                )
            },
        ],
        []
    );



    const handleView = (id) => {

    }

    const handleEdit = (id) => {
        navigate('/add-team/', { state: { rowId: id } })
    }
    const tableInstance = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 }
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const { globalFilter, pageIndex } = state


    return (
        <>
            <PageTitle activeMenu="Team" motherMenu="Settings" />
            {IsValidPermissions('employee.view_employeeteams') &&
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title">Team</h4>
                        {IsValidPermissions('employee.add_employeeteams') && <div className="d-inline-flex">
                            <Link to={`/add-team/`}>
                                <Button className='me-2' variant='primary btn-sm'>
                                    <i class="fa fa-user-plus" aria-hidden="true"></i> &nbsp; Team
                                </Button>
                            </Link>
                        </div>}
                    </div>
                    <div className="card-body">
                        <DataTable
                            isLoading={loadingGetTeamFn}
                            columns={columns}
                            tableInstance={tableInstance}
                        />

                    </div>
                </div>
            }

            {(!loadingGetTeamFn && !IsValidPermissions('employee.view_employeeteams')) &&
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <Alert variant="warning" show={true}>

                                <svg
                                    viewBox='0 0 24 24'
                                    width='24'
                                    height='24'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='me-2'
                                >
                                    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                    <line x1='12' y1='9' x2='12' y2='13'></line>
                                    <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                </svg>

                                <strong>Info!  </strong> You don't have permission to view Teams.

                            </Alert>
                        </div>
                    </div>
                </div>
            }

        </>
    )

}
export default TeamListView;