import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import { Col, Button, Row, Form, Spinner, Alert } from "react-bootstrap";

import { IsValidPermissions } from "../../services/AuthService";
import { ServiceContext } from "../../context/ServiceContext";
import 'rc-time-picker/assets/index.css';
import LoadingOverlay from 'react-loading-overlay';
import { useGetLeaveBalance, useGetLeavesByUUID, usePostLeave } from "../../actions/leave-actions";
import { toast } from "react-toastify";
import { TostifyConfig } from "../../utils/Constance";
import { useNavigate } from "react-router-dom";
import LeaveSummary from "./LeaveSummary";


const AddLeave = () => {

  const navigate = useNavigate()

  const { isAdminUser, getEmployeeUUID } = useContext(ServiceContext);

  const FormRef = useRef(null);

  const [leaveBalanceData, setLeaveBalanceData] = useState({});

  const [leaveData, setLeaveData] = useState({
    policy_name: '',
    start_date: '',
    end_date: '',
    number_of_days: 0,
    part_of_day: false,
    reason: '',
    approved_status: 4,
    employee_employee_uuid: getEmployeeUUID
  });

  const { data: getLeaveBalanceInfo, isLoading: loadingLeaveBalance } = useGetLeaveBalance(getEmployeeUUID)

  const { mutate: postLeaveFn, isLoading: postLeaveLoading } = usePostLeave()


  const policySelectChangeHandler = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectName = e.target.name;
    const selectValue = e.target.options[selectedIndex].value;
    setLeaveBalanceData({
      ...leaveBalanceData,
      [selectName]: selectValue,
    });
    setLeaveData((prevState) => ({
      ...prevState,
      policy_name: Number(selectValue),
    }))
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLeaveData({
      ...leaveData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (leaveData.start_date && leaveData.end_date) {
      const startDate = new Date(leaveData.start_date);
      const endDate = new Date(leaveData.end_date);

      let daysCount = 0;

      while (startDate <= endDate) {
        if (startDate.getDay() !== 0 && startDate.getDay() !== 6) {
          daysCount++;
        }
        startDate.setDate(startDate.getDate() + 1);
      }
      setLeaveData({
        ...leaveData,
        number_of_days: daysCount,
      });

    }
  }, [leaveData.start_date, leaveData.end_date])

  const handleSubmit = () => {
    let FormValidity = FormRef.current.reportValidity();

    if (FormValidity === true) {
      postLeaveFn(
        leaveData,
        {
          onSuccess: (response) => {
            navigate('/leave-list/');
            toast.success('Leave requested', TostifyConfig);
          },
        }
      );
    }
  }
  return (
    <Fragment>
      <div>
        
        {(IsValidPermissions('leave_management.can_add_employee_leave_policy')) &&
          <>
          <LeaveSummary employeeUUID={getEmployeeUUID}/>
          
            <div className='col-xl-12 col-xxl-12'>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <i class="fa fa-newspaper font-18 align-middle me-2 text-primary"></i>
                    &nbsp; Add Leave
                  </h4>
                </div>
                <LoadingOverlay
                  active={postLeaveLoading}
                  spinner
                  text='Loading...'
                  className="custom-overlay"
                >

                  <div className="card-body">
                    <form ref={FormRef}>
                      <Form.Group>
                        <Row>
                          <Col className="col-lg">
                            <label className="mb-2">Leave Type<span className="required">*</span></label>
                            <Form.Select
                              name="policy_name"
                              className="form-control"
                              value={leaveBalanceData.leave_type_name}
                              onChange={policySelectChangeHandler}
                              disabled={loadingLeaveBalance}
                              required
                            >
                              <option value="">Select Leave Type</option>
                              {getLeaveBalanceInfo?.map((option) => (
                                <option key={option.id} value={option.leave_type}>
                                  {option.leave_type_name}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>

                          <Col className="col-md-6">
                            <label className="mb-2">Start Date<span className="required">*</span></label>
                            <Form.Control
                              type="date"
                              className="mb-3"
                              name="start_date"
                              onChange={handleInputChange}
                              value={leaveData.start_date}
                              required
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col className="col-lg">
                            <label>End Date<span className="required">*</span></label>
                            <Form.Control
                              type="date"
                              className="mb-3"
                              name="end_date"
                              onChange={handleInputChange}
                              value={leaveData.end_date}
                              required
                            />
                          </Col>
                          <Col className="col-md-6">
                            <label>Number of Days<span className="required">*</span></label>
                            <Form.Control
                              className="mb-0"
                              type="text"
                              name="number_of_days"
                              value={leaveData.number_of_days}
                              onChange={handleInputChange}
                              required
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-lg">
                            <label>Reason<span className="required">*</span></label>
                            <Form.Control
                              className="mb-0"
                              type="text"
                              name="reason"
                              onChange={handleInputChange}
                              value={leaveData.reason}
                              required
                            />
                          </Col>
                        </Row>
                        {/* row */}
                      </Form.Group>
                      <Form.Group className="mt-2">
                        <Row>
                          <Col className="col-md-12">
                            <hr />
                            <div
                              style={{ display: "flex", justifyContent: "center" }}
                            >
                              <Button
                                variant="danger light btn-sm"
                                onClick={() => {
                                  navigate("/leave-list");
                                }}
                              >
                                Cancel
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                variant="primary btn-sm"
                                onClick={handleSubmit}
                                disabled={postLeaveLoading || loadingLeaveBalance}
                              >{(postLeaveLoading || loadingLeaveBalance) ? (
                                <>
                                  <Spinner animation="border" size="sm" className="me-2" />
                                  Loading...
                                </>
                              ) : (
                                'Submit'
                              )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </form>
                  </div>
                </LoadingOverlay>
              </div>
            </div>
            </>
          }

          {(!IsValidPermissions('leave_management.can_add_employee_leave_policy')) &&
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <Alert variant="warning" show={true}>

                                <svg
                                    viewBox='0 0 24 24'
                                    width='24'
                                    height='24'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    fill='none'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='me-2'
                                >
                                    <path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path>
                                    <line x1='12' y1='9' x2='12' y2='13'></line>
                                    <line x1='12' y1='17' x2='12.01' y2='17'></line>
                                </svg>

                                <strong>Info!  </strong> You don't have permission to Apply Leaves.

                            </Alert>
                        </div>
                    </div>
                </div>
            } 
      </div>
    </Fragment>
  )
}

export default AddLeave